import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  calibOpMode: {
    backgroundColor: "black",
    margin: 3,
    borderColor: "lightgrey",
    borderWidth: 2,
    borderStyle: "solid",
    color: "whitesmoke",
  },
  simOpMode: {
    backgroundColor: "dimgray",
    margin: 3,
    borderColor: "lightgrey",
    borderWidth: 2,
    borderStyle: "solid",
    color: "whitesmoke",
  },
  queryOpMode: {
    margin: 3,
  },
});

export type OpMode = {
  num: number
  simOpMode: boolean,
  calibOpMode: boolean
}

export const OpmodeChip = (props: { opMode: OpMode }) => {
  const classes = useStyles(props);

  if (props.opMode.simOpMode) {
    return (
      <Chip
        className={classes.simOpMode}
        title={"Simulation Opmode"}
        label={props.opMode.num}
        size="small"
      />
    );
  }
  else if (props.opMode.calibOpMode) {
    return (
      <Chip
        className={classes.calibOpMode}
        title={"Calibration Opmode"}
        label={props.opMode.num}
        size="small"
      />
    );
  }
  else {
    return (
      <Chip
        className={classes.queryOpMode}
        title={"Query Opmode"}
        label={props.opMode.num}
        size="small"
      />
    );
  }


};
