import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { authState } from "src/auth";
import config from "src/config";
import "typeface-roboto";

const atlasdHttpLink = createHttpLink({
  uri: "http://localhost:42069/graphql",
  fetchOptions: {
    mode: "cors",
  },
});

const atlasdWsLink = new WebSocketLink({
  uri: "ws://localhost:42069/graphql",
  options: {
    // reconnect: true,
    connectionParams: async () => {
      const auth = await authState.header();
      return { headers: auth };
    },
  },
});

const httpLink = createHttpLink({
  uri: config.hasura.uri,
  credentials: "include",
});

const authLink = setContext(async (_, { headers }) => {
  const auth = await authState.header();

  return {
    headers: {
      ...headers,
      ...auth,
    },
  };
});

const wsLink = new WebSocketLink({
  uri: config.hasura.ws,
  options: {
    reconnect: true,
    connectionParams: async () => {
      const auth = await authState.header();
      return { headers: auth };
    },
  },
});

const transportSplitLink = authLink.concat(
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  ),
);

const atlasdTransportSplitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  atlasdWsLink,
  atlasdHttpLink,
);

const apiSplitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.name?.value.startsWith("Atlasd") === true
    );
  },
  atlasdTransportSplitLink,
  transportSplitLink,
);

export const GQLErrorEvent = "GQLErrorEvent";
export const GQLNetworkErrorEvent = "GQLNetworkErrorEvent";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const gqlErrorEvent = new CustomEvent(GQLErrorEvent, {
      detail: graphQLErrors,
    });
    window.dispatchEvent(gqlErrorEvent);
  }

  if (networkError) {
    const gqlNetworkErrorEvent = new CustomEvent(GQLNetworkErrorEvent, {
      detail: networkError,
    });
    window.dispatchEvent(gqlNetworkErrorEvent);
  }
});

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case "task_field_option":
        return `${object.task_id}-${object.field_option_id}`;
      case "task_media":
        return `${object.task_id}-${object.media_id}`;
      case "project_user":
        return `${object.project_id}-${object.user_id}`;
      default:
        return object.id as string;
    }
  },
});

export const GQLClient = new ApolloClient({
  cache: cache,
  defaultOptions: {},
  link: from([errorLink, apiSplitLink]),
});

export { GQLErrorEventHandler } from "./GQLErrorHandler";
