import { Grid, LinearProgress, Typography } from "@material-ui/core";
import { useEffect, useState, useRef } from "react";
import { FileHeader } from "./FileHeader";
import { azureProvider } from "src/auth/azureProvider";
import config from "src/config";

export type MediaResponseProps = {
  id?: string;
  url?: string;
};

type Media = {
  title: string;
  description: string;
};

export interface MediaFileUploadWithProgressProps {
  doUpload: boolean;
  file: File;
  onClose: (file: File) => void;
  onUpload: (
    file: File,
    response: MediaResponseProps,
    onComplete: () => void,
  ) => void;
}

export function MediaFileUploadWithProgress({
  doUpload,
  file,
  onClose,
  onUpload,
}: MediaFileUploadWithProgressProps) {
  const [progress, setProgress] = useState(0);
  const uploading = useRef(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    async function upload() {
      let tokenResponse = undefined;
      if (doUpload && !uploading.current) {
        uploading.current = true;
        if (azureProvider) {
          tokenResponse = (
            await azureProvider.getAccessToken({
              scopes: [config.auth.azure.data_recording_ad_scope],
            })
          ).accessToken;
        }
        try {
          const response = await uploadFile(tokenResponse, file, setProgress);
          onUpload(file, response, () => {
            setUploadCompleted(true);
          });
        } catch (error: any) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          }
        } finally {
          uploading.current = false;
        }
      }
    }
    upload();
  }, [doUpload, file, onUpload]);

  useEffect(() => {
    if (uploadCompleted) {
      onClose(file);
    }
  }, [uploadCompleted, onClose, file]);

  return (
    <Grid item>
      <FileHeader file={file} onClose={onClose} error={errorMessage !== ""} />
      <LinearProgress variant="determinate" value={progress} />
      {
      errorMessage &&
      <div>
        <Typography color="error">{errorMessage}</Typography>
      </div>
      }
    </Grid>
  );
}

function uploadFile(
  access_token: string | undefined,
  file: File,
  onProgress: (percentage: number) => void,
) {
  return new Promise<MediaResponseProps>((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", config.auth.azure.media_endpoint);
    if (access_token) {
      xhr.setRequestHeader("Authorization", `Bearer ${access_token}`);
    }
    xhr.onload = () => {
      const resp = JSON.parse(xhr.responseText);
      const mediaResponse: MediaResponseProps = {
        id: resp.id,
        url: resp.url,
      };
      res(mediaResponse);
    };

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentage));
      }
    };
    const formData = new FormData();
    const media: Media = {
      title: file.name,
      description: "",
    };
    formData.append("metadata", JSON.stringify(media));
    formData.append("payload", file);

    xhr.onerror = () => {
      const error = new Error(
        `Network error (Status: ${xhr.status})`,
      );
      rej(error);
    };

    xhr.send(formData);
  });
}
