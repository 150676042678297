const {
  REACT_APP_HASURA_URI,
  REACT_APP_HASURA_WS_URI,
  REACT_APP_HASURA_ADMIN_SECRET,
  REACT_APP_HASURA_ASGARD_ROLE,
  REACT_APP_HASURA_MIDGARD_ROLE,
  REACT_APP_AUTH_PROVIDER,
  REACT_APP_AZURE_AUTHORITY,
  REACT_APP_AZURE_CLIENT_ID,
  REACT_APP_ENV,
  REACT_APP_DATA_RECORDING_V3_MEDIA_ENDPOINT,
  REACT_APP_DATA_RECORDING_V3_AD_SCOPE,
} = process.env;

type APP_ENV = "dev" | "prod";

const config = {
  env: (REACT_APP_ENV || "dev") as APP_ENV,
  hasura: {
    uri: REACT_APP_HASURA_URI || "http://localhost:8080/v1/graphql",
    ws: REACT_APP_HASURA_WS_URI || "ws://localhost:8080/v1/graphql",
    admin_secret: REACT_APP_HASURA_ADMIN_SECRET,
    asgard: {
      role: REACT_APP_HASURA_ASGARD_ROLE,
      id: "CF6C1271-81CB-432C-8443-17ADC1238A66",
    },
    midgard: {
      role: REACT_APP_HASURA_MIDGARD_ROLE,
      id: "19F56A82-D5F8-4741-A569-ECADBBC442AA",
    },
  },
  auth: {
    provider: REACT_APP_AUTH_PROVIDER || "local",
    azure: {
      authority: REACT_APP_AZURE_AUTHORITY || "",
      client_id: REACT_APP_AZURE_CLIENT_ID || "",
      media_endpoint: REACT_APP_DATA_RECORDING_V3_MEDIA_ENDPOINT || "",
      data_recording_ad_scope: REACT_APP_DATA_RECORDING_V3_AD_SCOPE || "",
    },
  },
  media_upload: {
    accepted: {
      images: [".png", ".jpg", ".gif"],
      videos: [".mp4"],
    },
    max_size: 30000 * 1024, // 30MB
    max_files: 3,
  },
};

export default config;
