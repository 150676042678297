import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Check as PassIcon, Close as FailIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  Engines,
  Integration_Test_Observations_Insert_Input,
  Integration_Test_Sequences as Sequences,
  Integration_Test_Steps as Steps,
  Media_Type_Enum,
  Order_By,
  Projects,
  useAllEnginesQuery,
  useAllIntegrationTestSequencesQuery,
  useInsertIntegrationTestObservanceMutation,
  useIntegrationTestSequenceQuery,
  useAllProjectsQuery,
} from "src/generated/asgard/graphql";
import { LanguageSelect } from "src/resources/Translation";
import { Resource } from "src/components/Resource";
import { useAuth } from "src/auth";
import { useNotification } from "src/Notification";
import { ArrowBack, Refresh } from "@material-ui/icons";

type ImageProps = {
  src: string;
  alt: string;
};

type ExpectationResults = {
  id: string;
  description: string;
  result: boolean;
  images: ImageProps[];
};

type InstructionResults = {
  id: string;
  description: string;
  step: DeepPartial<Steps>;
  results: ExpectationResults[];
  images: ImageProps[];
};

type IntegrationTestProps = {
  sequence?: { id: string };
  project?: { id: string };
  engine?: { id: string };
  onSuccess?: (id: string) => void;
};

const IntegrationTest = (props: IntegrationTestProps) => {
  const auth = useAuth();

  // Setup for project selector.
  const [selectedProject, setSelectedProject] =
    useState<DeepPartial<Projects> | null>(null);
  const [projectInput, setProjectInput] = useState("");
  const projectsQuery = useAllProjectsQuery({
    variables: {
      where: {
        // Only include projects actually have sequences attached.
        project_sequences: {},
      },
      order_by: [
        {
          customer: { codename: Order_By.Asc },
          deliverable: { name: Order_By.Asc },
          model: { codename: Order_By.Asc },
        },
      ],
    },
    fetchPolicy: "network-only",
  });
  const projectOptions = (projectsQuery.data?.projects ??
    []) as DeepPartial<Projects>[];
  useEffect(() => {
    if (props.project) {
      const selected =
        projectsQuery.data?.projects?.find(
          (project) => project.id === props.project!.id,
        ) ?? null;
      setSelectedProject(selected);
      setProjectInput(
        selected ? selected.project_name?.display_name ?? "unknown" : "",
      );
    }
  }, [props, projectsQuery]);

  // Setup for sequence selector.
  const [selectedSequence, setSelectedSequence] =
    useState<DeepPartial<Sequences> | null>(null);
  const [sequenceInput, setSequenceInput] = useState("");
  const sequencesQuery = useAllIntegrationTestSequencesQuery({
    variables: {
      order_by: [{ description: Order_By.Asc }],
      where: {
        project_sequences: {
          project_id: { _in: selectedProject?.id ? [selectedProject.id] : [] },
        },
      },
    },
    fetchPolicy: "network-only",
    skip: selectedProject === null,
  });
  const sequenceOptions = (sequencesQuery.data?.sequences ??
    []) as DeepPartial<Sequences>[];
  useEffect(() => {
    if (props.sequence) {
      const selected =
        sequencesQuery.data?.sequences?.find(
          (sequence) => sequence.id === props.sequence!.id,
        ) ?? null;
      setSelectedSequence(selected);
      setSequenceInput(selected?.description ?? "");
    }
  }, [props, sequencesQuery]);

  // Setup for engine selector.
  const [selectedEngine, setSelectedEngine] =
    useState<DeepPartial<Engines> | null>(null);
  const [engineInput, setEngineInput] = useState("");
  const enginesQuery = useAllEnginesQuery({
    variables: {
      order_by: [{ build_version: Order_By.Asc }],
      where: {
        engine_opmodes: {
          opmode: {
            opmode_projects: {
              project_id: {
                _in: selectedProject?.id ? [selectedProject.id] : [],
              },
            },
          },
        },
      },
    },
    fetchPolicy: "network-only",
    skip: selectedProject === null,
  });
  const engineOptions = (enginesQuery.data?.engines ??
    []) as DeepPartial<Engines>[];
  const getEngineLabel = (engine: DeepPartial<Engines>) => {
    const identifiers = [`Version ${engine.build_version}`];
    if (engine.azure_release_ref)
      identifiers.push(`Release ${engine.azure_release_ref}`);
    if (engine.build_number) identifiers.push(`${engine.build_number}`);
    return identifiers.join(" / ");
  };
  useEffect(() => {
    if (props.engine) {
      const selected =
        enginesQuery.data?.engines?.find(
          (engine) => engine.id === props.engine!.id,
        ) ?? null;
      setSelectedEngine(selected);
      setEngineInput(selected ? getEngineLabel(selected) : "");
    }
  }, [props, enginesQuery]);

  // Setup language selection.
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);

  // Use state for different "stages" of the test.
  const stages = ["Setup", "Test", "Results"];
  const [stageIndex, setStageIndex] = useState(0);

  // Use state for test results.
  const [results, setResults] = useState<InstructionResults[] | null>(null);
  const testPassed = useMemo(
    () =>
      results
        ?.reduce(
          (agg, item) => [...agg, ...item.results],
          [] as ExpectationResults[],
        )
        .reduce((agg, item) => agg && item.result, true),
    [results],
  );

  // Handle submission.
  const notification = useNotification();
  const [insertObservance] = useInsertIntegrationTestObservanceMutation();
  const submit = async (results: InstructionResults[]) => {
    const observationInputs: Integration_Test_Observations_Insert_Input[] = [];
    results?.forEach((step) => {
      step.results.forEach((expectation) =>
        observationInputs.push({
          expectation_id: expectation.id,
          step_id: step.id,
          result: expectation.result,
        }),
      );
    });
    try {
      const result = await (async () => {
        if (selectedSequence && selectedProject && observationInputs.length) {
          return await insertObservance({
            variables: {
              input: {
                user_id: auth.user.id,
                project_id: selectedProject?.id ?? "",
                engine_id: selectedEngine?.id ?? null,
                sequence_id: selectedSequence?.id ?? "",
                observations: {
                  data: observationInputs,
                },
              },
            },
          });
        }
      })();
      notification.create({
        severity: "success",
        title: `Test Submitted`,
        description: `Test Submitted`,
      });
      props.onSuccess && props.onSuccess(result?.data?.observance?.id ?? "");
    } catch (e) {}
  };

  return (
    <>
      <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }}>
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <Typography align="left" variant="h4">
              Integration Test
            </Typography>
          </Grid>
          <Grid item>
            <LanguageSelect
              onChange={(l) => setSelectedLanguage(l?.id ?? null)}
            />
          </Grid>
        </Grid>
      </div>
      <Stepper orientation="horizontal" activeStep={stageIndex}>
        {stages.map((stage) => (
          <Step key={stage}>
            <StepLabel>{stage}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ margin: 20 }}>
        {stages[stageIndex] === "Setup" && (
          <IntegrationTestColumnView
            left={
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    disabled={!!props.project}
                    options={projectOptions}
                    getOptionLabel={(project) =>
                      project.project_name?.display_name ?? "unknown"
                    }
                    getOptionSelected={(a, b) => a.id === b.id}
                    value={selectedProject}
                    inputValue={projectInput}
                    onInputChange={(_, nv) => setProjectInput(nv)}
                    onChange={(_event, nv) => {
                      setSelectedProject(nv ?? null);
                      if (!props.engine) setSelectedEngine(null);
                      if (!props.sequence) setSelectedSequence(null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Project"}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    disabled={!!props.sequence}
                    options={sequenceOptions}
                    getOptionLabel={(sequence) => `${sequence.description}`}
                    getOptionSelected={(a, b) => a.id === b.id}
                    value={selectedSequence}
                    inputValue={sequenceInput}
                    onInputChange={(_, nv) => setSequenceInput(nv)}
                    onChange={(_event, nv) => {
                      setSelectedSequence(nv ?? null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Sequence"}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    disabled={!!props.engine}
                    options={engineOptions}
                    getOptionLabel={getEngineLabel}
                    getOptionSelected={(a, b) => a.id === b.id}
                    groupBy={(engine) =>
                      engine.engine_opmodes
                        ?.filter((eo) => eo?.opmode?.number)
                        .map((eo) => `Opmode ${eo!.opmode!.number}`)
                        .join(", ") ?? "No Opmode"
                    }
                    value={selectedEngine}
                    inputValue={engineInput}
                    onInputChange={(_, nv) => setEngineInput(nv)}
                    onChange={(_event, nv) => {
                      setSelectedEngine(nv ?? null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Engine"}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </>
            }
            right={
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={
                    !selectedProject || !selectedSequence || !selectedEngine
                  }
                  onClick={() => setStageIndex((prev) => prev + 1)}
                >
                  Start Test
                </Button>
              </Grid>
            }
          />
        )}
        {stages[stageIndex] === "Test" && selectedSequence?.id && (
          <SequenceStepper
            sequence={{ id: selectedSequence.id }}
            language={selectedLanguage}
            onComplete={(values) => {
              setResults(values);
              submit(values);
              setStageIndex((prev) => prev + 1);
            }}
          />
        )}
        {stages[stageIndex] === "Results" && (
          <IntegrationTestColumnView
            left={
              <>
                <Grid item xs={12}>
                  <Typography variant="h4">Done!</Typography>
                </Grid>
                <Grid item>
                  {testPassed ? (
                    <Typography variant="h6">Passed</Typography>
                  ) : (
                    <Typography variant="h6">Failed</Typography>
                  )}
                </Grid>
                <Grid item>
                  {testPassed ? (
                    <PassIcon color="secondary" />
                  ) : (
                    <FailIcon color="error" />
                  )}
                </Grid>
              </>
            }
            right={
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setResults(null);
                    setStageIndex(0);
                  }}
                >
                  Start New Test
                </Button>
              </Grid>
            }
          />
        )}
      </div>
    </>
  );
};

type SequenceStepperProps = {
  sequence: { id: string };
  language: string | null;
  onComplete: (results: InstructionResults[]) => void;
};

const SequenceStepper = (props: SequenceStepperProps) => {
  // Create an object to store all the results for this sequence.
  const [results, setResults] = useState<InstructionResults[]>([]);
  const sequenceQuery = useIntegrationTestSequenceQuery({
    variables: { id: props.sequence.id },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (sequenceQuery.data) {
      setResults(
        sequenceQuery.data?.sequence?.steps?.map((step) => ({
          id: step.id ?? "",
          images: step.instruction.instruction_media
            .filter(({ medium }) => medium.type === Media_Type_Enum.Image)
            .map(({ medium }) => ({ src: medium.url, alt: medium.title })),
          description:
            step.instruction?.instruction_translations?.find(
              (item) => item.language === props.language,
            )?.description ??
            step.instruction?.description ??
            "",
          step: step,
          results:
            step.expectation_steps?.map(({ expectation }) => ({
              id: expectation?.id ?? "",
              description:
                expectation?.expectation_translations?.find(
                  (item) => item.language === props.language,
                )?.description ??
                expectation?.description ??
                "",
              result: false,
              images: expectation.expectation_media
                .filter(({ medium }) => medium.type === Media_Type_Enum.Image)
                .map(({ medium }) => ({ src: medium.url, alt: medium.title })),
            })) ?? [],
        })) ?? [],
      );
    }
  }, [sequenceQuery, props.language]);

  // Use to set the value of a single observation in the sequence.
  const setObservation = (
    instructionIndex: number,
    expectationIndex: number,
    value: boolean,
  ) => {
    const newResults = Array.from(results);
    const newInstruction = { ...results[instructionIndex] };
    const newInstructionResults = Array.from(newInstruction.results);
    const newExpectation = { ...newInstructionResults[expectationIndex] };
    newExpectation.result = value;
    newInstructionResults[expectationIndex] = newExpectation;
    newResults[instructionIndex].results = newInstructionResults;
    setResults(newResults);
  };

  // Flatten sequence into single array of "steps".
  const [stepIndex, setStepIndex] = useState(0);
  type Step = {
    instructionIndex: number;
    expectationIndex: number | null;
    text: string;
    images: ImageProps[];
  };
  const steps: Step[] = useMemo(() => {
    return results.reduce((agg, instruction, instructionIndex) => {
      const instructionStep = {
        instructionIndex: instructionIndex,
        expectationIndex: null,
        text: instruction.description,
        images: instruction.images,
      };
      const expectationSteps = instruction.results.map(
        (item, expectationIndex) => ({
          instructionIndex: instructionIndex,
          expectationIndex: expectationIndex,
          text: item.description + "?",
          images: item.images,
        }),
      );
      return [...agg, instructionStep, ...expectationSteps];
    }, [] as Step[]);
  }, [results]);

  const step = steps[stepIndex];
  return (
    <>
      {steps[stepIndex] && (
        <IntegrationTestColumnView
          left={
            <>
              <Grid item xs={12}>
                <Typography variant="h6">{step.text}</Typography>
                <Typography variant="caption">
                  {stepIndex + 1} / {steps.length}
                </Typography>
              </Grid>
              {step.images.map(({ src, alt }, i) => (
                <Grid item xs={12} key={i}>
                  <img src={src} alt={alt} style={{ maxWidth: "100%" }} />
                </Grid>
              ))}
            </>
          }
          right={
            <>
              {step.expectationIndex !== null ? (
                <>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setObservation(
                          step.instructionIndex,
                          step.expectationIndex!,
                          true,
                        );
                        if (steps[stepIndex + 1]) setStepIndex(stepIndex + 1);
                        else props.onComplete(results);
                      }}
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setObservation(
                          step.instructionIndex,
                          step.expectationIndex!,
                          false,
                        );
                        if (steps[stepIndex + 1]) setStepIndex(stepIndex + 1);
                        else props.onComplete(results);
                      }}
                    >
                      No
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (steps[stepIndex + 1]) setStepIndex(stepIndex + 1);
                        else props.onComplete(results);
                      }}
                    >
                      Done
                    </Button>
                  </Grid>
                </>
              )}
              <Grid item xs={12} container justify="flex-end">
                <IconButton
                  disabled={stepIndex < 1}
                  onClick={() => setStepIndex(stepIndex - 1)}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton
                  disabled={stepIndex === 0}
                  onClick={() => setStepIndex(0)}
                >
                  <Refresh />
                </IconButton>
              </Grid>
            </>
          }
        />
      )}
      {/* <Box>
        <LinearProgress
          variant="determinate"
          value={(100 * stepIndex) / (steps.length - 1)}
        />
      </Box> */}
    </>
  );
};

type IntegrationTestColumnViewProps = {
  left: React.ReactNode;
  right: React.ReactNode;
  bottom?: React.ReactNode;
};

const IntegrationTestColumnView = (props: IntegrationTestColumnViewProps) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        justify="space-around"
        alignItems="flex-start"
      >
        <Grid container spacing={2} item sm={8} xs={12} justify="flex-start">
          {props.left}
        </Grid>
        <Grid container spacing={2} item sm={4} xs={12} justify="flex-start">
          {props.right}
        </Grid>
        {props.bottom && (
          <Grid container spacing={2} item xs={12} justify="flex-start">
            {props.bottom}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const IntegrationTestResource = () => {
  return (
    <Resource
      path="integration-tests"
      create={(nav) => <IntegrationTest onSuccess={() => nav.create()} />}
    />
  );
};
