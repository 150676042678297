import React, { useEffect } from "react";

import { GQLErrorEvent, GQLNetworkErrorEvent } from "./apollo";
import { useLocation } from "react-router-dom";
import { useNotification } from "src/Notification";
import { GraphQLError } from "graphql";
import { md } from "src/resources/Utils";

export const GQLErrorEventHandler = () => {
  const location = useLocation();
  const notification = useNotification();

  useEffect(() => {
    const errorListener = (({ detail }: CustomEvent<GraphQLError[]>) => {
      const err = JSON.stringify(detail, null, 2);

      notification.create({
        severity: "error",
        body: md()
          .header("h4", "Error trace")
          .code(`Path: ${location.pathname}`)
          .syntax("json", err)
          .get(),
        title: "GraphQL error",
      });
    }) as EventListener;

    const networkErrorListener = (({ detail }: CustomEvent<Error>) => {
      notification.create({
        severity: "error",
        flashOnly: true,
        title: detail.message,
      });
    }) as EventListener;

    window.addEventListener(GQLErrorEvent, errorListener);
    window.addEventListener(GQLNetworkErrorEvent, networkErrorListener);
    return () => {
      window.removeEventListener(GQLErrorEvent, errorListener);
      window.removeEventListener(GQLNetworkErrorEvent, networkErrorListener);
    };
  }, [notification, location.pathname]);

  return <></>;
};
