import React, { useRef, useState } from "react";
import { Languages, useAllLanguagesQuery } from "src/generated/asgard/graphql";
import {
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  IconButton,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import { Translate } from "@material-ui/icons";
import { getLanguageDisplay } from "./TranslationTable";

type LanguageSelection = DeepPartial<Languages> | null;

type LanguageSelectProps = {
  onChange: (language: LanguageSelection) => void;
};

export const LanguageSelect = (props: LanguageSelectProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<LanguageSelection>(null);
  const languagesQuery = useAllLanguagesQuery();
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    language: LanguageSelection,
  ) => {
    handleChange(language);
    setOpen(false);
  };
  const handleChange = (language: LanguageSelection) => {
    setSelected(language);
    props.onChange(language);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Grid container ref={anchorRef}>
        {selected !== null && (
          <>
            <Typography variant="h6">{getLanguageDisplay(selected)}</Typography>
            <Divider orientation="vertical" variant="middle" flexItem />
          </>
        )}
        <IconButton size="small" color="inherit" onClick={handleToggle}>
          <Translate />
        </IconButton>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem
                    onClick={(event) => handleMenuItemClick(event, null)}
                  >
                    default
                  </MenuItem>
                  {languagesQuery.data?.languages.map((language, index) => (
                    <MenuItem
                      key={language.id}
                      onClick={(event) => handleMenuItemClick(event, language)}
                    >
                      {getLanguageDisplay(language)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
