import React, { useState, useEffect, useCallback } from "react";
import { FormControl, Card, makeStyles, TextField, Radio } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useAllDeliverablesQuery } from "src/generated/asgard/graphql";

type Option = {
  value: string;
  label: string;
  category?: string;
};

type SelectFilterProps = {
  update: (selected: string) => void;
  initialValue: string | null;
};

const options: Option[] = [
  { value: "all_projects", label: "All Projects" },
  { value: "my_projects", label: "My Projects" },
];

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    borderColor: "orange",
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
    },
  },
  formControl: {
    minWidth: "200px",
  },
});

const ProjectSelectFilter = ({ update, initialValue }: SelectFilterProps) => {
  const classes = useStyles();
  const allDeliverables = useAllDeliverablesQuery();
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const [deliverablesOptions, setDeliverablesOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (allDeliverables.data) {
      const newDeliverablesOptions = allDeliverables.data.deliverables.map(({ name }) => ({
        value: `deliverable/${name}`,
        label: name,
        category: "Deliverables",
      }));

      setDeliverablesOptions(newDeliverablesOptions);
      
      const optionsAndDeliverables = [...options, ...newDeliverablesOptions];
      const initialSelectedValue = initialValue 
        ? optionsAndDeliverables.find((opt) => opt.value === initialValue)
        : options[0];

      setSelectedValue(initialSelectedValue || options[0]);
    }
  }, [allDeliverables.data, initialValue]);

  const handleChange = useCallback((value: Option | null) => {
    const newValue = value || options[0];
    setSelectedValue(newValue);
    update(newValue.value);
  }, [update]);

  return (
    <Card className={classes.root}>
      <FormControl className={classes.formControl}>
        {deliverablesOptions.length > 0 && (
          <Autocomplete
            value={selectedValue}
            onChange={(_, newValue) => handleChange(newValue)}
            getOptionSelected={(option, value) => option.value === value.value}
            fullWidth
            options={[...options, ...deliverablesOptions]}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            groupBy={(option) => option.category || ""}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Radio style={{ marginRight: 4 }} checked={selected} />
                {option.label}
              </React.Fragment>
            )}
            renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
          />
        )}
      </FormControl>
    </Card>
  );
};

export default ProjectSelectFilter;