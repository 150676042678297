import { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Switch,
  Grid,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import { useAuth } from "src/auth";
import {
  useTaskInstructionAndTranslationByPkQuery,
  useUpsertInstructionTranslationMutationMutation,
  useListLanguagesQuery,
  useUpsertTaskInstructionContentMutation,
} from "src/generated/asgard/graphql";
import createPersistedState from "use-persisted-state";
import { useNotification } from "src/Notification";

const instructionDisplayLanguageState = createPersistedState(
  "instruction_display_language_state_hook",
);

type TaskInstructionAndTranslationProps = {
  taskId: string;
};

type InstructionDisplayContent = {
  id: string;
  content: string;
  createdAt: Date;
  language: string;
  status: string;
  updatedAt: Date;
  username: string;
};

export const TaskInstructionCard = (
  props: TaskInstructionAndTranslationProps,
) => {
  const [displayLanguage, setDisplayLanguage] =
    instructionDisplayLanguageState<string>("original");
  const displayContent =
    displayLanguage === "original" ? "instruction" : "translation";
  const notification = useNotification();
  // get current user info
  const auth = useAuth();
  const currentUser = {
    id: auth.user.id.toLowerCase(),
    name: auth.user.name ?? "",
  };
  // mutations
  const [updateTaskInstructionContentMutation] =
    useUpsertTaskInstructionContentMutation();
  const [upsertInstructionTranslation] =
    useUpsertInstructionTranslationMutationMutation();
  // query data from db
  const taskInstruction = useTaskInstructionAndTranslationByPkQuery({
    variables: { id: props.taskId },
    fetchPolicy: "network-only",
  });
  const listLanguages = useListLanguagesQuery();
  const serializeTaskInstruction =
    useCallback((): InstructionDisplayContent => {
      // extract what to display based on selected language from taskInstruction
      if (displayContent === "instruction") {
        return {
          id: taskInstruction.data?.tasks_by_pk?.task_instruction?.id ?? "",
          content:
            taskInstruction.data?.tasks_by_pk?.task_instruction?.content ?? "",
          createdAt: new Date(
            taskInstruction.data?.tasks_by_pk?.task_instruction?.created_at ??
              0,
          ),
          language: "",
          status:
            taskInstruction.data?.tasks_by_pk?.task_instruction?.status ?? "",
          updatedAt: new Date(
            taskInstruction.data?.tasks_by_pk?.task_instruction?.updated_at ??
              0,
          ),
          username:
            taskInstruction.data?.tasks_by_pk?.task_instruction?.user.name ??
            "",
        };
      } else {
        const translation =
          taskInstruction.data?.tasks_by_pk?.task_instruction?.instruction_translations?.find(
            (t) => t.language === displayLanguage,
          );
        return {
          id: translation?.id ?? "",
          content: translation?.content ?? "",
          createdAt: new Date(translation?.created_at ?? 0),
          language: translation?.language ?? "",
          status: translation?.status ?? "",
          updatedAt: new Date(translation?.updated_at ?? 0),
          username: translation?.user.name ?? "",
        };
      }
    }, [displayContent, displayLanguage, taskInstruction]);
  const [textFieldContent, setTextFieldContent] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const data = serializeTaskInstruction();
  useEffect(() => {
    setTextFieldContent(data.content);
  }, [
    data.content,
    taskInstruction,
    displayLanguage,
    serializeTaskInstruction,
  ]);
  const disableBottonBasedOnState = (status: string, updatedAt: Date) => {
    // we do not allow llm api call if ths status is pending or processing
    // with the exception that the status is pending or processing for more than 5 minutes
    const timeoutMs = 300000;
    const currentTime = new Date().getTime();
    return (
      (status === "PENDING" || status === "PROCESSING") &&
      currentTime - updatedAt.getTime() < timeoutMs
    );
  };
  return (
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item sm={2} xs={12}>
              <TextField
                select
                fullWidth
                variant="outlined"
                label="language"
                value={displayLanguage}
                onChange={(e) => {
                  setDisplayLanguage(e.target.value as string);
                  setIsEditable(false);
                }}
              >
                <MenuItem value={"original"}>default</MenuItem>
                {listLanguages.data?.languages.map((language) => (
                  <MenuItem key={language.id} value={language.id}>
                    {language.id}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={2} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() => setIsEditable(!isEditable)}
                    checked={isEditable}
                  />
                }
                label="Edit"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="task-intruction-textfield"
              multiline
              rows={25}
              variant="outlined"
              value={textFieldContent}
              onChange={(e) => setTextFieldContent(e.target.value)}
              disabled={!isEditable}
            />
          </Grid>
          <Grid container item spacing={2} justify="flex-end">
            <Grid item sm={2} xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  if (window.confirm("Confirm your changes?")) {
                    if (displayContent === "instruction") {
                      updateTaskInstructionContentMutation({
                        variables: {
                          task_id: props.taskId,
                          content: textFieldContent,
                          created_by: currentUser.id,
                          status: "SUCCEEDED",
                        },
                      })
                        .then(() => {
                          notification.create({
                            flashOnly: true,
                            severity: "success",
                            title: `Instruction updated`,
                          });
                          taskInstruction.refetch();
                        })
                        .catch((e) =>
                          notification.create({
                            severity: "warning",
                            flashOnly: true,
                            title: `${e}`,
                          }),
                        );
                    } else if (displayContent === "translation") {
                      const instructionId =
                        taskInstruction.data?.tasks_by_pk?.task_instruction?.id;
                      if (instructionId) {
                        upsertInstructionTranslation({
                          variables: {
                            task_instruction_id: instructionId,
                            content: textFieldContent,
                            created_by: currentUser.id,
                            status: "SUCCEEDED",
                            language: displayLanguage,
                          },
                        })
                          .then(() => {
                            notification.create({
                              flashOnly: true,
                              severity: "success",
                              title: `Instruction updated`,
                            });
                            taskInstruction.refetch();
                          })
                          .catch((e) =>
                            notification.create({
                              severity: "warning",
                              flashOnly: true,
                              title: `${e}`,
                            }),
                          );
                      } else {
                        notification.create({
                          flashOnly: true,
                          severity: "error",
                          title: `Instruction in the original language must be created before a translation can be created`,
                        });
                      }
                    }
                    setIsEditable(false);
                  }
                }}
                disabled={disableBottonBasedOnState(
                  data.status,
                  data.updatedAt,
                )}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
