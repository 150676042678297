import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuList,
  MenuProps,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  ClearAll,
  Close,
  DoneAllOutlined,
  FileCopyOutlined,
  InfoOutlined,
} from "@material-ui/icons";
import { Alert, AlertProps, AlertTitle } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import React, { useCallback, useMemo, useState } from "react";
import Markdown from "react-markdown";
import Moment from "react-moment";
import createPersistedState from "use-persisted-state";

export type NotificationType = {
  id: string;
  read: boolean;
  created: string;
  title: string;
  description?: string;
  body?: string;
  flashOnly?: boolean;
} & Pick<AlertProps, "severity">;

export type CreateNotificationProps = {} & Omit<
  NotificationType,
  "id" | "created" | "read"
>;

type NotificationContext = {
  create: (n: CreateNotificationProps) => void;
  clear: (filter: (n: NotificationType) => void) => void;
  notifications: NotificationType[];
};

const notificationContext = React.createContext<NotificationContext | null>(
  null,
);
export const NotificationProvider = notificationContext.Provider;
export const NotificationConsumer = notificationContext.Consumer;

export const useNotificationState = createPersistedState("notifications");

/**
 * useNotification returns the NotificationContext object. It will crash if
 * used outside of the NotificationProvider!
 */
export const useNotification = (): NotificationContext => {
  return React.useContext(notificationContext)!;
};

/**
 * useNotificationContext returns a managed NotificationContext that can be
 * passed to the NotificationProvider
 */
export const useNotificationContext = (): NotificationContext => {
  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useNotificationState<
    NotificationType[]
  >([]);

  const create = useCallback(
    (n: CreateNotificationProps) => {
      const nn: NotificationType = {
        ...n,
        id: generateNotificationId(),
        created: new Date().toISOString(),
        read: false,
      };

      if (n.flashOnly === undefined || n.flashOnly === false) {
        setNotifications((ns) => [nn, ...ns]);
      }
      enqueueSnackbar(nn.title, { variant: nn.severity });
    },
    [enqueueSnackbar, setNotifications],
  );

  const clear = useCallback(
    (filter: (n: NotificationType) => void) => {
      setNotifications(notifications.filter(filter));
    },
    [notifications, setNotifications],
  );

  return {
    notifications,
    create,
    clear,
  };
};

const generateNotificationId = () => {
  return `notification_${new Date().getTime()}`;
};

export type NotificationItemProps = {
  notification: NotificationType;
  onMoreInfoClicked?: (n: NotificationType) => void;
};

export const NotificationItem = ({
  notification,
  ...props
}: NotificationItemProps) => {
  return (
    <Alert
      style={{
        border: "none",
        width: "100%",
      }}
      severity={notification.severity}
      variant="outlined"
    >
      <AlertTitle>{notification.title}</AlertTitle>
      {notification.description}
      {notification.description ? <br /> : null}
      {notification.body ? (
        <Button
          size="small"
          variant="contained"
          disableElevation
          startIcon={<InfoOutlined />}
          onClick={() =>
            props.onMoreInfoClicked && props.onMoreInfoClicked(notification)
          }
        >
          More info
        </Button>
      ) : null}
      <Typography variant="caption" style={{ display: "block", color: "gray" }}>
        <Tooltip title="Copy to clipboard" placement="top">
          <IconButton size="small">
            <FileCopyOutlined style={{ fontSize: 14 }} />
          </IconButton>
        </Tooltip>
        <Moment fromNow>{notification.created}</Moment>
      </Typography>
    </Alert>
  );
};

type NotificationMenuProps = {} & Pick<
  MenuProps,
  "anchorEl" | "open" | "onClose"
>;

export const NotificationMenu = (props: NotificationMenuProps) => {
  const { notifications, clear } = useNotification();
  const [
    selectedNotification,
    setSelectedNotification,
  ] = useState<NotificationType | null>(null);
  // const [open, setOpen] = useState(false);

  const handleClose = () => {
    setSelectedNotification(null);
  };

  const notificationItemList = useMemo(
    () =>
      notifications
        .filter((n) => n.read === false)
        .slice(0, 5)
        .map((n) => [
          <NotificationItem
            notification={n}
            onMoreInfoClicked={(n) => setSelectedNotification(n)}
          />,
          <Divider key={"divider" + n.created} />,
        ]),
    [notifications],
  );

  const items = [
    <ListItem key="header">
      <ListItemText primary="Notifications" />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="clear"
          onClick={() => clear((n) => n.read === true)}
        >
          <ClearAll />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>,
    notificationItemList,
  ];

  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.onClose}
        style={{ marginTop: 33 }}
      >
        <MenuList style={{ outline: "none", width: 350, padding: 0 }}>
          {notificationItemList.length === 0 ? <NotificationsEmpty /> : items}
        </MenuList>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button>All notifications</Button>
        </div>
        <Dialog
          open={selectedNotification != null}
          fullWidth
          onClose={handleClose}
        >
          <NotificationInfoDialog
            onClose={handleClose}
            notification={selectedNotification}
          />
        </Dialog>
      </Menu>
    </>
  );
};

type NotificationInfoDialogProps = {
  notification?: NotificationType | null;
  onClose: () => void;
};

const NotificationInfoDialog = (props: NotificationInfoDialogProps) => {
  const [notification] = useState(() => props.notification);

  if (!notification) {
    return <DialogTitle>Nothing to show</DialogTitle>;
  }

  return (
    <>
      <Alert
        action={
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        }
        style={{
          border: "none",
          width: "100%",
          borderRadius: 0,
        }}
        severity={notification.severity}
      >
        <AlertTitle>{notification.title}</AlertTitle>
        {notification.description}
        <Typography
          variant="caption"
          style={{ display: "block", color: "gray" }}
        >
          <Tooltip title="Copy to clipboard" placement="top">
            <IconButton size="small">
              <FileCopyOutlined style={{ fontSize: 14 }} />
            </IconButton>
          </Tooltip>
          <Moment fromNow>{notification.created}</Moment>
        </Typography>
      </Alert>
      <DialogContent dividers>
        <Markdown source={notification.body ?? ""} />
      </DialogContent>
      <DialogActions>
        <Button>Mark as read</Button>
        <Button>Delete</Button>
      </DialogActions>
    </>
  );
};

const NotificationsEmpty = () => {
  return (
    <div style={{ width: "100%", textAlign: "center", padding: 20 }}>
      <DoneAllOutlined fontSize="large" color="primary" />
      <Typography>No unread notifications</Typography>
    </div>
  );
};
