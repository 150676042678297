import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { AddCircleOutline, Translate } from "@material-ui/icons";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
import {
  ActionInfo,
  FormContainer,
  FormikSubmitFailureEffect,
  FormHeader,
  FormAction,
  pathProxy,
  TextField,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, md, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  TableViewHeader,
  Tab,
  Tabs,
  TabContent,
  TabLabel,
  TabView,
} from "src/Layout";
import { useNotification } from "src/Notification";
import { TranslationTable } from "src/resources/Translation";
import {
  Integration_Test_Expectations as Expectation,
  Integration_Test_Expectations_Bool_Exp,
  useAllIntegrationTestExpectationsQuery,
  useIntegrationTestExpectationQuery,
  useIntegrationTestExpectationTranslationsSubscription,
  // useCreateIntegrationTestExpectationMutation,
  useUpdateIntegrationTestExpectationMutation,
  useUpsertIntegrationTestExpectationTranslationMutation,
} from "src/generated/asgard/graphql";

// Config table columns from Expectation fields
export const ExpectationTable = createTable<Expectation>()({
  keys: (expectation) => expectation.id ?? "",
  title: "Expectation",
  headers: {
    id: { display: "ID" },
    description: { display: "Name" },
  },
  columns: (expectation) => ({
    id: <EllipsisColumn value={expectation.id} />,
    description: <TextColumn value={expectation.description} />,
  }),
});

// Define a new table component for Expectations
type AllExpectationsTableProps = TableOptionals<typeof ExpectationTable> & {
  where?: Integration_Test_Expectations_Bool_Exp[];
};

export const AllExpectationsTable = (props: AllExpectationsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Integration_Test_Expectations_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  }
  // Add search terms for individual fields.
  const term = like(search);
  searchFilters.push({ description: { _ilike: term } });
  const { data } = useAllIntegrationTestExpectationsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, ...searchFilters] },
    },
  });

  return (
    <ExpectationTable
      {...props}
      {...pageController}
      total={data?.expectations_aggregate?.aggregate?.count}
      data={data?.expectations}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main Expectation resource page
type ExpectationIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof ExpectationTable>;

export const ExpectationIndex = (props: ExpectationIndexProps) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">Expectations</Typography>}
      >
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Expectation
        </Button>
      </TableViewHeader>
      <Card>
        <AllExpectationsTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Expectations
const expectationSchema = Yup.object({
  description: Yup.string().required("required"),
}).required();

type ExpectationFormSchema = Yup.InferType<typeof expectationSchema>;

type ExpectationFormAction = "insert" | "update";

type ExpectationFormInfo = {
  title: string;
  submitButtonTitle: string;
  notification: {
    success: (id: string) => {
      title: string;
      description: string;
    };
    error: (id: string) => {
      title: string;
      description: string;
    };
  };
};

export type ExpectationFormProps = {
  expectation?: { id: string };
  action: ExpectationFormAction;
  onSuccess?: (id: string) => void;
};

export const ExpectationForm = (props: ExpectationFormProps) => {
  const formInfo: ActionInfo<ExpectationFormAction, ExpectationFormInfo> = {
    insert: {
      title: "Create new Expectation",
      submitButtonTitle: "Create Expectation",
      notification: {
        success: (id) => ({
          title: "Expectation Created",
          description: `id: ${id}`,
        }),
        error: (id) => ({
          title: "Unable to Create Expectation",
          description: "An error occured when trying to create expectation.",
        }),
      },
    },
    update: {
      title: "Update Expectation",
      submitButtonTitle: "Update Expectation",
      notification: {
        success: (id) => ({
          title: "Expectation Updated",
          description: `id: ${id}`,
        }),
        error: (id) => ({
          title: "Could Not Update Expectation",
          description: `An error occured when trying to update expectation ${id}.`,
        }),
      },
    },
  };

  const info = formInfo[props.action];

  const notification = useNotification();

  // const [createExpectation] = useCreateIntegrationTestExpectationMutation();
  const [updateExpectation] = useUpdateIntegrationTestExpectationMutation();

  const formik = useFormik<ExpectationFormSchema>({
    validationSchema: expectationSchema,
    initialValues: {
      description: "",
    },
    onSubmit: async (values) => {
      try {
        const result = await (async () => {
          if (props.action === "update" && props.expectation) {
            return await updateExpectation({
              variables: {
                id: props.expectation.id,
                input: { ...values },
              },
            });
          }
        })();
        props.onSuccess && props.onSuccess(result?.data?.expectation?.id ?? "");
      } catch (e) {
        formik.setSubmitting(false);
      }
    },
  });

  // If updating existing, hydrate form.
  const { setValues, setSubmitting } = formik;
  const itemToUpdateQuery = useIntegrationTestExpectationQuery({
    variables: { id: props.expectation?.id ?? "" },
    skip: !props.expectation,
  });
  const itemToUpdate = itemToUpdateQuery.data?.expectation;
  useEffect(() => {
    if (props.action === "update") {
      if (!itemToUpdate) setSubmitting(true);
      else {
        setValues({ description: itemToUpdate.description ?? "" });
        setSubmitting(false);
      }
    }
  }, [props.action, itemToUpdate, setSubmitting, setValues]);

  const path = pathProxy<typeof formik.values>();

  return (
    <FormikProvider value={formik}>
      <FormHeader>
        <Typography variant="h6">{info.title}</Typography>
      </FormHeader>
      <FormContainer>
        <TextField
          bp={{ xs: 12 }}
          name={path.description._}
          label="Description"
        />
        <FormAction>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            {info.submitButtonTitle}
          </Button>
          <FormikSubmitFailureEffect
            onSubmitFailure={(errors) =>
              notification.create({
                severity: "error",
                title: `Expectation Form Error`,
                body: md()
                  .syntax("json", JSON.stringify(errors, null, 2))
                  .get(),
              })
            }
          />
        </FormAction>
      </FormContainer>
    </FormikProvider>
  );
};

// Create table for viewing and editing translations.
type ExpectationTranslationTableProps = {
  expectation: { id: string };
};

const ExpectationTranslationTable = (
  props: ExpectationTranslationTableProps,
) => {
  const expectationTranslations =
    useIntegrationTestExpectationTranslationsSubscription({
      variables: { id: props.expectation.id },
    });
  const [upsertTranslation] =
    useUpsertIntegrationTestExpectationTranslationMutation({});
  return (
    <TranslationTable
      data={expectationTranslations.data?.translations ?? []}
      schema={Yup.object({
        description: Yup.string().nullable(),
      }).required()}
      getValues={(data) => ({
        description: data.description ?? "",
      })}
      initialValues={{ description: "" }}
      getLanguage={(data) => data.language}
      labels={{ description: "Description" }}
      columns={(data) => ({ description: data.description ?? "" })}
      onUpdate={(data, values) =>
        upsertTranslation({
          variables: {
            object: {
              expectation_id: props.expectation.id,
              language: data.language.id,
              description: values.description,
            },
          },
        })
      }
      onCreate={(language, values) =>
        upsertTranslation({
          variables: {
            object: {
              expectation_id: props.expectation.id,
              language: language,
              ...values,
            },
          },
        })
      }
    />
  );
};

// Create a detailed 'show' page.
type ExpectationShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Expectation>) => void;
};

const ExpectationShow = (props: ExpectationShowProps) => {
  const expectationNav = useExpectationNav();

  const expectationQuery = useIntegrationTestExpectationQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const expectation = expectationQuery.data?.expectation;
  if (!expectation) return null;

  return (
    <ShowResourceView
      title={expectation.description ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb
            label="expectations"
            onClick={() => expectationNav.list()}
          />
          <Breadcrumb label={expectation.description ?? ""} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(expectation)}
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabProps) => (
          <Tabs {...tabProps}>
            <Tab
              label={<TabLabel label="Translations" icon={<Translate />} />}
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent index={0} current={current}>
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <ExpectationTranslationTable expectation={{ id: props.id }} />
                </CardContent>
              </Card>
            </TabContent>
          </>
        )}
      />
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "expectations";
export const useExpectationNav = () => useResourceNav(path);
export const ExpectationResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <ExpectationIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <ExpectationShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <ExpectationForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <ExpectationForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        expectation={{ id: id ?? "" }}
      />
    )}
  />
);
