import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { BlobServiceClient } from "@azure/storage-blob";
import config from "src/config";

// The auth provider should be a singleton. Best practice is to only have it
// ever instantiated once. Avoid creating an instance inside the component it
// will be recreated on each render. If two providers are created on the same
// page it will cause authentication errors.
export const azureProvider =
  config.auth.provider === "azure"
    ? new MsalAuthProvider(
        {
          auth: {
            authority:
              "https://login.microsoftonline.com/6b236992-2120-477a-9a50-7753c87b3574",
            clientId: "73d02787-27ed-420d-b681-93169643f282",
            postLogoutRedirectUri: window.location.origin,
            redirectUri: window.location.origin,
            validateAuthority: true,

            // After being redirected to the "redirectUri" page, should user
            // be redirected back to the Url where their login originated from?
            navigateToLoginRequestUrl: false,
          },
          // Enable logging of MSAL events for easier troubleshooting.
          // This should be disabled in production builds.
          system: {
            // logger: logger,
          },
          cache: {
            cacheLocation: "localStorage",
          },
        },
        {
          scopes: ["openid"],
        },
        {
          loginType: LoginType.Redirect,
          tokenRefreshUri: window.location.origin + "/auth.html",
        },
      )
    : null;

export const makeBlobServiceClient = (blob: string) => {
  if (azureProvider === null) {
    return null;
  }

  const auth = {
    getToken: async function (_scopes: any, _options: any) {
      if (azureProvider === null) {
        throw new Error("no azure/msal provider initialized");
      }

      const token = await azureProvider.acquireTokenSilent({
        scopes: ["https://storage.azure.com/user_impersonation"],
        account: undefined,
      });

      return {
        token: token.accessToken,
        expiresOnTimestamp: +token.expiresOn,
      };
    },
  };

  return new BlobServiceClient(blob, auth);
};
