import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Fab,
  Grid,
  TextField as MUITextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Delete,
  DragIndicator,
  ListAltOutlined,
  Info,
  Refresh,
} from "@material-ui/icons";
import {
  FieldArray,
  FormikErrors,
  FormikProvider,
  useField,
  useFormik,
  useFormikContext,
} from "formik";
import {
  ActionInfo,
  FormContainer,
  FormHeader,
  TextField,
  pathProxy,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import * as Yup from "yup";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  ChipColumn,
  TextColumn,
} from "src/components/table";
import { usePagination, like, md, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumbs,
  Breadcrumb,
  ShowResourceView,
  Tab,
  TabContent,
  TabLabel,
  Tabs,
  TabView,
  TableView,
  TableViewHeader,
} from "src/Layout";
import { createTriage } from "src/components/Triage";
import { useNotification } from "src/Notification";
import {
  Integration_Test_Sequences,
  Integration_Test_Steps,
  Integration_Test_Instructions_Constraint,
  Integration_Test_Instructions_Update_Column,
  Integration_Test_Expectations_Constraint,
  Integration_Test_Expectations_Update_Column,
  Integration_Test_Sequences_Bool_Exp,
  Integration_Test_Steps_Insert_Input,
  Integration_Test_Steps_Bool_Exp,
  useInsertIntegrationTestSequenceMutation,
  useIntegrationTestSequenceQuery,
  useUpdateIntegrationTestSequenceMutation,
  useAllIntegrationTestExpectationsQuery,
  useAllIntegrationTestInstructionsQuery,
  useAllIntegrationTestSequencesQuery,
  useAllIntegrationTestStepsQuery,
} from "src/generated/asgard/graphql";
import { Autocomplete } from "@material-ui/lab";
import { SearchBar } from "src/components/filters";
import { useInstructionNav } from "./instruction";

// Config table columns from Sequence fields
export const SequenceTable = createTable<Integration_Test_Sequences>()({
  keys: (sequence) => sequence.id ?? "",
  title: "Sequence",
  headers: {
    id: { display: "ID" },
    description: { display: "Description" },
  },
  columns: (sequence) => ({
    id: <EllipsisColumn value={sequence.id} />,
    description: <TextColumn value={sequence.description} />,
  }),
});

// Define a new table component for Sequences
type AllSequencesTableProps = {
  where?: Integration_Test_Sequences_Bool_Exp[];
} & TableOptionals<typeof SequenceTable>;

export const AllSequencesTable = (props: AllSequencesTableProps) => {
  // Search bar.
  const [search, setSearch] = useState("");
  const term = like(search);
  const searchFilters: Integration_Test_Sequences_Bool_Exp[] = [
    { description: { _ilike: term } },
  ];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  }

  const [pageVars, pageController] = usePagination();
  const { data } = useAllIntegrationTestSequencesQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, ...searchFilters] },
    },
    fetchPolicy: "network-only",
  });

  return (
    <SequenceTable
      {...props}
      {...pageController}
      total={data?.sequences_aggregate?.aggregate?.count}
      data={data?.sequences}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main Sequence resource page
type SequenceIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof SequenceTable>;

export const SequenceIndex = (props: SequenceIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Sequences</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Sequence
        </Button>
      </TableViewHeader>
      <Card>
        <AllSequencesTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Create a table for viewing steps in a given sequence.
export const SequenceStepsTable = createTable<Integration_Test_Steps>()({
  keys: (st) => st.id ?? "",
  title: "Sequence Steps",
  headers: {
    id: { display: "ID" },
    instruction: { display: "Instruction" },
    expectations: { display: "Expectations" },
  },
  columns: (step) => {
    return {
      id: <EllipsisColumn value={step.id} />,
      instruction: <TextColumn value={step.instruction?.description ?? ""} />,
      expectations: (
        <ChipColumn
          chips={step.expectation_steps?.map((item) => ({
            label: item.expectation?.description ?? "",
          }))}
        />
      ),
    };
  },
});

// Define a new table component for Sequences
type AllSequenceStepsTableProps = {
  where: Integration_Test_Steps_Bool_Exp[];
} & TableOptionals<typeof SequenceStepsTable>;

export const AllSequenceStepsTable = (props: AllSequenceStepsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const { data } = useAllIntegrationTestStepsQuery({
    variables: {
      ...pageVars,
      where: { _and: props.where },
    },
    fetchPolicy: "network-only",
  });

  return (
    <SequenceStepsTable
      {...props}
      {...pageController}
      selectable="none"
      total={data?.steps_aggregate?.aggregate?.count}
      data={data?.steps}
    />
  );
};

// Define form for creating and editing Sequences
const expectationSchema = Yup.object({
  key: Yup.string().required(),
  description: Yup.string().required(),
}).required();

const instructionSchema = Yup.object({
  key: Yup.string().required(),
  position: Yup.number().integer().min(0).max(100),
  description: Yup.string().required(),
  expectations: Yup.array(expectationSchema),
}).required();

const sequenceSchema = Yup.object({
  description: Yup.string().required(),
  instructions: Yup.array(instructionSchema),
}).required();

type ExpectationSchema = Yup.InferType<typeof expectationSchema>;
type InstructionSchema = Yup.InferType<typeof instructionSchema>;
type SequenceFormSchema = Yup.InferType<typeof sequenceSchema>;

const TriageSequenceInstructions = createTriage<
  ExpectationSchema,
  InstructionSchema
>();

type SequenceFormAction = "insert" | "update";

type SequenceFormInfo = {
  title: string;
  submitButtonTitle: string;
  notification: {
    success: (id: string) => {
      title: string;
      description: string;
    };
    error: (id: string) => {
      title: string;
      description: string;
    };
  };
};

export type SequenceFormProps = {
  sequence?: { id: string };
  action: SequenceFormAction;
  onSuccess?: (id: string) => void;
};

export const SequenceForm = (props: SequenceFormProps) => {
  const formInfo: ActionInfo<SequenceFormAction, SequenceFormInfo> = {
    insert: {
      title: "Create new Sequence",
      submitButtonTitle: "Create Sequence",
      notification: {
        success: (id) => ({
          title: "Sequence Created",
          description: `id: ${id}`,
        }),
        error: (id) => ({
          title: "Unable to Create Sequence",
          description: "An error occured when trying to create sequence.",
        }),
      },
    },
    update: {
      title: "Update Sequence",
      submitButtonTitle: "Update Sequence",
      notification: {
        success: (id) => ({
          title: "Sequence Updated",
          description: `id: ${id}`,
        }),
        error: (id) => ({
          title: "Could Not Update Sequence",
          description: `An error occured when trying to update sequence ${id}.`,
        }),
      },
    },
  };

  const info = formInfo[props.action];

  const notification = useNotification();

  const [insertSequence] = useInsertIntegrationTestSequenceMutation();
  const [updateSequence] = useUpdateIntegrationTestSequenceMutation();

  const existingSequencesFilters: Integration_Test_Sequences_Bool_Exp[] = [];
  if (props.sequence?.id) {
    existingSequencesFilters.push({ id: { _neq: props.sequence.id } });
  }
  const existingSequences = useAllIntegrationTestSequencesQuery({
    variables: { where: { _and: existingSequencesFilters } },
    fetchPolicy: "network-only",
  });
  const validateSequence = (values: SequenceFormSchema) => {
    const errors: FormikErrors<SequenceFormSchema> = {};
    if (existingSequences.data?.sequences?.length) {
      for (let seq of existingSequences.data?.sequences) {
        if (seq.description === values.description) {
          errors.description = `Sequence '${values.description}' already exists.`;
          break;
        }
      }
    }
    return errors;
  };

  const formik = useFormik<SequenceFormSchema>({
    validationSchema: sequenceSchema,
    validate: validateSequence,
    validateOnChange: false,
    initialValues: {
      description: "",
      instructions: [],
    },
    onSubmit: async (values) => {
      const { instructions, ...vals } = values;
      // Generate update input, which will attempt to insert new
      // instruction and expectation descriptions, if they don't exist,
      // and use existing ones if they do.
      const steps: Integration_Test_Steps_Insert_Input[] =
        instructions?.map((instruction, position) => ({
          position,
          instruction: {
            on_conflict: {
              constraint:
                Integration_Test_Instructions_Constraint.InstructionsDescriptionKey,
              update_columns: [
                Integration_Test_Instructions_Update_Column.Description,
              ],
            },
            data: {
              description: instruction.description,
            },
          },
          expectation_steps: {
            data:
              instruction.expectations?.map((expectation) => ({
                expectation: {
                  on_conflict: {
                    constraint:
                      Integration_Test_Expectations_Constraint.ExpectationsDescriptionKey,
                    update_columns: [
                      Integration_Test_Expectations_Update_Column.Description,
                    ],
                  },
                  data: {
                    description: expectation.description,
                  },
                },
              })) ?? [],
          },
        })) ?? [];
      try {
        const result = await (async () => {
          if (props.action === "update" && props.sequence?.id) {
            return await updateSequence({
              variables: {
                steps: steps.map((step) => ({
                  // Add sequence id to step inputs.
                  sequence_id: props.sequence!.id,
                  ...step,
                })),
                id: props.sequence!.id,
                sequence: {
                  ...vals,
                },
              },
            });
          } else {
            return await insertSequence({
              variables: {
                input: {
                  steps: { data: steps },
                  ...vals,
                },
              },
            });
          }
        })();
        props.onSuccess && props.onSuccess(result.data?.sequence?.id ?? "");
      } catch (e) {
        const err = JSON.stringify((e as any).graphQLErrors, null, 2);
        const note = info.notification.error("");
        notification.create({
          severity: "error",
          body: md().header("h4", "The json error").syntax("json", err).get(),
          ...note,
        });
      }
    },
  });

  // If updating an existing category, prevent submission until form is hydrated.
  const { setValues, setSubmitting } = formik;
  const sequenceQuery = useIntegrationTestSequenceQuery({
    variables: { id: props.sequence?.id ?? "" },
    skip: !props.sequence,
  });
  const sequence = sequenceQuery.data?.sequence;
  const hydrateFromQuery = () => {
    if (props.action === "update") {
      if (!sequence) setSubmitting(true);
      else {
        setValues({
          description: sequence.description ?? "",
          instructions:
            sequence.steps?.map((step) => ({
              key: step.id,
              position: step.position,
              description: step.instruction.description,
              expectations: step.expectation_steps.map(
                ({ expectation }, i) => ({
                  key: `${step.id}-${i}`,
                  description: expectation.description,
                }),
              ),
            })) ?? [],
        });
        setSubmitting(false);
      }
    }
  };
  useEffect(hydrateFromQuery, [
    props.action,
    sequence,
    setSubmitting,
    setValues,
  ]);

  // Populate list of instructions available from combo boxes.
  const instructionOptionsQuery = useAllIntegrationTestInstructionsQuery({
    fetchPolicy: "network-only",
  });
  const instructionOptions = useMemo(() => {
    const queried =
      instructionOptionsQuery.data?.instructions.map(
        (item) => item.description,
      ) ?? [];
    const entered =
      formik.values.instructions
        ?.map((item) => item.description)
        .filter((description) => queried.indexOf(description) === -1) ?? [];
    return [...queried, ...entered].sort();
  }, [formik.values.instructions, instructionOptionsQuery]);

  const path = pathProxy<typeof formik.values>();

  return (
    <FormikProvider value={formik}>
      <FormHeader>{info.title}</FormHeader>
      <TabView
        useUrlParams={false}
        renderTabs={(tabsProps) => (
          <Tabs {...tabsProps}>
            <Tab label={<TabLabel label="General" icon={<Info />} />} />
            <Tab
              label={
                <TabLabel
                  label="Instructions"
                  count={formik.values.instructions!.length}
                  icon={<ListAltOutlined />}
                />
              }
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent index={0} current={current}>
              <FormContainer>
                <TextField
                  bp={{ xs: 12 }}
                  name={path.description._}
                  label="Description"
                />
              </FormContainer>
            </TabContent>
            <TabContent index={1} current={current}>
              <FormContainer>
                <Grid item xs={12}>
                  <FieldArray
                    name={path.instructions!._}
                    render={(helper) => (
                      <>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Button
                              onClick={() => {
                                helper.insert(0, {
                                  key: Date.now().toString(),
                                  description: "",
                                  expectations: [],
                                });
                              }}
                              variant="contained"
                              color="primary"
                              startIcon={<AddCircleOutline />}
                              disableElevation
                            >
                              Add Instruction
                            </Button>
                          </Grid>
                        </Grid>
                        <TriageSequenceInstructions
                          id="triage-instructions"
                          groups={formik.values.instructions ?? []}
                          groupDirection="vertical"
                          getGroupItems={(group) => group.expectations ?? []}
                          setGroupItems={(group, items) =>
                            (group.expectations = items)
                          }
                          renderGroup={({ index, children, group }) => (
                            <Card style={{ marginTop: 8 }}>
                              <CardContent>
                                <Grid container direction="row" spacing={2}>
                                  <Grid item>
                                    <DragIndicator />
                                  </Grid>
                                  <Grid item xs container direction="column">
                                    <Grid
                                      item
                                      container
                                      spacing={2}
                                      justify="space-between"
                                    >
                                      <Grid item xs={8}>
                                        <InstructionEntry
                                          name={
                                            path.instructions![index]
                                              .description._
                                          }
                                          options={instructionOptions}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          variant="text"
                                          size="small"
                                          color="primary"
                                          disabled={!group.description.length}
                                          onClick={() => helper.remove(index)}
                                        >
                                          <Delete />
                                        </Button>
                                      </Grid>
                                    </Grid>
                                    <Grid item container>
                                      {children}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          )}
                          itemDirection="horizontal"
                          renderItem={({ item, group, groupIndex }) => (
                            <StyledChip
                              label={item.description}
                              onDelete={() => {
                                const replacement = { ...group };
                                replacement.expectations = replacement
                                  .expectations?.length
                                  ? replacement.expectations.filter(
                                      (expectation) =>
                                        expectation.key !== item.key,
                                    )
                                  : [];
                                helper.replace(groupIndex, replacement);
                              }}
                            />
                          )}
                          renderItemActions={({ group, index }) => (
                            <NewExpectationButton
                              onSubmit={(value) => {
                                const replacement = { ...group };
                                const newExpectation = {
                                  key: Date.now().toString(),
                                  description: value,
                                };
                                if (replacement.expectations?.length)
                                  replacement.expectations.push(newExpectation);
                                else
                                  replacement.expectations = [newExpectation];
                                helper.replace(index, replacement);
                              }}
                            />
                          )}
                          // Expectations are not orderable, so disable dragging.
                          disableItemDragging={true}
                          onChange={(instructions) => {
                            formik.setFieldValue("instructions", instructions);
                          }}
                        />
                      </>
                    )}
                  />
                </Grid>
              </FormContainer>
            </TabContent>
          </>
        )}
      />
      <div
        style={{
          position: "fixed",
          top: "auto",
          right: 30,
          bottom: 30,
          left: "auto",
        }}
      >
        <Fab
          size="large"
          color="primary"
          variant="extended"
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.resetForm();
            hydrateFromQuery();
          }}
        >
          <Refresh />
        </Fab>
        <Fab
          style={{ marginLeft: 4 }}
          size="large"
          color="primary"
          variant="extended"
          disabled={formik.isSubmitting || !formik.values.instructions?.length}
          onClick={() => {
            formik.handleSubmit();
            if (Object.keys(formik.errors).length) {
              notification.create({
                severity: "error",
                title: `Context Display Group Form Error`,
                body: md()
                  .syntax(
                    "json",
                    JSON.stringify(
                      { errors: formik.errors, values: formik.values },
                      null,
                      2,
                    ),
                  )
                  .get(),
              });
            }
          }}
        >
          {props.action}
        </Fab>
      </div>
    </FormikProvider>
  );
};

const StyledChip = withStyles({
  root: {
    margin: 4,
  },
})(Chip);

type InstructionEntryProps = {
  name: string;
  options: string[];
};

const InstructionEntry = (props: InstructionEntryProps) => {
  const [{ value, ...field }, meta] = useField(props);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);
  const errorMessage = JSON.stringify(meta.error);
  return (
    <>
      <Autocomplete
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disableClearable
        {...field}
        value={value}
        disabled={ctx.isSubmitting}
        options={props.options}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Instruction"
            name={field.name}
            error={error}
            helperText={error ? errorMessage : " "}
            variant="outlined"
          />
        )}
      />
    </>
  );
};

type NewExpectationButtonProps = {
  onSubmit: (value: string) => void;
  disabled?: boolean;
};

const NewExpectationButton = (props: NewExpectationButtonProps) => {
  const [open, setOpen] = useState(false);
  const existingExpectationsQuery = useAllIntegrationTestExpectationsQuery();
  return (
    <>
      <StyledChip
        icon={<AddCircleOutline fontSize="small" />}
        label={"Add Expectation"}
        color="primary"
        disabled={!!props.disabled}
        onClick={() => setOpen(true)}
      />
      <TextEntryDialog
        open={open}
        label="Expectation"
        onSubmit={(value) => {
          props.onSubmit(value);
          setOpen(false);
        }}
        onClose={() => setOpen(false)}
        options={
          existingExpectationsQuery.data?.expectations?.map(
            ({ description }) => description,
          ) ?? []
        }
      />
    </>
  );
};

type TextEntryDialogProps = {
  options?: string[];
  onSubmit: (value: string) => void;
  label: string;
  open: boolean;
  onClose: () => void;
};

const TextEntryDialog = (props: TextEntryDialogProps) => {
  const [value, setValue] = useState("");
  return (
    <Dialog fullWidth={true} open={props.open} onClose={props.onClose}>
      <DialogContent>
        <Autocomplete
          freeSolo
          disableClearable
          onInputChange={(_, newValue) => setValue(newValue ?? "")}
          onChange={(_, newValue) => setValue(newValue ?? "")}
          options={props.options ?? []}
          value={value}
          renderInput={(params) => (
            <MUITextField {...params} label={props.label} variant="outlined" />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (value.length) {
              props.onSubmit(value);
            }
            setValue("");
          }}
          variant="contained"
          color="primary"
          disableElevation
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type SequenceShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Integration_Test_Sequences>) => void;
};

const SequenceShow = (props: SequenceShowProps) => {
  const sequenceNav = useSequenceNav();
  const instructionNav = useInstructionNav();

  const sequenceQuery = useIntegrationTestSequenceQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const sequence = sequenceQuery.data?.sequence;
  if (!sequence) return null;

  return (
    <ShowResourceView
      title={sequence.description ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="sequences" onClick={() => sequenceNav.list()} />
          <Breadcrumb label={sequence.id} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(sequence)}
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabsProps) => (
          <Tabs {...tabsProps}>
            <Tab
              label={
                <TabLabel
                  label="Steps"
                  count={sequence.steps.length}
                  icon={<ListAltOutlined />}
                />
              }
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <TabContent
            index={0}
            current={current}
            loading={sequenceQuery.loading}
          >
            <Card style={{ marginTop: 20 }}>
              <CardContent>
                <TableView>
                  <AllSequenceStepsTable
                    where={[{ sequence_id: { _eq: sequence.id } }]}
                    showUrl={(item) =>
                      item.instruction?.id &&
                      instructionNav.showUrl(item.instruction.id)
                    }
                  />
                </TableView>
              </CardContent>
            </Card>
          </TabContent>
        )}
      />
    </ShowResourceView>
  );
};

export const useSequenceNav = () => useResourceNav("sequences");
export const SequenceResource = () => (
  <Resource
    path="sequences"
    list={(nav) => (
      <SequenceIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <SequenceShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <SequenceForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <SequenceForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        sequence={{ id: id ?? "" }}
      />
    )}
  />
);
