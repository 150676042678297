import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { SearchBar } from "src/components/filters";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Performance_Ratio_Metrics as RatioMetric,
  Performance_Ratio_Metrics_Bool_Exp as Ratio_Metrics_Bool_Exp,
  useAllRatioMetricsQuery,
  Order_By,
} from "src/generated/asgard/graphql";

// Config table columns from RatioMetric fields
export const RatioMetricTable = createTable<RatioMetric>()({
  keys: (ratioMetric) => ratioMetric.id ?? "",
  title: "RatioMetric",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    description: { display: "Description" },
  },
  columns: (ratioMetric) => ({
    id: <EllipsisColumn value={ratioMetric.id} />,
    name: <TextColumn value={ratioMetric.name} />,
    description: <TextColumn value={ratioMetric.description} />,
  }),
});

// Define a new table component for RatioMetrics
type AllRatioMetricsTableProps = TableOptionals<typeof RatioMetricTable> & {
  where?: Ratio_Metrics_Bool_Exp[];
};

export const AllRatioMetricsTable = (props: AllRatioMetricsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Ratio_Metrics_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    const term = like(search);
    searchFilters.push({ name: { _ilike: term } });
  }
  const { data } = useAllRatioMetricsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      order_by: { name: Order_By.Asc },
    },
  });

  return (
    <RatioMetricTable
      {...props}
      {...pageController}
      total={data?.ratio_metrics_aggregate?.aggregate?.count}
      data={data?.ratio_metrics}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};
