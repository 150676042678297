import { createMuiTheme } from "@material-ui/core";
import config from "src/config";

const LightTheme = createMuiTheme({
  palette: {
    // type: "dark",
    primary: {
      // light: will be calculated from palette.primary.main,
      main: config.env === "dev" ? "#AA0000" : "#3562a7",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: "#e1fb9e",
      main: "#b9d17a",
      // dark: "#596737",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#444e2d",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const DarkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

export const MaterialTheme = {
  light: LightTheme,
  dark: DarkTheme,
};
