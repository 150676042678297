import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Fab,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircleOutline, Info } from "@material-ui/icons";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  DialogSelect,
  DrawerButton,
  FormContainer,
  FormAction,
  IncDecField,
  pathProxy,
  TextField,
} from "src/components/Form";
import { useNotification } from "src/Notification";
import { md } from "src/resources/Utils";
import {
  usePlanProjectTasksQuery,
  useUpdateProjectTasksMutation,
  useAllPlansQuery,
  Order_By,
  Plans,
  Tasks_Bool_Exp,
  Plan_Tasks_Bool_Exp,
} from "src/generated/asgard/graphql";
import { AllTasksTable, TaskLink } from "src/resources/CollectionPlan";
import { CompareTable } from "src/components/CompareTable";

// Schemas for editor form.
const projectTaskSchema = Yup.object({
  key: Yup.string().required(),
  task_id: Yup.string().required(),
  legacy_template_id: Yup.number().required(),
  highlights: Yup.string(),
  inProject: Yup.object({
    id: Yup.string(),
    min_per_device: Yup.number()
      .integer("must be a valid integer")
      .min(1, "must be larger than 0")
      .required(),
    priority: Yup.number()
      .integer("must be a valid integer")
      .min(0, "must be positive")
      .required(),
  }).nullable(),
  inPlan: Yup.object({
    id: Yup.string(),
    min_per_device: Yup.number().required(),
    priority: Yup.number().required(),
  }).nullable(),
  inProjectOriginal: Yup.object({
    id: Yup.string(),
    min_per_device: Yup.number().required(),
    priority: Yup.number().required(),
  }).nullable(),
}).required();

type ProjectTaskSchema = Yup.InferType<typeof projectTaskSchema>;

const projectTaskFormSchema = Yup.object({
  tasks: Yup.array(projectTaskSchema).required().nullable(),
}).required();

type ProjectTaskFormSchema = Yup.InferType<typeof projectTaskFormSchema>;

// Helpers for determining whether or not a project-task entry has been changed.
function projectTaskRemoved(projectTask: ProjectTaskSchema) {
  return (
    projectTask.inProject === null && projectTask.inProjectOriginal !== null
  );
}

function projectTaskUpdated(projectTask: ProjectTaskSchema) {
  if (projectTaskRemoved(projectTask)) {
    return true;
  }
  if (projectTask.inProject) {
    if (
      projectTask.inProject.min_per_device !==
        projectTask.inProjectOriginal?.min_per_device ||
      projectTask.inProject.priority !== projectTask.inProjectOriginal?.priority
    ) {
      return true;
    }
  }
  return false;
}

// Project task editor component.
type ProjectTaskEditorProps = {
  projectId: string;
};

export const ProjectTaskEditor = (props: ProjectTaskEditorProps) => {
  // User may select plan to compare against.
  const allPlans = useAllPlansQuery({});
  const [plan, setPlan] = useState<DeepPartial<Plans> | undefined>(undefined);

  // Query for tasks attached to project,
  // and optionally tasks attached to a specified plan.
  const taskFilters: Tasks_Bool_Exp[] = [];
  const planTaskFilters: Plan_Tasks_Bool_Exp[] = [];
  if (plan?.id?.length) {
    taskFilters.push({
      _or: [
        { plan_tasks: { plan_id: { _eq: plan.id } } },
        { project_tasks: { project: { id: { _eq: props.projectId } } } },
      ],
    });
    planTaskFilters.push({ plan_id: { _eq: plan.id } });
  } else {
    taskFilters.push({
      project_tasks: { project_id: { _eq: props.projectId } },
    });
    planTaskFilters.push({ id: { _is_null: true } });
  }
  const tasks = usePlanProjectTasksQuery({
    variables: {
      tasks_where: { _and: taskFilters },
      plan_tasks_where: { _and: planTaskFilters },
      project_tasks_where: { project_id: { _eq: props.projectId } },
      order_by: [{ legacy_template_id: Order_By.Asc }],
    },
    fetchPolicy: "network-only",
  });

  const [updateProjectTasks] = useUpdateProjectTasksMutation({
    onCompleted: () => tasks.refetch && tasks.refetch(),
  });
  const notification = useNotification();

  // Setup form/submission.
  const formik = useFormik<ProjectTaskFormSchema>({
    validationSchema: projectTaskFormSchema,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: {
      tasks: [],
    },
    onSubmit: async (values) => {
      try {
        if (values.tasks?.length) {
          const projectTasksToUpsert = values.tasks
            .filter((t) => projectTaskUpdated(t) && t.inProject !== null)
            .map((t) => ({
              project_id: props.projectId,
              task_id: t.task_id,
              min_per_device: t.inProject!.min_per_device,
              priority: t.inProject!.priority,
            }));
          const projectTaskIdsToRemove = values.tasks
            .filter((t) => projectTaskRemoved(t))
            .map((t) => t.inProjectOriginal!.id! as string);
          await updateProjectTasks({
            variables: {
              upsert_project_tasks: projectTasksToUpsert,
              remove_project_task_ids: projectTaskIdsToRemove,
            },
          });
          notification.create({
            flashOnly: true,
            severity: "success",
            title: `update successful`,
          });
        }
      } catch (e) {
        formik.setSubmitting(false);
      }
    },
  });
  const { setFieldValue } = formik;

  // Hydrate form from task query.
  useEffect(() => {
    if (tasks.data?.tasks) {
      setFieldValue(
        "tasks",
        tasks.data.tasks.map((task) => ({
          key: task.id ?? "",
          task_id: task.id ?? "",
          legacy_template_id: task.legacy_template_id ?? 0,
          highlights: task.description ?? "",
          inPlan: task.plan_tasks?.length
            ? {
                id: task.plan_tasks[0].id ?? "",
                min_per_device: task.plan_tasks[0].min_per_device ?? 0,
                priority: task.plan_tasks[0].priority ?? 0,
              }
            : null,
          inProject: task.project_tasks?.length
            ? {
                id: task.project_tasks[0].id ?? "",
                min_per_device: task.project_tasks[0].min_per_device ?? 0,
                priority: task.project_tasks[0].priority ?? 0,
              }
            : null,
          inProjectOriginal: task.project_tasks?.length
            ? {
                id: task.project_tasks[0].id ?? "",
                min_per_device: task.project_tasks[0].min_per_device ?? 0,
                priority: task.project_tasks[0].priority ?? 0,
              }
            : null,
        })),
      );
    }
  }, [tasks, setFieldValue]);

  // Helper for determining how many edits are pending.
  const updatedCount = () =>
    formik.values.tasks?.filter((t) => projectTaskUpdated(t)).length;

  const path = pathProxy<typeof formik.values>();

  return (
    <Card style={{ marginBottom: 80 }}>
      <FormikProvider value={formik}>
        <Toolbar
          style={{ padding: 12, alignItems: "start" }}
          disableGutters={true}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tooltip
                placement="right-start"
                title={
                  <Typography variant="body2">
                    In order to organize the data collection effort for a{" "}
                    <b>project</b>, <b>tasks</b> must be added to that{" "}
                    <b>project</b>.
                    <br />
                    <br />
                    <b>Tasks</b> may be added individually, or from an existing{" "}
                    <b>plan</b>.
                    <br />
                    <br />
                    The <i>min per device</i> value will determine how many{" "}
                    <b>recordings</b> should be made for the given <b>task</b>,
                    for each of the devices under test. The <i>priority</i>{" "}
                    value indicates how important that task is to the data
                    collection effort.
                  </Typography>
                }
              >
                <Info color="disabled" />
              </Tooltip>
            </Grid>
            <DrawerButton
              bp={{ xs: 3 }}
              variant="contained"
              color="primary"
              label="Add Task"
              startIcon={<AddCircleOutline />}
              disableElevation
              fullWidth={true}
              content={(close) => (
                <AddNewProjectTaskForm
                  planId={plan?.id}
                  projectId={props.projectId}
                  onSuccess={() => {
                    close();
                    tasks.refetch && tasks.refetch();
                  }}
                />
              )}
            />
          </Grid>
        </Toolbar>
        <FieldArray
          name={path.tasks._}
          render={(helper) => (
            <CompareTable
              rows={formik.values.tasks ? formik.values.tasks : []}
              commonColumnHeader="Task"
              destinationColumnHeader={!!plan ? "This Project" : ""}
              rowEdited={(row) => projectTaskUpdated(row)}
              disableRowDelete={(row) => !row.inProject}
              sources={allPlans?.data?.plans ?? []}
              sourcePlaceholder="Compare with Plan"
              getSourceSelected={(a, b) => a.id === b.id}
              getSourceLabel={(plan) => plan.name}
              onSourceChange={(plan) => {
                setPlan(plan);
              }}
              onCopyAll={() =>
                setFieldValue(
                  "tasks",
                  formik.values.tasks?.map((t) => {
                    const replacement = { ...t };
                    if (replacement.inPlan)
                      replacement.inProject = replacement.inPlan;
                    return replacement;
                  }),
                )
              }
              onCopyRow={(row, index) => {
                const replacement = { ...row };
                replacement.inProject = replacement.inPlan;
                helper.replace(index, replacement);
              }}
              onDeleteAll={() =>
                setFieldValue(
                  "tasks",
                  formik.values.tasks?.map((t) => {
                    const replacement = { ...t };
                    replacement.inProject = null;
                    return replacement;
                  }),
                )
              }
              onDeleteRow={(row, index) => {
                const replacement = { ...row };
                replacement.inProject = null;
                helper.replace(index, replacement);
              }}
              onRevertAll={() =>
                setFieldValue(
                  "tasks",
                  formik.values.tasks?.map((t) => {
                    const replacement = { ...t };
                    replacement.inProject = replacement.inProjectOriginal;
                    return replacement;
                  }),
                )
              }
              onRevertRow={(row, index) => {
                const replacement = { ...row };
                replacement.inProject = replacement.inProjectOriginal;
                helper.replace(index, replacement);
              }}
              renderCommon={(row) => (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginRight: 20 }}>
                    <TaskLink
                      id={row.task_id}
                      number={row.legacy_template_id}
                    />
                  </div>
                  <div>{row.highlights ?? ""}</div>
                </div>
              )}
              renderDestination={(row, index) => (
                <Grid container direction="column" style={{ width: 175 }}>
                  {row.inProject ? (
                    <>
                      <IncDecField
                        name={path.tasks[index].inProject.min_per_device._}
                        label={"Min per Device"}
                        disabled={false}
                      />
                      <IncDecField
                        name={path.tasks[index].inProject.priority._}
                        label={"Priority"}
                        disabled={false}
                      />
                    </>
                  ) : row.inProjectOriginal ? (
                    <Typography variant="caption">Delete on update</Typography>
                  ) : (
                    <Typography variant="caption">Not in project</Typography>
                  )}
                </Grid>
              )}
              renderSource={(row, index) => (
                <Grid
                  container
                  direction="column"
                  style={{ width: row.inPlan ? 175 : 0 }}
                >
                  {plan?.name && row.inPlan ? (
                    <>
                      <IncDecField
                        name={path.tasks[index].inPlan.min_per_device._}
                        label={"Min per Device"}
                        disabled={true}
                      />
                      <IncDecField
                        name={path.tasks[index].inPlan.priority._}
                        label={"Priority"}
                        disabled={true}
                      />
                    </>
                  ) : (
                    plan?.name && (
                      <Typography variant="caption">Not in plan</Typography>
                    )
                  )}
                </Grid>
              )}
            />
          )}
        />
        <Fab
          size="large"
          color="primary"
          variant="extended"
          style={{
            position: "fixed",
            top: "auto",
            right: 30,
            bottom: 30,
            left: "auto",
          }}
          disabled={formik.isSubmitting || !updatedCount()}
          onClick={() => {
            formik.handleSubmit();
            if (Object.keys(formik.errors).length) {
              notification.create({
                severity: "error",
                title: `Project Task Form Error`,
                description: md()
                  .syntax("json", JSON.stringify(formik.errors))
                  .get(),
              });
            }
          }}
        >
          Update
        </Fab>
      </FormikProvider>
    </Card>
  );
};

// Component for adding task manually.
const newProjectTaskFormSchema = Yup.object({
  task: Yup.object({
    id: Yup.string().required(),
    number: Yup.string().required(),
  }).required(),
  min_per_device: Yup.number()
    .integer("must be a valid integer")
    .min(1, "must be larger than 0")
    .required()
    .nullable(),
  priority: Yup.number()
    .integer("must be a valid integer")
    .min(0, "cannot be negative")
    .required()
    .nullable(),
}).required();

type NewProjectTaskFormSchema = Yup.InferType<typeof newProjectTaskFormSchema>;

type AddNewProjectTaskFormProps = {
  projectId: string;
  planId: string | undefined;
  onSuccess?: () => void;
};

const AddNewProjectTaskForm = (props: AddNewProjectTaskFormProps) => {
  const notification = useNotification();

  const [updateProjectTasks] = useUpdateProjectTasksMutation();

  const formik = useFormik<NewProjectTaskFormSchema>({
    validationSchema: newProjectTaskFormSchema,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: true,
    initialValues: {
      task: { id: "", number: "" },
      min_per_device: 1,
      priority: 0,
    },
    onSubmit: async ({ task, ...values }) => {
      try {
        await updateProjectTasks({
          variables: {
            remove_project_task_ids: [],
            upsert_project_tasks: [
              {
                project_id: props.projectId,
                task_id: task!.id,
                ...values,
              },
            ],
          },
        });
        notification.create({
          flashOnly: true,
          severity: "success",
          title: `task added`,
        });
        props.onSuccess && props.onSuccess();
      } catch (e) {
        formik.setSubmitting(false);
      }
    },
  });

  const path = pathProxy<typeof formik.values>();

  return (
    <FormikProvider value={formik}>
      <FormContainer>
        <DialogSelect
          bp={{ xs: 12 }}
          name={path.task.number._}
          label="Task"
          onReset={() =>
            formik.setFieldValue("task", formik.initialValues.task)
          }
          content={(close) => {
            // Exclude tasks that are already attached to given project.
            const filters: Tasks_Bool_Exp[] = [
              { is_template: { _neq: true } },
              { is_test: { _neq: true } },
              {
                _not: {
                  project_tasks: { project_id: { _eq: props.projectId } },
                },
              },
            ];
            // Optionally, exclude tasks that are already attached to given plan.
            if (props.planId)
              filters.push({
                _not: { plan_tasks: { plan_id: { _eq: props.planId } } },
              });
            return (
              <AllTasksTable
                where={filters}
                selectable="single"
                onSelect={(task) => {
                  formik.setFieldValue("task", {
                    id: task.id,
                    number: `${task.legacy_template_id}`,
                  });
                  close();
                }}
              />
            );
          }}
        />
        <Grid item sm={6} xs={12}>
          <TextField name={path.min_per_device._} label="Min per Device" />
          <TextField name={path.priority._} label="Priority" />
        </Grid>
        <FormAction>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Add
          </Button>
        </FormAction>
      </FormContainer>
    </FormikProvider>
  );
};
