import { Card, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import qs from "query-string";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";

type QParams = { [field: string]: string };

const idArraySchema = Yup.array(Yup.string().required()).required();

export type ResourceNav = {
  route: string;
  showUrl: (id: string, params?: QParams) => string;
  show: (id: string, params?: QParams) => void;
  editUrl: (id: string, params?: QParams) => string;
  edit: (id: string, params?: QParams) => void;
  listUrl: (params?: QParams) => string;
  list: (params?: QParams) => void;
  createUrl: (params?: QParams) => string;
  create: (params?: QParams) => void;
  compareUrl: (ids: string[], params?: QParams) => string;
  compare: (ids: string[], params?: QParams) => void;
};

export const useResourceNav = (route: string): ResourceNav => {
  const history = useHistory();
  const makeUrl = (path: string, params: QParams | undefined) =>
    params && Object.keys(params).length > 0
      ? `${path}?${qs.stringify(params)}`
      : `${path}`;
  return {
    route: route,

    showUrl: (id: string, params?: QParams) =>
      makeUrl(`/${route}/${id}`, params),
    show: (id: string, params?: QParams) =>
      history.push(makeUrl(`/${route}/${id}`, params)),

    editUrl: (id: string, params?: QParams) =>
      makeUrl(`/${route}/${id}/edit`, params),
    edit: (id: string, params?: QParams) =>
      history.push(makeUrl(`/${route}/${id}/edit`, params)),

    createUrl: (params?: QParams) => makeUrl(`/${route}/create`, params),
    create: (params?: QParams) =>
      history.push(makeUrl(`/${route}/create`, params)),

    listUrl: (params?: QParams) => makeUrl(`/${route}`, params),
    list: (params?: QParams) => history.push(makeUrl(`/${route}`, params)),

    compareUrl: (ids: string[], params?: QParams) =>
      makeUrl(`/${route}/compare`, { ...params, ids: ids.join(",") }),
    compare: (ids: string[], params?: QParams) =>
      history.push(
        makeUrl(`/${route}/compare`, { ...params, ids: ids.join(",") }),
      ),
  };
};

type ResourceProps = {
  path: string;
  create?: (nav: ResourceNav) => React.ReactElement;
  list?: (nav: ResourceNav) => React.ReactElement;
  show?: (nav: ResourceNav, id?: string) => React.ReactElement;
  edit?: (nav: ResourceNav, id?: string) => React.ReactElement;
  compare?: (nav: ResourceNav, ids?: string[]) => React.ReactElement;
};

export const Resource = ({
  path,
  create,
  show,
  list,
  edit,
  compare,
}: ResourceProps) => {
  const classes = useStyles();
  const nav = useResourceNav(path);
  const match = useRouteMatch("/" + path);

  if (!match) return null;

  return (
    <div className={classes.container}>
      <Switch>
        {create && (
          <Route path={`/${path}/create`}>
            <Card className={classes.card} style={{ maxWidth: 1200 }}>
              {create(nav)}
            </Card>
          </Route>
        )}
        {compare && (
          <Route
            path={`/${path}/compare`}
            render={({ location }) => {
              const q = qs.parse(location.search, {
                arrayFormat: "comma",
              });
              // Allow single id (which will not be collected into an array).
              const ids = Array.isArray(q.ids) ? q.ids : [q.ids];
              const valid = idArraySchema.validateSync(ids);
              return compare(nav, valid ?? []);
            }}
          />
        )}
        {edit && (
          <Route
            path={`/${path}/:id/edit`}
            render={({ match }) => (
              <Card className={classes.card} style={{ maxWidth: 1200 }}>
                {edit(nav, match.params?.id)}
              </Card>
            )}
          />
        )}
        {show && (
          <Route
            path={`/${path}/:id`}
            render={({ match }) => show(nav, match.params?.id)}
          />
        )}
        {list && <Route path={`/${path}`}>{list(nav)}</Route>}
      </Switch>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { padding: 20, width: "100%", height: "100%", overflow: "auto" },
    card: {
      // width: "100%",
      // maxWidth: "800px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  }),
);
