import React from "react";
import { Button, Card, CardContent, Chip, Typography } from "@material-ui/core";
import { AddCircleOutline, ListAltOutlined } from "@material-ui/icons";
import { FormContainer, FormHeader, TextField } from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import {
  Customers as Customer,
  Order_By,
  Roles_Enum,
  useAllCustomersQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useCustomerQuery,
} from "src/generated/asgard/graphql";
import { useRequireRole } from "src/auth";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  Tab,
  Tabs,
  TabContent,
  TabLabel,
  TableViewHeader,
  TabView,
} from "src/Layout";
import { usePagination } from "src/resources/Utils";
import * as Yup from "yup";
import { AllProjectsTable, useProjectNav } from "../Project";
import { FormAction, ResourceForm } from "src/components/ResourceForm";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

export const CustomerTable = createTable<Customer>()({
  keys: (customer) => customer.id ?? "",
  title: "Customers",
  headers: {
    id: { display: "ID" },
    codename: { display: "Codename", align: "right" },
    projects: { display: "Projects" },
  },
  columns: (customer) => ({
    id: <EllipsisColumn value={customer.id} />,
    codename: <TextColumn value={customer.codename} />,
    projects: (
      <div>
        {customer.projects?.map((project) => {
          return (
            <Link
              key={project.id}
              component={RouterLink}
              to={`/projects/${project.id}`}
              style={{
                margin: "2px",
                display: "inline-block",
              }}
            >
              <Chip
                variant="outlined"
                label={project?.project_name?.display_name}
                onClick={() => null}
              />
            </Link>
          );
        })}
      </div>
    ),
  }),
});

export type CustomerListProps = {} & TableOptionals<typeof CustomerTable>;

export const CustomerList = (props: CustomerListProps) => {
  const [pageVars, pageController] = usePagination();
  const query = useAllCustomersQuery({
    variables: {
      ...pageVars,
      order_by: [{ codename: Order_By.Asc }],
    },
  });

  return (
    <CustomerTable
      {...props}
      {...pageController}
      total={query?.data?.customers_aggregate?.aggregate?.count}
      data={query.data?.customers}
    />
  );
};

type CustomerIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof CustomerTable>;

export const CustomerIndex = (props: CustomerIndexProps) => {
  const ag = useRequireRole([Roles_Enum.Asgard]);
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Customers</Typography>}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
          disabled={!ag}
          onClick={() => {
            props.onAddNew();
          }}
        >
          Add customer
        </Button>
      </TableViewHeader>
      <Card>
        <CustomerList selectable="none" {...props} />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Customers
const customerSchema = Yup.object({
  codename: Yup.string().required("required"),
}).required();

export type CustomerFormProps = {
  action: FormAction;
  customer?: { id: string };
  onSuccess?: (id: string) => void;
};

export const CustomerForm = (props: CustomerFormProps) => {
  const [createCustomer] = useCreateCustomerMutation();
  const [updateCustomer] = useUpdateCustomerMutation();

  const allCustomers = useAllCustomersQuery({
    variables: {
      where: { id: { _nin: props.customer?.id ? [props.customer.id] : [] } },
    },
    fetchPolicy: "network-only",
  });

  const existingCustomer = useCustomerQuery({
    variables: { id: props.customer?.id ?? "" },
    skip: !props.customer,
  });

  return (
    <ResourceForm
      action={props.action}
      schema={customerSchema}
      resourceToUpdate={existingCustomer.data?.customer}
      transform={(customer) => ({
        codename: customer.codename,
      })}
      initialValues={{
        codename: "",
      }}
      customValidator={(values, errors) => {
        for (let existing of allCustomers.data?.customers ?? []) {
          if (existing.codename === values.codename) {
            errors.codename = `Customer ${values.codename} already exists`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const result = await (async () => {
          if (props.customer?.id)
            return await updateCustomer({
              variables: {
                id: props.customer.id,
                input: { ...values },
              },
            });
        })();
        props.onSuccess && props.onSuccess(result?.data?.customer?.id ?? "");
      }}
      onInsert={async (values) => {
        const result = await (async () => {
          return await createCustomer({ variables: { ...values } });
        })();
        props.onSuccess && props.onSuccess(result.data?.customer?.id ?? "");
      }}
      render={({ path }) => (
        <>
          <FormHeader>
            {props.customer ? "Update Customer" : "Create New Customer"}
          </FormHeader>
          <FormContainer>
            <TextField
              bp={{ xs: 12, sm: 8 }}
              name={path.codename._}
              label="Codename"
            />
          </FormContainer>
        </>
      )}
    />
  );
};

type CustomerShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Customer>) => void;
};

const CustomerShow = (props: CustomerShowProps) => {
  const projectNav = useProjectNav();
  const customerNav = useCustomerNav();
  const customerQuery = useCustomerQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const customer = customerQuery.data?.customer;

  if (!customer) return null;
  return (
    <ShowResourceView
      title={customer.codename ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="customers" onClick={() => customerNav.list()} />
          <Breadcrumb label={customer.codename} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(customer)}
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabsProps) => (
          <Tabs {...tabsProps}>
            <Tab
              label={<TabLabel label="Projects" icon={<ListAltOutlined />} />}
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent index={0} current={current}>
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <AllProjectsTable
                    selectable="none"
                    hideColumns={{ customer: true }}
                    showUrl={(item) => item.id && projectNav.showUrl(item.id)}
                    where={[{ customer_id: { _eq: customer.id } }]}
                  />
                </CardContent>
              </Card>
            </TabContent>
          </>
        )}
      />
    </ShowResourceView>
  );
};

export const useCustomerNav = () => useResourceNav("customers");
export const CustomerResource = () => {
  return (
    <Resource
      path="customers"
      list={(nav) => (
        <CustomerIndex
          onAddNew={() => nav.create()}
          showUrl={(item) => item.id && nav.showUrl(item.id)}
        />
      )}
      edit={(nav, id) => (
        <CustomerForm
          customer={{ id: id ?? "" }}
          action="update"
          onSuccess={(id) => nav.show(id)}
        />
      )}
      create={(nav) => (
        <CustomerForm action="insert" onSuccess={(id) => nav.show(id)} />
      )}
      show={(nav, id) => (
        <CustomerShow
          id={id ?? ""}
          onEditAction={(item) => item.id && nav.edit(item.id)}
        />
      )}
    />
  );
};
