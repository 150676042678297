import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  Container,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { AddCircleOutline, Archive, Refresh } from "@material-ui/icons";
import { Pagination as MUIPagination, Skeleton } from "@material-ui/lab";
import Fuse from "fuse.js";
import qs from "query-string";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RequireRole, useAuth } from "src/auth";
import { Gravatar } from "src/components";
import { SearchFilter } from "src/components/SearchFilter";
import ProjectSelectFilter from "src/components/ProjectSelectFilter";
import { OpMode, OpmodeChip } from "src/components/table/OpmodeChip";
import {
  AllSearchableProjectsQueryResult,
  Roles_Enum,
  useAllSearchableProjectsQuery,
  useProjectDatasetAggregateLazyQuery,
} from "src/generated/asgard/graphql";
import { useDebouncedEffect } from "src/hooks/useDebouncedEffect";
import { TableViewHeader } from "src/Layout";
import { useProjectNav } from "src/resources/Project";
import { PageController, usePagination } from "src/resources/Utils";
import * as Yup from "yup";

export function ProjectList() {
  const projectNav = useProjectNav();
  const [pageVars, pageController] = usePagination({ limit: 12 });

  const [selected, setSelected] = useState<string[]>([]);
  const history = useHistory();
  const [{ search: initialValue }] = useState<{ search: string }>(
    () => qs.parse(history.location.search) as any,
  );
  const [{ filter: initialFilter }] = useState<{ filter: string }>(
    () => qs.parse(history.location.search) as any,
  );
  const [filter, setFilter] = useState<string | null>(initialFilter);
  const [title, setTitle] = useState("All Projects");
  const handleSelect = (projectId: string, isSelected: boolean) => {
    setSelected((current) => {
      const filtered = current.filter((id) => id !== projectId);
      return isSelected ? [...filtered, projectId] : filtered;
    });
  };

  const projects = useAllSearchableProjectsQuery();
  const auth = useAuth();
  const [searchResult, setSearchResult] = useState<
    | NonNullable<AllSearchableProjectsQueryResult["data"]>["projects"]
    | undefined
  >([]);

  const projectResult = useMemo(() => {
    const result = searchResult ?? projects?.data?.projects ?? [];

    if (filter === "my_projects") {
      return result.filter(({ project_users }) =>
        project_users.some(
          ({ user_id }) =>
            user_id.trim().toLowerCase() === auth.user.id.trim().toLowerCase(),
        ),
      );
    }

    if (filter?.startsWith("deliverable/")) {
      const deliverableName = filter.split("/")[1];
      return result.filter(
        ({ deliverable }) => deliverable.name === deliverableName,
      );
    }

    return result;
  }, [filter, searchResult, projects, auth.user.id]);

  const total = projectResult.length;

  const fuse = useMemo(() => {
    const options: Fuse.IFuseOptions<any> = {
      includeScore: true,
      includeMatches: true,
      threshold: 0.3,
      keys: [
        "id",
        "project_name.display_name",
        "project_name.name",
        "deliverable.name",
        "opmode_projects.opmode.number",
        "simulation_opmode.number",
        "calibration_opmode.number",
        "project_users.user.name",
        "project_users.user.email",
      ],
    };

    return new Fuse(projects.data?.projects ?? [], options);
  }, [projects.data?.projects]);

  const updateFilter = useCallback(
    (filter: string) => {
      const { ...query } = qs.parse(history.location.search);
      history.replace("?" + qs.stringify({ ...query, filter: filter }));
      if (filter === "my_projects") {
        setTitle("My Projects");
      } else if (filter?.startsWith("deliverable/")) {
        const deliverableName = filter.split("/")[1];
        setTitle(`${deliverableName} Projects`);
      } else {
        setTitle("All Projects");
      }
    },
    [history],
  );

  useEffect(() => {
    updateFilter(filter || "all_projects");
  }, [filter, updateFilter]);

  const handleSearch = useCallback(
    (s: string) => {
      const term = s.trim();
      const { ...query } = qs.parse(history.location.search);
      history.replace("?" + qs.stringify({ ...query, search: term }));
      if (term.length === 0) {
        setSearchResult(undefined);
        return;
      }
      const result = fuse.search(term);
      setSearchResult(
        result
          .sort((a, b) => {
            const aDeleted = Boolean(a.item.deleted_at);
            const bDeleted = Boolean(b.item.deleted_at);

            return aDeleted && !bDeleted ? 1 : !aDeleted && bDeleted ? -1 : 0;
          })
          .map((r) => r.item),
      );
    },
    [fuse, history],
  );

  const projectSlice = useMemo(
    () =>
      projectResult.slice(pageVars.offset, pageVars.offset + pageVars.limit),
    [projectResult, pageVars],
  );

  const [fetchProjectDataset, projectDatasetResult] =
    useProjectDatasetAggregateLazyQuery();

  const projectDataset =
    projectDatasetResult?.data?.projects?.reduce<
      Record<string, number | undefined>
    >((acc, p) => {
      acc[p.id] = p.project_dataset_aggregate?.aggregate?.count;
      return acc;
    }, {}) ?? {};

  // Only fetch recording count from dataset when appropriate
  useDebouncedEffect(
    () => {
      if (projects.loading) {
        return;
      }

      fetchProjectDataset({
        variables: { ids: projectSlice?.map((p) => p.id) ?? [] },
      });
    },
    [projects.loading, projectSlice],
    250,
  );

  if (projects.loading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Container style={{ padding: 0 }}>
        <TableViewHeader title={<Typography variant="h5">{title}</Typography>}>
          <RequireRole roles={[Roles_Enum.Asgard]}>
            <Button
              onClick={() => projectNav.create()}
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutline />}
              disableElevation
            >
              New Project
            </Button>
          </RequireRole>
        </TableViewHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SearchFilter
            update={handleSearch}
            initialValue={initialValue ?? ""}
          />
          <div style={{ width: 10 }} />
          <ProjectSelectFilter
            update={setFilter}
            initialValue={initialFilter}
          />
        </div>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          {projectSlice.map((p) => {
            const opmodes = p.opmode_projects.map(
              (o): OpMode => ({
                num: o.opmode.number,
                simOpMode: false,
                calibOpMode: false,
              }),
            );
            if (p.simulation_opmode) {
              opmodes.push({
                num: p.simulation_opmode.number,
                simOpMode: true,
                calibOpMode: false,
              });
            }
            if (p.calibration_opmode) {
              opmodes.push({
                num: p.calibration_opmode.number,
                simOpMode: false,
                calibOpMode: true,
              });
            }
            const name = p.project_name?.display_name ?? "unknown";
            const created_at = p.created_at;
            const date = created_at ? new Date(created_at) : null;
            const month_year = date
              ? date.toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                })
              : "N/A";
            const managers = p.project_users.filter(
              (u) => u.user?.name?.length,
            );
            const manager = managers.length > 0 ? managers[0] : null;
            return (
              <Fragment key={p.id}>
                <ProjectCard
                  key={p.id}
                  id={p.id}
                  archivedAt={p.deleted_at}
                  opmodes={opmodes}
                  selected={selected.includes(p.id)}
                  onSelectChange={(isSelected) =>
                    handleSelect(p.id, isSelected)
                  }
                >
                  <ProjectTitle
                    title={name}
                    subTitle={
                      manager !== null
                        ? managers.length > 1
                          ? `managed by ${manager.user.name} +${
                              managers.length - 1
                            }`
                          : `managed by ${manager.user.name}`
                        : `no manager assigned`
                    }
                    email={manager?.user.email}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={1}>
                      <StatusItem primary={month_year} secondary="Created" />
                      <StatusItem
                        primary={
                          projectDataset[p.id] ?? <Skeleton variant="text" />
                        }
                        secondary="Recordings"
                      />
                      <StatusItem
                        primary={
                          p.scores[0]
                            ? (p.scores[0].greatness * 100).toFixed(1) + "%"
                            : "N/A"
                        }
                        secondary="Greatness"
                      />
                    </Grid>
                  </CardContent>
                </ProjectCard>
              </Fragment>
            );
          })}
        </Grid>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 50,
              paddingBottom: 20,
            }}
          >
            <Pagination {...pageController} total={total} />
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          position: "fixed",
          top: "auto",
          right: 30,
          bottom: 30,
          left: "auto",
        }}
      >
        <Fab
          size="large"
          color="primary"
          variant="extended"
          disabled={selected.length < 1}
          onClick={() => {
            setSelected([]);
          }}
        >
          <Refresh />
        </Fab>
        <Fab
          style={{ marginLeft: 4 }}
          size="large"
          color="primary"
          variant="extended"
          disabled={selected.length < 1}
          onClick={() => {
            projectNav.compare(selected);
          }}
        >
          Compare {selected.length} Project(s)
        </Fab>
      </div>
    </>
  );
}

const PageURLQuery = Yup.object({
  page: Yup.string().required(),
});

type PaginationProps = {
  total?: number | null;
  page?: number | null;
} & PageController;

function Pagination(props: PaginationProps) {
  const [page, setPage] = useState(props.page ?? 0);
  const hist = useHistory();
  const loc = useLocation();
  const count = props.total ? Math.ceil(props.total / props.limit) : 0;
  const query = qs.parse(loc.search);

  const handleChangePage = (_: unknown, newPage: number) => {
    const newqs = qs.stringify({ ...query, page: newPage - 1 });
    hist.push("?" + newqs);
  };

  useEffect(() => {
    if (count === 0) {
      return;
    }

    if (!PageURLQuery.isValidSync(query)) {
      return;
    }

    setPage(parseInt(query.page) % count);
    return;
  }, [count, loc.search, props, query]);

  useEffect(() => {
    props.setOffset(page * props.limit);
  }, [page, props]);

  return (
    <MUIPagination
      size="medium"
      page={page + 1}
      count={count}
      siblingCount={2}
      onChange={handleChangePage}
    />
  );
}

type ProjectCardProps = {
  id: string;
  archivedAt?: null | string;
  opmodes: Array<OpMode>;
  tags?: Array<string>;
  children?: JSX.Element | Array<JSX.Element | null> | null;
  selected?: boolean;
  onSelectChange?: (selected: boolean) => void;
};

const useStyles = makeStyles({
  projectCard: {
    marginTop: "10px",
    transition: "all 0.2s ease",
    boxShadow: "0px 2px 4px #00000055",
    "&:hover": {
      boxShadow: "0px 10px 20px #00000055",
      transform: "translateY(-10px)",
    },
    backgroundColor: (props: ProjectCardProps) =>
      props.archivedAt ? "#dddddd" : undefined,
  },
  cardLayer: {
    margin: 5,
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  compareSelect: {
    margin: 10,
    position: "absolute",
    top: 0,
    left: 0,
  },
});

function ProjectCard(props: ProjectCardProps) {
  const projectNav = useProjectNav();
  const classes = useStyles(props);

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Link
        to={projectNav.showUrl(props.id)}
        style={{ textDecoration: "none" }}
      >
        <Card className={classes.projectCard}>
          <div style={{ position: "relative" }}>
            <CardMedia
              style={{
                height: 0,
                paddingTop: "36.29%",
                backgroundPositionY: "center",
              }}
              image={`https://picsum.photos/seed/${props.id}/400/400`}
              title="cover"
            />
            <div className={classes.cardLayer}>
              {props.tags?.map((t) => (
                <Chip
                  style={{ margin: 3 }}
                  key={t}
                  title="Tag"
                  label={t}
                  size="small"
                />
              ))}
              {props.archivedAt && (
                <Chip
                  icon={<Archive />}
                  style={{ margin: 3 }}
                  key={"archived"}
                  title="Tag"
                  label={"archived"}
                  size="small"
                />
              )}
              {props.opmodes.map((o) => (
                <OpmodeChip
                  opMode={o}
                  key={`${o.num}_${o.simOpMode}${o.calibOpMode}`}
                />
              ))}
            </div>
          </div>
          {props.children}
        </Card>
      </Link>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={props.selected}
              onChange={(event) =>
                props.onSelectChange &&
                props.onSelectChange(event.target.checked)
              }
            />
          }
          label={<Typography variant="caption">Compare</Typography>}
        />
      </Box>
    </Grid>
  );
}

type ProjectTitleProps = {
  title: string;
  email?: string | null;
  subTitle?: string | null;
};

function ProjectTitle(props: ProjectTitleProps) {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Gravatar email={props.email ?? props.title} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography style={{ fontWeight: "bold" }}>{props.title}</Typography>
        }
        secondary={
          <Fragment>
            <Typography
              component="span"
              variant="body2"
              style={{ display: "inline" }}
              color="textPrimary"
            >
              {props.subTitle}
            </Typography>
          </Fragment>
        }
      />
    </ListItem>
  );
}

type StatusItemProps = {
  primary: React.ReactNode;
  secondary: string | number;
};

function StatusItem(props: StatusItemProps) {
  return (
    <Grid item xs={4} style={{ textAlign: "center" }}>
      <Typography variant="h6">{props.primary}</Typography>
      <Typography variant="caption">{props.secondary}</Typography>
    </Grid>
  );
}
