import { TextField, InputAdornment, Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { usePrevious } from "src/resources/Utils";
import { Search, CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

type SearchFieldProps = {
  onChange: (text: string) => void;
  delay?: number;
};

export const SearchBar = ({ onChange, delay }: SearchFieldProps) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [text, setText] = useState("");
  const prev = usePrevious(text);

  useEffect(() => {
    if (prev === text) return;

    if (timer != null) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        onChange(text);
      }, delay ?? 650),
    );
  }, [text, onChange, timer, prev, delay]);

  return (
    <TextField
      placeholder="search"
      variant="outlined"
      fullWidth
      onChange={(ev) => setText(ev.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

type MultiSelectFilterProps<T> = {
  options?: Array<T>;
  value?: Array<T>;
  getOptionLabel: (opt: T) => string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  onChange?: (val: Array<T>) => void;
  getOptionSelected?: (option: T, value: T) => boolean;
  groupBy?: (val: T) => string;
};

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export function MultiSelectFilter<T>(props: MultiSelectFilterProps<T>) {
  return (
    <Autocomplete
      value={props.value}
      disabled={props.disabled || !props.options}
      onChange={({ target, ...e }, nv) => {
        props.onChange && props.onChange(nv);
      }}
      getOptionSelected={props.getOptionSelected}
      fullWidth
      multiple
      options={props.options ?? []}
      groupBy={props.groupBy}
      disableCloseOnSelect
      getOptionLabel={props.getOptionLabel}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {props.getOptionLabel(option)}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={true}
          variant="outlined"
          label={props.label}
          multiline={false}
          placeholder={props.placeholder}
        />
      )}
    />
  );
}
