import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  Checkbox as MuiCheckbox,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
  IconButton,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Close,
  Code,
  FormatListNumbered,
  Info,
  ListAltOutlined,
  Person,
  TrackChanges,
  Traffic,
  DeveloperBoard,
  LinearScale,
  Delete,
} from "@material-ui/icons";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { Moment } from "moment";
import MomentUtils from "@date-io/moment";
import { FieldArray, useField, useFormikContext } from "formik";
import Markdown from "react-markdown";
import * as Yup from "yup";
import {
  Checkbox,
  DialogSelect,
  DrawerButton,
  FormContainer,
  FormHeader,
  IncDecField,
  TextField,
  TextFieldWithOptions,
  NumericField,
} from "src/components/Form";
import {
  Opmode_Project_Insert_Input,
  useAllProjectsQuery,
  useAg_CreateProjectMutation,
  useProjectQuery,
  useAg_UpdateProjectMutation,
  Opmode_Project_Arr_Rel_Insert_Input,
  useAllUsersQuery,
  useAllXtensaConfigurationsQuery,
  Project_Combo_Arr_Rel_Insert_Input,
  Project_Combo_Insert_Input,
  useUpsertDynamicScalingMutation,
  Mode_Project_Insert_Input,
} from "src/generated/asgard/graphql";
import { TabLabel, InfoDisplay, Tablature } from "src/Layout";
import { CustomerList } from "src/resources/Customer";
import { AllDeliverablesTable } from "src/resources/Deliverable";
import { AllModelsTable } from "src/resources/Model";
import { AllOpmodesTable } from "src/resources/Opmode";
import { md } from "src/resources/Utils";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import { AllProjectsTable } from "./table";
import { AllModesTable } from "../Mode";

const projectSchema = Yup.object({
  min_devices_per_task: Yup.number().integer().min(0).max(10000).required(),
  slack_channel: Yup.string(),
  enable_greatness_processing: Yup.boolean().required(),
  enable_ground_truth_processing: Yup.boolean().required(),
  enable_arcadia_processing: Yup.boolean().required(),
  customer: Yup.object({
    id: Yup.string().required("required"),
    codename: Yup.string().required("required"),
  }).required(),
  model: Yup.object({
    id: Yup.string().required("required"),
    codename: Yup.string().required("required"),
  }).required(),
  deliverable: Yup.object({
    id: Yup.string().required("required"),
    name: Yup.string().required("required"),
    ground_truth_type: Yup.object({
      name: Yup.string().required(),
      default_near_threshold: Yup.number().nullable(true),
      default_far_threshold: Yup.number().nullable(true),
      min_near_threshold: Yup.number().nullable(true),
      max_near_threshold: Yup.number().nullable(true),
      min_far_threshold: Yup.number().nullable(true),
      max_far_threshold: Yup.number().nullable(true),
    }).required(),
  }).required(),
  variant: Yup.string().matches(/^\S*$/, "variant may not contain whitespace"),
  description: Yup.string(),
  usersToAdd: Yup.array().of(Yup.string().required("required")),
  simOpmode: Yup.object({
    id: Yup.string().nullable(),
    number: Yup.string().nullable(),
  })
    .required()
    .nullable(),
  calibOpmode: Yup.object({
    id: Yup.string().nullable(),
    number: Yup.string().nullable(),
  })
    .nullable()
    .required(),
  azure_build_ref: Yup.string().matches(
    /^[0-9]+$/,
    "must be a positive integer",
  ),
  azure_release_ref: Yup.string().matches(
    /^[0-9]+$/,
    "must be a positive integer",
  ),
  firmware_engine_release_ref: Yup.string().matches(
    /^[0-9]+$/,
    "must be a positive integer",
  ),
  XtensaConfiguration: Yup.object({
    id: Yup.string().nullable(),
    value: Yup.string().nullable(),
  })
    .nullable()
    .required(),
  mongoQuery: Yup.object({
    collection: Yup.string()
      .matches(/^(danais)/)
      .required(),
    params: Yup.object({
      operation_modes: Yup.array(
        Yup.string()
          .matches(/^[0-9]+$/, "must be a positive integer")
          .required(),
      ),
      date_start: Yup.date().nullable(),
      date_end: Yup.date().nullable(),
    }).required(),
  }).nullable(),
  opmodesToAdd: Yup.array(
    Yup.object({
      opmode: Yup.object({
        id: Yup.string().required(),
        number: Yup.number().required(),
      }).required(),
    }).required(),
  ),
  opmodesToRemove: Yup.array(
    Yup.object({
      op: Yup.object({
        id: Yup.string().required(),
        number: Yup.number().required(),
      }).required(),
    }).required(),
  ),
  modes: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      name: Yup.string().required(),
      parameters: Yup.array(
        Yup.object({
          name: Yup.string().required(),
          value: Yup.string().required(),
        }).required(),
      ),
    }).required(),
  ),
  // TODO!!! Replace thresholds with modes!!!
  near_threshold: Yup.number()
    .integer()
    .nullable()
    .when(
      [
        "deliverable.ground_truth_type.min_near_threshold",
        "deliverable.ground_truth_type.max_near_threshold",
      ],
      (min, max, schema: any) => {
        if (min !== null || max !== null) {
          // Use the min if exists, otherwise set min to 0.
          const newSchema = schema.required().min(min !== null ? min : 0);
          // Use the max if exists, otherwise no max.
          return max !== null ? newSchema.max(max) : newSchema;
        } else {
          return schema;
        }
      },
    ),
  far_threshold: Yup.number()
    .integer()
    .nullable()
    .when(
      [
        "deliverable.ground_truth_type.min_far_threshold",
        "deliverable.ground_truth_type.max_far_threshold",
        "near_threshold",
      ],
      (min, max, nearThreshold, schema: any) => {
        if (min !== null || max !== null || nearThreshold !== null) {
          // Use the greater of the two minimum values.
          // (Makes for the most conservative range.)
          const newMin = Math.max(
            min !== null ? min : 0,
            nearThreshold !== null ? nearThreshold : 0,
          );
          const newSchema = schema.required().min(newMin);
          return max !== null ? newSchema.max(max) : newSchema;
        } else {
          return schema;
        }
      },
    ),
  comboProjects: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      name: Yup.string().required(),
    }).required(),
  ),
  dynamic_scaling: Yup.object({
    min_value: Yup.number()
      .integer()
      .nullable()
      .min(1, "Minimum value must be at least 1")
      .max(1000, "Minimum value must be at most 1000"),
    max_value: Yup.number()
      .integer()
      .nullable()
      .min(1, "Maximum value must be at least 1")
      .max(1000, "Maximum value must be at most 1000")
      .test(
        "all-or-none",
        "All three values (min_value, max_value, benchmark_scaling) must be provided if any one is set",
        function (value) {
          const { min_value, benchmark_scaling } = this.parent;
          const allNull =
            value === null && min_value === null && benchmark_scaling === null;
          const allProvided =
            value !== null && min_value !== null && benchmark_scaling !== null;
          return allNull || allProvided;
        },
      )
      .test(
        "max-greater-than-min",
        "Maximum value must be greater than minimum value",
        function (value) {
          const { min_value } = this.parent;
          return min_value === null || value === null || value > min_value;
        },
      ),
    benchmark_scaling: Yup.number()
      .integer()
      .nullable()
      .min(1, "Benchmark value must be at least 1")
      .max(1000, "Benchmark value must be at most 1000")
      .test(
        "all-or-none",
        "All three values (min_value, max_value, benchmark_scaling) must be provided if any one is set",
        function (value) {
          const { min_value, max_value } = this.parent;
          const allNull =
            value === null && min_value === null && max_value === null;
          const allProvided =
            value !== null && min_value !== null && max_value !== null;
          return allNull || allProvided;
        },
      ),
  }),
}).required();

export type ProjectFormProps = {
  action: FormAction;
  project?: { id: string };
  onSuccess?: (id: string) => void;
};

interface DynamicScaling {
  min_value: number | null | undefined;
  max_value: number | null | undefined;
  benchmark_scaling: number | null | undefined;
}

export const ProjectForm = (props: ProjectFormProps) => {
  const [createProject] = useAg_CreateProjectMutation();
  const [updateProject] = useAg_UpdateProjectMutation();

  const existingProjects = useAllProjectsQuery({
    variables: {
      where: { id: { _nin: props.project?.id ? [props.project.id] : [] } },
    },
  });

  // Get unique list of variants, to suggest to user.
  const existingVariants: string[] =
    existingProjects.data?.projects
      ?.map((project) => project.variant)
      .filter((value, index, self) => self.indexOf(value) === index) ?? [];

  const existingSimOpmodeIds =
    existingProjects.data?.projects
      .filter((project) => project.simulation_opmode?.id)
      .map(
        (project) =>
          // It should be safe to use ! here because of the filter
          project.simulation_opmode!.id,
      ) ?? [];

  const projectQuery = useProjectQuery({
    variables: { id: props.project?.id ?? "" },
    skip: !props.project,
    fetchPolicy: "network-only",
  });
  const existingProjectOpmodeIds: string[] =
    projectQuery?.data?.project?.opmode_projects?.map((op) => op.opmode.id) ??
    [];
  const existingComboProjectIds: string[] =
    projectQuery?.data?.project?.project_combos?.map(
      (pc) => pc.other_project.id,
    ) ?? [];

  const existingModeIds =
    projectQuery?.data?.project?.mode_projects?.map((mp) => mp.mode_id) ?? [];

  const projectName = projectQuery.data?.project?.project_name?.name;
  const [showVariantWarning, setShowVariantWarning] = useState(false);
  const [showDeliverableWarning, setShowDeliverableWarning] = useState(false);
  const [allowChangeVariant, setAllowChangeVariant] = useState(
    props.action === "insert",
  );
  const [allowChangeDeliverable, setAllowChangeDeliverable] = useState(
    props.action === "insert",
  );
  const { data: xtensaData } = useAllXtensaConfigurationsQuery();
  const xtensaConfigurations =
    xtensaData?.xtensa_configurations.map((config) => ({
      id: config.id,
      value: config.value,
    })) || [];
  const [upsertDynamicScaling] = useUpsertDynamicScalingMutation();

  const handleUpsertDynamicScaling = async (
    dynamic_scaling?: DynamicScaling,
  ): Promise<string | null> => {
    const minValue = dynamic_scaling?.min_value;
    const maxValue = dynamic_scaling?.max_value;
    const benchmarkScaling = dynamic_scaling?.benchmark_scaling;
    if (
      typeof minValue === "number" &&
      typeof maxValue === "number" &&
      typeof benchmarkScaling === "number"
    ) {
      const { data } = await upsertDynamicScaling({
        variables: {
          min_value: minValue,
          max_value: maxValue,
          benchmark_scaling: benchmarkScaling,
        },
      });
      return data?.insert_dynamic_scaling?.returning[0]?.id ?? null;
    }
    return null;
  };

  return (
    <ResourceForm
      action={props.action}
      schema={projectSchema}
      resourceToUpdate={projectQuery.data?.project}
      transform={(project) => {
        let mongoSourceQuery = project.mongo_source_query;
        let currentOpmodes = project.opmode_projects?.map((opmodes) =>
          String(opmodes.opmode.number),
        );

        try {
          mongoSourceQuery =
            mongoSourceQuery && JSON.parse(String(mongoSourceQuery));
        } catch (e) {}

        if (mongoSourceQuery == null) {
          mongoSourceQuery = {
            collection: "danais",
            params: {
              operation_modes: currentOpmodes,
            },
          };
        }
        return {
          min_devices_per_task: project.min_devices_per_task ?? 0,
          slack_channel: project.slack_channel ?? "",
          enable_greatness_processing:
            project.enable_greatness_processing ?? false,
          enable_ground_truth_processing:
            project.enable_ground_truth_processing ?? false,
          enable_arcadia_processing: project.enable_arcadia_processing ?? false,
          modes: project.mode_projects.map((pm) => ({
            id: pm.mode.id,
            name: pm.mode.name,
            parameters: pm.mode.mode_parameters.map((mp) => ({
              name: mp.parameter.name,
              value: mp.value,
            })),
          })),
          near_threshold: project.near_threshold ?? null,
          far_threshold: project.far_threshold ?? null,
          customer: {
            id: project.customer.id,
            codename: project.customer.codename,
          },
          model: {
            id: project.model.id,
            codename: project.model.codename,
          },
          deliverable: {
            id: project.deliverable.id,
            name: project.deliverable.name,
            ground_truth_type: {
              name: project.deliverable.ground_truth_type?.name ?? "",
              default_near_threshold:
                project.deliverable.ground_truth_type?.default_near_threshold ??
                null,
              default_far_threshold:
                project.deliverable.ground_truth_type?.default_far_threshold ??
                null,
              min_near_threshold:
                project.deliverable.ground_truth_type?.min_near_threshold ??
                null,
              max_near_threshold:
                project.deliverable.ground_truth_type?.max_near_threshold ??
                null,
              min_far_threshold:
                project.deliverable.ground_truth_type?.min_far_threshold ??
                null,
              max_far_threshold:
                project.deliverable.ground_truth_type?.max_far_threshold ??
                null,
            },
          },
          variant: project.variant,
          description: project.description ?? "",
          usersToAdd: [],
          simOpmode: project.simulation_opmode
            ? {
                id: project.simulation_opmode.id,
                number: String(project.simulation_opmode.number),
              }
            : { id: null, number: "" },
          calibOpmode: project.calibration_opmode
            ? {
                id: project.calibration_opmode.id,
                number: String(project.calibration_opmode.number),
              }
            : { id: null, number: "" },
          azure_build_ref: `${project.azure_build_ref ?? ""}`,
          azure_release_ref: `${project.azure_release_ref ?? ""}`,
          firmware_engine_release_ref: `${
            project.firmware_engine_release_ref ?? ""
          }`,
          XtensaConfiguration: project.xtensa_configuration
            ? {
                id: project.xtensa_configuration.id,
                value: project.xtensa_configuration.value,
              }
            : { id: null, value: "" },
          mongoQuery: mongoSourceQuery,
          opmodesToAdd: [],
          opmodesToRemove: [],
          comboProjects: project.project_combos.map((combo) => ({
            id: combo.other_project.id,
            name: combo.other_project?.project_name?.name ?? "",
          })),
          dynamic_scaling: project.dynamic_scaling
            ? {
                min_value: project.dynamic_scaling.min_value,
                max_value: project.dynamic_scaling.max_value,
                benchmark_scaling: project.dynamic_scaling.benchmark_scaling,
              }
            : { min_value: null, max_value: null, benchmark_scaling: null },
        };
      }}
      initialValues={{
        description: "",
        variant: "",
        slack_channel: "",
        min_devices_per_task: 3,
        enable_greatness_processing: false,
        enable_ground_truth_processing: false,
        enable_arcadia_processing: false,
        near_threshold: null,
        far_threshold: null,
        modes: [],
        customer: {
          id: "",
          codename: "",
        },
        model: {
          id: "",
          codename: "",
        },
        deliverable: {
          id: "",
          name: "",
          ground_truth_type: {
            name: "",
            default_near_threshold: null,
            default_far_threshold: null,
            min_near_threshold: null,
            max_near_threshold: null,
            min_far_threshold: null,
            max_far_threshold: null,
          },
        },
        usersToAdd: [],
        simOpmode: { id: null, number: "" },
        calibOpmode: { id: null, number: "" },
        mongoQuery: {
          collection: "danais",
          params: {
            operation_modes: [],
            date_start: undefined,
            date_end: undefined,
          },
        },
        opmodesToAdd: [],
        opmodesToRemove: [],
        XtensaConfiguration: {
          id: null,
          value: "",
        },
        comboProjects: [],
        dynamic_scaling: {
          min_value: null,
          max_value: null,
          benchmark_scaling: null,
        },
      }}
      customValidator={(values, errors) => {
        for (let existing of existingProjects.data?.projects ?? []) {
          if (
            existing.customer.id === values.customer.id &&
            existing.model.id === values.model.id &&
            existing.variant === values.variant
          ) {
            const message = `Project already exists for ${values.customer.codename}/${values.model.codename}/${values.variant}`;
            errors.customer = { codename: message };
            errors.model = { codename: message };
            errors.variant = message;
            break;
          }
        }
        if (!values.simOpmode?.id) {
          if (values.enable_greatness_processing)
            errors.enable_greatness_processing = `cannot be enabled without a sim opmode`;
          if (values.enable_ground_truth_processing)
            errors.enable_ground_truth_processing = `cannot be enabled without a sim opmode`;
          if (values.enable_arcadia_processing)
            errors.enable_arcadia_processing = `cannot be enabled without a sim opmode`;
        }
      }}
      onUpdate={async (values) => {
        const {
          deliverable,
          model,
          customer,
          calibOpmode,
          simOpmode,
          mongoQuery,
          azure_build_ref,
          azure_release_ref,
          firmware_engine_release_ref,
          XtensaConfiguration,
          slack_channel,
          opmodesToAdd,
          opmodesToRemove,
          usersToAdd,
          comboProjects,
          dynamic_scaling,
          modes,
          ...projectInput
        } = values;
        const addOpmodeProjects: Opmode_Project_Insert_Input[] = opmodesToAdd!
          .filter((input) => input.opmode)
          .map((input) => ({
            project_id: props.project?.id,
            opmode_id: input.opmode?.id,
          }));
        const removeOpmodeProjects = opmodesToRemove!
          .filter((input) => input.op)
          .map((input) => input.op?.id) as string[];
        const allComboProjectIds: string[] = comboProjects!.map(({ id }) => id);
        const addComboProjects: Project_Combo_Insert_Input[] =
          allComboProjectIds!
            .filter((id) => !existingComboProjectIds.includes(id))
            .map((id) => ({
              project_id: props.project?.id,
              other_project_id: id,
            }));
        const removeComboProjects: string[] = existingComboProjectIds.filter(
          (id) => !allComboProjectIds.includes(id),
        );

        const dynamicScalingId = await handleUpsertDynamicScaling(
          dynamic_scaling,
        );
        const result = await (async () => {
          if (props.project?.id) {
            const modeIds: string[] = modes?.map((m) => m.id!) ?? [];
            const modesToAdd: Mode_Project_Insert_Input[] = modeIds
              .filter((id) => !existingModeIds.includes(id))
              .map((id) => ({ mode_id: id, project_id: props.project!.id }));
            const modeIdsToRemove: string[] = existingModeIds.filter(
              (id) => !modeIds.includes(id),
            );
            return await updateProject({
              variables: {
                id: props.project.id,
                input: {
                  ...projectInput,
                  calibration_opmode_id: calibOpmode?.id,
                  simulation_opmode_id: simOpmode?.id ?? null,
                  customer_id: customer.id,
                  deliverable_id: deliverable.id,
                  model_id: model.id,
                  slack_channel: slack_channel?.length ? slack_channel : null,
                  mongo_source_query: mongoQuery && JSON.stringify(mongoQuery),
                  date_start:
                    mongoQuery?.params?.date_start?.toString() ?? null,
                  date_end: mongoQuery?.params?.date_end?.toString() ?? null,
                  azure_build_ref: azure_build_ref
                    ? parseInt(azure_build_ref)
                    : null,
                  azure_release_ref: azure_release_ref
                    ? parseInt(azure_release_ref)
                    : null,
                  firmware_engine_release_ref: firmware_engine_release_ref
                    ? parseInt(firmware_engine_release_ref)
                    : null,
                  xtensa_configuration_id: XtensaConfiguration?.id,
                  dynamic_scaling_id: dynamicScalingId,
                },
                add_opmodes: addOpmodeProjects,
                remove_opmode_project_ids: removeOpmodeProjects,
                add_combo_projects: addComboProjects,
                remove_combo_project_ids: removeComboProjects,
                add_modes: modesToAdd,
                remove_mode_ids: modeIdsToRemove,
              },
            });
          }
        })();
        props.onSuccess && props.onSuccess(result?.data?.project?.id ?? "");
      }}
      onInsert={async (values) => {
        const {
          deliverable,
          model,
          customer,
          calibOpmode,
          simOpmode,
          mongoQuery,
          azure_build_ref,
          azure_release_ref,
          firmware_engine_release_ref,
          XtensaConfiguration,
          slack_channel,
          opmodesToAdd,
          opmodesToRemove,
          usersToAdd,
          comboProjects,
          dynamic_scaling,
          modes,
          ...projectInput
        } = values;
        const addOpmodeProjects: Opmode_Project_Arr_Rel_Insert_Input = {
          data: opmodesToAdd!
            .filter((input) => input.opmode)
            .map((input) => ({
              opmode_id: input.opmode.id,
            })),
        };
        const addComboProjects: Project_Combo_Arr_Rel_Insert_Input = {
          data: comboProjects!.map((cp) => ({ other_project_id: cp.id })),
        };
        const dynamicScalingId = await handleUpsertDynamicScaling(
          dynamic_scaling,
        );
        const result = await (async () => {
          return await createProject({
            variables: {
              input: {
                ...projectInput,
                calibration_opmode_id: calibOpmode?.id ?? null,
                customer_id: customer.id,
                deliverable_id: deliverable.id,
                model_id: model.id,
                project_users: {
                  data: usersToAdd?.map((user_id) => ({ user_id })) ?? [],
                },
                simulation_opmode_id: simOpmode?.id ?? null,
                slack_channel: slack_channel?.length ? slack_channel : null,
                mongo_source_query: mongoQuery && JSON.stringify(mongoQuery),
                date_start: mongoQuery?.params?.date_start?.toString() ?? null,
                date_end: mongoQuery?.params?.date_end?.toString() ?? null,
                azure_build_ref: azure_build_ref
                  ? parseInt(azure_build_ref)
                  : null,
                azure_release_ref: azure_release_ref
                  ? parseInt(azure_release_ref)
                  : null,
                firmware_engine_release_ref: firmware_engine_release_ref
                  ? parseInt(firmware_engine_release_ref)
                  : null,
                xtensa_configuration_id: XtensaConfiguration?.id,
                opmode_projects: addOpmodeProjects,
                project_combos: addComboProjects,
                dynamic_scaling_id: dynamicScalingId,
                mode_projects: {
                  data: (modes ?? []).map((m) => ({ mode_id: m.id })),
                },
              },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.project?.id ?? "");
      }}
      render={({ path, formik }) => {
        /**
         * When removing a "sim opmode", need to also remove from the mongo
         * query, and the "opmodes to add" array.
         */
        const opmodeRemovedFromMongoQuery = (
          opmodeId: string | null,
          opmodeNumber: string | undefined,
        ) => {
          if (!opmodeId) return;
          let mongoQuery = formik.values.mongoQuery;
          if (!!mongoQuery && !existingProjectOpmodeIds.includes(opmodeId)) {
            // Only remove from query if it is not already
            // attached to the project.
            const operation_modes = mongoQuery.params.operation_modes?.filter(
              (number) => number !== opmodeNumber,
            );
            mongoQuery.params = {
              ...mongoQuery.params,
              operation_modes,
            };
          }
          return mongoQuery;
        };
        const opmodeRemovedFromOpmodesToAdd = (
          opmodeId: string | null,
          opmodeNumber: string | undefined,
        ) => {
          if (!opmodeId) return;
          const opmodesToAdd = formik.values.opmodesToAdd!.filter(
            ({ opmode }) => opmode.id !== opmodeId,
          );
          return opmodesToAdd;
        };
        return (
          <>
            <FormHeader>
              {props.project ? "Update Project" : "Create New Project"}
            </FormHeader>
            <Tablature
              useUrlParams={false}
              tabs={[
                {
                  name: "general",
                  label: <TabLabel label="General" icon={<Info />} />,
                  content: (
                    <FormContainer>
                      {/* Variant can be edited, but only after a challenge. */}
                      {props.action === "update" && (
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={allowChangeVariant}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setShowVariantWarning(true);
                                  } else {
                                    setAllowChangeVariant(false);
                                  }
                                }}
                              />
                            }
                            label="Allow changes to variant"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={allowChangeDeliverable}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setShowDeliverableWarning(true);
                                  } else {
                                    setAllowChangeDeliverable(false);
                                  }
                                }}
                              />
                            }
                            label="Allow changes to deliverable"
                          />
                          <Dialog
                            open={showVariantWarning}
                            onClose={() => setShowVariantWarning(false)}
                          >
                            <DialogContent>
                              <Typography
                                variant="h6"
                                color="error"
                                align="center"
                                display="block"
                              >
                                Warning!
                              </Typography>
                              <Typography align="center" display="block">
                                Changing the variant will change the project
                                name everywhere.
                              </Typography>
                              {projectName && (
                                <Typography align="center" display="block">
                                  "{projectName}" will no longer refer to this
                                  project.
                                </Typography>
                              )}
                              <Typography align="center" display="block">
                                Are you sure?
                              </Typography>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setShowVariantWarning(false);
                                  setAllowChangeVariant(true);
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                onClick={() => {
                                  setShowVariantWarning(false);
                                  setAllowChangeVariant(false);
                                }}
                              >
                                No
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Dialog
                            open={showDeliverableWarning}
                            onClose={() => setShowVariantWarning(false)}
                          >
                            <DialogContent>
                              <Typography
                                variant="h6"
                                color="error"
                                align="center"
                                display="block"
                              >
                                Warning!
                              </Typography>
                              <Typography align="center" display="block">
                                Changing the deliverable may invalidate the
                                ground truth for this project. Rerun the ground
                                truth build if necessary.
                              </Typography>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setShowDeliverableWarning(false);
                                  setAllowChangeDeliverable(true);
                                }}
                              >
                                I understand
                              </Button>
                              <Button
                                onClick={() => {
                                  setShowDeliverableWarning(false);
                                  setAllowChangeDeliverable(false);
                                }}
                              >
                                Never mind
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      )}
                      <DialogSelect
                        disabled={props.action === "update"}
                        bp={{ xs: 12, sm: 4 }}
                        name={path.customer.codename._}
                        label="Customer"
                        onReset={() =>
                          // Reset the customer field to it's initial values
                          formik.setValues({
                            ...formik.values, // preserve all prevous fields
                            customer: formik.initialValues.customer,
                            usersToAdd: [],
                          })
                        }
                        content={(close) => (
                          <CustomerList
                            hideColumns={{ projects: true }}
                            selectable="single"
                            onSelect={(customer) => {
                              formik.setValues({
                                ...formik.values, // preserve all previous fields
                                customer: {
                                  id: customer.id!,
                                  codename: customer.codename!,
                                },
                                usersToAdd: [],
                              });
                              close(); // Close the dialog after setting customer value
                            }}
                          />
                        )}
                      />
                      <DialogSelect
                        disabled={props.action === "update"}
                        bp={{ xs: 12, sm: 4 }}
                        name={path.model.codename._}
                        label="Model"
                        onReset={() =>
                          formik.setValues({
                            ...formik.values,
                            model: formik.initialValues.model,
                          })
                        }
                        content={(close) => (
                          <AllModelsTable
                            hideColumns={{ projects: true }}
                            selectable="single"
                            onSelect={(model) => {
                              formik.setValues({
                                ...formik.values, // preserve all previous fields
                                model: {
                                  id: model.id ?? "",
                                  codename: model.codename ?? "",
                                },
                              });
                              close();
                            }}
                          />
                        )}
                      />
                      <TextFieldWithOptions
                        disabled={
                          props.action === "update" &&
                          allowChangeVariant === false
                        }
                        bp={{ xs: 12, sm: 4 }}
                        name={path.variant?._ ?? "variant"}
                        label="Variant"
                        options={existingVariants}
                      />
                      <DialogSelect
                        disabled={
                          props.action === "update" &&
                          allowChangeDeliverable === false
                        }
                        bp={{ xs: 12 }}
                        name={path.deliverable.name._}
                        label="Deliverable"
                        onReset={() =>
                          formik.setValues({
                            ...formik.values,
                            deliverable: formik.initialValues.deliverable,
                          })
                        }
                        content={(close) => (
                          <AllDeliverablesTable
                            selectable="single"
                            onSelect={(deliverable) => {
                              formik.setValues({
                                ...formik.values, // preserve all previous fields
                                near_threshold:
                                  deliverable.ground_truth_type
                                    ?.default_near_threshold,
                                far_threshold:
                                  deliverable.ground_truth_type
                                    ?.default_far_threshold,
                                deliverable: {
                                  id: deliverable.id ?? "",
                                  name: deliverable.name ?? "",
                                  ground_truth_type: {
                                    name:
                                      deliverable.ground_truth_type?.name ?? "",
                                    default_near_threshold:
                                      deliverable.ground_truth_type
                                        ?.default_near_threshold ?? null,
                                    default_far_threshold:
                                      deliverable.ground_truth_type
                                        ?.default_far_threshold ?? null,
                                    min_near_threshold:
                                      deliverable.ground_truth_type
                                        ?.min_near_threshold ?? null,
                                    max_near_threshold:
                                      deliverable.ground_truth_type
                                        ?.max_near_threshold ?? null,
                                    min_far_threshold:
                                      deliverable.ground_truth_type
                                        ?.min_far_threshold ?? null,
                                    max_far_threshold:
                                      deliverable.ground_truth_type
                                        ?.max_far_threshold ?? null,
                                  },
                                },
                              });
                              close();
                            }}
                          />
                        )}
                      />
                      <TextField
                        bp={{ xs: 12 }}
                        multiline
                        name={path.description?._ ?? "description"}
                        label="Description"
                      />
                      <TextField
                        bp={{ sm: 8, xs: 12 }}
                        multiline
                        name={path.slack_channel?._ ?? "slack_channel"}
                        label="Slack Channel"
                      />
                      <IncDecField
                        bp={{ sm: 4, xs: 12 }}
                        name={path.min_devices_per_task._}
                        label="Min Devices per Task"
                      />
                    </FormContainer>
                  ),
                },
                {
                  name: "members",
                  hide: props.action !== "insert",
                  label: (
                    <TabLabel
                      label="Members"
                      icon={<Person />}
                      count={formik.values.usersToAdd?.length}
                    />
                  ),
                  content: (
                    <FormContainer>
                      <Grid item xs={12}>
                        <ProjectUserPicker
                          heading={
                            formik.values.customer?.codename?.length
                              ? `Select which users to copy from other projects
                              with customer "${formik.values.customer.codename}"`
                              : `Select a customer to see members that can be
                              copied from other projects.`
                          }
                          customerId={formik.values.customer.id}
                          name={path.usersToAdd?._ ?? "usersToAdd"}
                        />
                      </Grid>
                    </FormContainer>
                  ),
                },
                {
                  name: "opmodes",
                  label: (
                    <TabLabel label="Opmodes" icon={<ListAltOutlined />} />
                  ),
                  content: (
                    <FormContainer>
                      <DialogSelect
                        bp={{ xs: 12 }}
                        name={path.simOpmode?.number._ ?? "simOpmode.number"}
                        label="Simulation Opmode"
                        tooltip="The operation mode used for setting up the engine to simulate and train a project's recordings"
                        onReset={() => {
                          const simOpmode = formik.values.simOpmode;
                          if (!simOpmode?.id) return;
                          formik.setValues({
                            ...formik.values,
                            opmodesToAdd: opmodeRemovedFromOpmodesToAdd(
                              simOpmode.id,
                              simOpmode.number ?? "",
                            ),
                            mongoQuery: opmodeRemovedFromMongoQuery(
                              simOpmode.id,
                              simOpmode.number ?? "",
                            ),
                            simOpmode: formik.initialValues.simOpmode,
                          });
                        }}
                        content={(close) => (
                          <AllOpmodesTable
                            hideColumns={{}}
                            selectable="single"
                            where={[
                              {
                                id: { _nin: existingSimOpmodeIds },
                              },
                            ]}
                            onSelect={(opmode) => {
                              /**
                               * Sim opmode must be added to the "opmodes to add"
                               * and "mongodb query opmodes" arrays.
                               */
                              if (!opmode) return;
                              const opmodesToAdd =
                                formik.values.opmodesToAdd ?? [];
                              if (
                                opmodesToAdd.findIndex(
                                  (o) => o.opmode.id === opmode.id,
                                ) === -1
                              ) {
                                opmodesToAdd.push({
                                  opmode: {
                                    id: opmode.id ?? "",
                                    number: opmode.number ?? 0,
                                  },
                                });
                              }
                              const mongoQuery =
                                formik.values.mongoQuery ??
                                formik.initialValues.mongoQuery;
                              if (
                                mongoQuery!.params?.operation_modes?.findIndex(
                                  (number) => number === String(opmode.number),
                                ) === -1
                              ) {
                                mongoQuery!.params.operation_modes.push(
                                  String(opmode.number),
                                );
                              }
                              formik.setValues({
                                ...formik.values, // preserve all previous fields
                                simOpmode: {
                                  id: opmode.id ?? "",
                                  number: `${opmode.number ?? ""}`,
                                },
                              });
                              close();
                            }}
                          />
                        )}
                      />
                      <DialogSelect
                        bp={{ xs: 12 }}
                        name={
                          path.calibOpmode?.number._ ?? "calibOpmode.number"
                        }
                        label="Calibration Opmode"
                        tooltip="The operation mode used for calibration"
                        onReset={() => {
                          formik.setValues({
                            ...formik.values,
                            calibOpmode: formik.initialValues.calibOpmode,
                          });
                        }}
                        content={(close) => (
                          <AllOpmodesTable
                            hideColumns={{}}
                            selectable="single"
                            where={[
                              {
                                id: { _nin: existingSimOpmodeIds },
                              },
                            ]}
                            onSelect={(opmode) => {
                              /**
                               * Calibration opmode is not added added to the
                               * "opmodes to add" nor "mongodb query opmodes"
                               * array.
                               */
                              if (!opmode) return;
                              formik.setValues({
                                ...formik.values, // preserve all previous fields
                                calibOpmode: {
                                  id: opmode.id ?? "",
                                  number: `${opmode.number ?? ""}`,
                                },
                              });
                              close();
                            }}
                          />
                        )}
                      />
                      {/* Existing opmodes (to remove) */}
                      <FieldArray
                        name={path.opmodesToRemove!._}
                        render={(helper) =>
                          projectQuery.data?.project?.opmode_projects
                            // Filter out any opmodes the user is planning to remove.
                            .filter(
                              ({ opmode }) =>
                                formik.values.opmodesToRemove?.findIndex(
                                  ({ op }) => op.id === opmode.id,
                                ) === -1,
                            )
                            .map(({ opmode }) => (
                              <Grid
                                key={opmode.id}
                                container
                                item
                                xs={12}
                                spacing={2}
                              >
                                <Grid item xs={11}>
                                  <InfoDisplay
                                    items={[
                                      {
                                        label: "Query Opmode",
                                        value: opmode.number,
                                      },
                                    ]}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Button
                                    size="small"
                                    color="primary"
                                    variant="text"
                                    onClick={() => {
                                      const index: number | undefined =
                                        formik.values.mongoQuery?.params?.operation_modes?.indexOf(
                                          String(opmode.number),
                                        ) || -1;
                                      if (~index) {
                                        formik.values.mongoQuery?.params?.operation_modes?.splice(
                                          index,
                                          1,
                                        );
                                      }
                                      helper.push({
                                        op: {
                                          id: opmode.id,
                                          number: opmode.number,
                                        },
                                      });
                                    }}
                                  >
                                    <Close />
                                  </Button>
                                </Grid>
                                <Grid item xs={12}>
                                  <Divider />
                                </Grid>
                              </Grid>
                            )) ?? null
                        }
                      />
                      {/* Opmodes to add */}
                      <FieldArray
                        name={path.opmodesToAdd!._}
                        render={(helper) => {
                          // Exclude opmodes already associated with this project.
                          let opmodeIdsToExclude: string[] =
                            projectQuery?.data?.project?.opmode_projects?.map(
                              ({ opmode }) => opmode.id,
                            ) ?? [];
                          // Also exclude opmodes already added to this form.
                          opmodeIdsToExclude.push(
                            ...formik.values.opmodesToAdd!.map(
                              ({ opmode }) => opmode.id,
                            ),
                          );
                          return (
                            <>
                              {formik.values.opmodesToAdd!.map(
                                (opmodeToAdd, index) => (
                                  <Grid
                                    key={opmodeToAdd.opmode.id}
                                    container
                                    item
                                    xs={12}
                                    spacing={2}
                                  >
                                    <Grid item xs={11}>
                                      <InfoDisplay
                                        items={[
                                          {
                                            label: "Query Opmode",
                                            value: opmodeToAdd.opmode.number,
                                          },
                                        ]}
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Button
                                        size="small"
                                        color="primary"
                                        variant="text"
                                        onClick={() => {
                                          formik.values.mongoQuery?.params?.operation_modes?.splice(
                                            formik.values.mongoQuery?.params?.operation_modes?.indexOf(
                                              String(opmodeToAdd.opmode.number),
                                            ),
                                            1,
                                          );
                                          helper.remove(index);
                                        }}
                                      >
                                        <Close />
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Divider />
                                    </Grid>
                                  </Grid>
                                ),
                              )}
                              <Grid container item xs={12} spacing={2}>
                                <DrawerButton
                                  bp={{ xs: 4 }}
                                  variant="contained"
                                  color="primary"
                                  label="Select Query Opmodes"
                                  tooltip="Additional operation modes used for querying the database for which recordings to simulate and train on"
                                  startIcon={<FormatListNumbered />}
                                  disableElevation
                                  fullWidth={true}
                                  content={(close) => (
                                    <AllOpmodesTable
                                      selectable="single"
                                      where={[
                                        { id: { _nin: opmodeIdsToExclude } },
                                      ]}
                                      onSelect={(om) => {
                                        formik.values.mongoQuery?.params?.operation_modes?.push(
                                          String(om.number),
                                        );
                                        helper.insert(0, {
                                          opmode: {
                                            id: om.id,
                                            number: om.number,
                                          },
                                        });
                                        close();
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          );
                        }}
                      />
                    </FormContainer>
                  ),
                },
                {
                  name: "datafilters",
                  label: (
                    <TabLabel
                      label="Data Filters"
                      icon={<Code />}
                      warning={true}
                    />
                  ),
                  content: (
                    <FormContainer>
                      <Grid container item lg={6} xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <Markdown
                            source={md()
                              .syntax(
                                "json",
                                JSON.stringify(
                                  formik.values.mongoQuery,
                                  null,
                                  2,
                                ),
                              )
                              .get()}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="error">
                            To prepare for the upcoming removal of our MongoDB
                            instance, all public APIs that query for project
                            recordings should now generate the query parameters
                            automatically, using only the start/end dates and
                            opmodes. The JSON object above will continue to be
                            stored as shown, but will not be used verbatim.
                          </Typography>
                          <Typography
                            variant="body2"
                            color="error"
                            style={{ marginTop: 20 }}
                          >
                            Please let someone from MLOps know if this should
                            cause any issues.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item lg={6} xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <DatePicker
                            label="Recording Date Start"
                            name={
                              path.mongoQuery?.params.date_start._ ??
                              "mongoQuery.params.date_start"
                            }
                            disabled={!formik.values.mongoQuery}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <DatePicker
                            label="Recording Date End"
                            name={
                              path.mongoQuery?.params.date_end._ ??
                              "mongoQuery.params.date_end"
                            }
                            disabled={!formik.values.mongoQuery}
                          />
                        </Grid>
                      </Grid>
                    </FormContainer>
                  ),
                },
                {
                  name: "thresholds",
                  label: (
                    <TabLabel
                      label="Thresholds"
                      icon={<LocationSearchingIcon />}
                    />
                  ),
                  content: (
                    <>
                      <FormContainer>
                        {props.action === "update" && (
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              Thresholds and modes are closely tied to a
                              project's deliverable, and like the deliverable,
                              are not meant to change once a project is
                              underway. If you need to add or update any of
                              these values, please use the switch in the GENERAL
                              tab to "Allow changes to deliverable".
                            </Typography>
                          </Grid>
                        )}
                        <NumericField
                          disabled={
                            props.action === "update" &&
                            projectQuery.data?.project?.near_threshold !==
                              null &&
                            allowChangeDeliverable === false
                          }
                          bp={{ xs: 12 }}
                          name="near_threshold"
                          label="Near Threshold [mm]"
                        />
                        <NumericField
                          bp={{ xs: 12 }}
                          disabled={
                            props.action === "update" &&
                            projectQuery.data?.project?.far_threshold !==
                              null &&
                            allowChangeDeliverable === false
                          }
                          name="far_threshold"
                          label="Far Threshold [mm]"
                        />
                        <Grid item xs={12}>
                          <Typography variant="body2" color="error">
                            Please attach a "mode" below that best matches the
                            near and far thresholds above. In the future, the
                            threshold fields above will be replaced entirely by
                            the mode selector.
                            <br />
                            <br />
                            If you cannot find a mode that matches the given
                            thresholds, please contact MLOps to create one for
                            you.
                          </Typography>
                        </Grid>
                        <FieldArray
                          name={path.modes!._}
                          render={(helper) => (
                            <>
                              <DrawerButton
                                bp={{ xs: 4 }}
                                disabled={
                                  props.action === "update" &&
                                  allowChangeDeliverable === false
                                }
                                variant="contained"
                                color="primary"
                                label="Add Mode"
                                startIcon={<AddCircleOutline />}
                                disableElevation
                                fullWidth={true}
                                content={(close) => (
                                  <AllModesTable
                                    selectable="single"
                                    where={[
                                      {
                                        id: {
                                          _nin: (formik.values.modes ?? []).map(
                                            ({ id }) => id,
                                          ),
                                        },
                                      },
                                    ]}
                                    onSelect={(mode) => {
                                      helper.insert(0, {
                                        id: mode.id,
                                        name: mode.name ?? "",
                                        parameters: mode.mode_parameters?.map(
                                          (mp) => ({
                                            name: mp.parameter?.name ?? "",
                                            value: mp.value,
                                          }),
                                        ),
                                      });
                                      close();
                                    }}
                                  />
                                )}
                              />
                              {(formik.values.modes ?? []).map((mode, i) => (
                                <Fragment key={mode!.id}>
                                  <Grid item xs={12}>
                                    <Divider />
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Typography variant="body1">
                                      {mode.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <IconButton
                                      disabled={
                                        props.action === "update" &&
                                        allowChangeDeliverable === false
                                      }
                                      onClick={() => helper.remove(i)}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                  <InfoDisplay
                                    key={mode!.id}
                                    bp={{ xs: 12 }}
                                    items={mode.parameters!.map((prop) => ({
                                      label: prop.name,
                                      value: prop.value,
                                    }))}
                                  />
                                </Fragment>
                              ))}
                            </>
                          )}
                        />
                      </FormContainer>
                    </>
                  ),
                },
                {
                  name: "ci",
                  label: <TabLabel label="CI/CD" icon={<TrackChanges />} />,
                  content: (
                    <Tablature
                      useUrlParams={false}
                      tabs={[
                        {
                          name: "azure",
                          label: <TabLabel label="Azure" icon={<Traffic />} />,
                          content: (
                            <FormContainer>
                              <Checkbox
                                bp={{ xs: 12, sm: 4 }}
                                name={path.enable_greatness_processing._}
                                label="Enable Greatness"
                              />
                              <Checkbox
                                bp={{ xs: 12, sm: 4 }}
                                name={path.enable_ground_truth_processing._}
                                label="Enable Ground Truth"
                              />
                              <Checkbox
                                bp={{ xs: 12, sm: 4 }}
                                name={path.enable_arcadia_processing._}
                                label="Enable Arcadia"
                              />
                              <TextField
                                bp={{ xs: 12, sm: 6 }}
                                multiline
                                name={
                                  path.azure_build_ref?._ ?? "azure_build_ref"
                                }
                                label="Azure Build Definition ID"
                              />
                              <TextField
                                bp={{ xs: 12, sm: 6 }}
                                multiline
                                name={
                                  path.azure_release_ref?._ ??
                                  "azure_release_ref"
                                }
                                label="Azure Release Definition ID"
                              />
                            </FormContainer>
                          ),
                        },
                        {
                          name: "dsp",
                          label: (
                            <TabLabel label="DSP" icon={<DeveloperBoard />} />
                          ),
                          content: (
                            <FormContainer>
                              <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    multiline
                                    name={
                                      path.firmware_engine_release_ref?._ ??
                                      "firmware_engine_release"
                                    }
                                    label="Firmware Engine Release ID"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Autocomplete
                                    fullWidth
                                    options={xtensaConfigurations}
                                    value={formik.values.XtensaConfiguration}
                                    getOptionLabel={(option) =>
                                      option.value || ""
                                    }
                                    getOptionSelected={(option, value) =>
                                      option.id === value.id
                                    }
                                    onInputChange={(_, newInputValue) => {
                                      if (newInputValue === "") {
                                        formik.setValues({
                                          ...formik.values,
                                          XtensaConfiguration: {
                                            id: null,
                                            value: null,
                                          },
                                        });
                                      }
                                    }}
                                    onChange={(_event, nv) => {
                                      if (nv) {
                                        formik.setValues({
                                          ...formik.values,
                                          XtensaConfiguration: {
                                            id: nv.id,
                                            value: nv.value,
                                          },
                                        });
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Xtensa Configuration"
                                        name={"xtensa_configuration"}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </FormContainer>
                          ),
                        },
                        {
                          name: "combos",
                          label: (
                            <TabLabel
                              label="Combo Projects"
                              icon={<LocationSearchingIcon />}
                            />
                          ),
                          content: (
                            <FormContainer>
                              <FieldArray
                                name={path.comboProjects!._}
                                render={(helper) => (
                                  <>
                                    <Grid container item xs={12} spacing={2}>
                                      <DrawerButton
                                        bp={{ xs: 4 }}
                                        variant="contained"
                                        color="primary"
                                        label="Add Project"
                                        startIcon={<AddCircleOutline />}
                                        disableElevation
                                        fullWidth={true}
                                        content={(close) => (
                                          <AllProjectsTable
                                            where={[
                                              {
                                                id: {
                                                  _nin:
                                                    formik.values.comboProjects?.map(
                                                      ({ id }) => id,
                                                    ) ?? [],
                                                },
                                              },
                                            ]}
                                            selectable="single"
                                            onSelect={(project) => {
                                              helper.insert(0, {
                                                id: project.id,
                                                name:
                                                  project.project_name?.name ??
                                                  "",
                                              });
                                              close();
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    {formik.values.comboProjects!.map(
                                      (proj, index) => (
                                        <Grid
                                          container
                                          item
                                          xs={12}
                                          spacing={2}
                                          key={proj.id}
                                        >
                                          <Grid
                                            container
                                            item
                                            xs={11}
                                            spacing={4}
                                          >
                                            <Grid item xs={6}>
                                              <Typography variant="caption">
                                                ID
                                              </Typography>
                                              <Typography variant="body1">
                                                {proj.id}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                              <Typography variant="caption">
                                                Name
                                              </Typography>
                                              <Typography variant="body1">
                                                {proj.name}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={1}>
                                            <Button
                                              size="small"
                                              color="primary"
                                              variant="text"
                                              onClick={() =>
                                                helper.remove(index)
                                              }
                                            >
                                              <Close />
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      ),
                                    )}
                                  </>
                                )}
                              />
                            </FormContainer>
                          ),
                        },
                      ]}
                    />
                  ),
                },
                {
                  name: "Scaling",
                  label: <TabLabel label="Scaling" icon={<LinearScale />} />,
                  content: (
                    <FormContainer>
                      <NumericField
                        bp={{ xs: 12 }}
                        name="dynamic_scaling.min_value"
                        label="Scale Factor Min"
                      />
                      <NumericField
                        bp={{ xs: 12 }}
                        name="dynamic_scaling.max_value"
                        label="Scale Factor Max"
                      />
                      <NumericField
                        bp={{ xs: 12 }}
                        name="dynamic_scaling.benchmark_scaling"
                        label="Scale Factor Benchmark"
                      />
                      
                    </FormContainer>
                  ),
                },
              ]}
            />
          </>
        );
      }}
    />
  );
};

type DatePickerProps = {
  label: string;
  disabled?: boolean;
  name: string;
};

const DatePicker = (props: DatePickerProps) => {
  const [, meta, helpers] = useField(props.name);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);
  const { value } = meta;
  const { setValue } = helpers;

  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const handleDateChange = (date: Moment | null) => {
    setSelectedDate(date);
    // Timestamps for queries are formatted with awkward ISO variation.
    // When set to `undefined`, removes the field from the form.
    setValue(date?.format("YYYY-MM-DDT00:00:00") ?? undefined);
  };
  useEffect(() => {
    if (!!value) {
      setSelectedDate(moment(value));
    } else {
      setSelectedDate(null);
    }
  }, [value, setSelectedDate]);

  return (
    <FormControl fullWidth variant="outlined" error={error}>
      <FormLabel>{props.label}</FormLabel>
      <FormGroup row>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            disabled={error || props.disabled || ctx.isSubmitting}
            variant="dialog"
            clearable
            format="yyyy/MM/DD"
            margin="normal"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </FormGroup>
      <FormHelperText>{error ? meta.error : ""}</FormHelperText>
    </FormControl>
  );
};

type ProjectUserPickerProps = {
  customerId?: string;
  heading: string;
  name: string;
};

const ProjectUserPicker = (props: ProjectUserPickerProps) => {
  const [, meta, helpers] = useField(props.name);
  const ctx = useFormikContext();
  const error = !!(meta.error && meta.touched);
  const { value } = meta;
  const { setValue } = helpers;

  const selectedUsers = value as string[];
  const setUsers = setValue;

  const query = useAllUsersQuery({
    variables: {
      where: {
        project_users: {
          project: {
            customer_id: { _eq: props.customerId },
          },
        },
      },
    },
    skip: !props.customerId,
  });
  const queriedUsers = query.data?.users ?? [];

  const allSelected =
    queriedUsers.length > 0 &&
    queriedUsers.filter((user) => selectedUsers.includes(user.id)).length ===
      queriedUsers.length;

  return (
    <List>
      <List>
        <ListItem
          dense
          button
          disabled={!queriedUsers.length || ctx.isSubmitting}
          onClick={() => {
            allSelected
              ? setUsers([])
              : setUsers(queriedUsers.map((user) => user.id));
          }}
        >
          <ListItemIcon>
            <MuiCheckbox
              style={{ margin: 0 }}
              disabled={!queriedUsers.length || ctx.isSubmitting}
              checked={allSelected}
            />
          </ListItemIcon>
          <ListItemText primary={props.heading} secondary={error} />
        </ListItem>
        <Divider />
        {queriedUsers.map((user) => (
          <ListItem
            key={user.id}
            dense
            button
            disabled={ctx.isSubmitting}
            onClick={() => {
              const replacement = selectedUsers.includes(user.id)
                ? selectedUsers.filter((id) => id !== user.id)
                : [...selectedUsers, user.id];
              setUsers(replacement);
            }}
          >
            <ListItemIcon>
              <MuiCheckbox
                style={{ margin: 0 }}
                checked={selectedUsers.includes(user.id)}
              />
            </ListItemIcon>
            <ListItemText primary={user.name} />
          </ListItem>
        ))}
      </List>
    </List>
  );
};
