import React, { useState } from "react";
import { FieldArray } from "formik";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Delete,
  DragIndicator,
  ListAltOutlined,
} from "@material-ui/icons";
import * as Yup from "yup";
import {
  Fields_Bool_Exp,
  Fields as Field,
  Order_By,
  useAllTaskGroupsHierarchiesQuery,
  useAllFieldsQuery,
  useCreateTaskGroupsHierarchyMutation,
  useTaskGroupsHierarchyQuery,
  useUpdateTaskGroupsHierarchyMutation,
  Task_Groups_Hierarchies as Task_Groups_Hierarchy,
  Task_Groups_Hierarchies_Bool_Exp,
} from "src/generated/asgard/graphql";
import { createTriage } from "src/components/Triage";
import {
  Breadcrumb,
  Breadcrumbs,
  ClosableDrawer,
  ShowResourceView,
  TabLabel,
  Tablature,
  TableView,
  TableViewHeader,
} from "src/Layout";
import { SearchBar } from "src/components/filters";
import { Resource, useResourceNav } from "src/components/Resource";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import { FieldTable } from "src/resources/CollectionPlan";
import { like, usePagination, uuidIsValid } from "src/resources/Utils";
import { FormContainer, FormHeader, TextField } from "src/components/Form";
import { GroupedProjectTasks } from "src/resources/TaskGroup";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";

// Config table columns
export const TaskGroupsHierarchyTable = createTable<Task_Groups_Hierarchy>()({
  keys: (hierarchy) => hierarchy.id ?? "",
  title: "TaskGroupsHierarchy",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    description: { display: "Description" },
  },
  columns: (hierarchy) => ({
    id: <EllipsisColumn value={hierarchy.id} />,
    name: <TextColumn value={hierarchy.name ?? ""} />,
    description: <TextColumn value={hierarchy.description ?? ""} />,
  }),
});

// Define a new table component for TaskGroupsHierarchy
type AllTaskGroupsHierarchyTableProps = TableOptionals<
  typeof TaskGroupsHierarchyTable
> & {
  where?: Task_Groups_Hierarchies_Bool_Exp[];
};

export const AllTaskGroupsHierarchyTable = (
  props: AllTaskGroupsHierarchyTableProps,
) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Task_Groups_Hierarchies_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  }
  // Add search terms for individual fields.
  const term = like(search);
  searchFilters.push({ name: { _ilike: term } });
  const { data } = useAllTaskGroupsHierarchiesQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, ...searchFilters] },
      // Add other query variables.
    },
  });

  return (
    <TaskGroupsHierarchyTable
      {...props}
      {...pageController}
      total={data?.hierarchies_aggregate?.aggregate?.count}
      data={data?.hierarchies}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main TaskGroupsHierarchy resource page
type TaskGroupsHierarchyIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof TaskGroupsHierarchyTable>;

export const TaskGroupsHierarchyIndex = (
  props: TaskGroupsHierarchyIndexProps,
) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">Task Groupings</Typography>}
      >
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Task Grouping
        </Button>
      </TableViewHeader>
      <Card>
        <AllTaskGroupsHierarchyTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing TaskGroupsHierarchy
const taskGroupFieldSchema = Yup.object({
  key: Yup.string().required(),
  field_id: Yup.string().required(),
  field_name: Yup.string().required(),
}).required();

const taskGroupSchema = Yup.object({
  key: Yup.string().required(),
  name: Yup.string().required("group name required"),
  fields: Yup.array(taskGroupFieldSchema),
}).required();

const hierarchySchema = Yup.object({
  name: Yup.string().required("required"),
  description: Yup.string(),
  groups: Yup.array(taskGroupSchema),
}).required();

type HierarchySchema = Yup.InferType<typeof hierarchySchema>;
type TaskGroupSchema = Yup.InferType<typeof taskGroupSchema>;
type TaskGroupFieldSchema = Yup.InferType<typeof taskGroupFieldSchema>;

const TriageTaskGroups = createTriage<TaskGroupFieldSchema, TaskGroupSchema>();

type TaskGroupsHierarchyFormProps = {
  action: FormAction;
  hierarchy?: { id: string };
  onSuccess: (id: string) => void;
};

export const TaskGroupsHierarchyForm = (
  props: TaskGroupsHierarchyFormProps,
) => {
  const hierarchyQuery = useTaskGroupsHierarchyQuery({
    variables: { id: props.hierarchy?.id ?? "" },
    fetchPolicy: "network-only",
    skip: !props.hierarchy,
  });

  const existingHierarchies = useAllTaskGroupsHierarchiesQuery({
    variables: {
      where: { id: { _nin: props.hierarchy?.id ? [props.hierarchy.id] : [] } },
    },
    fetchPolicy: "network-only",
  });

  const [createHierarchy] = useCreateTaskGroupsHierarchyMutation();
  const [updateHierarchy] = useUpdateTaskGroupsHierarchyMutation();

  // Use to get all fields currently added to a context display,
  // to prevent user from using the same field more than once.
  const getUsedFieldIds = (hierarchy: HierarchySchema) => {
    const groupedFieldIds: string[][] = hierarchy.groups
      ? hierarchy.groups
          .filter((group) => !!group?.fields?.length)
          .map((group) => group!.fields!.map((field) => field.field_id))
      : [];
    const fieldIds: string[] = groupedFieldIds.reduce(
      (accumulator, value) => accumulator.concat(value),
      [],
    );
    return fieldIds.filter((value, i) => fieldIds.indexOf(value) === i);
  };

  return (
    <ResourceForm
      action={props.action}
      schema={hierarchySchema}
      resourceToUpdate={hierarchyQuery.data?.hierarchy}
      transform={(hierarchy) => ({
        name: hierarchy.name ?? "",
        description: hierarchy.description ?? "",
        groups:
          hierarchy.hierarchy_groups?.map((hg) => ({
            key: hg.group.id,
            name: hg.group.name,
            fields:
              hg.group.group_fields?.map((gf) => ({
                key: gf.field_id,
                field_id: gf.field_id,
                field_name: gf.field.name,
              })) ?? [],
          })) ?? [],
      })}
      initialValues={{
        name: "",
        description: "",
        groups: [],
      }}
      customValidator={(values, errors) => {
        for (let existing of existingHierarchies.data?.hierarchies ?? []) {
          if (existing.name === values.name) {
            errors.name = `Task Group Hierarchy '${values.name}' already exists`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const { groups, ...hierarchy_fields } = values;
        const result = await (async () => {
          if (props.hierarchy?.id)
            return await updateHierarchy({
              variables: {
                hierarchy_id: props.hierarchy!.id,
                hierarchy_fields,
                hierarchy_groups:
                  groups?.map((group, index) => ({
                    hierarchy_id: props.hierarchy!.id,
                    position: index,
                    group: {
                      data: {
                        name: group.name,
                        group_fields: {
                          data:
                            group.fields?.map((field, i) => ({
                              position: i,
                              field_id: field.field_id,
                            })) ?? [],
                        },
                      },
                    },
                  })) ?? [],
              },
            });
        })();
        props.onSuccess && props.onSuccess(result?.data?.hierarchy?.id ?? "");
      }}
      onInsert={async (values) => {
        const { groups, ...hierarchy_fields } = values;
        const result = await (async () => {
          return await createHierarchy({
            variables: {
              input: {
                ...hierarchy_fields,
                hierarchy_groups: {
                  data:
                    groups?.map((group, index) => ({
                      position: index,
                      group: {
                        data: {
                          name: group.name,
                          group_fields: {
                            data:
                              group.fields?.map((field, i) => ({
                                position: i,
                                field_id: field.field_id,
                              })) ?? [],
                          },
                        },
                      },
                    })) ?? [],
                },
              },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.hierarchy?.id ?? "");
      }}
      disableSubmit={(values) => (values.groups ?? []).length === 0}
      render={({ formik, path }) => (
        <>
          <FormHeader>
            {props.hierarchy
              ? "Update Task Groupings"
              : "Create New Task Groupings"}
          </FormHeader>
          <FormContainer>
            <TextField bp={{ xs: 12, sm: 8 }} name={path.name._} label="Name" />
            <TextField
              bp={{ xs: 12 }}
              name={path.description?._ ?? "description"}
              label="Description"
            />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <FieldArray
              name={path.groups!._}
              render={(helper) => (
                <>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => {
                        helper.insert(0, {
                          key: Date.now().toString(),
                          name: "",
                          fields: [],
                        });
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircleOutline />}
                      disableElevation
                    >
                      New Group
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TriageTaskGroups
                      id="triage-context-fields"
                      groups={formik.values.groups ?? []}
                      groupDirection="vertical"
                      getGroupItems={(group) => group.fields ?? []}
                      setGroupItems={(group, items) => (group.fields = items)}
                      renderGroup={({ index, children, group }) => (
                        <Card style={{ marginTop: 8 }}>
                          <CardContent>
                            <Grid container direction="row" spacing={2}>
                              <Grid item>
                                <DragIndicator />
                              </Grid>
                              <Grid item xs container direction="column">
                                <Grid
                                  item
                                  container
                                  spacing={2}
                                  justify="space-between"
                                >
                                  <TextField
                                    bp={{ xs: 8 }}
                                    name={path.groups![index].name._}
                                    size="small"
                                    variant="outlined"
                                    label="Group"
                                  />
                                  <Grid item>
                                    <Button
                                      variant="text"
                                      size="small"
                                      color="primary"
                                      disabled={!group.name.length}
                                      onClick={() => helper.remove(index)}
                                    >
                                      <Delete />
                                    </Button>
                                  </Grid>
                                </Grid>
                                <Grid item container>
                                  {children}
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      )}
                      itemDirection="horizontal"
                      renderItem={({ item, group, groupIndex }) => (
                        <StyledChip
                          icon={<DragIndicator />}
                          label={item.field_name}
                          onDelete={() => {
                            const replacement = { ...group };
                            replacement.fields = replacement.fields?.length
                              ? replacement.fields.filter(
                                  (field) => field.key !== item.key,
                                )
                              : [];
                            helper.replace(groupIndex, replacement);
                          }}
                        />
                      )}
                      renderItemActions={({ group, index }) => (
                        <NewFieldChip
                          key="new-field-chip"
                          fieldsWhere={{
                            _and: [
                              { id: { _nin: getUsedFieldIds(formik.values) } },
                            ],
                          }}
                          onSelect={(field, close) => {
                            if (field.id) {
                              const replacement = { ...group };
                              const newField: TaskGroupFieldSchema = {
                                key: field.id,
                                field_id: field.id,
                                field_name: field.name ?? "",
                              };
                              if (replacement.fields?.length) {
                                replacement.fields.push(newField);
                              } else {
                                replacement.fields = [newField];
                              }
                              helper.replace(index, replacement);
                              close();
                            }
                          }}
                        />
                      )}
                      onChange={(groups) => {
                        formik.setFieldValue("groups", groups);
                      }}
                    />
                  </Grid>
                </>
              )}
            />
          </FormContainer>
        </>
      )}
    />
  );
};

const StyledChip = withStyles({
  root: {
    margin: 4,
  },
})(Chip);

type NewFieldChipProps = {
  fieldsWhere: Fields_Bool_Exp;
  onSelect: (item: DeepPartial<Field>, close: () => void) => void;
  disabled?: boolean;
};

const NewFieldChip = (props: NewFieldChipProps) => {
  const [search, setSearch] = useState("");
  const searchTerm = like(search);
  const searchFilters: Fields_Bool_Exp[] = [
    { name: { _ilike: searchTerm } },
    { display: { _ilike: searchTerm } },
  ];

  const [show, setShow] = useState(false);
  const [pageVars, pageController] = usePagination();
  const fields = useAllFieldsQuery({
    ...pageVars,
    variables: {
      where: { _and: [{ _or: searchFilters }, props.fieldsWhere] },
      order_by: [{ name: Order_By.Asc }],
    },
    fetchPolicy: "network-only",
  });
  return (
    <>
      <StyledChip
        icon={<AddCircleOutline fontSize="small" />}
        onClick={() => setShow(true)}
        disabled={!!props.disabled}
        label="Add Field"
        color="primary"
      />
      <ClosableDrawer show={show} setShow={setShow}>
        <FieldTable
          {...pageController}
          total={fields.data?.fields_aggregate?.aggregate?.count}
          data={fields.data?.fields}
          hideColumns={{ context: true }}
          selectable="single"
          onSelect={(item) => props.onSelect(item, () => setShow(false))}
          tools={
            <Grid item xs={12}>
              <SearchBar onChange={setSearch} />
            </Grid>
          }
        />
      </ClosableDrawer>
    </>
  );
};

// Create a detailed 'show' page.
type TaskGroupsHierarchyShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Task_Groups_Hierarchy>) => void;
};

const TaskGroupsHierarchyShow = (props: TaskGroupsHierarchyShowProps) => {
  const hierarchyNav = useTaskGroupsHierarchyNav();

  const hierarchyQuery = useTaskGroupsHierarchyQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const hierarchy = hierarchyQuery.data?.hierarchy;
  if (!hierarchy) return null;

  return (
    <ShowResourceView
      title={hierarchy.name ?? ""}
      properties={[
        { label: "Description", value: hierarchy.description ?? "" },
      ]}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb
            label="Task Groupings"
            onClick={() => hierarchyNav.list()}
          />
          <Breadcrumb label={hierarchy.name ?? ""} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(hierarchy)}
    >
      <Tablature
        tabs={[
          {
            name: "grouped-tasks",
            label: (
              <TabLabel label="Grouped Tasks" icon={<ListAltOutlined />} />
            ),
            content: <GroupedProjectTasks hierarchyId={props.id} />,
          },
        ]}
      />
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "task-groups";
export const useTaskGroupsHierarchyNav = () => useResourceNav(path);
export const TaskGroupsHierarchyResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <TaskGroupsHierarchyIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <TaskGroupsHierarchyShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <TaskGroupsHierarchyForm
        action="insert"
        onSuccess={(id) => nav.show(id)}
      />
    )}
    edit={(nav, id) => (
      <TaskGroupsHierarchyForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        hierarchy={{ id: id ?? "" }}
      />
    )}
  />
);
