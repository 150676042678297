import { BarSvgProps, ResponsiveBar } from "@nivo/bar";
import { useMemo } from "react";
import {
  useAggDevicesByProjectQuery,
  useAggRoomByProjectQuery,
  useUserRecordingsByDayQuery,
} from "src/generated/asgard/graphql";

export type RecordingsChartProps = {
  project_id: string;
  value_to_plot: "count" | "duration";
  include: {
    clean: boolean;
    dirty: boolean;
    untagged: boolean;
  };
};

export function RecordingsChart({
  project_id,
  value_to_plot,
  include,
}: RecordingsChartProps) {
  const stats = useUserRecordingsByDayQuery({
    variables: {
      project_id: project_id,
    },
  });

  const data = stats.data?.project?.user_recordings_by_day_stats;

  const keys = data
    ?.map((d) => d.user?.email ?? d.user?.name ?? "")
    .filter((value, index, self) => self.indexOf(value) === index);

  const mapped = useMemo(() => {
    const flattenData: {
      [key: number]: any;
    } = {};
    if (!data) return null;
    data.forEach((item) => {
      const dt = new Date(item.day ?? 0);
      const day = dt.getTime() / 1000;
      const { date: oldDay, ...rest } = flattenData[day] ?? {};
      let value = null;
      switch (value_to_plot) {
        case "count":
          value =
            (include.clean === true ? item.clean_recordings ?? 0 : 0) +
            (include.dirty === true ? item.dirty_recordings ?? 0 : 0) +
            (include.untagged === true ? item.untagged_recordings ?? 0 : 0);
          break;
        case "duration":
          value =
            (include.clean === true ? item.clean_duration ?? 0 : 0) +
            (include.dirty === true ? item.dirty_duration ?? 0 : 0) +
            (include.untagged === true ? item.untagged_duration ?? 0 : 0);
          value = Math.round(value / 1000 / 60);
          break;
      }
      flattenData[day] = {
        day: `${dt.getDate()}/${dt.getMonth() + 1}`,
        [item.user?.email ?? item.user?.name ?? ""]: value,
        ...rest,
      };
    });
    return Object.values(flattenData);
  }, [data, value_to_plot, include]);

  if (!data || !mapped) return <div>loading...</div>;

  return (
    <ResponsiveBar
      {...chartConfig}
      data={mapped}
      keys={keys}
      indexBy="day"
      margin={{ top: 15, right: 20, bottom: 50, left: 60 }}
      axisBottom={{
        ...chartConfig.axisBottom,
        legend: "date (Day/Month)",
      }}
    />
  );
}

export type DeviceRecordingsChartProps = {
  project_id: string;
};

export function DeviceRecordingsChart({
  project_id,
}: DeviceRecordingsChartProps) {
  const { data: stats } = useAggDevicesByProjectQuery({
    variables: {
      project_id,
    },
  });

  const data = stats?.project?.project_dataset;

  const mapped = useMemo(
    () =>
      data?.flatMap((d) => {
        if (d.device?.serialno === undefined) return [];
        return [
          {
            serial: d.device?.serialno,
            clean: d.device?.clean?.aggregate?.count ?? 0,
            dirty: d.device?.dirty?.aggregate?.count ?? 0,
            untagged: d.device?.untagged?.aggregate?.count ?? 0,
          },
        ];
      }),
    [data],
  );

  if (!data || !mapped) return <div>loading...</div>;

  return (
    <ResponsiveBar
      {...chartConfig}
      data={mapped}
      keys={["untagged", "clean", "dirty"]}
      indexBy="serial"
      axisBottom={{
        ...chartConfig.axisBottom,
        legend: "Serial",
      }}
    />
  );
}

export function RoomRecordingsChart({
  project_id,
}: DeviceRecordingsChartProps) {
  const { data: stats } = useAggRoomByProjectQuery({
    variables: {
      project_id,
    },
  });

  const data = stats?.project?.project_dataset;

  const mapped = useMemo(
    () =>
      data?.flatMap((d) => {
        if (d.room?.id === undefined) return [];
        return [
          {
            index: `${d.room?.name} / ${d.room?.building} / ${d.room?.city}`,
            clean: d.room?.clean?.aggregate?.count ?? 0,
            dirty: d.room?.dirty?.aggregate?.count ?? 0,
            untagged: d.room?.untagged?.aggregate?.count ?? 0,
          },
        ];
      }),
    [data],
  );

  if (!data || !mapped) return <div>loading...</div>;

  return (
    <ResponsiveBar
      {...chartConfig}
      data={mapped}
      keys={["untagged", "clean", "dirty"]}
      indexBy="index"
      margin={{ top: 15, right: 20, bottom: 10, left: 60 }}
      axisBottom={null}
    />
  );
}

const chartConfig: Omit<BarSvgProps, "data" | "keys" | "indexBy"> = {
  margin: { top: 15, right: 20, bottom: 100, left: 60 },
  padding: 0.3,
  colors: { scheme: "paired" },
  defs: [
    {
      id: "dots",
      type: "patternDots",
      background: "inherit",
      color: "#38bcb2",
      size: 4,
      padding: 1,
      stagger: true,
    },
    {
      id: "lines",
      type: "patternLines",
      background: "inherit",
      color: "#eed312",
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
  ],
  borderColor: { from: "color", modifiers: [["darker", 1.6]] },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: -45,
    legendPosition: "middle",
    legendOffset: 40,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "recordings",
    legendPosition: "middle",
    legendOffset: -40,
  },
  labelSkipWidth: 12,
  labelSkipHeight: 12,
  labelTextColor: { from: "color", modifiers: [["darker", 1.6]] },
  legends: [
    {
      dataFrom: "keys",
      anchor: "top-left",
      direction: "column",
      justify: false,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: "left-to-right",
      itemOpacity: 0.85,
      symbolSize: 20,
      effects: [
        {
          on: "hover",
          style: { itemOpacity: 1 },
        },
      ],
    },
  ],
  animate: true,
  motionStiffness: 90,
  motionDamping: 15,
};
