import { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Close,
  Info,
  TrackChanges,
  LocationSearching,
  Build,
} from "@material-ui/icons";
import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
import {
  DialogSelect,
  DrawerButton,
  FormContainer,
  FormHeader,
  TextField,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  ChipColumn,
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import {
  usePagination,
  like,
  uuidIsValid,
  nullIfEmpty,
} from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ClosableDrawer,
  InfoDisplay,
  ShowResourceView,
  TabLabel,
  Tablature,
  TableView,
  TableViewHeader,
} from "src/Layout";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import {
  Deliveries_Drivers as Driver,
  Deliveries_Packages as Package,
  Deliveries_Artifacts as PackageArtifact,
  Deliveries_Package_Signatures as PackageSignatures,
  Deliveries_Packages_Bool_Exp as Packages_Bool_Exp,
  useAllPackagesQuery,
  usePackageQuery,
  usePackageArtifactQuery,
  usePackageSignaturesQuery,
  useOperatingSystemsQuery,
  useCreatePackageMutation,
  useUpdatePackageMutation,
  Order_By,
  Deliveries_Drivers_Bool_Exp,
  Roles_Enum,
  useArchivePackageMutation,
  useCheckPackageHasVersionQuery,
} from "src/generated/asgard/graphql";
import { AllDriverVersionsTable, useDriverVersionNav } from "./driver_version";
import { FieldArray } from "formik";
import { CustomerList } from "../Customer";
import { createTriage } from "src/components/Triage";
import { AllDriversTable } from "./driver";
import { Autocomplete } from "@material-ui/lab";
import { useRequireRole } from "src/auth";

// Config table columns from Package fields

export const PackageTable = createTable<Package>()({
  keys: (pkg) => pkg.id ?? "",
  title: "Package",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    customer: { display: "Customer" },
    version: { display: "Version" },
    versions: { display: "Versions" },
    url: { display: "Blob url" },
    sbom: { display: "SBOM" },
    is_archived: { display: "Archived" },
  },
  columns: (pkg) => ({
    id: <EllipsisColumn value={pkg.id} />,
    name: <TextColumn value={pkg.name} />,
    customer: <TextColumn value={pkg.customer?.codename ?? ""} />,
    // The "version" and "versions" columns should never be used at the same
    // time. The "version" column should only be used when listing versioned
    // package entries, and the "versions" column should only be used when
    // listing null-versioned package entries.
    version: <TextColumn value={pkg.version} />,
    versions: (
      <ChipColumn
        chips={
          pkg.released_packages?.map((p) => ({ label: p.version ?? "" })) ?? []
        }
      />
    ),
    url: <TextColumn value={pkg.url} />,
    sbom: <TextColumn value={pkg.sbom_blob_url} />,
    is_archived: (
      <Checkbox checked={pkg.deleted_at ? true : false} disabled={true} />
    ),
  }),
});

// Define a new table component for Packages
type AllPackagesTableProps = TableOptionals<typeof PackageTable> & {
  where?: Packages_Bool_Exp[];
};

export const AllPackagesTable = (props: AllPackagesTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Packages_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    const term = like(search);
    searchFilters.push({
      _or: [
        { name: { _ilike: term } },
        { released_packages: { version: { _ilike: term } } },
      ],
    });
  }
  const { data } = useAllPackagesQuery({
    variables: {
      ...pageVars,
      where: {
        _and: [{ _and: props.where }, ...searchFilters],
      },
      order_by: [
        { deleted_at: Order_By.DescNullsFirst },
        { created_at: Order_By.Desc },
      ],
    },
    fetchPolicy: "network-only",
  });

  return (
    <PackageTable
      {...props}
      {...pageController}
      total={data?.packages_aggregate?.aggregate?.count}
      data={data?.packages}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

function formatTimestamp(timestamp: string): string {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  })
    .format(date)
    .replace(/(\d{2})\/(\d{2})\/(\d{4}),/, "$3-$1-$2");
}

export const PackageArtifactTable = createTable<PackageArtifact>()({
  keys: (artifact) => artifact.id ?? "",
  title: "Artifact States",
  headers: {
    id: { display: "ID" },
    storage_blob_url: { display: "Storage Blob URL" },
    signatures: { display: "WHQL OS signatures" },
    created_at: { display: "Created At" },
  },
  columns: (artifact) => ({
    id: <EllipsisColumn value={artifact.id} />,
    storage_blob_url: <TextColumn value={artifact.storage_blob_url} />,
    signatures: (
      <TextColumn
        value={
          artifact.signatures && artifact.signatures.length > 0
            ? artifact.signatures
                .filter((sig) => sig.operating_system)
                .map((sig) => sig.operating_system?.fullname || "Unknown OS")
                .sort()
                .join(", ")
            : "None"
        }
      />
    ),
    created_at: (
      <TextColumn
        value={
          artifact.created_at ? formatTimestamp(artifact.created_at) : "N/A"
        }
      />
    ),
  }),
});

type PackageArtifactTableProps = TableOptionals<typeof PackageArtifactTable> & {
  packageId: string;
};

export const PackageArtifactsTable = ({
  packageId,
  ...props
}: PackageArtifactTableProps) => {
  const [pageVars, pageController] = usePagination();
  const { data } = usePackageArtifactQuery({
    variables: {
      ...pageVars,
      package_id: packageId,
    },
    fetchPolicy: "network-only",
  });

  return (
    <PackageArtifactTable
      {...props}
      {...pageController}
      data={data?.packageArtifacts}
      total={data?.packageArtifacts_aggregate?.aggregate?.count}
    />
  );
};

export const PackageSignatureTable = createTable<PackageSignatures>()({
  keys: (signature) =>
    `${signature.id}-${signature.operating_system_fullname}-${signature.authority}`,
  title: "Package Signatures",
  headers: {
    id: { display: "ID" },
    operating_system_fullname: { display: "OS" },
    authority: { display: "Authority" },
    device_family_codename: { display: "Device Family" },
    storage_blob_url: { display: "Storage Blob URL" },
  },
  columns: (signature) => ({
    id: <EllipsisColumn value={signature.id || ""} />,
    operating_system_fullname: (
      <TextColumn value={signature.operating_system_fullname || "Unknown OS"} />
    ),
    authority: <TextColumn value={signature.authority} />,
    device_family_codename: (
      <TextColumn value={signature.device_family_codename || "N/A"} />
    ),
    storage_blob_url: <TextColumn value={signature.storage_blob_url} />,
  }),
});

type PackageSignatureTableProps = TableOptionals<
  typeof PackageSignatureTable
> & {
  packageId: string;
};

export const PackageSignaturesTable = ({
  packageId,
  ...props
}: PackageSignatureTableProps) => {
  const [pageVars, pageController] = usePagination();
  const { data } = usePackageSignaturesQuery({
    variables: {
      ...pageVars,
      package_id: packageId,
    },
    fetchPolicy: "network-only",
  });

  return (
    <PackageSignatureTable
      {...props}
      {...pageController}
      data={data?.packageSignatures}
      total={data?.packageSignatures_aggregate?.aggregate?.count}
    />
  );
};

// Define content to display in the main Package resource page
type PackageIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof PackageTable>;

export const PackageIndex = (props: PackageIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Packages</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Package
        </Button>
      </TableViewHeader>
      <Card>
        <AllPackagesTable
          {...props}
          where={[{ spec_package_id: { _is_null: true } }]}
          hideColumns={{ version: true, url: true, sbom: true }}
          selectable="none"
        />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Packages
const driverVersionSchema = Yup.object({
  id: Yup.string().required(),
  key: Yup.string().required(),
  number: Yup.string().required(),
}).required();

const driverSchema = Yup.object({
  id: Yup.string().required(),
  key: Yup.string().required(),
  name: Yup.string().required(),
  projectName: Yup.string().nullable(),
  versions: Yup.array(driverVersionSchema),
}).required();

const osTestSchema = Yup.object({
  os_fullname: Yup.string().required(),
  is_tested: Yup.boolean().required(),
});

const packageSchema = Yup.object({
  name: Yup.string().required(),
  customer: Yup.object({
    id: Yup.string().required(),
    codename: Yup.string().required(),
  }).required(),
  drivers: Yup.array(driverSchema).required(),
  external_package_name: Yup.string(),
  slack_channel: Yup.string(),
  sharepoint_upload_path: Yup.string(),
  sharepoint_release_notes_path: Yup.string(),
  bundle_struct_version: Yup.number().integer().nullable(),
  url: Yup.string().nullable(),
  package_os_tests: Yup.array(osTestSchema).required(),
}).required();

type DriverSchema = Yup.InferType<typeof driverSchema>;
type DriverVersionSchema = Yup.InferType<typeof driverVersionSchema>;
const PackageDriverTriage = createTriage<DriverVersionSchema, DriverSchema>();

export type PackageFormProps = {
  action: FormAction;
  package?: { id: string };
  onSuccess?: (id: string) => void;
};

export const PackageForm = (props: PackageFormProps) => {
  const [createPackage] = useCreatePackageMutation();
  const [updatePackage] = useUpdatePackageMutation();

  const allPackages = useAllPackagesQuery({
    variables: {
      where: { id: { _nin: props.package?.id ? [props.package.id] : [] } },
    },
    fetchPolicy: "network-only",
  });

  const existingPackage = usePackageQuery({
    variables: { id: props.package?.id ?? "" },
    fetchPolicy: "network-only",
    skip: !props.package,
  });
  const allOperatingSystems = useOperatingSystemsQuery({
    fetchPolicy: "network-only",
  });
  if (allOperatingSystems.loading) {
    return null;
  }
  return (
    <ResourceForm
      disableSubmit={() => !!existingPackage.data?.package?.version}
      action={props.action}
      schema={packageSchema}
      resourceToUpdate={existingPackage.data?.package}
      transform={(pkg) => {
        const transformed = {
          name: pkg.name,
          customer: {
            id: pkg.customer.id ?? "",
            codename: pkg.customer.codename ?? "",
          },
          drivers:
            pkg.driver_packages?.map((dp) => ({
              id: dp.driver.id,
              key: dp.driver.id,
              name: dp.driver.name,
              projectName:
                dp.driver.driver_project?.project?.project_name?.name ?? null,
              versions: pkg.driver_version_packages
                .filter((dvp) => dvp.driver_version.driver.id === dp.driver.id)
                .map((dvp) => ({
                  id: dvp.driver_version.id,
                  key: dvp.driver_version.id,
                  number: dvp.driver_version.version,
                })),
            })) ?? [],
          external_package_name: pkg.external_package_name ?? "",
          slack_channel: pkg.slack_channel ?? "",
          sharepoint_upload_path: pkg.sharepoint_upload_path ?? "",
          sharepoint_release_notes_path: pkg.sharepoint_release_notes_path ?? "",
          bundle_struct_version: pkg.bundle_struct_version ?? null,
          package_os_tests:
            allOperatingSystems.data?.operating_systems.map((os) => ({
              os_fullname: os.fullname,
              is_tested:
                pkg.package_operating_systems.find(
                  (pkg_os) => pkg_os.os_id === os.id,
                )?.is_tested ?? false,
            })) ?? [],
        };
        return transformed;
      }}
      initialValues={{
        name: "",
        customer: {
          id: "",
          codename: "",
        },
        drivers: [],
        external_package_name: "",
        slack_channel: "",
        sharepoint_upload_path: "",
        sharepoint_release_notes_path: "",
        bundle_struct_version: null,
        package_os_tests:
          allOperatingSystems.data?.operating_systems.map((os) => ({
            os_fullname: os.fullname,
            is_tested: false,
          })) ?? [],
      }}
      customValidator={(values, errors) => {
        for (let existing of allPackages.data?.packages ?? []) {
          if (
            existing.name === values.name &&
            existing.customer.id === values.customer.id &&
            existing.version === null
          ) {
            errors.name = `Package ${values.name} is already defined for customer ${existing.customer.codename}`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const { customer, drivers, package_os_tests, ...columns } = values;
        // Get drivers to add and remove.
        const selectedDriverIds = drivers.map(({ id }) => id);
        const existingDriverIds =
          existingPackage.data?.package?.driver_packages
            ?.filter((dp) => !!dp.driver.id)
            .map((dp) => dp.driver.id) ?? [];
        const driverIdsToRemove = existingDriverIds.filter(
          (id) => !selectedDriverIds.includes(id),
        );
        const driverIdsToAdd = selectedDriverIds.filter(
          (id) => !existingDriverIds.includes(id),
        );
        // Get driver versions to add and remove.
        const selectedDriverVersionIds: string[] = drivers.reduce(
          (acc, driver) => [
            ...acc,
            ...(driver.versions ?? []).map(({ id }) => id),
          ],
          [] as string[],
        );
        const existingDriverVersionIds =
          existingPackage.data?.package?.driver_version_packages
            ?.filter((dvp) => !!dvp.driver_version.id)
            .map((dvp) => dvp.driver_version.id) ?? [];
        const driverVersionIdsToRemove = existingDriverVersionIds.filter(
          (id) => !selectedDriverVersionIds.includes(id),
        );
        const driverVersionIdsToAdd = selectedDriverVersionIds.filter(
          (id) => !existingDriverVersionIds.includes(id),
        );
        const packageOsTests = package_os_tests.map((package_os_test) => ({
          os_id: allOperatingSystems.data?.operating_systems.find(
            (os) => os.fullname === package_os_test?.os_fullname,
          )?.id,
          is_tested: package_os_test?.is_tested,
          package_id: props.package?.id,
        }));
        const result = await (async () => {
          if (props.package?.id)
            return await updatePackage({
              variables: {
                id: props.package.id,
                input: {
                  ...columns,
                  external_package_name: nullIfEmpty(
                    values.external_package_name ?? "",
                  ),
                  slack_channel: nullIfEmpty(values.slack_channel ?? ""),
                  sharepoint_upload_path: nullIfEmpty(
                    values.sharepoint_upload_path ?? "",
                  ),
                  sharepoint_release_notes_path: nullIfEmpty(
                    values.sharepoint_release_notes_path ?? "",
                  ),
                },
                delete_driver_ids: driverIdsToRemove,
                add_drivers: driverIdsToAdd.map((id) => ({
                  package_id: props.package!.id,
                  driver_id: id,
                })),
                delete_driver_version_ids: driverVersionIdsToRemove,
                add_driver_versions: driverVersionIdsToAdd.map((id) => ({
                  package_id: props.package!.id,
                  driver_version_id: id,
                })),
                package_os_tests: packageOsTests,
              },
            });
        })();
        props.onSuccess && props.onSuccess(result?.data?.package?.id ?? "");
      }}
      onInsert={async (values) => {
        const { customer, drivers, package_os_tests, ...columns } = values;
        const selectedDriverVersionIds: string[] = drivers.reduce(
          (acc, driver) => [
            ...acc,
            ...(driver.versions ?? []).map(({ id }) => id),
          ],
          [] as string[],
        );
        const result = await (async () => {
          return await createPackage({
            variables: {
              input: {
                ...columns,
                external_package_name: nullIfEmpty(
                  values.external_package_name ?? "",
                ),
                slack_channel: nullIfEmpty(values.slack_channel ?? ""),
                sharepoint_upload_path: nullIfEmpty(
                  values.sharepoint_upload_path ?? "",
                ),
                sharepoint_release_notes_path: nullIfEmpty(
                  values.sharepoint_release_notes_path ?? "",
                ),
                customer_id: customer.id,
                driver_version_packages: {
                  data: selectedDriverVersionIds.map((id) => ({
                    driver_version_id: id,
                  })),
                },
                driver_packages: {
                  data: drivers.map(({ id }) => ({ driver_id: id })),
                },
                package_operating_systems: {
                  data: package_os_tests.map((package_os_test) => ({
                    os_id: allOperatingSystems.data?.operating_systems.find(
                      (os) => os.fullname === package_os_test?.os_fullname,
                    )?.id,
                    is_tested: package_os_test?.is_tested,
                    package_id: props.package?.id,
                  })),
                },
              },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.package?.id ?? "");
      }}
      render={({ formik, path }) => (
        <>
          <FormHeader>
            {props.package ? "Update Package" : "Create New Package"}
          </FormHeader>
          <Tablature
            useUrlParams={false}
            tabs={[
              {
                name: "general",
                label: <TabLabel label="General" icon={<Info />} />,
                content: (
                  <FormContainer key="general">
                    <TextField
                      bp={{ xs: 12, sm: 6 }}
                      name={path.name._}
                      label="Name"
                    />
                    <TextField
                      bp={{ xs: 12, sm: 6 }}
                      name={
                        path.external_package_name?._ ?? "external_package_name"
                      }
                      label="External Name (used for official releases to the customer)"
                      variant="outlined"
                    />
                    <DialogSelect
                      bp={{ xs: 12, sm: 6 }}
                      name={path.customer.codename._}
                      disabled={props.action === "update"}
                      label="Customer"
                      onReset={() =>
                        formik.setValues({
                          ...formik.values,
                          customer: formik.initialValues.customer,
                        })
                      }
                      content={(close) => (
                        <CustomerList
                          selectable="single"
                          onSelect={(customer) => {
                            formik.setValues({
                              ...formik.values,
                              customer: {
                                id: customer.id ?? "",
                                codename: customer.codename ?? "",
                              },
                            });
                            close();
                          }}
                        />
                      )}
                    />
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={[
                          { id: null, value: 'None' },
                          { id: 1, value: '1' },
                          { id: 2, value: '2' }
                        ]}
                        value={
                          formik.values.bundle_struct_version !== null && formik.values.bundle_struct_version !== undefined
                            ? {
                              id: formik.values.bundle_struct_version,
                              value: formik.values.bundle_struct_version.toString()
                            }
                            : { id: null, value: 'None' }
                        }
                        getOptionLabel={(option) => option.value || ""}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(_event, nv) => {
                          formik.setFieldValue(
                            "bundle_struct_version",
                            nv ? nv.id : null,
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Bundle structure version"
                            name={"bundle_struct_version"}
                            bp={{ xs: 12, sm: 6 }}
                          />
                        )}
                      />
                    </Grid>
                  </FormContainer>
                ),
              },
              {
                name: "ci",
                label: <TabLabel label="CI/CD" icon={<TrackChanges />} />,
                content: (
                  <FormContainer key="ci">
                    <TextField
                      bp={{ xs: 12, sm: 8 }}
                      name={path.slack_channel?._ ?? "slack_channel"}
                      label="Slack channel"
                    />
                    <TextField
                      bp={{ xs: 12, sm: 8 }}
                      name={
                        path.sharepoint_upload_path?._ ??
                        "sharepoint_upload_path"
                      }
                      label="Sharepoint Upload Path"
                    />
                    <TextField
                      bp={{ xs: 12, sm: 8 }}
                      name={
                        path.sharepoint_release_notes_path?._ ??
                        "sharepoint_release_notes_path"
                      }
                      label="Sharepoint Release Notes Path"
                    />
                  </FormContainer>
                ),
              },
              {
                name: "hlk",
                label: (
                  <TabLabel label="HLK Tests" icon={<LocationSearching />} />
                ),
                content: (
                  <FormContainer>
                    {allOperatingSystems.data?.operating_systems.map(
                      (os, _) => (
                        <FormControlLabel
                          key={os.fullname}
                          control={
                            <Checkbox
                              checked={
                                formik.values.package_os_tests.find(
                                  (package_os_test) =>
                                    os.fullname ===
                                    package_os_test?.os_fullname,
                                )?.is_tested
                              }
                              onChange={(event) => {
                                const updatedOsTests =
                                  formik.values.package_os_tests.map(
                                    (package_os_test) =>
                                      package_os_test?.os_fullname ===
                                      os.fullname
                                        ? {
                                            ...package_os_test,
                                            is_tested: event.target.checked,
                                          }
                                        : package_os_test,
                                  );
                                formik.setFieldValue(
                                  "package_os_tests",
                                  updatedOsTests,
                                );
                              }}
                            />
                          }
                          label={os.fullname}
                        />
                      ),
                    )}
                  </FormContainer>
                ),
              },
              {
                name: "drivers",
                label: <TabLabel label="Drivers" icon={<Build />} />,
                content: (
                  <FieldArray
                    name={path.drivers._}
                    render={(helper) => (
                      <>
                        <FormContainer>
                          <DrawerButton
                            bp={{ xs: 4 }}
                            disabled={!formik.values.customer.id}
                            variant="contained"
                            color="primary"
                            label="Add Driver"
                            startIcon={<AddCircleOutline />}
                            disableElevation
                            fullWidth={true}
                            content={(close) => {
                              const selectedDriverIds =
                                formik.values.drivers.map(({ id }) => id);
                              return (
                                <SelectPackageDriverTable
                                  selectedDriverIds={selectedDriverIds}
                                  packageCustomerId={formik.values.customer.id}
                                  onSelect={(d) => {
                                    helper.insert(0, {
                                      id: d.id,
                                      key: d.id,
                                      name: d.name ?? "",
                                      projectName:
                                        d.driver_project?.project?.project_name
                                          ?.name ?? null,
                                      versions: [],
                                    });
                                    close();
                                  }}
                                />
                              );
                            }}
                          />
                        </FormContainer>
                        <FormContainer>
                          <Grid item xs={12}>
                            <PackageDriverTriage
                              id="triage-package-drivers"
                              disableItemDragging={true}
                              disableGroupDragging={true}
                              groups={formik.values.drivers ?? []}
                              groupDirection="vertical"
                              getGroupItems={(group) => group.versions ?? []}
                              setGroupItems={(group, items) =>
                                (group.versions = items)
                              }
                              onChange={(groups) =>
                                formik.setFieldValue("drivers", groups)
                              }
                              renderGroup={({ index, children, group }) => (
                                <Card style={{ marginTop: 8 }}>
                                  <CardContent>
                                    <Grid container direction="row" spacing={2}>
                                      <Grid
                                        item
                                        xs
                                        container
                                        direction="column"
                                      >
                                        <Grid
                                          item
                                          container
                                          spacing={2}
                                          justify="space-between"
                                        >
                                          <Grid item>
                                            <Typography variant="body1">
                                              {group.projectName
                                                ? `${group.name} (${group.projectName})`
                                                : group.name}
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <IconButton
                                              onClick={() =>
                                                helper.remove(index)
                                              }
                                            >
                                              <Close />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                        <Grid item container>
                                          {children}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              )}
                              itemDirection="horizontal"
                              renderItem={({ item, group, groupIndex }) => (
                                <Chip
                                  style={{ marginRight: 10 }}
                                  label={item.number}
                                  onDelete={() => {
                                    const replacement = { ...group };
                                    if (replacement.versions?.length) {
                                      replacement.versions =
                                        replacement.versions.filter(
                                          ({ key }) => key !== item.key,
                                        );
                                    } else {
                                      replacement.versions = [];
                                    }
                                    helper.replace(groupIndex, replacement);
                                  }}
                                />
                              )}
                              renderItemActions={({ group, index }) => (
                                <NewDriverVersionButton
                                  // Disable for multiple selections of a non-project specific driver.
                                  disabled={
                                    !group.projectName &&
                                    !!group.versions?.length
                                  }
                                  driverId={group.id}
                                  selectedDriverVersions={group.versions ?? []}
                                  onSubmit={(version) => {
                                    const replacement = { ...group };
                                    replacement.versions = [
                                      ...(replacement.versions ?? []),
                                      version,
                                    ];
                                    helper.replace(index, replacement);
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </FormContainer>
                      </>
                    )}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    />
  );
};

type SelectPackageDriverTableProps = {
  onSelect: (item: DeepPartial<Driver>) => void;
  selectedDriverIds: string[];
  packageCustomerId?: string;
};
const SelectPackageDriverTable = (props: SelectPackageDriverTableProps) => {
  const [isolatePackageCustomer, setIsolatePackageCustomer] = useState(true);
  const filters: Deliveries_Drivers_Bool_Exp[] = [
    { id: { _nin: props.selectedDriverIds } },
    { deleted_at: { _is_null: true } },
  ];
  if (isolatePackageCustomer && !!props.packageCustomerId) {
    filters.push({
      _or: [
        { _not: { driver_project: {} } },
        {
          driver_project: {
            project: { customer_id: { _eq: props.packageCustomerId } },
          },
        },
      ],
    });
  }
  return (
    <>
      <Grid container item>
        <FormControlLabel
          control={
            <Switch
              onChange={(event) =>
                setIsolatePackageCustomer(event.target.checked)
              }
              checked={isolatePackageCustomer}
            />
          }
          label="Hide Drivers with Different Customer"
        />
      </Grid>
      <AllDriversTable
        selectable="single"
        hideColumns={{ id: true, versions: true, is_archived: true }}
        where={[{ _and: filters }]}
        onSelect={props.onSelect}
      />
    </>
  );
};

type NewDriverVersionButtonProps = {
  driverId: string;
  selectedDriverVersions: DriverVersionSchema[];
  onSubmit: (dv: DriverVersionSchema) => void;
  disabled?: boolean;
};
const NewDriverVersionButton = (props: NewDriverVersionButtonProps) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Chip
        icon={<AddCircleOutline fontSize="small" />}
        label={"Add Version"}
        color="primary"
        disabled={props.disabled ?? false}
        onClick={() => setShow(true)}
      />
      <ClosableDrawer show={show} setShow={setShow}>
        <AllDriverVersionsTable
          selectable="single"
          hideColumns={{ id: true, blob: true }}
          where={[
            {
              id: { _nin: props.selectedDriverVersions.map(({ id }) => id) },
              driver_id: { _eq: props.driverId },
            },
          ]}
          onSelect={(d) => {
            props.onSubmit({
              id: d.id ?? "",
              key: d.id ?? "",
              number: d.version ?? "",
            });
            setShow(false);
          }}
        />
      </ClosableDrawer>
    </>
  );
};

// Create a detailed 'show' page.
type PackageShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Package>) => void;
};

const PackageShow = (props: PackageShowProps) => {
  const packageNav = usePackageNav();
  const driverVersionNav = useDriverVersionNav();
  const ag = useRequireRole([Roles_Enum.Asgard]);
  const [archivePackage] = useArchivePackageMutation();
  const packageQuery = usePackageQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const checkPackageHasVersionQuery = useCheckPackageHasVersionQuery({
    variables: { spec_package_id: props.id },
    fetchPolicy: "network-only",
  });
  const pkg = packageQuery.data?.package;
  if (!pkg) return null;

  const isVersioned = !!pkg.spec_package_id;
  const properties = [
    { label: "ID", value: pkg.id },
    { label: "Name", value: pkg.name ?? "" },
    { label: "Customer", value: pkg.customer?.codename ?? "" },
  ];
  const versionedPackages =
    checkPackageHasVersionQuery.data?.versioned_packages;
  const canArchiveUnarchive =
    !isVersioned && (versionedPackages?.length ?? 0) > 0;
  return (
    <ShowResourceView
      title={isVersioned ? pkg.version ?? "" : pkg.name ?? ""}
      subtitle={
        <Typography variant="caption">
          {isVersioned
            ? `This is a release of the ${pkg.name} package. It cannot be edited.`
            : `This is the "specification" of the ${pkg.name} package. It can be edited, and its ID can be used to make releases.`}
        </Typography>
      }
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="packages" onClick={() => packageNav.list()} />
          {isVersioned && (
            <Breadcrumb
              label={pkg.name ?? ""}
              onClick={() => packageNav.show(pkg.spec_package_id!)}
            />
          )}
          <Breadcrumb
            label={isVersioned ? pkg.version ?? "" : pkg.name ?? ""}
          />
        </Breadcrumbs>
      }
      properties={properties}
      onEditAction={
        !!pkg.version
          ? undefined
          : () => props.onEditAction && props.onEditAction(pkg)
      }
      archivedAt={pkg?.deleted_at}
      onArchiveAction={
        ag && canArchiveUnarchive
          ? () => {
              archivePackage({
                variables: {
                  id: pkg.id,
                  deleted_at: new Date().toISOString(),
                },
              });
            }
          : undefined
      }
      onUnarchiveAction={
        ag && canArchiveUnarchive
          ? () => {
              archivePackage({
                variables: {
                  id: pkg.id,
                  deleted_at: null,
                },
              });
            }
          : undefined
      }
    >
      <Tablature
        useUrlParams
        tabs={[
          {
            name: "driverversions",
            label: "Drivers",
            content: (
              <AllDriverVersionsTable
                hideColumns={{ target: true }}
                selectable="none"
                showUrl={(item) => item.id && driverVersionNav.showUrl(item.id)}
                where={[
                  {
                    driver_version_packages: { package_id: { _eq: props.id } },
                  },
                ]}
              />
            ),
          },
          {
            name: "targets",
            label: "Projects",
            content: (
              <>
                {pkg.targets.map((target) => (
                  <InfoDisplay
                    key={target.driver_version?.id}
                    items={[
                      {
                        label: "Project",
                        value:
                          target.driver_version?.driver?.driver_project?.project
                            ?.project_name?.name ?? "",
                        bp: { xs: 6, sm: 4 },
                      },
                      {
                        label: "Engine",
                        value:
                          target.driver_version?.driver_version_engine?.engine
                            ?.build_number ?? "",
                        bp: { xs: 6, sm: 4 },
                      },
                      {
                        label: "Driver Version",
                        value: target.driver_version?.version ?? "",
                        bp: { xs: 6, sm: 4 },
                      },
                    ]}
                  />
                ))}
              </>
            ),
          },
          {
            name: "versions",
            label: "Versions",
            content: (
              <AllPackagesTable
                selectable="none"
                hideColumns={{
                  versions: true,
                  name: true,
                  customer: true,
                  is_archived: true,
                  url: true
                }}
                onShowAction={(item) => item.id && packageNav.show(item.id)}
                showUrl={(item) => item.id && packageNav.showUrl(item.id)}
                where={[{ spec_package_id: { _eq: props.id } }]}
              />
            ),
            hide: pkg.version !== null,
          },
          {
            name: "artifacts",
            label: "Artifacts",
            content: (
              <PackageArtifactsTable packageId={props.id} selectable="none" />
            ),
            hide: pkg.version === null,
          },
          {
            name: "signatures",
            label: "Signatures",
            content: (
              <PackageSignaturesTable packageId={props.id} selectable="none" />
            ),
            hide: pkg.version === null,
          },
        ]}
      />
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "packages";
export const usePackageNav = () => useResourceNav(path);
export const PackageResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <PackageIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <PackageShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <PackageForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <PackageForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        package={{ id: id ?? "" }}
      />
    )}
  />
);
