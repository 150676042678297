import {
  createStyles,
  LinearProgress,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { FileError } from "react-dropzone";
import { FileHeader } from "./FileHeader";

export interface UploadErrorProps {
  file: File;
  onClose: (file: File) => void;
  errors: FileError[];
}

const ErrorLinearProgress = withStyles((theme) =>
  createStyles({
    bar: {
      backgroundColor: theme.palette.error.main,
    },
  }),
)(LinearProgress);

export function UploadError({ file, onClose, errors }: UploadErrorProps) {
  return (
    <React.Fragment>
      <FileHeader file={file} onClose={onClose} error={true} />
      <ErrorLinearProgress variant="determinate" value={100} />
      {errors.map((error) => (
        <div key={error.code}>
          <Typography color="error">{error.message}</Typography>
        </div>
      ))}
    </React.Fragment>
  );
}
