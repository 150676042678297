import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

type Keyable = {
  key: string;
};

type RenderGroupProps<Group extends Keyable> = {
  group: Group;
  index: number;
  children: JSX.Element;
};

type RenderItemProps<Group extends Keyable, Item extends Keyable> = {
  item: Item;
  group: Group;
  groupIndex: number;
  itemIndex: number;
};

type RenderItemActionProps<Group extends Keyable> = {
  group: Group;
  index: number;
};

/**
 * A drag/drop component for building an element similar to a Trello board.
 */
type TriageProps<Item extends Keyable, Group extends Keyable> = {
  id: string;
  groups: Group[];
  groupDirection?: "vertical" | "horizontal";
  getGroupItems: (group: Group) => Item[];
  setGroupItems: (group: Group, items: Item[]) => void;
  renderGroup: (helper: RenderGroupProps<Group>) => React.ReactNode;
  disableGroupDragging?: boolean;
  itemDirection?: "vertical" | "horizontal";
  renderItem: (helper: RenderItemProps<Group, Item>) => React.ReactNode;
  renderItemActions: (helper: RenderItemActionProps<Group>) => React.ReactNode;
  itemPlaceholder?: React.ReactNode;
  itemActions?: React.ReactNode;
  disableItemDragging?: boolean;
  onChange: (groups: Group[]) => void;
};

function Triage<Item extends Keyable, Group extends Keyable>(
  props: TriageProps<Item, Group>,
) {
  // Default orientation like Trello board, with groups organized horizontally,
  // and items within each group organized vertically.
  const groupDirection = props.groupDirection ?? "horizontal";
  const itemDirection = props.itemDirection ?? "vertical";

  // Avoid mutating data from props, using JSON coding/encoding
  // to "deepcopy".
  const copyGroup = (group: Group) => JSON.parse(JSON.stringify(group));

  const onDragEnd = ({ destination, source, type }: DropResult) => {
    if (!destination) return;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    const newGroups = Array.from(props.groups);
    if (type === "group") {
      // Respond to dragging group.
      const movedGroup = newGroups[source.index];
      newGroups.splice(source.index, 1);
      newGroups.splice(destination.index, 0, movedGroup);
      props.onChange(newGroups);
      return;
    } else if (type === "item") {
      // Respond to dragging item within group or to another group.
      const sourceGroupIndex = newGroups.findIndex(
        (g) => g.key === source.droppableId,
      );
      const destGroupIndex = newGroups.findIndex(
        (g) => g.key === destination.droppableId,
      );
      if (sourceGroupIndex === -1 || destGroupIndex === -1) {
        return;
      }
      const newSourceGroup = copyGroup(newGroups[sourceGroupIndex]);
      const newDestGroup =
        sourceGroupIndex === destGroupIndex
          ? newSourceGroup
          : copyGroup(newGroups[destGroupIndex]);
      const newSourceGroupItems = props.getGroupItems(newSourceGroup);
      const newDestGroupItems = props.getGroupItems(newDestGroup);
      const movedItem = newSourceGroupItems[source.index];
      // Rearrange new item arrays.
      newSourceGroupItems.splice(source.index, 1);
      newDestGroupItems.splice(destination.index, 0, movedItem);
      props.setGroupItems(newSourceGroup, newSourceGroupItems);
      props.setGroupItems(newDestGroup, newDestGroupItems);
      // Replace modified groups in new group array.
      newGroups[sourceGroupIndex] = newSourceGroup;
      newGroups[destGroupIndex] = newDestGroup;
      props.onChange(newGroups);
      return;
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={props.id} direction={groupDirection} type="group">
        {(provided) => (
          <div
            style={{
              display: "flex",
              flexDirection: groupDirection === "horizontal" ? "row" : "column",
              flexWrap: "wrap", // ????
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {props.groups.map((group, groupIndex) => {
              // Wrap child items in <Draggable /> elements.
              const children = props
                .getGroupItems(group)
                .map((item, itemIndex) => {
                  const renderItemHelper: RenderItemProps<Group, Item> = {
                    group: copyGroup(group),
                    groupIndex: groupIndex,
                    item: item!,
                    itemIndex: itemIndex,
                  };
                  return (
                    <Draggable
                      key={item.key}
                      draggableId={item.key}
                      index={itemIndex}
                      isDragDisabled={!!props.disableItemDragging}
                    >
                      {(providedItem) => (
                        <div
                          ref={providedItem.innerRef}
                          {...providedItem.draggableProps}
                          {...providedItem.dragHandleProps}
                        >
                          {props.renderItem(renderItemHelper)}
                        </div>
                      )}
                    </Draggable>
                  );
                });
              const renderGroupHelper: RenderGroupProps<Group> = {
                group: group!,
                index: groupIndex,
                // Wrap all children in a droppable element.
                children: (
                  <Droppable
                    droppableId={group.key}
                    direction={itemDirection}
                    type="item"
                  >
                    {(providedItems) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection:
                            itemDirection === "horizontal" ? "row" : "column",
                          flexWrap: "wrap",
                        }}
                        ref={providedItems.innerRef}
                        {...providedItems.droppableProps}
                      >
                        {children.length === 0
                          ? props.itemPlaceholder && props.itemPlaceholder
                          : children}
                        {providedItems.placeholder}
                        {props.renderItemActions({
                          group: copyGroup(group),
                          index: groupIndex,
                        })}
                      </div>
                    )}
                  </Droppable>
                ),
              };
              return (
                <Draggable
                  key={group.key}
                  draggableId={group.key}
                  index={groupIndex}
                  isDragDisabled={!!props.disableGroupDragging}
                >
                  {(providedGroup) => (
                    <div
                      {...providedGroup.draggableProps}
                      {...providedGroup.dragHandleProps}
                      ref={providedGroup.innerRef}
                    >
                      {props.renderGroup(renderGroupHelper)}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export function createTriage<Item extends Keyable, Group extends Keyable>() {
  return (props: TriageProps<Item, Group>) => <Triage {...props} />;
}
