import { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  InputLabel,
  MenuItem,
  Fab,
  makeStyles,
  createStyles,
  Card,
  Theme,
  TextField,
} from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { Resource, useResourceNav } from "src/components/Resource";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import CopyToClipboard from "react-copy-to-clipboard";
import * as Yup from "yup";
import { Formik } from "formik";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// Globals and Utilities
const AUDIBLE_FLOOR: number = 20000; // Frequencies must be higher than this
const db2mag = (decibels: number) => {
  return 10 ** (decibels / 20);
};

// Options
const samplingFrequencyOptions = ["48kHz", "96kHz"] as const;
type SamplingFrequency = typeof samplingFrequencyOptions[number];
const chirpLengthOptions = [512, 1024, 2048, 3072] as const;
type ChirpLength = typeof chirpLengthOptions[number];
const algorithmOptions = ["sharplite", "sharpfft", "hanning"];
type Algorithm = typeof algorithmOptions[number];

// Option Maps
const radioFormControlLabel = (option: string | number) => {
  return (
    <FormControlLabel
      value={option}
      control={<Radio />}
      label={String(option)}
    />
  );
};

const menuItems = (option: string | number) => {
  return <MenuItem value={option}>{option}</MenuItem>;
};

// Style stuff
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: 20,
      width: "100%",
      height: "100%",
      overflow: "auto",
      fontSize: 20,
    },
    form: {
      padding: 20,
      marginLeft: "auto",
      marginRight: "auto",
    },
  }),
);

// Logic
function calculateStartFrequencies(chirp: Chirp): Chirp {
  let samplingRate = 1000 * parseInt(chirp.samplingFrequency.substring(0, 2));
  let nyquistCeiling = (samplingRate / 2) | 0;
  let frequencyInterval = samplingRate / chirp.chirpLength;

  let firstPossibleFrequency =
    AUDIBLE_FLOOR + (frequencyInterval - (AUDIBLE_FLOOR % frequencyInterval));
  let frequencyRange = (nyquistCeiling - firstPossibleFrequency) | 0;
  let numOptions = (frequencyRange / frequencyInterval) | 0;
  let suitableIntervals = Array.from(Array(numOptions).keys()).map(
    (x: number) => x * frequencyInterval + firstPossibleFrequency,
  );
  let intSuitableIntervals = suitableIntervals.filter(
    (num) => num > 0 && num % (num | 0) === 0,
  );
  let nyquistSuitableIntervals = intSuitableIntervals.filter(
    (num) => num < nyquistCeiling,
  );
  chirp.startingFrequency = nyquistSuitableIntervals[0];
  chirp.mixdownFrequency = 21000;
  chirp.endingFrequency = nyquistSuitableIntervals[1];
  chirp.startingFrequencyOptions = nyquistSuitableIntervals;
  return chirp;
}

function calculateMixdownFrequencies(chirp: Chirp): Chirp {
  let samplingRate = 1000 * parseInt(chirp.samplingFrequency.substring(0, 2));
  if (samplingRate === 48000) {
    chirp.mixdownFrequencyOptions = [21000];
  } else {
    let nyquistCeiling = (samplingRate / 2) | 0;
    let frequencyInterval = samplingRate / chirp.chirpLength;

    let firstPossibleFrequency =
      AUDIBLE_FLOOR + (frequencyInterval - (AUDIBLE_FLOOR % frequencyInterval));
    let frequencyRange = (nyquistCeiling - firstPossibleFrequency) | 0;
    let numOptions = (frequencyRange / frequencyInterval) | 0;
    let suitableIntervals = Array.from(Array(numOptions).keys()).map(
      (x: number) => x * frequencyInterval + firstPossibleFrequency,
    );
    let intSuitableIntervals = suitableIntervals.filter(
      (num) => num > 0 && num % (num | 0) === 0,
    );
    let nyquistSuitableIntervals = intSuitableIntervals.filter(
      (num) => num < nyquistCeiling,
    );
    chirp.mixdownFrequency = nyquistSuitableIntervals[0];
    chirp.mixdownFrequencyOptions = nyquistSuitableIntervals;
  }
  return chirp;
}

function calculateEndFrequencies(chirp: Chirp): Chirp {
  let samplingRate = 1000 * parseInt(chirp.samplingFrequency.substring(0, 2));
  let nyquistCeiling = (samplingRate / 2) | 0;
  let frequencyInterval = samplingRate / chirp.chirpLength;

  let firstPossibleFrequency =
    AUDIBLE_FLOOR + (frequencyInterval - (AUDIBLE_FLOOR % frequencyInterval));
  let frequencyRange = (nyquistCeiling - firstPossibleFrequency) | 0;
  let numOptions = (frequencyRange / frequencyInterval) | 0;
  let suitableIntervals = Array.from(Array(numOptions).keys()).map(
    (x: number) => x * frequencyInterval + firstPossibleFrequency,
  );
  let intSuitableIntervals = suitableIntervals.filter(
    (num) => num > 0 && num % (num | 0) === 0,
  );
  let nyquistSuitableIntervals = intSuitableIntervals.filter(
    (num) => num < nyquistCeiling,
  );
  let startingSuitableIntervals = nyquistSuitableIntervals.filter(
    (num) => num > chirp.startingFrequency,
  );
  chirp.endingFrequency = startingSuitableIntervals[0];
  chirp.endingFrequencyOptions = startingSuitableIntervals;
  return chirp;
}

function calculateScalings(chirp: Chirp): [number, number] {
  let scaling = parseFloat(chirp.scaling);
  if (chirp.projectIs24Bit) {
    scaling = parseFloat(chirp.scaling) / 16;
  }
  let speakerScalingFactor = db2mag(parseFloat(chirp.speakerScalingFactor));
  let speakerScaling = parseFloat((scaling / speakerScalingFactor).toFixed(4));

  return [scaling, speakerScaling];
}

// Chirp Object
interface Chirp {
  samplingFrequency: SamplingFrequency;
  chirpLength: ChirpLength;
  algorithm: Algorithm;
  scaling: string;
  speakerScalingFactor: string;
  speakerScaling: number;
  projectIs24Bit: boolean;
  startingFrequency: number;
  mixdownFrequency: number;
  endingFrequency: number;
  startingFrequencyOptions: number[];
  mixdownFrequencyOptions: number[];
  endingFrequencyOptions: number[];
}

const initialChirp: Chirp = {
  samplingFrequency: "96kHz",
  chirpLength: 512,
  algorithm: "sharplite",
  scaling: "0.2",
  speakerScalingFactor: "17",
  speakerScaling: 0.0117,
  projectIs24Bit: false,
  startingFrequency: 20250,
  mixdownFrequency: 21000,
  endingFrequency: 23250,
  startingFrequencyOptions: [20250],
  mixdownFrequencyOptions: [21000],
  endingFrequencyOptions: [23250],
};

// This is a hack to make the output play nicely with the python tool.
// We can deprecate this when initial engine moves to manager
const chirpToString = (chirp: Chirp) => {
  let [scaling, speakerScaling] = calculateScalings(chirp);
  let jsonChirp = {
    chirp_length: chirp.chirpLength,
    start_freq: chirp.startingFrequency,
    end_freq: chirp.endingFrequency,
    mixdown: chirp.mixdownFrequency,
    scaling: scaling,
    speaker_scaling: speakerScaling,
    algo: chirp.algorithm,
  };
  let stringChirp = JSON.stringify(jsonChirp);
  stringChirp = stringChirp.replaceAll(",", ", ");
  stringChirp = stringChirp.replaceAll(":", ": ");
  stringChirp = stringChirp.replaceAll('"', '\\"');
  stringChirp = '"' + stringChirp + '"';
  return stringChirp;
};

let intermediate_state: Chirp = initialChirp;

// Validation
const chirpSchema = Yup.object({
  samplingFrequency: Yup.string(),
  chirpLength: Yup.number(),
  algorithm: Yup.string(),
  scaling: Yup.number()
    .min(0, "Minimum scaling is 0.0")
    .max(1.0, "Maximum scaling is 1.0")
    .required("This field is required"),
  speakerScalingFactor: Yup.number()
    .min(1, "Minimum scaling factor is 1")
    .max(100, "Maximum scaling factor is 100")
    .required("This field is required"),
  projectIs24Bit: Yup.bool(),
  startingFrequency: Yup.number(),
  mixdownFrequency: Yup.number(),
  endingFrequency: Yup.number(),
  startingFrequencyOptions: Yup.array(Yup.number()),
  mixdownFrequencyOptions: Yup.array(Yup.number()),
  endingFrequencyOptions: Yup.array(Yup.number()),
});

// Chirp Designer Form
export const ChirpDesignerForm = () => {
  //Make it pretty
  const classes = useStyles();

  // Form state for dynamic updating
  const [state, setState] = useState<Chirp>(initialChirp);
  const [copied, setCopied] = useState(false);

  function refreshAllOptions(state: Chirp): Chirp {
    state = calculateStartFrequencies(state);
    state = calculateMixdownFrequencies(state);
    state = calculateEndFrequencies(state);
    return state;
  }

  refreshAllOptions(initialChirp);

  return (
    <Formik
      validationSchema={chirpSchema}
      initialValues={initialChirp}
      values={state}
      validateOnBlur={true}
      onSubmit={async () => {}}
    >
      {({ values, handleBlur, touched, errors, setFieldTouched }) => {
        return (
          <>
            <div className={classes.header}>{"Chirp Designer Tool"}</div>
            <Card className={classes.form}>
              {/*Sampling Frequency*/}
              <FormControl style={{ width: "100%" }}>
                <FormLabel>Sampling Frequency</FormLabel>
                <RadioGroup
                  row
                  value={state.samplingFrequency}
                  onChange={(e) => {
                    intermediate_state = refreshAllOptions({
                      ...state,
                      samplingFrequency: e.target.value as any,
                    });
                    setState(intermediate_state);
                  }}
                >
                  {samplingFrequencyOptions.map((x) =>
                    radioFormControlLabel(x),
                  )}
                </RadioGroup>
              </FormControl>

              {/*Chirp Length*/}
              <FormControl style={{ width: "100%" }}>
                <FormLabel>Chirp Length</FormLabel>
                <RadioGroup
                  row
                  value={state.chirpLength}
                  onChange={(e) => {
                    intermediate_state = refreshAllOptions({
                      ...state,
                      chirpLength: Number(e.target.value) as any,
                    });
                    setState(intermediate_state);
                  }}
                >
                  {chirpLengthOptions.map((x) => radioFormControlLabel(x))}
                </RadioGroup>
              </FormControl>

              {/*Algorithm*/}
              <FormControl style={{ width: "100%" }}>
                <FormLabel>Algorithm</FormLabel>
                <RadioGroup
                  row
                  value={state.algorithm}
                  onChange={(e) => {
                    intermediate_state = {
                      ...state,
                      algorithm: e.target.value as any,
                    };
                    setState(intermediate_state);
                  }}
                >
                  {algorithmOptions.map((x) => radioFormControlLabel(x))}
                </RadioGroup>
              </FormControl>

              {/*24 Bit Project*/}
              <FormControl style={{ width: "100%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"projectIs24bit"}
                      value={state.projectIs24Bit}
                      onChange={(e) => {
                        intermediate_state = refreshAllOptions({
                          ...state,
                          projectIs24Bit: e.target.value as any,
                        });
                        setState(intermediate_state);
                      }}
                    />
                  }
                  label="24-Bit Project"
                />
              </FormControl>

              {/*Chirp Scaling*/}
              <FormControl style={{ width: "100%", marginBottom: 20 }}>
                <TextField
                  name={"scaling"}
                  value={values.scaling}
                  error={errors.scaling ? true : false}
                  helperText={errors.scaling}
                  onBlur={() => setFieldTouched("scaling")}
                  InputProps={{
                    inputProps: {
                      inputMode: "numeric",
                      pattern: "[0-9].[0.9]*",
                    },
                  }}
                  label="Chirp Scaling"
                  onChange={(e) => {
                    setState({ ...state, scaling: e.target.value });
                    values.scaling = e.target.value;
                  }}
                />
              </FormControl>

              {/*Speaker Scaling Factor*/}
              <FormControl style={{ width: "100%", marginBottom: 20 }}>
                <TextField
                  name={"speakerScalingFactor"}
                  value={values.speakerScalingFactor}
                  error={errors.speakerScalingFactor ? true : false}
                  helperText={errors.speakerScalingFactor}
                  onBlur={() => setFieldTouched("speakerScalingFactor")}
                  InputProps={{
                    inputProps: {
                      inputMode: "numeric",
                      pattern: "[0-9].[0.9]*",
                    },
                  }}
                  label="Speaker Scaling Factor"
                  onChange={(e) => {
                    setState({
                      ...state,
                      speakerScalingFactor: e.target.value,
                    });
                    values.speakerScalingFactor = e.target.value;
                  }}
                />
              </FormControl>

              {/*Starting Frequency*/}
              <FormControl required variant="outlined" fullWidth margin="dense">
                <InputLabel>Starting Frequency</InputLabel>
                <Select
                  id="starting-frequency"
                  value={state.startingFrequency}
                  label="Starting Frequency"
                  onChange={(e) => {
                    intermediate_state = {
                      ...state,
                      startingFrequency: Number(e.target.value),
                    };
                    setState(intermediate_state);
                  }}
                >
                  {state.startingFrequencyOptions.map((x) => menuItems(x))}
                </Select>
              </FormControl>

              {/*Mixdown Frequency*/}
              <FormControl required variant="outlined" fullWidth margin="dense">
                <InputLabel>Mixdown Frequency</InputLabel>
                <Select
                  id="mixdown-frequency"
                  value={state.mixdownFrequency}
                  label="Mixdown Frequency"
                  onChange={(e) => {
                    intermediate_state = {
                      ...state,
                      mixdownFrequency: Number(e.target.value),
                    };
                    setState(intermediate_state);
                  }}
                >
                  {state.mixdownFrequencyOptions.map((x) => menuItems(x))}
                </Select>
              </FormControl>

              {/*Ending Frequency*/}
              <FormControl required variant="outlined" fullWidth margin="dense">
                <InputLabel>Ending Frequency</InputLabel>
                <Select
                  id="ending-frequency"
                  value={state.endingFrequency}
                  label="Ending Frequency"
                  onChange={(e) => {
                    intermediate_state = {
                      ...state,
                      endingFrequency: Number(e.target.value),
                    };
                    setState(intermediate_state);
                  }}
                >
                  {state.endingFrequencyOptions.map((x) => menuItems(x))}
                </Select>
              </FormControl>
            </Card>
            <div
              style={{
                position: "fixed",
                top: "auto",
                right: 30,
                bottom: 30,
                left: "auto",
              }}
            >
              <Fab
                size="large"
                color="primary"
                variant="extended"
                disabled={false}
                onClick={() => {
                  setState(initialChirp);
                }}
              >
                <Refresh />
              </Fab>
              <CopyToClipboard
                text={chirpToString(state)}
                onCopy={() => setCopied(true)}
              >
                <Fab
                  style={{ marginLeft: 4 }}
                  size="large"
                  color="primary"
                  variant="extended"
                  disabled={false}
                  onClick={() => {}}
                >
                  Generate
                </Fab>
              </CopyToClipboard>
              <Snackbar
                open={copied}
                autoHideDuration={2500}
                onClose={() => setCopied(false)}
              >
                <Alert severity="success">
                  Chirp copied to clipboard as JSON object
                </Alert>
              </Snackbar>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

// Use basic resource UI for consistency
const path = "ChirpDesigner";
export const useChirpDesignerNav = () => useResourceNav(path);
export const ChirpDesignerResource = () => (
  <Resource path={path} create={() => <ChirpDesignerForm />} />
);
