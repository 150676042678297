import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Delete, MoreVertOutlined } from "@material-ui/icons";
import React, { Fragment } from "react";
import { Gravatar } from "src/components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      "&.Mui-disabled": {
        color: theme.palette.text.disabled,
      },
    },
  }),
);

type UserListItemProps = {
  id: string;
  name: string;
  email: string;
  recordings?: number | null;
  onRemoveUser: (id: string) => void;
  disableAction?: boolean;
  deactivated?: boolean;
};

export function UserListItem({
  name,
  email,
  recordings,
  ...props
}: UserListItemProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveUser = () => {
    props.onRemoveUser(props.id);
    handleClose();
  };

  return (
    <Fragment>
      <ListItem
        classes={classes}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        disabled={!!props.deactivated}
      >
        <ListItemAvatar>
          <Gravatar email={email} />
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={recordings ? `recordings: ${recordings}` : null}
        />
        {props.disableAction !== true ? (
          <ListItemSecondaryAction style={{ right: 0 }}>
            <IconButton onClick={handleClick}>
              <MoreVertOutlined />
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuList style={{ outline: "none" }}>
          <MenuItem onClick={handleRemoveUser}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <Typography>Remove</Typography>
          </MenuItem>
        </MenuList>
      </Menu>
    </Fragment>
  );
}
