import React, { useState } from "react";
import {
  Grid,
  ButtonGroup,
  Button,
  ButtonProps,
  Toolbar,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@material-ui/core";
import { ArrowForward, Delete, Refresh } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

// Component for button with tooltip.
type TooltipButtonProps = {
  tooltip: string | JSX.Element;
  icon?: JSX.Element;
} & ButtonProps;

const TooltipButton = ({ icon, tooltip, ...props }: TooltipButtonProps) => (
  <Tooltip title={tooltip}>
    <span>
      <Button {...props}>{icon}</Button>
    </span>
  </Tooltip>
);

/**
 * Component for comparing the items of some destination group to the items of another group.
 */
type CompareTableProps<Row extends object> = {
  rows: Row[];
  sources: any;
  sourcePlaceholder?: string;
  renderSourceOption?: (option: any) => React.ReactNode;
  getSourceLabel?: (option: any) => string;
  getSourceSelected: (option: any, value: any) => boolean;
  onSourceChange: (option: any | undefined) => void;
  onCopyRow?: (row: Row, index: number) => void;
  onRevertRow?: (row: Row, index: number) => void;
  onDeleteRow?: (row: Row, index: number) => void;
  onCopyAll?: () => void;
  onRevertAll?: () => void;
  onDeleteAll?: () => void;
  renderDestination: (row: Row, index: number) => JSX.Element;
  renderSource: (row: Row, index: number) => JSX.Element;
  renderCommon: (row: Row, index: number) => JSX.Element;
  rowEdited?: (row: Row) => boolean;
  disableRowDelete?: (row: Row) => boolean;
  disableRowCopy?: (row: Row) => boolean;
  commonColumnHeader?: React.ReactNode;
  sourceColumnHeader?: React.ReactNode;
  destinationColumnHeader?: React.ReactNode;
};

export function CompareTable<Row extends object>(
  props: CompareTableProps<Row>,
) {
  const [source, setSource] = useState<any | undefined>(undefined);

  // By default, stringify option.
  const getSourceLabel = props.getSourceLabel ?? ((source) => `${source}`);

  const showCopy = props.onCopyRow || props.onCopyAll;
  const showTools =
    props.onDeleteAll ||
    props.onDeleteRow ||
    props.onRevertAll ||
    props.onRevertRow;

  const editedRows = props.rows.filter(
    (row) => props.rowEdited && props.rowEdited(row),
  );

  return (
    <>
      <Toolbar
        style={{ padding: 12, alignItems: "start" }}
        disableGutters={true}
      >
        <Grid item xs={12}>
          <Autocomplete
            options={props.sources}
            renderOption={props.renderSourceOption ?? getSourceLabel}
            getOptionLabel={getSourceLabel}
            getOptionSelected={(option, value) =>
              props.getSourceSelected(option, value)
            }
            disabled={props.rowEdited && editedRows.length > 0}
            onChange={(_event, nv) => {
              setSource(nv ?? undefined);
              props.onSourceChange(nv ?? undefined);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.sourcePlaceholder ?? "Source"}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Toolbar>
      <TableContainer component="div">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="common">
                {props.commonColumnHeader ?? ""}
              </TableCell>
              <TableCell key="source">
                {props.sourceColumnHeader
                  ? props.sourceColumnHeader
                  : source && getSourceLabel(source)}
              </TableCell>
              {showCopy && (
                <TableCell key="massCopy">
                  {props.onCopyAll && source !== undefined && (
                    <TooltipButton
                      size="small"
                      color="primary"
                      variant="text"
                      disabled={!source}
                      onClick={() => props.onCopyAll && props.onCopyAll()}
                      icon={<ArrowForward />}
                      tooltip="copy all"
                    />
                  )}
                </TableCell>
              )}
              <TableCell key="destination">
                {props.destinationColumnHeader ?? ""}
              </TableCell>
              {showTools && (
                <TableCell key="tools">
                  <ButtonGroup>
                    {props.onDeleteAll && (
                      <TooltipButton
                        size="small"
                        color="primary"
                        variant="text"
                        disabled={!props.rows.length}
                        onClick={() => props.onDeleteAll && props.onDeleteAll()}
                        icon={<Delete />}
                        tooltip="stage all for deletion"
                      />
                    )}
                    {props.onRevertAll && (
                      <TooltipButton
                        size="small"
                        color="primary"
                        variant="text"
                        disabled={props.rowEdited && editedRows.length === 0}
                        onClick={() => props.onRevertAll && props.onRevertAll()}
                        icon={<Refresh />}
                        tooltip="revert all changes"
                      />
                    )}
                  </ButtonGroup>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row, index) => (
              <TableRow
                key={index}
                selected={props.rowEdited && props.rowEdited(row)}
              >
                <TableCell>{props.renderCommon(row, index)}</TableCell>
                <TableCell>{props.renderSource(row, index)}</TableCell>
                {showCopy && (
                  <TableCell>
                    {props.onCopyRow && source !== undefined && (
                      <TooltipButton
                        size="small"
                        color="primary"
                        variant="text"
                        disabled={
                          props.disableRowCopy && props.disableRowCopy(row)
                        }
                        onClick={() =>
                          props.onCopyRow && props.onCopyRow(row, index)
                        }
                        icon={<ArrowForward />}
                        tooltip="copy"
                      />
                    )}
                  </TableCell>
                )}
                <TableCell>{props.renderDestination(row, index)}</TableCell>
                {showTools && (
                  <TableCell>
                    <ButtonGroup>
                      {props.onDeleteRow && (
                        <TooltipButton
                          size="small"
                          color="primary"
                          variant="text"
                          disabled={
                            props.disableRowDelete &&
                            props.disableRowDelete(row)
                          }
                          onClick={() =>
                            props.onDeleteRow && props.onDeleteRow(row, index)
                          }
                          icon={<Delete />}
                          tooltip="stage for deletion"
                        />
                      )}
                      {props.onRevertRow && (
                        <TooltipButton
                          size="small"
                          color="primary"
                          variant="text"
                          disabled={props.rowEdited && !props.rowEdited(row)}
                          onClick={() =>
                            props.onRevertRow && props.onRevertRow(row, index)
                          }
                          icon={<Refresh />}
                          tooltip="revert"
                        />
                      )}
                    </ButtonGroup>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
