import React, { useState } from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import { AddCircleOutline, ListAltOutlined } from "@material-ui/icons";
import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
import {
  Checkbox,
  FormContainer,
  FormHeader,
  TextField,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  ChipColumn,
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  TableViewHeader,
  Tab,
  Tabs,
  TabContent,
  TabLabel,
  TabView,
} from "src/Layout";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import {
  Models as Model,
  Models_Bool_Exp,
  useAllModelsQuery,
  useModelQuery,
  useCreateModelMutation,
  useUpdateModelMutation,
} from "src/generated/asgard/graphql";
import { AllProjectsTable, useProjectNav } from "src/resources/Project";

// Config table columns from Model fields
export const ModelTable = createTable<Model>()({
  keys: (model) => model.id ?? "",
  title: "Models",
  headers: {
    id: { display: "ID" },
    codename: { display: "Codename" },
    description: { display: "Description" },
    suspend_resume_at_transitions: { display: "Suspend Resume" },
    projects: { display: "Projects" },
  },
  columns: (model) => ({
    id: <EllipsisColumn value={model.id} />,
    codename: <TextColumn value={model.codename} />,
    description: <TextColumn value={model.description} />,
    suspend_resume_at_transitions: (
      <TextColumn value={`${model.suspend_resume_at_transitions}`} />
    ),
    projects: (
      <ChipColumn
        chips={model.projects?.map((project) => ({
          id: project.id,
          label: project.project_name?.display_name ?? "unknown",
          show: (
            <div style={{ padding: 25 }}>
              <div>
                <p>ID: {project.id}</p>
              </div>
            </div>
          ),
        }))}
      />
    ),
  }),
});

// Define a new table component for Models
type AllModelsTableProps = TableOptionals<typeof ModelTable> & {
  where?: Models_Bool_Exp[];
};

export const AllModelsTable = (props: AllModelsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Models_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  }
  // Add search terms for individual fields.
  const term = like(search);
  searchFilters.push({ codename: { _ilike: term } });
  const { data } = useAllModelsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, ...searchFilters] },
      // Add other query variables.
    },
  });

  return (
    <ModelTable
      {...props}
      {...pageController}
      total={data?.models_aggregate?.aggregate?.count}
      data={data?.models}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main Model resource page
type ModelIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof ModelTable>;

export const ModelIndex = (props: ModelIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Models</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Model
        </Button>
      </TableViewHeader>
      <Card>
        <AllModelsTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Models
const modelSchema = Yup.object({
  // Add fields to be validated.
  codename: Yup.string().required("required"),
  suspend_resume_at_transitions: Yup.boolean(),
  description: Yup.string().required("required"),
}).required();

export type ModelFormProps = {
  action: FormAction;
  model?: { id: string };
  onSuccess?: (id: string) => void;
};

export const ModelForm = (props: ModelFormProps) => {
  const [createModel] = useCreateModelMutation();
  const [updateModel] = useUpdateModelMutation();

  const allModels = useAllModelsQuery({
    variables: {
      where: { id: { _nin: props.model?.id ? [props.model.id] : [] } },
    },
  });

  const existingModel = useModelQuery({
    variables: { id: props.model?.id ?? "" },
    skip: !props.model,
  });

  return (
    <ResourceForm
      action={props.action}
      schema={modelSchema}
      resourceToUpdate={existingModel.data?.model}
      transform={(model) => ({
        codename: model.codename ?? "",
        description: model.description ?? "",
        suspend_resume_at_transitions:
          model.suspend_resume_at_transitions ?? false,
      })}
      initialValues={{
        codename: "",
        description: "",
        suspend_resume_at_transitions: false,
      }}
      customValidator={(values, errors) => {
        for (let existing of allModels.data?.models ?? []) {
          if (existing.codename === values.codename) {
            errors.codename = `Model ${values.codename} already exists`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const result = await (async () => {
          if (props.model?.id)
            return await updateModel({
              variables: {
                id: props.model.id,
                input: { ...values },
              },
            });
        })();
        props.onSuccess && props.onSuccess(result?.data?.model?.id ?? "");
      }}
      onInsert={async (values) => {
        const result = await (async () => {
          return await createModel({ variables: { input: { ...values } } });
        })();
        props.onSuccess && props.onSuccess(result.data?.model?.id ?? "");
      }}
      render={({ path }) => (
        <>
          <FormHeader>
            {props.model ? "Update Model" : "Create New Model"}
          </FormHeader>
          <FormContainer>
            <TextField
              bp={{ xs: 12, sm: 8 }}
              name={path.codename._}
              label="Name"
            />
            <Checkbox
              bp={{ xs: 12, sm: 4 }}
              name={path.suspend_resume_at_transitions?._ ?? ""}
              label="Suspend Resume at Transitions"
            />
            <TextField
              multiline
              bp={{ xs: 12, sm: 12 }}
              name={path.description._}
              label="Description"
            />
          </FormContainer>
        </>
      )}
    />
  );
};

// Create a detailed 'show' page.
type ModelShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Model>) => void;
};

const ModelShow = (props: ModelShowProps) => {
  const modelNav = useModelNav();
  const projectNav = useProjectNav();

  const modelQuery = useModelQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const model = modelQuery.data?.model;
  if (!model) return null;

  return (
    <ShowResourceView
      title={model.codename}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="models" onClick={() => modelNav.list()} />
          <Breadcrumb label={model.codename ?? ""} />
        </Breadcrumbs>
      }
      properties={[
        { label: "Description", value: model.description ?? "" },
        {
          label: "Suspend Resume at Transitions",
          value: `${model.suspend_resume_at_transitions}`,
        },
      ]}
      onEditAction={() => props.onEditAction && props.onEditAction(model)}
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabProps) => (
          <Tabs {...tabProps}>
            <Tab
              label={<TabLabel label="Projects" icon={<ListAltOutlined />} />}
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent index={0} current={current}>
              <AllProjectsTable
                selectable="none"
                where={[{ model_id: { _eq: props.id } }]}
                showUrl={(item) => item.id && projectNav.showUrl(item.id)}
                hideColumns={{ model: true }}
              />
            </TabContent>
          </>
        )}
      />
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "models";
export const useModelNav = () => useResourceNav(path);
export const ModelResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <ModelIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <ModelShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <ModelForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <ModelForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        model={{ id: id ?? "" }}
      />
    )}
  />
);
