import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { More } from "@material-ui/icons";

export const TextColumn = (props: {
  value?: string | number | null;
  charLimit?: number;
}) => {
  if (props.value === undefined) {
    return (
      <Typography style={{ color: "grey" }} variant="caption">
        unknown
      </Typography>
    );
  } else if (props.value === null) {
    return <>{props.value}</>;
  }
  const chars = String(props.value).split("");
  if (props.charLimit && chars.length > props.charLimit) {
    const display = chars.slice(0, props.charLimit).join("");
    return (
      <>
        <Typography variant="body2">{display}...</Typography>
        <Tooltip title={props.value}>
          <More fontSize="small" color="disabled" />
        </Tooltip>
      </>
    );
  }
  return (
    <>
      <Typography variant="body2">{props.value}</Typography>
    </>
  );
};
