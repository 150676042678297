import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { FormikProvider, useFormik, FormikErrors } from "formik";
import {
  ActionInfo,
  FormContainer,
  FormHeader,
  FormAction,
  TextField,
  DialogSelect,
  pathProxy,
} from "src/components/Form";
import {
  AddCircleOutline,
  ListAltOutlined,
  TranslateOutlined,
} from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { Resource, useResourceNav } from "src/components/Resource";
import * as Yup from "yup";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like } from "src/resources/Utils";
import {
  Breadcrumbs,
  Breadcrumb,
  ShowResourceView,
  Tab,
  Tabs,
  TabContent,
  TabLabel,
  TabView,
  TableView,
  TableViewHeader,
} from "src/Layout";
import {
  Options as Option,
  useOptionQuery,
  useAllOptionsQuery,
  Options_Bool_Exp,
  useFieldQuery,
  useAllTypesQuery,
  AllTypesQuery,
  useCreateOptionMutation,
  useUpdateOptionMutation,
  useInsertFieldOptionsMutation,
  Order_By,
  useOptionTranslationsSubscription,
  useUpsertOptionTranslationMutation,
} from "src/generated/asgard/graphql";
import { SearchBar } from "src/components/filters";
import { MultiSelectFilter } from "src/components/filters/SearchBar";
import { AllTypesTable } from "./type";
import { AllTasksTable, useTaskNav } from "./task";
import { TranslationTable } from "src/resources/Translation";

// Create a detailed description of the Field resource
export const optionDescription = (
  <Typography variant="body2">
    <b>Options</b> are the values that are are assigned to <b>fields</b>.
    <br />
    <br />
    <b>Fields</b> are like variables. They have a <b>type</b>, and can only be{" "}
    assigned an <b>option</b> with the same <b>type</b>. When an <b>option</b>{" "}
    is assigned to a <b>field</b>, the resulting <b>field-option</b> pair can be
    used to describe a single detail of a <b>task</b>.
  </Typography>
);

export const OptionTable = createTable<Option>()({
  keys: (option) => option.id ?? "",
  title: "Option",
  headers: {
    id: { display: "ID" },
    display: { display: "Display" },
    value: { display: "Value" },
    type: { display: "Type" },
  },
  columns: (option) => ({
    id: <EllipsisColumn value={option.id} />,
    display: <TextColumn value={option.display} />,
    value: <TextColumn value={option.value} />,
    type: <TextColumn value={option.type?.name} />,
  }),
});

type AllOptionsTableProps = { where?: Options_Bool_Exp[] } & TableOptionals<
  typeof OptionTable
>;

export const AllOptionsTable = (props: AllOptionsTableProps) => {
  const [pageVars, pageController] = usePagination();

  const types = useAllTypesQuery({});

  const filters: Options_Bool_Exp[] = [];

  const [typeFilter, setTypeFilter] = useState<
    AllTypesQuery["types"] | undefined
  >(undefined);
  if (typeFilter?.length) {
    filters.push({ type_id: { _in: typeFilter.map((t) => t.id) } });
  }

  const [optionSearch, setOptionSearch] = useState("");
  if (optionSearch.length) {
    filters.push({
      _or: [
        { display: { _ilike: like(optionSearch) } },
        { value: { _ilike: like(optionSearch) } },
      ],
    });
  }

  const { data } = useAllOptionsQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _and: filters }] },
      order_by: [{ value: Order_By.Asc }],
    },
  });

  return (
    <OptionTable
      {...props}
      {...pageController}
      tools={
        <>
          <Grid item sm={8} xs={12}>
            <SearchBar onChange={(search) => setOptionSearch(search)} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <MultiSelectFilter
              placeholder="Option type"
              onChange={(types) => setTypeFilter(types)}
              options={types.data?.types}
              getOptionLabel={(t) => t.name}
            />
          </Grid>
        </>
      }
      total={data?.options_aggregate?.aggregate?.count}
      data={data?.options}
    />
  );
};

type OptionIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof OptionTable>;

export const OptionIndex = (props: OptionIndexProps) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">Options</Typography>}
        info={optionDescription}
      >
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Option
        </Button>
      </TableViewHeader>
      <Card>
        <AllOptionsTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

const optionSchema = Yup.object({
  value: Yup.string().required(),
  display: Yup.string().required(),
  type: Yup.object({
    id: Yup.string().required(),
    name: Yup.string(),
  }).required(),
  field: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  })
    .notRequired()
    .default(undefined),
}).required();

type OptionFormSchema = Yup.InferType<typeof optionSchema>;

type OptionFormAction = "insert" | "update";

type OptionFormInfo = {
  title: string;
  submitButtonTitle: string;
  notification: {
    success: (id: string) => {
      title: string;
      description: string;
    };
    error: (id: string) => {
      title: string;
      description: string;
    };
  };
};

export type OptionFormProps = {
  option?: { id: string };
  action: OptionFormAction;
  onSuccess?: (id: string) => void;
};

export const OptionForm = (props: OptionFormProps) => {
  const formInfo: ActionInfo<OptionFormAction, OptionFormInfo> = {
    insert: {
      title: "Create new Option",
      submitButtonTitle: "Create Option",
      notification: {
        success: (id) => ({
          title: "",
          description: "",
        }),
        error: (id) => ({
          title: "",
          description: "",
        }),
      },
    },
    update: {
      title: "Update Option",
      submitButtonTitle: "Update Option",
      notification: {
        success: (id) => ({
          title: "",
          description: "",
        }),
        error: (id) => ({
          title: "",
          description: "",
        }),
      },
    },
  };

  const info = formInfo[props.action];

  const [createOption] = useCreateOptionMutation();
  const [updateOption] = useUpdateOptionMutation();
  const [insertFieldOptions] = useInsertFieldOptionsMutation();

  const existingOptionsFilters: Options_Bool_Exp[] = [];
  if (props.action === "update" && props.option) {
    existingOptionsFilters.push({ id: { _neq: props.option.id } });
  }
  const existingOptions = useAllOptionsQuery({
    variables: { where: { _and: existingOptionsFilters } },
  });
  const validateOption = (values: OptionFormSchema) => {
    const errors: FormikErrors<OptionFormSchema> = {};
    existingOptions.data?.options.forEach((option) => {
      if (option.value === values.value && option.display === values.display) {
        errors.value = `Option '${values.value}' already exists with display '${values.display}'`;
      }
    });
    return errors;
  };

  const formik = useFormik<OptionFormSchema>({
    validationSchema: optionSchema,
    validate: validateOption,
    initialValues: {
      value: "",
      display: "",
      type: {
        id: "",
        name: "",
      },
    },
    onSubmit: async (values) => {
      try {
        const { type, field, ...vals } = values;
        // Create or update option.
        const result = await (async () => {
          if (props.action === "update" && props.option?.id) {
            return await updateOption({
              variables: {
                id: props.option.id,
                input: {
                  ...vals,
                },
              },
            });
          } else {
            return await createOption({
              variables: {
                input: {
                  ...vals,
                  type_id: type.id,
                },
              },
            });
          }
        })();
        // If relevant, add option to field.
        await (async () => {
          if (
            props.action === "insert" &&
            field?.id &&
            type?.id &&
            result.data?.option?.id
          ) {
            return await insertFieldOptions({
              variables: {
                input: [
                  {
                    field_id: field.id,
                    type_id: type.id,
                    option_id: result.data?.option?.id,
                  },
                ],
              },
            });
          }
        })();
        props.onSuccess && props.onSuccess(result.data?.option?.id ?? "");
      } catch (e) {
        formik.setSubmitting(false);
      }
    },
  });

  const { setFieldValue, setValues, setSubmitting } = formik;

  // If updating an existing context, prevent submission until loaded.
  const optionQuery = useOptionQuery({
    variables: { id: props.option?.id ?? "" },
    skip: !props.option,
  });
  const option = optionQuery.data?.option;
  useEffect(() => {
    if (props.action === "update") {
      if (!option) setSubmitting(true);
      else {
        setValues({
          display: option.display ?? "",
          value: option.value ?? "",
          type: {
            id: option.type?.id ?? "",
            name: option.type?.name ?? "",
          },
        });
        setSubmitting(false);
      }
    }
  }, [props.action, option, setSubmitting, setValues]);

  // If adding to an existing field, prevent submission until loaded.
  const loc = useLocation();
  const query = qs.parse(loc.search);
  const fieldQuery = useFieldQuery({
    variables: { id: (query.field as string) ?? "" },
    skip: !query.field,
  });
  const field = fieldQuery.data?.field;
  useEffect(() => {
    if (props.action === "insert" && !!query.field) {
      if (!field) setSubmitting(true);
      else {
        setFieldValue("type", {
          id: field.type?.id ?? "",
          name: field.type?.name ?? "",
        });
        setFieldValue("field", {
          id: field.id ?? "",
          name: field.name ?? "",
        });
        setSubmitting(false);
      }
    }
  }, [props.action, field, setSubmitting, setFieldValue, query.field]);

  const path = pathProxy<typeof formik.values>();

  return (
    <FormikProvider value={formik}>
      <FormHeader>
        {info.title}
        {!!field && formik.values.field?.name && (
          <Typography variant="subtitle1">
            for "{formik.values.field.name}" field
          </Typography>
        )}
      </FormHeader>
      <FormContainer>
        <TextField bp={{ xs: 12, sm: 6 }} name={path.value._} label="Value" />
        <TextField
          bp={{ xs: 12, sm: 6 }}
          name={path.display._}
          label="Display"
        />

        {/**
         * Only show type selector for brand new options.
         * Changing the type of an existing option will result in errors
         * if it already has already been attached to a field.
         */}
        {props.action === "insert" && !field && (
          <DialogSelect
            bp={{ xs: 12, sm: 6 }}
            name={path.type.name!._}
            label="Type"
            onReset={() =>
              formik.setValues({
                ...formik.values,
                type: formik.initialValues.type,
              })
            }
            content={(close) => (
              <AllTypesTable
                selectable="single"
                onSelect={(type) => {
                  formik.setValues({
                    ...formik.values,
                    type: {
                      id: type.id ?? "",
                      name: type.name ?? "",
                    },
                  });
                  close();
                }}
              />
            )}
          />
        )}

        <FormAction>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            {info.submitButtonTitle}
          </Button>
        </FormAction>
      </FormContainer>
    </FormikProvider>
  );
};

// Translations of option displays and descriptions.
type OptionTranslationTableProps = {
  option: { id: string };
};

const OptionTranslationTable = (props: OptionTranslationTableProps) => {
  const fieldTranslations = useOptionTranslationsSubscription({
    variables: { id: props.option.id },
  });
  const [upsertTranslation] = useUpsertOptionTranslationMutation({});
  return (
    <TranslationTable
      data={fieldTranslations.data?.translations ?? []}
      schema={Yup.object({ display: Yup.string().required() }).required()}
      getValues={(data) => ({ display: data.display ?? "" })}
      initialValues={{ display: "" }}
      getLanguage={(data) => data.language}
      labels={{ display: "Display" }}
      columns={(data) => ({ display: data.display ?? "" })}
      onUpdate={(data, values) =>
        upsertTranslation({
          variables: {
            object: {
              option_id: props.option.id,
              language: data.language.id,
              display: values.display,
            },
          },
        })
      }
      onCreate={(language, values) =>
        upsertTranslation({
          variables: {
            object: {
              option_id: props.option.id,
              language: language,
              ...values,
            },
          },
        })
      }
    />
  );
};

// Detailed content for a single option "show" page.
type OptionShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Option>) => void;
};

const OptionShow = (props: OptionShowProps) => {
  const optionNav = useOptionNav();
  const taskNav = useTaskNav();

  const optionQuery = useOptionQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const option = optionQuery.data?.option;

  if (!option) return null;
  return (
    <ShowResourceView
      title={option.display}
      properties={[
        { label: "Type", value: option.type.name ?? "" },
        { label: "Display", value: option.display ?? "" },
      ]}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="options" onClick={() => optionNav.list()} />
          <Breadcrumb label={option.display} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(option)}
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabProps) => (
          <Tabs {...tabProps}>
            <Tab
              label={<TabLabel label="Tasks" icon={<ListAltOutlined />} />}
            />
            <Tab
              label={
                <TabLabel label="Translations" icon={<TranslateOutlined />} />
              }
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent index={0} current={current}>
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <TableView>
                    <AllTasksTable
                      where={[
                        {
                          task_field_options: {
                            field_option: { option_id: { _eq: props.id } },
                          },
                        },
                      ]}
                      hideColumns={{ num_recordings: true }}
                      showUrl={(item) => item.id && taskNav.showUrl(item.id)}
                      selectable="none"
                    />
                  </TableView>
                </CardContent>
              </Card>
            </TabContent>
            <TabContent index={1} current={current}>
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <OptionTranslationTable option={{ id: props.id }} />
                </CardContent>
              </Card>
            </TabContent>
          </>
        )}
      />
    </ShowResourceView>
  );
};

export const useOptionNav = () => useResourceNav("options");
export const OptionResource = () => (
  <Resource
    path="options"
    list={(nav) => (
      <OptionIndex
        onAddNew={() => nav.create()}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        editUrl={(item) => item.id && nav.editUrl(item.id)}
      />
    )}
    show={(nav, id) => (
      <OptionShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <OptionForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <OptionForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        option={{ id: id ?? "" }}
      />
    )}
  />
);
