import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { DrawerButton } from "src/components/Form";
import {
  AllBenchmarkPlansTable,
  useBenchmarkPlanNav,
} from "src/resources/Benchmarking";
import {
  Project_Rabbit_Test_Instances,
  Roles_Enum,
  useAddProjectBenchmarkPlanMutation,
  useProjectBenchmarkPlansQuery,
  useProjectBenchmarkInstancesQuery,
  useRemoveProjectBenchmarkPlanMutation,
  useBenchmarkInstanceRecordingsQuery,
} from "src/generated/asgard/graphql";
import { TextColumn, createTable } from "src/components/table";
import { FormTable } from "src/components/FormTable";
import { Link } from "react-router-dom";
import { useRequireRole } from "src/auth";

export const BenchmarkInstanceTable =
  createTable<Project_Rabbit_Test_Instances>()({
    keys: (ins) =>
      `${ins.plan_id}${ins.plan_id}${ins.user_id}${ins.device_id}${ins.opmode_id}${ins.engine_id}}`,
    title: "Benchmark Test Instances",
    headers: {
      plan: { display: "Plan" },
      user: { display: "User" },
      started_at: { display: "Started at" },
      device: { display: "Device" },
      engine: { display: "Engine" },
      opmode: { display: "Opmode" },
      progress: { display: "Progress" },
    },
    columns: (instance) => ({
      plan: <TextColumn value={instance.rabbit_test_plan?.name ?? ""} />,
      user: <TextColumn value={instance.user?.name ?? ""} />,
      started_at: <TextColumn value={instance.started_at} />,
      device: <TextColumn value={instance.device?.serialno ?? ""} />,
      engine: <TextColumn value={instance.engine?.build_number ?? ""} />,
      opmode: <TextColumn value={instance.opmode?.number ?? ""} />,
      progress: (
        <TextColumn
          value={`${instance.num_distinct_tasks} / ${instance.rabbit_test_plan?.plan_tasks_aggregate?.aggregate?.count}`}
        />
      ),
    }),
  });

type ProjectBenchmarkingTabProps = { projectId: string };

export const ProjectBenchmarkPlanCard = (
  props: ProjectBenchmarkingTabProps,
) => {
  const benchmarkPlanNav = useBenchmarkPlanNav();
  const query = useProjectBenchmarkPlansQuery({
    variables: { projectId: props.projectId },
    fetchPolicy: "network-only",
  });
  const existingBenchmarkPlanIds = query.data?.rabbit_test_plans?.map(
    (plan) => plan.id,
  );
  const [addBenchmarkTest] = useAddProjectBenchmarkPlanMutation({
    onCompleted: () => query?.refetch && query.refetch(),
  });
  const [removeBenchmarkTest] = useRemoveProjectBenchmarkPlanMutation({
    onCompleted: () => query?.refetch && query.refetch(),
  });
  return (
    <Card style={{ marginBottom: 20 }}>
      <CardHeader title="Benchmark Plans" />
      <CardContent>
        <Grid container spacing={3}>
          <DrawerButton
            bp={{ xs: 4 }}
            variant="contained"
            color="primary"
            label="Add Benchmark Plan"
            startIcon={<AddCircleOutline />}
            disableElevation
            fullWidth={true}
            content={(close) => (
              <AllBenchmarkPlansTable
                selectable="single"
                where={[{ id: { _nin: existingBenchmarkPlanIds } }]}
                onSelect={(plan) => {
                  if (plan.id)
                    addBenchmarkTest({
                      variables: {
                        projectId: props.projectId,
                        planId: plan.id,
                      },
                    });
                  close();
                }}
              />
            )}
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormTable
              rows={query.data?.rabbit_test_plans ?? []}
              columns={[
                {
                  header: "Benchmark Plan",
                  render: (item) => (
                    <Button
                      component={Link}
                      to={benchmarkPlanNav.showUrl(item.id)}
                      color="primary"
                    >
                      {item.name}
                    </Button>
                  ),
                },
                {
                  header: "Description",
                  render: (item) => item.description ?? "",
                },
              ]}
              onRemove={(item) =>
                removeBenchmarkTest({
                  variables: {
                    projectId: props.projectId,
                    planId: item.id,
                  },
                })
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const ProjectBenchmarkInstancesCard = (
  props: ProjectBenchmarkingTabProps,
) => {
  const query = useProjectBenchmarkInstancesQuery({
    variables: { projectId: props.projectId },
    fetchPolicy: "network-only",
  });
  return (
    <Card style={{ marginBottom: 20 }}>
      <CardHeader title="Benchmark Test Instances" />
      <CardContent>
        <BenchmarkInstanceTable
          selectable="none"
          data={query.data?.project_rabbit_test_instances}
          expandedContent={(instance) => (
            <InstanceRecordingsTable instance={instance} />
          )}
        />
      </CardContent>
    </Card>
  );
};

const InstanceRecordingsTable = (props: {
  instance: DeepPartial<Project_Rabbit_Test_Instances>;
}) => {
  const { instance } = props;
  const query = useBenchmarkInstanceRecordingsQuery({
    variables: {
      project_id: instance.project_id ?? "",
      device_id: instance.device_id ?? "",
      engine_id: instance.engine_id ?? "",
      opmode_id: instance.opmode_id ?? "",
      plan_id: instance.plan_id ?? "",
      user_id: instance.user_id ?? "",
    },
  });
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Task</TableCell>
          <TableCell>Recorded at</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {query.data?.recordings?.map((rec, i) => {
          return (
            <TableRow key={rec?.id ?? i}>
              <TableCell>{rec?.task?.legacy_template_id ?? ""}</TableCell>
              <TableCell>{rec?.recorded_at ?? ""}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export const ProjectBenchmarkingTab = (props: ProjectBenchmarkingTabProps) => {
  const ag = useRequireRole([Roles_Enum.Asgard]);
  return (
    <>
      {/* Only asgard has access to mutations in the "project plans" card */}
      {ag && <ProjectBenchmarkPlanCard {...props} />}
      <ProjectBenchmarkInstancesCard {...props} />
    </>
  );
};
