import React from "react";
import { Box, LinearProgress, Typography } from "@material-ui/core";

export type ProgressBarProps = {
  numerator: number;
  denominator: number;
  hideFraction?: boolean;
  hidePercentage?: boolean;
};

export const ProgressBar = (props: ProgressBarProps) => {
  const percentage =
    props.denominator > 0 ? (100 * props.numerator) / props.denominator : 0;
  // If percentage is less than one, round to nearest tenth.
  // Otherwise, round to nearest whole number.
  const rounded =
    percentage < 1 ? Math.round(10 * percentage) / 10 : Math.round(percentage);
  const percentageLabel = rounded > 100 ? `>100%` : `${rounded}%`;
  const fractionLabel = `${props.numerator}/${props.denominator}`;
  const label =
    props.hidePercentage && props.hideFraction
      ? null
      : props.hidePercentage
      ? fractionLabel
      : props.hideFraction
      ? percentageLabel
      : `${percentageLabel} (${fractionLabel})`;
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%">
        <LinearProgress variant="determinate" value={Math.min(100, rounded)} />
      </Box>
      <Box ml={1}>
        {label && <Typography variant="caption">{label}</Typography>}
      </Box>
    </Box>
  );
};
