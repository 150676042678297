import { Button, Grid } from "@material-ui/core";

export interface FileHeaderProps {
  file: File;
  onClose: (file: File) => void;
  error: boolean;
}

export function FileHeader({ file, onClose, error }: FileHeaderProps) {
  if (error) {
    return (
      <Grid container justify="space-between" alignItems="center">
        <Grid item>{file.name}</Grid>
        <Grid item>
          <Button size="small" onClick={() => onClose(file)}>
            Close
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container justify="space-between" alignItems="center">
        <Grid item>{file.name}</Grid>
      </Grid>
    );
  }
}
