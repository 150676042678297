import { useEffect, useMemo, useRef, useState } from "react";
import { Order } from "src/components/table";
import { Order_By } from "src/generated/asgard/graphql";

export const usePrevious = <T extends {}>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

type UsePaginationType = [{ limit: number; offset: number }, PageController];

export type PageController = {
  offset: number;
  limit: number;
  setOffset: (p: number) => void;
  setLimit: (r: number) => void;
};

export const usePagination = (opts?: {
  limit?: number;
  offset?: number;
}): UsePaginationType => {
  const [offset, setOffset] = useState(opts?.offset ?? 0);
  const [limit, setLimit] = useState(opts?.limit ?? 10);

  const p: UsePaginationType = useMemo(
    () => [
      {
        limit,
        offset,
      },
      {
        offset,
        setOffset: (p: number) => setOffset(p),
        limit,
        setLimit: (r: number) => setLimit(r),
      },
    ],
    [offset, limit, setOffset, setLimit],
  );

  return p;
};

export function uuidIsValid(uuid?: string): string | null {
  if (uuid === undefined) return null;

  if (
    /[0-9a-f]{22}|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.test(
      uuid,
    )
  ) {
    return uuid;
  }

  return null;
}

export function like(term?: string | null): string | undefined {
  if (term === "" || term === undefined || term === null) {
    return undefined;
  }

  return `%${term}%`;
}

export function orderBy(o: Order): Order_By | null {
  if (o === undefined) return null;
  return o === "asc" ? Order_By.Asc : Order_By.Desc;
}

class MarkdownBuilder {
  markdown: string = "";

  code(code: string) {
    this.markdown += `\`${code}\``;
    return this;
  }

  syntax(syntax: string, code: string) {
    this.markdown += `\n\`\`\`${syntax}\n${code}\n\`\`\`\n`;
    return this;
  }

  header(h: "h1" | "h2" | "h3" | "h4" | "h5" | "h6", t: string) {
    const num = parseInt(h.charAt(1));
    const prefix = "#".repeat(num);
    this.markdown += `${prefix} ${t}\n`;
    return this;
  }

  get() {
    return this.markdown;
  }
}

export function md() {
  return new MarkdownBuilder();
}

export function uuidArrToPostgresLiteral(uuids: string[]): string {
  const commaSep = uuids.join(", ");
  return `{${commaSep}}`;
}

export function strArrToPostgresLiteral(
  strs: string[],
  spaceSeparator: boolean = false,
  useDoubleQuotes: boolean = false,
): string {
  let commaSep: string;
  const quoteChar = useDoubleQuotes ? `"` : `'`;
  if (spaceSeparator === null || spaceSeparator === false) {
    commaSep = strs.map((s) => `${quoteChar}${s}${quoteChar}`).join(", ");
  } else {
    commaSep = strs.map((s) => `${quoteChar}${s}${quoteChar}`).join(",");
  }
  return `{${commaSep}}`;
}

export function nullIfEmpty(str: string): string | null {
  return str.length === 0 ? null : str;
}
