import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { InfoDisplay } from "src/Layout";
import {
  createTable,
  ChipColumn,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import {
  Order_By,
  Project_Dataset,
  Recordings_Bool_Exp,
  useAllRecordingTagsQuery,
  useProjectDatasetQuery,
} from "src/generated/asgard/graphql";
import { usePagination } from "src/resources/Utils";
import { MultiSelectFilter } from "src/components/filters/SearchBar";
import { TaskLink } from "../CollectionPlan";

// Generic project table
export const RecordingTable = createTable<Project_Dataset>()({
  keys: (ds) => ds.recording?.id ?? "",
  title: "Projects",
  headers: {
    id: { display: "ID" },
    project: { display: "Project" },
    device: { display: "Device" },
    task: { display: "Task" },
    description: { display: "Description/Note" },
    opmode: { display: "Opmode" },
    tags: { display: "Tags" },
  },
  columns: ({ recording }) => ({
    id: <EllipsisColumn value={recording?.id ?? ""} />,
    project: <TextColumn value={recording?.project?.model?.codename} />,
    device: <TextColumn value={recording?.device?.serialno} />,
    task:
      recording?.task?.id && recording?.task?.legacy_template_id ? (
        <TaskLink
          id={recording.task.id!}
          number={recording.task.legacy_template_id!}
        />
      ) : (
        <></>
      ),
    description: <TextColumn value={recording?.task?.note} />,
    opmode: (
      <TextColumn
        value={recording?.engine_opmode_recordings
          ?.map((o) => o.opmode?.number)
          ?.join(",")}
      />
    ),
    tags: (
      <ChipColumn
        chips={recording?.tags?.map((tag) => ({
          id: tag?.timestamp ?? "",
          label: tag?.recording_tag?.name ?? "",
          show: (
            <div style={{ margin: 20 }}>
              <InfoDisplay
                items={[
                  { label: "Tag", value: tag?.recording_tag?.name ?? "" },
                  {
                    label: "Description",
                    value: tag?.recording_tag?.description ?? "",
                  },
                  { label: "by", value: tag?.user?.name ?? "" },
                  { label: "at", value: tag?.timestamp ?? "" },
                ]}
              />
            </div>
          ),
        }))}
      />
    ),
  }),
});

type RecordingListProps = {
  projectId: string;
  selections?: Recordings_Bool_Exp[];
} & TableOptionals<typeof RecordingTable>;

export const AllRecordingsTable = ({
  selections,
  ...props
}: RecordingListProps) => {
  const [pageVars, pageController] = usePagination();
  const [searchTags, setSearchTags] = useState<string[]>([]);

  const tagsQuery = useAllRecordingTagsQuery({
    variables: {
      // If grouping the tags by dirty/crean in <Autocomplete />,
      // the "options" need to be sorted.
      order_by: [{ dirty: Order_By.Desc }],
    },
  });

  const query = useProjectDatasetQuery({
    fetchPolicy: "network-only",
    variables: {
      ...pageVars,
      project_id: props.projectId,
      order_by: [
        {
          recording: { created_at: Order_By.Desc },
        },
        {
          recording: { id: Order_By.Desc },
        },
      ],
      where: {
        recording: {
          _and: [
            { _and: selections },
            {
              _and: searchTags.map((id) => ({
                tags: { recording_tag_id: { _eq: id } },
              })),
            },
          ],
        },
      },
    },
  });

  const { data } = query;

  return (
    <RecordingTable
      {...props}
      {...pageController}
      total={data?.project?.project_dataset_aggregate.aggregate?.count}
      data={data?.project?.project_dataset}
      tools={
        <Grid item xs={12}>
          <MultiSelectFilter
            label="Tags"
            onChange={(tags) => setSearchTags(tags.map((tag) => tag.id))}
            groupBy={(tag) => (tag.dirty ? "dirty" : "clean")}
            options={tagsQuery?.data?.recording_tags}
            getOptionLabel={(tag) => tag.name}
          />
        </Grid>
      }
    />
  );
};
