import React, { useState } from "react";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { SearchBar } from "src/components/filters";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Order_By,
  Performance_Report_Templates as Template,
  Performance_Report_Templates_Bool_Exp as Templates_Bool_Exp,
  useAllTemplatesQuery,
} from "src/generated/asgard/graphql";
import { useAuth } from "src/auth";

// Config table columns from Template fields
export const TemplateTable = createTable<Template>()({
  keys: (template) => template.id ?? "",
  title: "Template",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    description: { display: "Description" },
    created_by: { display: "Created by" },
  },
  columns: (template) => ({
    id: <EllipsisColumn value={template.id} />,
    name: <TextColumn value={template.name} />,
    description: <TextColumn value={template.description} />,
    created_by: <TextColumn value={template.user?.name ?? ""} />,
  }),
});

// Define a new table component for Templates
type AllTemplatesTableProps = TableOptionals<typeof TemplateTable> & {
  where?: Templates_Bool_Exp[];
};

export const AllTemplatesTable = (props: AllTemplatesTableProps) => {
  const auth = useAuth();
  const userId = auth.user.id;
  const [pageVars, pageController] = usePagination();
  const [isolateUserTemplate, setIsolateUserTemplates] = useState(false);
  const [search, setSearch] = useState("");
  const searchFilters: Templates_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    const term = like(search);
    searchFilters.push({ name: { _ilike: term } });
  }
  if (isolateUserTemplate && uuidIsValid(userId)) {
    searchFilters.push({ created_by: { _eq: userId } });
  }
  const { data } = useAllTemplatesQuery({
    variables: {
      ...pageVars,
      where: {
        _and: [{ _and: props.where }, { _and: searchFilters }],
      },
      order_by: { updated_at: Order_By.Desc },
    },
    fetchPolicy: "network-only",
  });
  return (
    <TemplateTable
      {...props}
      {...pageController}
      total={data?.templates_aggregate?.aggregate?.count}
      data={data?.templates}
      tools={
        <>
          <Grid item xs={12} sm={8}>
            <SearchBar onChange={setSearch} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(event) =>
                    setIsolateUserTemplates(event.target.checked)
                  }
                  checked={isolateUserTemplate}
                />
              }
              label="Isolate My Templates"
            />
          </Grid>
        </>
      }
    />
  );
};
