import React, { useState } from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { SearchBar } from "src/components/filters";
import {
  ChipColumn,
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import { TableView, TableViewHeader } from "src/Layout";
import {
  Recording_Data_Modes as Mode,
  Recording_Data_Modes_Bool_Exp as Modes_Bool_Exp,
  useAllModesQuery,
  Order_By,
} from "src/generated/asgard/graphql";

// Config table columns from Mode fields
export const ModeTable = createTable<Mode>()({
  keys: (mode) => mode.id ?? "",
  title: "Mode",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    description: { display: "Description" },
    parameters: { display: "Parameters" },
  },
  columns: (mode) => ({
    id: <EllipsisColumn value={mode.id} />,
    name: <TextColumn value={mode.name} />,
    description: <TextColumn value={mode.description ?? ""} />,
    parameters: (
      <ChipColumn
        chips={mode.mode_parameters?.map((mp) => ({
          label: `${mp.parameter?.name}: ${mp.value}`,
        }))}
      />
    ),
  }),
});

// Define a new table component for Mode
type AllModesTableProps = TableOptionals<typeof ModeTable> & {
  where?: Modes_Bool_Exp[];
};

export const AllModesTable = (props: AllModesTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Modes_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    const term = like(search);
    searchFilters.push({ name: { _ilike: term } });
  }
  const { data } = useAllModesQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      order_by: { name: Order_By.Asc },
    },
  });

  return (
    <ModeTable
      {...props}
      {...pageController}
      total={data?.modes_aggregate?.aggregate?.count}
      data={data?.modes}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main Mode resource page
type ModeIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof ModeTable>;

export const ModeIndex = (props: ModeIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Modes</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Mode
        </Button>
      </TableViewHeader>
      <Card>
        <AllModesTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};
