import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Close,
  ListAltOutlined,
  RemoveCircleOutline,
} from "@material-ui/icons";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  DrawerButton,
  FormAction,
  FormContainer,
  FormHeader,
  pathProxy,
  TextField,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import * as Yup from "yup";
import {
  createTable,
  DialogColumn,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { like, orderBy, usePagination, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ClosableDrawer,
  InfoDisplay,
  ShowResourceView,
  Tab,
  TabContent,
  TabLabel,
  Tabs,
  TabView,
  TableView,
  TableViewHeader,
  Tablature,
} from "src/Layout";
import {
  Order_By,
  PlanOverviewSubscription,
  Plans as Plan,
  Plans_Bool_Exp,
  Plan_Tasks as PlanTask,
  Plan_Tasks_Bool_Exp,
  Plan_Tasks_Order_By,
  Plan_Tasks_Insert_Input,
  useAllPlansQuery,
  useAllPlanTasksQuery,
  useAllPlanTasksLazyQuery,
  useCreatePlanMutation,
  usePlanOverviewSubscription,
  usePlanQuery,
  useUpdatePlanMutation,
  useUpdatePlanTaskMutation,
} from "src/generated/asgard/graphql";
import { AllFieldOptionsTable, AllTasksTable, useTaskNav } from "./task";
import { SearchBar } from "src/components/filters";
import {
  FormAction as ResourceFormAction,
  ResourceForm,
} from "src/components/ResourceForm";

const AssistTextColumn = (props: { value: string }) => {
  return (
    <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
      {props.value}
    </Typography>
  );
};

// Config columns for a table displaying Tasks attached to Plans
export const PlanTasksTable = createTable<PlanTask>()({
  keys: (pt) => pt?.id ?? "",
  title: "Plan Tasks",
  headers: {
    id: { display: "ID" },
    legacy_id: { display: "Number" },
    // truth: { display: "Truth" },
    instruction: { display: "Instruction" },
    highlights: { display: "Highlights" },
    note: { display: "Note" },
    priority: { display: "Priority" },
    device_count: { display: "Minimum" },
    field_options: { display: "Field/Options" },
  },
  columns: (pt) => ({
    id: <EllipsisColumn value={pt.task?.id} />,
    instruction: <AssistTextColumn value={pt.task?.task_instruction?.content ?? ""} />,
    note: pt.task?.note?.length ? (
      <EllipsisColumn value={pt.task.note} />
    ) : (
      <></>
    ),
    legacy_id: <TextColumn value={pt.task?.legacy_template_id} />,
    // truth: (
    //   <TextColumn
    //     value={
    //       pt.task?.force_ground_truth_to_negative ? "Force negative" : "Normal"
    //     }
    //   />
    // ),
    highlights: <TextColumn value={pt.task?.description ?? ""} />,
    priority: <TextColumn value={pt.priority} />,
    device_count: <TextColumn value={pt.min_per_device} />,
    field_options: (
      <DialogColumn
        label={`Show (${pt?.task?.task_field_options_aggregate?.aggregate?.count})`}
        content={
          <AllFieldOptionsTable
            selectable="none"
            where={[{ task_field_options: { task_id: { _eq: pt.task?.id } } }]}
          />
        }
      />
    ),
  }),
});

// Define a new table component for Tasks attached to Plans
type AllPlanTasksTableProps = {
  where?: Plan_Tasks_Bool_Exp[];
} & TableOptionals<typeof PlanTasksTable>;

const defaultOrder: Plan_Tasks_Order_By = {
  task: { legacy_template_id: Order_By.Asc },
};

export const AllPlanTasksTable = (props: AllPlanTasksTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<Plan_Tasks_Order_By>(defaultOrder);
  
  const term = like(search);
  const filters: Plan_Tasks_Bool_Exp[] = [{ task: { note: { _ilike: term } } }];

  if (uuidIsValid(search)) {
    filters.push({ id: { _eq: search } });
  }

  if (parseInt(search)) {
    filters.push({ task: { legacy_template_id: { _eq: parseInt(search) } } });
  }

  const { data } = useAllPlanTasksQuery({
    variables: {
      ...pageVars,
      order_by: order,
      where: { _and: [{ _or: filters }, { _and: props.where }] },
    },
    fetchPolicy: "network-only",
  });

  return (
    <PlanTasksTable
      {...props}
      {...pageController}
      total={data?.plan_tasks_aggregate?.aggregate?.count}
      data={data?.plan_tasks}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
      orderColumn={(order) => {
        if (!order) {
          setOrder(defaultOrder);
          return;
        }
        setOrder({
          id: orderBy(order.id),
          min_per_device: orderBy(order.device_count),
          task: {
            note: orderBy(order.note),
            legacy_template_id: orderBy(order.legacy_id),
            description: orderBy(order.highlights),
          },
        });
      }}
    />
  );
};

// Config table columns from Plan fields
export const PlanTable = createTable<Plan>()({
  keys: (plan) => plan.id ?? "",
  title: "Plan",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    description: { display: "Description" },
    tasks: { display: "Total Tasks" },
  },
  columns: (plan) => ({
    id: <EllipsisColumn value={plan.id} />,
    name: <TextColumn value={plan.name} />,
    description: <TextColumn value={plan.description} />,
    tasks: <TextColumn value={plan.plan_tasks_aggregate?.aggregate?.count} />,
  }),
});

// Define a new table component for Plans
type AllPlansTableProps = {
  where?: Plans_Bool_Exp[];
} & TableOptionals<typeof PlanTable>;

export const AllPlansTable = (props: AllPlansTableProps) => {
  const [pageVars, pageController] = usePagination();
  const { data } = useAllPlansQuery({
    variables: {
      ...pageVars,
      where: {
        _and: props.where,
      },
      order_by: [{ name: Order_By.Asc }],
    },
  });

  return (
    <PlanTable
      {...props}
      {...pageController}
      total={data?.plans_aggregate?.aggregate?.count}
      data={data?.plans}
    />
  );
};

// Define content to display in the main Context resource page
type PlanIndexProps = {
  onAddNew: () => void;
  // onSelect: (item: DeepPartial<Plan>) => void;
} & TableOptionals<typeof PlanTable>;

export const PlanIndex = (props: PlanIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Plans</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Plan
        </Button>
      </TableViewHeader>
      <Card>
        <AllPlansTable {...props} />
      </Card>
    </TableView>
  );
};

// Define a form for updating the properties of a plan-task pair
const updatePlanTaskSchema = Yup.object({
  min_per_device: Yup.number()
    .integer("must be a integer")
    .min(1, "must be greater than 0")
    .max(10000)
    .required("required"),
  priority: Yup.number()
    .integer("must be an integer")
    .min(0, "must be positive")
    .max(1000)
    .required("required"),
}).required();

type UpdatePlanTaskFormSchema = Yup.InferType<typeof updatePlanTaskSchema>;

type UpdatePlanTaskFormInfo = {
  title: string;
  submitButtonTitle: string;
  notification: {
    success: (id: string) => {
      title: string;
      description: string;
    };
    error: (id: string) => {
      title: string;
      description: string;
    };
  };
};

export type UpdatePlanTaskFormProps = {
  planTask?: DeepPartial<PlanTask>;
  onSuccess?: (id: string) => void;
};

export const UpdatePlanTaskForm = (props: UpdatePlanTaskFormProps) => {
  const info: UpdatePlanTaskFormInfo = {
    title: "Update Task in Plan",
    submitButtonTitle: "Update Task in Plan",
    notification: {
      success: (id) => ({
        title: "Plan Task Updated",
        description: `id: ${id}`,
      }),
      error: (id) => ({
        title: "Could Not Update Plan Task",
        description: `An error occured when trying to update plan task ${id}.`,
      }),
    },
  };

  const [updatePlanTask] = useUpdatePlanTaskMutation();

  const formik = useFormik<UpdatePlanTaskFormSchema>({
    validationSchema: updatePlanTaskSchema,
    initialValues: {
      min_per_device: 0,
      priority: 0,
    },
    onSubmit: async (values) => {
      try {
        const result = await (async () => {
          return await updatePlanTask({
            variables: {
              id: props.planTask?.id ?? "",
              input: { ...values },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.plan_task?.id ?? "");
      } catch (e) {
        formik.setSubmitting(false);
      }
    },
  });

  const { setValues } = formik;

  useEffect(() => {
    if (props.planTask) {
      setValues({
        min_per_device: props.planTask.min_per_device ?? 0,
        priority: props.planTask.priority ?? 0,
      });
    }
  }, [props.planTask, setValues]);

  const path = pathProxy<typeof formik.values>();

  return (
    <FormikProvider value={formik}>
      <FormHeader>
        <Typography variant="h6">{info.title}</Typography>
      </FormHeader>
      <FormContainer>
        <TextField
          bp={{ xs: 6 }}
          name={path.priority._}
          label="Priority"
          tooltip="The greater the number, the higher this task should appear on the task list."
        />
        <TextField
          bp={{ xs: 6 }}
          name={path.min_per_device._}
          label="Min per Device"
          tooltip="The number of recordings that should be made for this task on each device."
        />
        <FormAction>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            {info.submitButtonTitle}
          </Button>
        </FormAction>
      </FormContainer>
    </FormikProvider>
  );
};

// Define form for creating and editing Plans
const taskToAddSchema = Yup.object({
  key: Yup.number().required(),
  priority: Yup.number()
    .integer("must be an integer")
    .min(0, "must be positive")
    .max(1000)
    .required("priority is required"),
  minimum: Yup.number()
    .integer("must be an integer")
    .min(0, "must be positive")
    .max(10000)
    .required("minimum per device is required"),
  task: Yup.object({
    id: Yup.string().required(),
    description: Yup.string(),
    number: Yup.number().required(),
  }).required(),
}).required();

type TaskToAddSchema = Yup.InferType<typeof taskToAddSchema>;

const planSchema = Yup.object({
  name: Yup.string().required("a unique name is required"),
  description: Yup.string().nullable(),
  tasksToAdd: Yup.array(taskToAddSchema),
  tasksToRemove: Yup.array(
    Yup.object({
      key: Yup.number().required(),
      pt: Yup.object({
        id: Yup.string().required(),
        number: Yup.number(),
        description: Yup.string(),
        minimum: Yup.number(),
        priority: Yup.number(),
      }).required(),
    }).required(),
  ),
}).required();

export type PlanFormProps = {
  plan?: { id: string };
  action: ResourceFormAction;
  onSuccess?: (id: string) => void;
};

export const PlanForm = (props: PlanFormProps) => {
  const [createPlan] = useCreatePlanMutation();
  const [updatePlan] = useUpdatePlanMutation();

  const existingPlansFilters: Plans_Bool_Exp[] = [];
  if (props.action === "update" && props.plan) {
    existingPlansFilters.push({ id: { _neq: props.plan.id } });
  }
  const existingPlans = useAllPlansQuery({
    variables: { where: { _and: existingPlansFilters } },
    fetchPolicy: "network-only",
  });

  const planQuery = usePlanQuery({
    variables: { id: props.plan?.id ?? "" },
    skip: !props.plan,
    fetchPolicy: "network-only",
  });

  const planToUpdate = planQuery.data?.plan;

  return (
    <ResourceForm
      action={props.action}
      schema={planSchema}
      resourceToUpdate={planToUpdate}
      transform={(plan) => ({
        name: plan.name,
        description: plan.description ?? null,
        tasksToAdd: [],
        tasksToRemove: [],
      })}
      initialValues={{
        name: "",
        description: "",
        tasksToAdd: [],
        tasksToRemove: [],
      }}
      customValidator={(values, errors) => {
        for (let existing of existingPlans.data?.plans ?? []) {
          if (existing.name === values.name) {
            errors.name = `Plan "${values.name}" already exists`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const { tasksToAdd, tasksToRemove, ...vals } = values;
        const result = await (async () => {
          if (props.plan?.id) {
            const addPlanTasks: Plan_Tasks_Insert_Input[] = tasksToAdd!
              .filter((input) => input.task)
              .map((input) => ({
                plan_id: props.plan?.id,
                task_id: input.task?.id,
                priority: input.priority ?? 0,
                min_per_device: input.minimum,
              }));
            const removePlanTaskIds = tasksToRemove!
              .filter((input) => input.pt)
              .map((input) => input.pt?.id) as string[];
            return await updatePlan({
              variables: {
                id: props.plan.id,
                plan: { ...vals },
                add_plan_tasks: addPlanTasks,
                remove_plan_task_ids: removePlanTaskIds,
              },
            });
          }
        })();
        props.onSuccess && props.onSuccess(result?.data?.plan?.id ?? "");
      }}
      onInsert={async (values) => {
        const { tasksToAdd, tasksToRemove, ...vals } = values;
        const result = await (async () => {
          return await createPlan({
            variables: {
              input: { ...vals },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.plan?.id ?? "");
      }}
      render={({ path, formik }) => (
        <>
          <FormHeader>
            {props.plan ? "Update Plan" : "Create New Plan"}
          </FormHeader>
          <FormContainer>
            <TextField
              bp={{ xs: 12, sm: 6 }}
              name={path.name._ ?? "name"}
              label="Name"
            />
            <TextField
              bp={{ xs: 12, sm: 6 }}
              name={path.description?._ ?? "description"}
              label="Description"
            />
          </FormContainer>
          {props.action === "update" && props.plan && (
            <Tablature
              tabs={[
                {
                  label: "Tasks to Add",
                  name: "taskstoadd",
                  content: (
                    <FormContainer>
                      <FieldArray
                        name={path.tasksToAdd!._}
                        render={(helper) => {
                          const selectedTaskIds = formik.values.tasksToAdd!.map(
                            (t) => t.task?.id,
                          ) as string[];
                          return (
                            <>
                              <Grid container item xs={12} spacing={2}>
                                <DrawerButton
                                  bp={{ xs: 4 }}
                                  variant="contained"
                                  color="primary"
                                  label="Select Task to Add"
                                  startIcon={<AddCircleOutline />}
                                  disableElevation
                                  fullWidth={true}
                                  content={(close) => (
                                    <AllTasksTable
                                      selectable="single"
                                      where={[
                                        { is_template: { _neq: true } },
                                        { is_test: { _neq: true } },
                                        {
                                          // Prevent users from selecting tasks
                                          // that they have already added.
                                          id: { _nin: selectedTaskIds },
                                        },
                                        {
                                          // Prevent users from selecting tasks
                                          // that are already in this plan.
                                          _not: {
                                            plan_tasks: {
                                              plan_id: {
                                                _eq: props.plan?.id ?? "",
                                              },
                                            },
                                          },
                                        },
                                      ]}
                                      onSelect={(task) => {
                                        helper.insert(0, {
                                          key: Date.now(),
                                          minimum: "",
                                          priority: "",
                                          task: {
                                            id: task.id,
                                            description: task.description ?? "",
                                            number: task.legacy_template_id,
                                          },
                                        });
                                        close();
                                      }}
                                    />
                                  )}
                                />
                                <DrawerButton
                                  bp={{ xs: 4 }}
                                  variant="contained"
                                  color="primary"
                                  label="Copy Tasks from Existing Plan"
                                  startIcon={<AddCircleOutline />}
                                  disableElevation
                                  fullWidth={true}
                                  content={(close) => (
                                    <CopyFromPlanSelector
                                      targetPlan={{
                                        id: planToUpdate?.id ?? "",
                                      }}
                                      onSelect={(planTasks) => {
                                        planTasks
                                          .filter(
                                            (pt) =>
                                              !selectedTaskIds.includes(
                                                pt.task.id,
                                              ),
                                          )
                                          .forEach((pt) => helper.push(pt));
                                        close();
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              {formik.values.tasksToAdd!.map(
                                (taskToAdd, index) => (
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    spacing={2}
                                    key={taskToAdd.key}
                                  >
                                    <Grid item xs={12}>
                                      <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <InfoDisplay
                                        items={[
                                          {
                                            label: "Task",
                                            value: taskToAdd.task.number,
                                          },
                                          {
                                            label: "Description",
                                            value:
                                              taskToAdd.task.description ?? "",
                                          },
                                        ]}
                                      />
                                    </Grid>
                                    <TextField
                                      bp={{ xs: 6 }}
                                      name={path.tasksToAdd![index].priority._}
                                      label="Priority"
                                      tooltip="The greater the number, the higher this task should appear on the task list."
                                    />
                                    <TextField
                                      bp={{ xs: 6 }}
                                      name={path.tasksToAdd![index].minimum._}
                                      label="Min per Device"
                                      tooltip="The number of recordings that should be made for this task on each device."
                                    />
                                    <Grid item xs />
                                    <Grid item xs={1}>
                                      <Button
                                        size="small"
                                        color="primary"
                                        variant="text"
                                        onClick={() => helper.remove(index)}
                                      >
                                        <Close />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                ),
                              )}
                            </>
                          );
                        }}
                      />
                    </FormContainer>
                  ),
                },
                {
                  label: "Tasks to Remove",
                  name: "taskstoremove",
                  content: (
                    <FormContainer>
                      <FieldArray
                        name={path.tasksToRemove!._}
                        render={(helper) => {
                          const selectedPlanTaskIds =
                            formik.values.tasksToRemove!.map(
                              (t) => t.pt?.id,
                            ) as string[];
                          return (
                            <>
                              <Grid container item xs={12} spacing={2}>
                                <DrawerButton
                                  bp={{ xs: 4 }}
                                  variant="contained"
                                  color="primary"
                                  label="Select Task to Remove"
                                  startIcon={<RemoveCircleOutline />}
                                  disableElevation
                                  fullWidth={true}
                                  content={(close) => (
                                    <AllPlanTasksTable
                                      where={[
                                        {
                                          id: { _nin: selectedPlanTaskIds },
                                          plan_id: {
                                            _eq: props.plan?.id ?? "",
                                          },
                                        },
                                      ]}
                                      selectable="single"
                                      onSelect={(pt) => {
                                        helper.insert(0, {
                                          key: Date.now(),
                                          pt: {
                                            id: pt.id,
                                            number:
                                              pt.task?.legacy_template_id ?? 0,
                                            description:
                                              pt.task?.description ?? "",
                                            minimum: pt.min_per_device ?? 0,
                                            priority: pt.priority ?? 0,
                                          },
                                        });
                                        close();
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              {formik.values.tasksToRemove!.map(
                                (taskToRemove, index) => (
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    spacing={2}
                                    key={taskToRemove.key}
                                  >
                                    <Grid item xs={11}>
                                      <InfoDisplay
                                        items={[
                                          {
                                            label: "Task",
                                            value: taskToRemove.pt.number ?? "",
                                          },
                                          {
                                            label: "Description",
                                            value:
                                              taskToRemove.pt.description ?? "",
                                          },
                                          {
                                            label: "Min per Device",
                                            value:
                                              taskToRemove.pt.minimum ?? "",
                                          },
                                          {
                                            label: "Priority",
                                            value:
                                              taskToRemove.pt.priority ?? "",
                                          },
                                        ]}
                                      />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Button
                                        size="small"
                                        color="primary"
                                        variant="text"
                                        onClick={() => helper.remove(index)}
                                      >
                                        <Close />
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Divider />
                                    </Grid>
                                  </Grid>
                                ),
                              )}
                            </>
                          );
                        }}
                      />
                    </FormContainer>
                  ),
                },
              ]}
            />
          )}
        </>
      )}
    />
  );
};

const CopyFromPlanSelector = (props: {
  targetPlan: { id: string };
  onSelect: (tasks: TaskToAddSchema[]) => void;
}) => {
  const { onSelect, targetPlan } = props;
  const [planTasksLoading, setPlanTasksLoading] = useState(false);
  const [planTasksQuery, planTasks] = useAllPlanTasksLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (planTasks.loading || planTasks.error) return;
    if (planTasksLoading && !!planTasks.data?.plan_tasks) {
      onSelect(
        planTasks.data.plan_tasks.map((pt) => ({
          key: Date.now(),
          priority: pt.priority ?? "",
          minimum: pt.min_per_device ?? "",
          task: {
            id: pt.task.id ?? "",
            description: pt.task.description ?? "",
            number: pt.task.legacy_template_id ?? "",
          },
        })),
      );
      setPlanTasksLoading(false);
    }
  }, [planTasksLoading, planTasks, onSelect]);

  return (
    <AllPlansTable
      selectable="single"
      // Don't include the target plan.
      where={targetPlan.id ? [{ id: { _neq: targetPlan.id } }] : undefined}
      onSelect={(plan) => {
        const filters: Plan_Tasks_Bool_Exp[] = [];
        // Make sure not to include tasks that have already been added to the
        // target plan.
        if (targetPlan.id) {
          filters.push({
            _not: { task: { plan_tasks: { plan_id: { _eq: targetPlan.id } } } },
          });
        }
        if (plan.id) {
          filters.push({ plan_id: { _eq: plan.id } });
          setPlanTasksLoading(true);
          planTasksQuery({ variables: { where: { _and: filters } } });
        }
      }}
    />
  );
};

type PlanOverviewProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Plan>) => void;
};

const PlanOverview = (props: PlanOverviewProps) => {
  const planNav = usePlanNav();
  const taskNav = useTaskNav();
  const planCache = useRef<PlanOverviewSubscription | undefined>(undefined);
  const planQuery = usePlanOverviewSubscription({
    variables: { id: props.id },
  });
  // States for toggling columns in the table
  const [showInstructions, setShowInstructions] = useState(true);
  const [showHighlights, setShowHighlights] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const [showFieldOptions, setShowFieldOptions] = useState(false);

  if (planQuery.data) {
    planCache.current = planQuery.data;
  }

  const plan = planCache.current?.plan;

  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const [planTask, setPlanTask] = useState<DeepPartial<PlanTask> | undefined>(
    undefined,
  );
  if (!plan) return null;

  return (
    <>
      <ShowResourceView
        title={plan?.name ?? ""}
        breadcrumbs={
          <Breadcrumbs>
            <Breadcrumb label="plans" onClick={() => planNav.list()} />
            <Breadcrumb label={plan?.name ?? ""} />
          </Breadcrumbs>
        }
        properties={[{ label: "Description", value: plan?.description ?? "" }]}
        onEditAction={() => props.onEditAction && props.onEditAction(plan)}
      >
        <TabView
          useUrlParams={true}
          renderTabs={(tabProps) => (
            <Tabs {...tabProps}>
              <Tab
                label={<TabLabel label="Tasks" icon={<ListAltOutlined />} />}
              />
            </Tabs>
          )}
          renderContent={(current) => (
            <>
              <FormControl>
                <FormLabel component="legend">Show columns</FormLabel>
                <FormGroup style={{ display: "flex", flexDirection: "row" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showInstructions}
                        onChange={() => setShowInstructions(!showInstructions)}
                      />
                    }
                    label="Instruction (LLM)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showHighlights}
                        onChange={() => setShowHighlights(!showHighlights)}
                      />
                    }
                    label="Highlights"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showNotes}
                        onChange={() => setShowNotes(!showNotes)}
                      />
                    }
                    label="Notes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showFieldOptions}
                        onChange={() => setShowFieldOptions(!showFieldOptions)}
                      />
                    }
                    label="Field/options"
                  />
                </FormGroup>
              </FormControl>
              <TabContent index={0} current={current}>
                <Card style={{ marginTop: 20 }}>
                  <CardContent>
                    <AllPlanTasksTable
                      selectable="none"
                      where={[{ plan_id: { _eq: props.id } }]}
                      onEditAction={(item) => {
                        setShowUpdateForm(true);
                        setPlanTask(item);
                      }}
                      showUrl={(item) =>
                        item.task?.id && taskNav.showUrl(item.task.id)
                      }
                      hideColumns={{
                        field_options: !showFieldOptions,
                        highlights: !showHighlights,
                        note: !showNotes,
                        instruction: !showInstructions,
                      }}
                    />
                  </CardContent>
                </Card>
              </TabContent>
            </>
          )}
        />
      </ShowResourceView>
      {planTask && (
        <ClosableDrawer show={showUpdateForm} setShow={setShowUpdateForm}>
          <UpdatePlanTaskForm
            onSuccess={() => setShowUpdateForm(false)}
            planTask={planTask}
          />
        </ClosableDrawer>
      )}
    </>
  );
};

export const usePlanNav = () => useResourceNav("plans");
export const PlanResource = () => (
  <Resource
    path="plans"
    list={(nav) => (
      <PlanIndex
        selectable="none"
        onAddNew={() => nav.create()}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        editUrl={(item) => item.id && nav.editUrl(item.id)}
      />
    )}
    show={(nav, id) => (
      <PlanOverview
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <PlanForm action="insert" onSuccess={(id) => nav.edit(id)} />
    )}
    edit={(nav, id) => (
      <PlanForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        plan={{ id: id ?? "" }}
      />
    )}
  />
);
