import { Fragment, useEffect, useState } from "react";
import { createHash } from "crypto";
import { FieldArray } from "formik";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Switch,
  TextField as MUITextField,
  Tooltip,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  AddCircleOutline,
  Add,
  Attachment,
  Subscriptions,
  SubscriptionsOutlined,
  Delete,
  Info,
  ListAltOutlined,
  ListOutlined,
  AssignmentOutlined,
  MoveToInbox,
  Visibility,
  OndemandVideo,
  SubtitlesOutlined,
  TranslateOutlined,
} from "@material-ui/icons";
import qs from "query-string";
import { SearchBar } from "src/components/filters";
import { MultiSelectFilter } from "src/components/filters/SearchBar";
import { MediaFileUploadField } from "src/components/MediaUpload";
import {
  DrawerButton,
  FormContainer,
  FormHeader,
  TextField,
  MultiSelect,
} from "src/components/Form";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid, orderBy } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ClosableDrawer,
  ShowResourceView,
  TableView,
  TableViewHeader,
  TabLabel,
  Tablature,
  InfoTooltip,
} from "src/Layout";
import {
  Tasks as Task,
  Categories as Category,
  Contexts as Context,
  Fields as Field,
  Field_Options,
  Task_Field_Option,
  useTaskSimpleQuery,
  useAllTasksQuery,
  useAllFieldOptionsQuery,
  useAllTaskFieldOptionsQuery,
  useTaskOptionDisplaySubscription,
  Field_Options_Bool_Exp,
  Task_Field_Option_Bool_Exp,
  Tasks_Bool_Exp,
  Tasks_Order_By,
  Field_Options_Order_By,
  Task_Field_Option_Order_By,
  Order_By,
  useAllCategoriesQuery,
  useAllContextsQuery,
  useAllFieldsQuery,
  useTaskLazyQuery,
  useUpsertTaskMutation,
  useSetTaskFieldOptionHighlightMutation,
  useUpdateTaskMutation,
  useUpsertTaskTranslationMutation,
  useTaskTranslationsSubscription,
  Contexts_Bool_Exp,
  AllCategoriesQuery,
  Roles_Enum,
  Media_Type_Enum,
  Task_Field_Option_Constraint,
  Task_Field_Option_Update_Column,
  Task_Media_Constraint,
  Task_Media_Update_Column,
  Media_Constraint,
  Media_Update_Column,
  Media_Bool_Exp,
  Media,
  useAllMediaQuery,
  useTaskQuery,
  useTaskFieldOptionHashesQuery,
} from "src/generated/asgard/graphql";
import { TranslationTable } from "src/resources/Translation";
import {
  AllPlansTable,
  useContextNav,
  usePlanNav,
} from "src/resources/CollectionPlan";
import { TaskForm as MultiTaskForm } from "src/resources/CollectionPlan/TaskForm";
import { AllProjectsTable, useProjectNav } from "src/resources/Project";
import { useRequireRole } from "src/auth";
import { TaskInstructionCard } from "src/resources/CollectionPlan/TaskInstructionCard";

// Make reusable link to task "show" pages.
type TaskLinkProps = {
  id: string;
  number: number;
  openNewTab?: boolean;
};

export const TaskLink = ({ id, number, openNewTab }: TaskLinkProps) => {
  const nav = useTaskNav();
  return openNewTab ? (
    <Button
      component={Link}
      to={nav.showUrl(id)}
      target="_blank"
      rel="noopener noreferrer"
      color="primary"
    >
      {number}
    </Button>
  ) : (
    <Button component={Link} to={nav.showUrl(id)} color="primary">
      {number}
    </Button>
  );
};

// Should always order field options the same way when looking at a task.
const OrderFieldOptionsBy: Field_Options_Order_By[] = [
  {
    field: {
      context: { category: { name: Order_By.Asc } },
    },
  },
  {
    field: {
      name: Order_By.Asc,
    },
  },
];

// Config table columns from Media fields
export const MediaTable = createTable<Media>()({
  keys: (m) => m.id ?? "",
  title: "Media",
  headers: {
    id: { display: "ID" },
    title: { display: "title" },
    description: { display: "description" },
    url: { display: "url" },
    type: { display: "type" },
  },
  columns: (m) => ({
    id: <EllipsisColumn value={m.id} />,
    title: <TextColumn value={m?.title} />,
    description: <TextColumn value={m?.description} />,
    url: (
      <IconButton key={m.id} title={m?.url} target="_blank" href={m?.url ?? ""}>
        <OndemandVideo />
      </IconButton>
    ),
    type: <TextColumn value={m?.type} />,
  }),
});

// Define a new table component for Media
type AllMediaTableProps = TableOptionals<typeof MediaTable> & {
  where?: Media_Bool_Exp[];
};

export const AllMediaTable = (props: AllMediaTableProps) => {
  const [pageVars, pageController] = usePagination();
  const Filters: Media_Bool_Exp[] = [{ _and: props.where }];

  const { data } = useAllMediaQuery({
    fetchPolicy: "network-only",
    variables: {
      ...pageVars,
      where: { _and: Filters },
    },
  });

  return (
    <MediaTable
      {...props}
      {...pageController}
      total={data?.media_aggregate?.aggregate?.count}
      data={data?.media}
    />
  );
};

export const FieldOptionsTable = createTable<Field_Options>()({
  keys: (fo) => fo?.id ?? "",
  title: "Field Options",
  headers: {
    id: { display: "Field-option ID" },
    category: { display: "Category" },
    type: { display: "Type" },
    field_id: { display: "Field ID" },
    field_name: { display: "Field" },
    option_id: { display: "Option ID" },
    option_display: { display: "Option" },
  },
  columns: (fo) => ({
    id: <EllipsisColumn value={fo.id} />,
    category: <TextColumn value={fo.field?.context?.category?.name} />,
    field_name: <TextColumn value={fo.field?.display} />,
    option_display: <TextColumn value={fo.option?.display} />,
    type: <TextColumn value={fo.type?.name} />,
    field_id: <EllipsisColumn value={fo.field?.id} />,
    option_id: <EllipsisColumn value={fo.option?.id} />,
  }),
});

export const OrderTaskFieldOptionsBy: Task_Field_Option_Order_By[] =
  OrderFieldOptionsBy.map((clause) => ({ field_option: clause }));

type ToggleHighlightProps = {
  tfo: DeepPartial<Task_Field_Option>;
};

const ToggleHighlight = (props: ToggleHighlightProps) => {
  const [highlight, setHighlight] = useState(props.tfo.highlight ?? false);
  const [setTFOH] = useSetTaskFieldOptionHighlightMutation();
  return (
    <Switch
      checked={highlight}
      onChange={(_, c) => {
        const { task_id, field_option_id } = props.tfo;
        if (!task_id || !field_option_id) return;
        setHighlight(c);
        setTFOH({ variables: { task_id, field_option_id, highlight: c } });
      }}
    />
  );
};

export const TaskFieldOptionTable = createTable<Task_Field_Option>()({
  keys: (tfo) => tfo?.field_option_id ?? "",
  title: "Field Options",
  headers: {
    id: { display: "Field-option ID" },
    category: { display: "Category" },
    type: { display: "Type" },
    field_id: { display: "Field ID" },
    field_name: { display: "Field" },
    option_id: { display: "Option ID" },
    option_display: { display: "Option" },
    highlight: {
      display: "Highlight",
      info: "Highlights the field and option for use in descriptions etc.",
    },
  },
  columns: ({ task_id, field_option_id, highlight, field_option: tfo }) => ({
    id: <EllipsisColumn value={tfo?.id} />,
    category: <TextColumn value={tfo?.field?.context?.category?.name} />,
    field_name: <TextColumn value={tfo?.field?.display} />,
    option_display: <TextColumn value={tfo?.option?.display} />,
    type: <TextColumn value={tfo?.type?.name} />,
    highlight: (
      <ToggleHighlight
        tfo={{ task_id, field_option_id, highlight, field_option: tfo }}
      />
    ),
    field_id: <EllipsisColumn value={tfo?.field?.id} />,
    option_id: <EllipsisColumn value={tfo?.option?.id} />,
  }),
});

type AllFieldOptionsTableProps = {
  where?: Field_Options_Bool_Exp[];
} & TableOptionals<typeof FieldOptionsTable>;

export const AllFieldOptionsTable = (props: AllFieldOptionsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const { data } = useAllFieldOptionsQuery({
    fetchPolicy: "network-only",
    variables: {
      ...pageVars,
      where: {
        _and: props.where,
      },
      order_by: OrderFieldOptionsBy,
    },
  });

  return (
    <FieldOptionsTable
      {...props}
      {...pageController}
      total={data?.fo_aggregate?.aggregate?.count}
      data={data?.fo}
    />
  );
};

type AllTaskFieldOptionsTableProps = {
  taskId: string;
  taskFieldOptionsWhere?: Task_Field_Option_Bool_Exp[];
} & TableOptionals<typeof TaskFieldOptionTable>;

export const AllTaskFieldOptionsTable = (
  props: AllTaskFieldOptionsTableProps,
) => {
  const [pageVars, pageController] = usePagination();
  const allFilters: Task_Field_Option_Bool_Exp[] = [
    { _and: props.taskFieldOptionsWhere },
  ];

  // Text search filter.
  const [search, setSearch] = useState("");
  const textSearchFilters: Task_Field_Option_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    textSearchFilters.push(
      { field_option_id: { _eq: search } },
      { field_option: { field_id: { _eq: search } } },
      { field_option: { option_id: { _eq: search } } },
    );
  } else {
    const term = like(search);
    textSearchFilters.push(
      { field_option: { field: { name: { _ilike: term } } } },
      { field_option: { field: { display: { _ilike: term } } } },
      { field_option: { option: { display: { _ilike: term } } } },
    );
  }
  if (textSearchFilters) allFilters.push({ _or: textSearchFilters });

  // Drop-down category select filter.
  const categories = useAllCategoriesQuery({});
  const [selectedCategories, setSelectedCategories] = useState<
    AllCategoriesQuery["categories"] | undefined
  >(undefined);
  const categoryFilters: Task_Field_Option_Bool_Exp[] =
    selectedCategories?.map(({ id }) => ({
      field_option: { field: { context: { category_id: { _eq: id } } } },
    })) ?? [];
  if (categoryFilters.length > 0) allFilters.push({ _or: categoryFilters });

  const { data } = useAllTaskFieldOptionsQuery({
    fetchPolicy: "network-only",
    variables: {
      ...pageVars,
      where: { _and: allFilters },
      order_by: OrderTaskFieldOptionsBy,
      task_id: props.taskId,
    },
  });

  return (
    <TaskFieldOptionTable
      {...props}
      {...pageController}
      total={data?.task?.task_field_options_aggregate?.aggregate?.count}
      data={data?.task?.task_field_options}
      tools={
        <>
          <Grid item xs={12} sm={8}>
            <SearchBar onChange={setSearch} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MultiSelectFilter
              label="Categories"
              onChange={(selected) => setSelectedCategories(selected)}
              placeholder="Category"
              options={categories?.data?.categories}
              getOptionLabel={(o) => o.name}
            />
          </Grid>
        </>
      }
    />
  );
};

export type MediaShowProps = {
  content?: (close: () => void) => React.ReactElement;
  show_button?: boolean;
  title?: string;
};

export const MediaShow = ({ content, show_button, title }: MediaShowProps) => {
  const [show, setShow] = useState(false);
  if (show_button) {
    return (
      <>
        <IconButton title={title} onClick={() => setShow(true)}>
          <SubscriptionsOutlined />
        </IconButton>
        <ClosableDrawer show={show} setShow={setShow}>
          {content && content(() => setShow(false))}
        </ClosableDrawer>
      </>
    );
  } else {
    return <></>;
  }
};

export const TaskTable = createTable<Task>()({
  keys: (task) => task.id ?? "",
  title: "Task",
  headers: {
    id: { display: "ID" },
    legacy_id: { display: "Number" },
    note: { display: "Note" },
    highlights: { display: "Highlights" },
    num_recordings: { display: "Total Recordings" },
    media: { display: "Media" },
    deleted: { display: "Archived At" },
  },
  columns: (task) => {
    return {
      id: <EllipsisColumn value={task.id} />,
      legacy_id: <TextColumn value={task.legacy_template_id} />,
      note: <TextColumn value={task.note ?? ""} charLimit={100} />,
      highlights: <TextColumn value={task.description} charLimit={100} />,
      num_recordings: (
        <TextColumn value={task.recordings_aggregate?.aggregate?.count} />
      ),
      media: (() => {
        return (
          <>
            {
              <MediaShow
                title={task.task_media
                  ?.map((m) => `title: ${m.media?.title}, url: ${m.media?.url}`)
                  .join("\n")}
                show_button={task.task_media!.length > 0}
                content={() => (
                  <AllMediaTable
                    selectable="none"
                    where={[
                      {
                        id: {
                          _in:
                            task.task_media?.map((m) => m.media_id) ??
                            ([] as any),
                        },
                      },
                    ]}
                  />
                )}
              />
            }
          </>
        );
      })(),
      deleted: <TextColumn value={task.deleted_at ?? ""} />,
    };
  },
});

type AllTasksTableProps = {
  where?: Tasks_Bool_Exp[];
  enableIsolateTemplates?: boolean;
  isolateTemplatesDefault?: boolean;
  enableIsolateTestingTasks?: boolean;
  isolateTestingTasksDefault?: boolean;
} & TableOptionals<typeof TaskTable>;

export const AllTasksTable = (props: AllTasksTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const [isolateTemplates, setIsolateTemplates] = useState(
    !!props.enableIsolateTemplates && !!props.isolateTemplatesDefault,
  );
  const [isolateTestingTasks, setIsolateTestingTasks] = useState(
    !!props.enableIsolateTestingTasks && !!props.isolateTestingTasksDefault,
  );
  const [includeArchived, setIncludeArchived] = useState(false);
  const [order, setOrder] = useState<Tasks_Order_By>({
    updated_at: Order_By.Desc,
  });

  const term = like(search);
  const filters: Tasks_Bool_Exp[] = [{ note: { _ilike: term } }];

  if (uuidIsValid(search)) {
    filters.push({ id: { _eq: search } });
  }

  if (parseInt(search)) {
    filters.push({ legacy_template_id: { _eq: parseInt(search) } });
  }

  const where: Tasks_Bool_Exp[] = [{ _or: filters }, { _and: props.where }];
  if (!includeArchived) where.push({ deleted_at: { _is_null: true } });
  if (isolateTemplates) where.push({ is_template: { _eq: true } });
  if (isolateTestingTasks) where.push({ is_test: { _eq: true } });

  const { data } = useAllTasksQuery({
    fetchPolicy: "network-only",
    variables: {
      ...pageVars,
      order_by: [order],
      where: { _and: where },
    },
  });

  return (
    <TaskTable
      {...props}
      {...pageController}
      total={data?.tasks_aggregate?.aggregate?.count}
      data={data?.tasks}
      hideColumns={includeArchived ? undefined : { deleted: true }}
      tools={
        <>
          <Grid item xs={12}>
            <SearchBar onChange={setSearch} />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={includeArchived}
                  onChange={(event) => setIncludeArchived(event.target.checked)}
                />
              }
              label="Include Archived Tasks"
            />
          </Grid>
          {props.enableIsolateTemplates && (
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(event) =>
                      setIsolateTemplates(event.target.checked)
                    }
                    checked={isolateTemplates}
                  />
                }
                label="Isolate Templates"
              />
            </Grid>
          )}
          {props.enableIsolateTestingTasks && (
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(event) =>
                      setIsolateTestingTasks(event.target.checked)
                    }
                    checked={isolateTestingTasks}
                  />
                }
                label="Isolate Testing Tasks"
              />
            </Grid>
          )}
        </>
      }
      orderColumn={(order) => {
        if (!order) {
          setOrder({ updated_at: Order_By.Desc });
          return;
        }

        setOrder({
          id: orderBy(order.id),
          note: orderBy(order.note),
          legacy_template_id: orderBy(order.legacy_id),
        });
      }}
    />
  );
};

type TaskIndexProps = {
  onAddNew: () => void;
  onAddNewMultiple: () => void;
} & TableOptionals<typeof TaskTable>;

export const TaskIndex = (props: TaskIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Tasks</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Task
        </Button>
        <Button
          onClick={props.onAddNewMultiple}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          Multiple New Tasks
        </Button>
      </TableViewHeader>
      <Card>
        <AllTasksTable
          {...props}
          selectable="none"
          enableIsolateTemplates
          enableIsolateTestingTasks
        />
      </Card>
    </TableView>
  );
};

const fieldOptionSchema = Yup.object({
  key: Yup.number().required(),
  highlight: Yup.boolean().required(),
  category: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  context: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  field: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
    type_id: Yup.string().required(),
  }).required(),
  option: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
    field_option_id: Yup.string().required(),
  })
    .required("Option is required")
    .nullable(),
}).required();

const taskMedia = Yup.object({
  id: Yup.string().required(),
  url: Yup.string().required(),
  title: Yup.string().required(),
  description: Yup.string().nullable().required(),
  type: Yup.mixed<Media_Type_Enum>()
    .oneOf(Object.values(Media_Type_Enum))
    .required(),
}).required();

const taskSchema = Yup.object({
  note: Yup.string(),
  is_template: Yup.boolean().required(),
  is_test: Yup.boolean().required(),
  fgt: Yup.boolean().required(),
  legacyTemplateId: Yup.number().optional(),
  fieldOptions: Yup.array(fieldOptionSchema).min(1),
  task_media: Yup.array(taskMedia),
  currentMediaIds: Yup.array(Yup.string().required()),
  currentFieldOptionIds: Yup.array(Yup.string().required()),
}).required();

type FieldOptionSchema = Yup.InferType<typeof fieldOptionSchema>;
type TaskMediaSchema = Yup.InferType<typeof taskMedia>;
type TaskFormSchema = Yup.InferType<typeof taskSchema>;

function transformTaskMedia(
  tms: DeepPartial<Task["task_media"]>,
): TaskMediaSchema[] {
  return tms
    .filter((tm) => !!tm?.media?.url && !!tm?.media?.title && !!tm?.media?.type)
    .map((tm) => ({
      id: tm!.media!.id ?? "",
      url: tm!.media!.url!,
      title: tm!.media!.title!,
      type: tm!.media!.type!,
      description: tm!.media!.description ?? "",
    }));
}

// Make transorm "task_field_options" to form "fieldOptions".
function transformTaskFieldOptions(
  tfos: DeepPartial<Task_Field_Option>[],
): FieldOptionSchema[] {
  return tfos.map((tfo, i) => ({
    key: i,
    new: true,
    remove: false,
    highlight: tfo.highlight ?? false,
    category: {
      id: tfo.field_option?.field?.context?.category?.id ?? "",
      name: tfo.field_option?.field?.context?.category?.name ?? "",
    },
    context: {
      id: tfo.field_option?.field?.context?.id ?? "",
      name: tfo.field_option?.field?.context?.name ?? "",
    },
    field: {
      id: tfo.field_option?.field?.id ?? "",
      name: tfo.field_option?.field?.display ?? "",
      type_id: tfo.field_option?.field?.type_id ?? "",
    },
    option: {
      id: tfo.field_option?.option?.id ?? "",
      name: tfo.field_option?.option?.display ?? "",
      field_option_id: tfo.field_option?.id ?? "",
    },
  }));
}

function transformTask(task: DeepPartial<Task>): TaskFormSchema {
  return {
    note: task.note ?? "",
    is_template: task.is_template ?? false,
    is_test: task.is_test ?? false,
    legacyTemplateId: task.legacy_template_id ?? undefined,
    fgt: task.force_ground_truth_to_negative ?? false,
    currentFieldOptionIds:
      task.task_field_options
        ?.filter((fo) => !!fo.field_option_id)
        .map((fo) => fo.field_option_id!) ?? [],
    fieldOptions: transformTaskFieldOptions(task.task_field_options ?? []),
    task_media: transformTaskMedia(task.task_media ?? []),
    currentMediaIds:
      task.task_media
        ?.filter((tm) => !!tm.media_id)
        .map((tm) => tm.media_id!) ?? [],
  };
}

function fileTypeStringToEnum(fileType: String): Media_Type_Enum {
  if (fileType.includes("image")) {
    return Media_Type_Enum.Image;
  } else {
    return Media_Type_Enum.Video;
  }
}

export type TaskFormProps = {
  action: FormAction;
  task?: { id: string };
  onSuccess?: (id: string) => void;
};

function getIdsToRemove(
  prevIds: string[] = [],
  newIds: (string | undefined)[] = [],
): string[] {
  return prevIds.filter(
    (prev_id) => !newIds?.some((new_id) => prev_id === new_id),
  );
}

export const TaskForm = (props: TaskFormProps) => {
  const [upsertTask] = useUpsertTaskMutation();
  async function upsert(values: TaskFormSchema) {
    console.log(values);
    const result = await (async () => {
      return await upsertTask({
        variables: {
          update_task: props.task?.id !== undefined,
          task_id: props.task?.id,
          field_option_ids_to_remove:
            getIdsToRemove(
              values.currentFieldOptionIds,
              values.fieldOptions!.map(
                (field_option) => field_option.option?.field_option_id,
              ),
            ) ?? [],
          media_ids_to_remove:
            getIdsToRemove(
              values.currentMediaIds,
              values.task_media!.map((tm) => tm.id),
            ) ?? [],
          input: {
            ...(props.action === "update" && {
              legacy_template_id: values?.legacyTemplateId,
            }),
            note: values.note ?? "",
            is_template: values.is_template,
            is_test: values.is_test,
            force_ground_truth_to_negative: values.fgt,
            task_field_options: {
              on_conflict: {
                constraint: Task_Field_Option_Constraint.TaskFieldOptionPkey,
                update_columns: [
                  Task_Field_Option_Update_Column.FieldOptionId,
                  Task_Field_Option_Update_Column.TaskId,
                  Task_Field_Option_Update_Column.Highlight,
                ],
              },
              data: values.fieldOptions!.map((fo) => ({
                field_option_id: fo.option?.field_option_id,
                highlight: fo.highlight,
              })),
            },
            task_media: {
              on_conflict: {
                constraint: Task_Media_Constraint.TaskMediaPkey,
                update_columns: [
                  Task_Media_Update_Column.TaskId,
                  Task_Media_Update_Column.MediaId,
                ],
              },
              data: values.task_media!.map((tm) => ({
                media: {
                  on_conflict: {
                    constraint: Media_Constraint.MediaUrlKey,
                    update_columns: [
                      Media_Update_Column.Title,
                      Media_Update_Column.Url,
                      Media_Update_Column.Description,
                    ],
                  },
                  data: {
                    title: tm?.title,
                    url: tm?.url,
                    description: tm?.description,
                    type: tm?.type,
                  },
                },
              })),
            },
          },
        },
      });
    })();
    props.onSuccess && props.onSuccess(result?.data?.task?.id ?? "");
  }

  const taskQuery = useTaskQuery({
    variables: { id: props.task?.id ?? "" },
    skip: !props.task,
    fetchPolicy: "network-only",
  });

  // Manage states for the category, context, and field selectors.
  const [category, setCategory] = useState<DeepPartial<Category> | null>(null);
  const categories = useAllCategoriesQuery({
    fetchPolicy: "network-only",
  });
  const [context, setContext] = useState<DeepPartial<Context> | null>(null);
  const contextFilters: Contexts_Bool_Exp[] = [
    { category: { id: { _eq: category?.id } } },
  ];
  const [usedFields, setUsedFields] = useState<
    /**
     * Keep track of which fields have been selected, in order to limit the
     * contexts available for a given category to only those that have already
     * been used. This should prevent the user from adding two fields from
     * different contexts in the same category.
     */
    {
      fieldId: string;
      categoryId: string;
      contextId: string;
      fieldOptionId: string;
    }[]
  >([]);
  usedFields.forEach(({ categoryId, contextId }) => {
    if (categoryId === category?.id) {
      contextFilters.push({ id: { _eq: contextId } });
    }
  });
  const contexts = useAllContextsQuery({
    fetchPolicy: "network-only",
    variables: {
      where: { _and: contextFilters },
    },
    skip: !category?.id,
  });
  const [field, setField] = useState<DeepPartial<Field> | null>(null);
  const fields = useAllFieldsQuery({
    fetchPolicy: "network-only",
    variables: {
      where: {
        _and: [
          { context: { id: { _eq: context?.id } } },
          { id: { _nin: usedFields.map(({ fieldId }) => fieldId) } },
        ],
      },
      order_by: [{ display: Order_By.Asc }],
    },
    skip: !context?.id,
  });

  // Setup lazy query for a task the user wants to "clone".
  const [taskToCloneQuery, taskToClone] = useTaskLazyQuery({
    fetchPolicy: "network-only",
  });
  const [taskToCloneLoading, setTaskToCloneLoading] = useState(false);
  useEffect(() => {
    if (taskToClone.loading || taskToClone.error) return;
    setTaskToCloneLoading(false);
  }, [taskToCloneLoading, setTaskToCloneLoading, taskToClone]);

  // Setup query on the "hashes" view to check for existing tasks with the same
  // set of field-option pairs.
  const [currentHash, setCurrentHash] = useState("");
  const matchingHashesQuery = useTaskFieldOptionHashesQuery({
    variables: { where: { md5: { _eq: currentHash } } },
    skip: !currentHash,
    fetchPolicy: "network-only",
  });
  const matchingTasks: number[] =
    matchingHashesQuery.data?.task_field_option_hashes
      ?.filter((item) => !!item.task?.legacy_template_id)
      .map((item) => item.task?.legacy_template_id as number) ?? [];

  const disableFieldOptionEdit =
    props.action === "update" &&
    !!(taskQuery.data?.task?.project_tasks_aggregate?.aggregate?.count ?? 1);

  const initialValues: TaskFormSchema = {
    note: "",
    is_template: false,
    is_test: false,
    fgt: false,
    legacyTemplateId: undefined,
    fieldOptions: [],
    task_media: [],
    currentMediaIds: [],
    currentFieldOptionIds: [],
  };

  return (
    <ResourceForm
      action={props.action}
      schema={taskSchema}
      resourceToUpdate={taskQuery.data?.task}
      initialValues={initialValues}
      transform={transformTask}
      modifyForm={
        !!taskToClone?.data?.task
          ? (path, formik) => {
              const clone = taskToClone.data!.task!;
              formik.setFieldValue(
                path.fgt?._ ?? "fgt",
                clone.force_ground_truth_to_negative,
              );
              formik.setFieldValue(
                path.fieldOptions?._ ?? "fieldOptions",
                transformTaskFieldOptions(clone.task_field_options ?? []),
              );
            }
          : undefined
      }
      disableSubmit={() => taskToCloneLoading}
      customValidator={(_, errors) => {
        const matches = matchingTasks.filter(
          (taskNum) =>
            props.action !== "update" ||
            taskNum !== taskQuery.data?.task?.legacy_template_id,
        );
        if (matches.length > 0) {
          errors.legacyTemplateId = `Found tasks with identical field options: ${matchingTasks.join(
            ", ",
          )}`;
        }
      }}
      onValueChange={(values) => {
        const hashContents = (values.fieldOptions ?? [])
          .filter((fo) => !!fo.option?.field_option_id)
          .map((fo) => fo.option!.field_option_id)
          .sort()
          .reverse()
          .join(";");
        const md5 = createHash("md5").update(hashContents).digest("hex");
        if (md5 !== currentHash) {
          setCurrentHash(md5);
          setUsedFields(
            (values.fieldOptions ?? []).map((fo) => ({
              fieldId: fo.field.id,
              categoryId: fo.category.id,
              contextId: fo.context.id,
              fieldOptionId: fo.option?.field_option_id ?? "",
            })),
          );
        }
      }}
      onInsert={upsert}
      onUpdate={upsert}
      render={({ path, formik }) => (
        <>
          <FormHeader>
            {props.task ? "Update Task" : "Create New Task"}
          </FormHeader>
          <Typography
            variant="body1"
            color="error"
            style={{ margin: 15, marginLeft: 23 }}
          >
            Please be sure to include a "polarity" field from appropriate
            "gesture" context!
          </Typography>
          <Tablature
            useUrlParams={false}
            tabs={[
              {
                name: "general",
                label: <TabLabel label="General" icon={<Info />} />,
                content: (
                  <FormContainer>
                    <Grid item xs={12}>
                      <FormControlLabel
                        disabled={disableFieldOptionEdit}
                        control={
                          <Checkbox
                            onChange={(event) => {
                              formik.setFieldValue(
                                path.is_template._,
                                event.target.checked,
                              );
                            }}
                            checked={formik.values.is_template}
                          />
                        }
                        label={
                          <>
                            This is a Template
                            <InfoTooltip
                              size="small"
                              content={
                                <Typography>
                                  A template is only used to create other tasks.
                                  It cannot not be added to a collection or
                                  benchmarking plan.
                                </Typography>
                              }
                            />
                          </>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) => {
                              formik.setFieldValue(
                                path.is_test._,
                                event.target.checked,
                              );
                            }}
                            checked={formik.values.is_test}
                          />
                        }
                        label={
                          <>
                            This is a Benchmarking Task
                            <InfoTooltip
                              size="small"
                              content={
                                <Typography>
                                  A benchmarking task cannot be used for other
                                  data collection plans.
                                </Typography>
                              }
                            />
                          </>
                        }
                      />
                    </Grid>
                    <TextField
                      bp={{ xs: 12 }}
                      name={path.note!._!}
                      multiline
                      label="Note"
                      tooltip="A custom note is optional. It should only be used to hold information that cannot otherwise be encoded in fields and options."
                    />
                  </FormContainer>
                ),
              },
              {
                name: "fieldoptions",
                label: (
                  <TabLabel label="Fields/Options" icon={<ListAltOutlined />} />
                ),
                disabled: disableFieldOptionEdit,
                content: (
                  <FormContainer>
                    <DrawerButton
                      bp={{ xs: 12 }}
                      color="primary"
                      variant="contained"
                      disableElevation
                      label="Clone Existing Task"
                      content={(close) => (
                        <TaskToCloneTable
                          onSelect={(item) => {
                            if (item.id?.length) {
                              setTaskToCloneLoading(true);
                              taskToCloneQuery({
                                variables: {
                                  id: item.id,
                                  order_field_options_by:
                                    OrderTaskFieldOptionsBy,
                                },
                              });
                            }
                            close();
                          }}
                        />
                      )}
                    />
                    <FieldArray
                      name={path.fieldOptions!._}
                      render={(helper) => {
                        return (
                          <>
                            <Grid item sm={6} xs={12}>
                              <Autocomplete
                                options={categories?.data?.categories ?? []}
                                getOptionLabel={(category) => category.name}
                                getOptionSelected={(o, v) => o.id === v.id}
                                disableClearable
                                onChange={({ target, ...e }, nv) => {
                                  setCategory(nv ?? null);
                                  setContext(null);
                                  setField(null);
                                }}
                                renderInput={(params) => (
                                  <MUITextField
                                    {...params}
                                    label="Category"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <Autocomplete
                                key={category?.id ?? ""}
                                options={contexts?.data?.contexts ?? []}
                                getOptionLabel={(context) => context.name}
                                getOptionSelected={(o, v) => o.id === v.id}
                                disableClearable
                                // Use `inputValue` to ensure that display is cleared
                                // when category is changed
                                inputValue={context?.name ?? ""}
                                onChange={(_, nv) => {
                                  setContext(nv ?? null);
                                  setField(null);
                                }}
                                renderInput={(params) => (
                                  <MUITextField
                                    {...params}
                                    label="Context"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item sm={10} xs={12}>
                              <Autocomplete
                                // Let react know the component has changed
                                // after a field is added.
                                key={formik.values.fieldOptions
                                  ?.map((fo) => fo.field.id)
                                  .join(";")}
                                options={fields?.data?.fields ?? []}
                                getOptionLabel={(field) => field.display}
                                getOptionSelected={(o, v) => o.id === v.id}
                                disableClearable
                                inputValue={field?.display ?? ""}
                                onChange={(_, nv) => {
                                  setField(nv ?? null);
                                }}
                                renderInput={(params) => (
                                  <MUITextField
                                    {...params}
                                    label="Field"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Fab
                                disabled={
                                  !(context?.id && category?.id && field?.id)
                                }
                                color="primary"
                                variant="extended"
                                onClick={() => {
                                  helper.insert(0, {
                                    key: +new Date(),
                                    highlight: false,
                                    category: {
                                      id: category?.id ?? "",
                                      name: category?.name ?? "",
                                    },
                                    context: {
                                      id: context?.id ?? "",
                                      name: context?.name ?? "",
                                    },
                                    field: {
                                      id: field?.id ?? "",
                                      type_id: field?.type_id ?? "",
                                      name: field?.display ?? "",
                                    },
                                    option: null,
                                  });
                                  setField(null);
                                  // UPDATE LIST OF USED FIELDS?
                                }}
                              >
                                <Add />
                                Field
                              </Fab>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            {formik.values.fieldOptions!.map((fo, index) => (
                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                key={fo.key}
                              >
                                <FieldOptionSelector
                                  label={`${fo.field.name} (${fo.category.name}/${fo.context.name})`}
                                  fieldId={fo.field.id}
                                  optionPath={
                                    path.fieldOptions![index].option._
                                  }
                                />
                                <Grid item xs={2}>
                                  <FormControlLabel
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        path.fieldOptions![index].highlight._,
                                        (e.target as any).checked,
                                      )
                                    }
                                    control={
                                      <Switch
                                        name={
                                          path.fieldOptions![index].highlight._
                                        }
                                        checked={
                                          formik.values.fieldOptions![index]
                                            .highlight
                                        }
                                      />
                                    }
                                    labelPlacement="bottom"
                                    label="Highlight"
                                  />
                                </Grid>
                                <Grid container item xs={1}>
                                  <Grid item xs={1}>
                                    <IconButton
                                      onClick={() => helper.remove(index)}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </>
                        );
                      }}
                    />
                  </FormContainer>
                ),
              },
              {
                name: "media",
                label: <TabLabel label="Media" icon={<Subscriptions />} />,
                content: (
                  <>
                    <FormContainer>
                      <Grid item xs={12}>
                        <MediaFileUploadField
                          onUpdate={(files) => {
                            if (files.length !== 0) {
                              formik.setValues({
                                ...formik.values,
                                task_media: [
                                  ...(formik.values.task_media ?? []),
                                  ...files
                                    .filter(
                                      (f) =>
                                        formik.values.task_media?.find(
                                          (m) => m.id === f.id,
                                        ) == null,
                                    )
                                    .map((f) => ({
                                      id: f.id ?? "",
                                      url: f.url ?? "",
                                      title: f.file.name,
                                      description: "",
                                      type: fileTypeStringToEnum(f.file.type),
                                    })),
                                ],
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <FieldArray
                        name={path.task_media!._}
                        render={(helper) =>
                          formik.values.task_media!.map((media, index) => (
                            <Grid
                              container
                              item
                              xs={12}
                              spacing={2}
                              key={index}
                            >
                              <TextField
                                bp={{ xs: 12, sm: 4 }}
                                multiline
                                name={path.task_media![index].title._}
                                label="Title"
                              />
                              <TextField
                                bp={{ xs: 12, sm: 5 }}
                                multiline
                                name={
                                  path.task_media![index].description?._ ??
                                  "description"
                                }
                                label="Description"
                              />
                              <Grid container item xs={2}>
                                <Grid item sm={2} xs={2}>
                                  <Button
                                    style={{
                                      maxHeight: "54px",
                                      minHeight: "54px",
                                      borderRadius: 6,
                                    }}
                                    startIcon={<OndemandVideo />}
                                    key={index}
                                    title={`${media?.title}\n${media?.url}`}
                                    target="_blank"
                                    href={media?.url ?? ""}
                                  >
                                    {media.type}
                                  </Button>
                                </Grid>
                              </Grid>
                              <Grid container item xs={1}>
                                <Grid item xs={1}>
                                  <IconButton
                                    onClick={() => helper.remove(index)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))
                        }
                      />
                    </FormContainer>
                    <FormContainer>
                      <FieldArray
                        name={path.task_media!._}
                        render={(helper) => {
                          let mediaIdsToExclude: string[] =
                            formik!.values!.task_media!.map(
                              (media) => media!.id!,
                            ) ?? [];
                          return (
                            <>
                              <Grid item xs={12}>
                                <DrawerButton
                                  bp={{ xs: 3 }}
                                  variant="contained"
                                  color="primary"
                                  label="Add existing media"
                                  tooltip="Add existing media"
                                  startIcon={<Attachment />}
                                  disableElevation
                                  fullWidth={true}
                                  content={(close) => (
                                    <AllMediaTable
                                      selectable="single"
                                      where={[
                                        {
                                          id: {
                                            _nin: mediaIdsToExclude,
                                          },
                                        },
                                      ]}
                                      onSelect={(m) => {
                                        helper.insert(0, m);
                                        close();
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          );
                        }}
                      />
                    </FormContainer>
                  </>
                ),
              },
            ]}
          />
        </>
      )}
    />
  );
};

type FieldOptionSelectorProps = {
  label: string;
  fieldId: string;
  optionPath: string;
};

const FieldOptionSelector = (props: FieldOptionSelectorProps) => {
  const fieldOptions = useAllFieldOptionsQuery({
    fetchPolicy: "network-only",
    variables: {
      where: { field_id: { _eq: props.fieldId } },
    },
    skip: !props.fieldId,
  });
  return (
    <MultiSelect
      bp={{ sm: 9, xs: 12 }}
      name={props.optionPath}
      label={props.label}
      options={fieldOptions.data?.fo.map((f) => ({
        field_option_id: f.id,
        id: f.option?.id,
        name: f.option?.display,
      }))}
      getOptionLabel={(f) => f.name ?? "unknown"}
      getOptionSelected={(f, s) => f.id === s.id}
      multiple={false}
    />
  );
};

type TaskToCloneTableProps = {
  onSelect: (item: DeepPartial<Task>) => void;
};

const TaskToCloneTable = (props: TaskToCloneTableProps) => {
  // const [isolateTemplates, setIsolateTemplates] = useState(true);
  return (
    <AllTasksTable
      selectable="single"
      onSelect={(item) => props.onSelect(item)}
      enableIsolateTemplates={true}
      isolateTemplatesDefault={true}
    />
  );
};

// Translations of field displays and descriptions.
type TaskTranslationTableProps = {
  task: { id: string; note?: string };
};

const TaskTranslationTable = (props: TaskTranslationTableProps) => {
  const fieldTranslations = useTaskTranslationsSubscription({
    variables: { id: props.task.id },
  });
  const [upsertTranslation] = useUpsertTaskTranslationMutation({});
  return (
    <TranslationTable
      data={fieldTranslations.data?.translations ?? []}
      schema={Yup.object({ note: Yup.string().required() }).required()}
      getValues={(data) => ({ note: data.note ?? "" })}
      initialValues={{ note: "" }}
      getLanguage={(data) => data.language}
      labels={{ note: "Note" }}
      columns={(data) => ({ note: data.note ?? "" })}
      onUpdate={(data, values) =>
        upsertTranslation({
          variables: {
            object: {
              task_id: props.task.id,
              language: data.language.id,
              note: values.note,
            },
          },
        })
      }
      onCreate={(language, values) =>
        upsertTranslation({
          variables: {
            object: {
              task_id: props.task.id,
              language: language,
              ...values,
            },
          },
        })
      }
    />
  );
};

// Detailed content for single task "show" page.
type TaskShowProps = {
  id: string;
  onEditAction: (item: DeepPartial<Task>) => void;
};

const TaskShow = (props: TaskShowProps) => {
  const ag = useRequireRole([Roles_Enum.Asgard]);
  const taskNav = useTaskNav();
  const planNav = usePlanNav();
  const projectNav = useProjectNav();
  const [updateTask] = useUpdateTaskMutation();
  const taskQuery = useTaskSimpleQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const task = taskQuery.data?.task;
  if (!task) return null;

  return (
    <ShowResourceView
      title={`${task.legacy_template_id ?? ""}`}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb
            label="tasks"
            onClick={ag ? () => taskNav.list() : undefined}
          />
          <Breadcrumb label={`${task.legacy_template_id}`} />
        </Breadcrumbs>
      }
      onEditAction={
        ag ? () => props.onEditAction && props.onEditAction(task) : undefined
      }
      archivedAt={task.deleted_at}
      onArchiveAction={
        ag
          ? () => {
              updateTask({
                variables: {
                  id: props.id,
                  input: { deleted_at: new Date().toISOString() },
                },
              });
            }
          : undefined
      }
      onUnarchiveAction={
        ag
          ? () => {
              updateTask({
                variables: {
                  id: props.id,
                  input: { deleted_at: null },
                },
              });
            }
          : undefined
      }
      properties={[
        { label: "Highlights", value: task.description ?? "" },
        { label: "Note", value: task.note ?? "" },
        { label: "Is Benchmark Task?", value: task.is_test ? "Yes" : "No" },
      ]}
    >
      <Tablature
        useUrlParams
        tabs={[
          {
            name: "Instruction",
            label: (
              <TabLabel label="Instructions" icon={<SubtitlesOutlined />} />
            ),
            content: <TaskInstructionCard taskId={props.id} />,
            hide: !ag,
          },
          {
            name: "digest",
            label: <TabLabel label="Digest" icon={<AssignmentOutlined />} />,
            content: (
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <TaskFieldOptions
                    taskId={props.id}
                    enableContextLinks={ag}
                    enableFieldTooltips
                  />
                </CardContent>
              </Card>
            ),
          },
          {
            name: "fieldoptions",
            label: <TabLabel label="Fields/Options" icon={<ListOutlined />} />,
            content: (
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <AllTaskFieldOptionsTable
                    selectable="none"
                    taskId={task.id}
                    hideColumns={ag ? undefined : { highlight: true }}
                  />
                </CardContent>
              </Card>
            ),
          },
          {
            name: "media",
            label: <TabLabel label="Media" icon={<Subscriptions />} />,
            content: (
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <AllMediaTable
                    selectable="none"
                    where={[{ task_media: { task_id: { _eq: task.id } } }]}
                  />
                </CardContent>
              </Card>
            ),
          },
          {
            name: "translations",
            label: (
              <TabLabel label="Translations" icon={<TranslateOutlined />} />
            ),
            content: (
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <TaskTranslationTable
                    task={{ id: props.id, note: task.note ?? undefined }}
                  />
                </CardContent>
              </Card>
            ),
          },
          {
            name: "plans",
            hide: !ag,
            label: <TabLabel label="Plans" icon={<ListAltOutlined />} />,
            content: (
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <AllPlansTable
                    selectable="none"
                    showUrl={(item) => item?.id && planNav.showUrl(item.id)}
                    where={[
                      {
                        plan_tasks: {
                          task_id: { _eq: task.id },
                        },
                      },
                    ]}
                  />
                </CardContent>
              </Card>
            ),
          },
          {
            name: "projects",
            label: <TabLabel label="Projects" icon={<MoveToInbox />} />,
            content: (
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <AllProjectsTable
                    selectable="none"
                    showUrl={(item) => item?.id && projectNav.showUrl(item.id)}
                    where={[{ project_tasks: { task_id: { _eq: task.id } } }]}
                  />
                </CardContent>
              </Card>
            ),
          },
        ]}
      />
    </ShowResourceView>
  );
};

// Create a reusable element for displaying the field-option pairs of a task,
// using "context groups".
export type TaskFieldOptionsProps = {
  taskId: string;
  enableContextLinks?: boolean;
  enableFieldTooltips?: boolean;
  categoryTypographyProps?: TypographyProps;
  displayGroupTypographyProps?: TypographyProps;
  optionTypographyProps?: TypographyProps;
};

export const TaskFieldOptions = (props: TaskFieldOptionsProps) => {
  const categoryTypographyProps: TypographyProps =
    props.categoryTypographyProps ?? { variant: "h6" };
  const displayGroupTypographyProps: TypographyProps =
    props.displayGroupTypographyProps ?? { variant: "caption" };
  const optionTypographyProps: TypographyProps =
    props.optionTypographyProps ?? { variant: "body2" };

  const contextNav = useContextNav();
  const { data } = useTaskOptionDisplaySubscription({
    variables: { id: props.taskId },
    fetchPolicy: "network-only",
  });
  const categories = data?.categories ?? [];
  return (
    <>
      {categories?.map((category) => (
        <Fragment key={category.id}>
          {category.contexts?.map((context) => (
            <List
              dense
              key={context.id}
              subheader={
                <ListSubheader>
                  <div style={{ display: "flex", gap: 10 }}>
                    <Typography {...categoryTypographyProps}>
                      {category.name} / {context.name ?? ""}
                    </Typography>
                    {props.enableContextLinks && (
                      <IconButton
                        size="small"
                        href={contextNav.showUrl(context.id)}
                      >
                        <Visibility />
                      </IconButton>
                    )}
                  </div>
                </ListSubheader>
              }
            >
              {context.context_displays?.map((cd, i) => {
                const fieldOptions: {
                  field: string;
                  option: string;
                }[] = cd.context_display_fields?.map((cdf) => ({
                  field: cdf.field?.display ?? "",
                  option: cdf.field?.field_options[0]?.option?.display ?? "–",
                }));
                return (
                  <ListItem style={{ paddingLeft: 30 }} key={i}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography {...displayGroupTypographyProps}>
                          {cd.name}
                        </Typography>
                      }
                      secondary={
                        <MUIBreadcrumbs>
                          {fieldOptions.map(({ field, option }) => {
                            const component = (
                              <Typography {...optionTypographyProps}>
                                {option}
                              </Typography>
                            );
                            return props.enableFieldTooltips ? (
                              <Tooltip title={field} key={`${field}${option}`}>
                                {component}
                              </Tooltip>
                            ) : (
                              component
                            );
                          })}
                        </MUIBreadcrumbs>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          ))}
        </Fragment>
      ))}
    </>
  );
};

/**
 * 2023/08/8: Created new "multiple" task form, which needs more rigorous
 * testing, and does not yet support the "edit" action, so both forms are still
 * in use. The "multiple" task form will render if the query params include
 * `?multiple=true`.
 *
 * Once the "multiple" task form supports "edit" action, the old form can be
 * removed, and the query params will be unnecessary.
 */
const TaskCreate = (props: { onSuccess: () => void }) => {
  const loc = useLocation();
  const query = qs.parse(loc.search);
  return query.multiple === "true" ? (
    <MultiTaskForm {...props} />
  ) : (
    <TaskForm {...props} action={"insert"} />
  );
};

export const useTaskNav = () => useResourceNav("tasks");
export const TaskResource = () => {
  return (
    <Resource
      path="tasks"
      list={(nav) => (
        <TaskIndex
          onAddNew={() => nav.create()}
          onAddNewMultiple={() => nav.create({ multiple: "true" })}
          editUrl={(item) => item.id && nav.editUrl(item.id)}
          showUrl={(item) => item.id && nav.showUrl(item.id)}
        />
      )}
      show={(nav, id) => (
        <TaskShow
          id={id ?? ""}
          onEditAction={(item) => item.id && nav.edit(item.id)}
        />
      )}
      create={(nav) => <TaskCreate onSuccess={() => nav.list()} />}
      edit={(nav, id) => (
        <TaskForm
          action="update"
          onSuccess={(id) => nav.show(id)}
          task={{ id: id ?? "" }}
        />
      )}
    />
  );
};
