import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import CopyToClipboard from "react-copy-to-clipboard";
import { Typography } from "@material-ui/core";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type EllipsisFieldProps = {
  value?: string;
};

export const EllipsisColumn = ({ value }: EllipsisFieldProps) => {
  const [copied, setCopied] = useState(false);

  if (value === undefined) {
    return <Typography variant="caption">unknown</Typography>;
  }

  return (
    <>
      <Tooltip title={value} placement="top">
        <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
          <Button color="primary" style={{ fontFamily: "monospace" }}>
            {value.slice(0, 3)}..{value.slice(-3)}
          </Button>
        </CopyToClipboard>
      </Tooltip>
      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
      >
        <Alert severity="success">Successfully copied to clipboard</Alert>
      </Snackbar>
    </>
  );
};
