import { Avatar, AvatarProps } from "@material-ui/core";
import * as crypto from "crypto";
import React from "react";

type GravatarProps = {
  email: string;
} & AvatarProps;

export function Gravatar({ email, ...props }: GravatarProps) {
  const md5 = crypto
    .createHash("md5")
    .update(email)
    .digest("hex");

  return (
    <Avatar
      src={`https://www.gravatar.com/avatar/${md5}?d=identicon`}
      alt={email}
      {...props}
    />
  );
}
