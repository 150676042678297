import React from "react";
import { Button, Card, Typography } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { Resource, useResourceNav } from "src/components/Resource";
import { TableOptionals } from "src/components/table";
import { TableView, TableViewHeader } from "src/Layout";
import { ProjectOverview } from "./overview";
import { ProjectList } from "./list";
import { ProjectCompare } from "./compare";
import { ProjectForm } from "./ProjectForm";
import { AllProjectsTable, ProjectTable } from "./table";

export * from "./table";

type ProjectIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof ProjectTable>;

export const ProjectIndex = (props: ProjectIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Projects</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New project
        </Button>
      </TableViewHeader>
      <Card>
        <AllProjectsTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

export const useProjectNav = () => useResourceNav("projects");
export const ProjectResource = () => (
  <Resource
    path="projects"
    list={() => <ProjectList />}
    show={(_, id) => <ProjectOverview id={id ?? ""} />}
    create={(nav) => (
      <ProjectForm action="insert" onSuccess={(id) => id && nav.show(id)} />
    )}
    edit={(nav, projectID) => (
      <ProjectForm
        action="update"
        onSuccess={(id) => id && nav.show(id)}
        project={{ id: projectID ?? "" }}
      />
    )}
    compare={(nav, ids) => <ProjectCompare ids={ids ?? []} />}
  />
);
