import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ClientAuthError } from "msal";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AzureAD, IAzureADFunctionProps } from "react-aad-msal";
import config from "src/config";
import { Roles_Enum } from "src/generated/asgard/graphql";
import createPersistedState from "use-persisted-state";
import * as Yup from "yup";
import { azureProvider } from "./azureProvider";

type AuthHeader = {
  authorization?: string;
  "x-hasura-admin-secret"?: string;
  "x-hasura-role"?: string;
  "x-hasura-user-id"?: string;
};

type Provider = {
  header: () => Promise<AuthHeader | undefined>;
};

export enum AuthenticationState {
  Unauthenticated = "Unauthenticated",
  InProgress = "InProgress",
  Authenticated = "Authenticated",
}

type AuthContext = {
  user: {
    id: string;
    email?: string | null;
    name?: string | null;
    roles: Roles_Enum[];
  };
  login: () => void;
  logout: () => void;
  status: AuthenticationState;
};

export const useRequireRole = (roles?: Roles_Enum[]) => {
  const auth = useAuth();

  if (roles && !auth.user.roles.every((r) => roles?.includes(r))) {
    return false;
  }

  return true;
};

type RequireRoleProps = {
  roles?: Roles_Enum[];
  children?: React.ReactNode | React.ReactNode[];
};

export const RequireRole = (props: RequireRoleProps) => {
  const access = useRequireRole(props.roles);
  if (access === false) {
    return <></>;
  }

  return <>{props.children ?? null}</>;
};

const authContext = createContext<AuthContext | null>(null);

const AuthContextProvider = authContext.Provider;
export const AuthContextConsumer = authContext.Consumer;

/**
 * useAuth returnes the AuthContext object. It will crash if used outside of
 * the AuthContextProvider!
 */
export const useAuth = (): AuthContext => {
  return useContext(authContext)!;
};

let local_header: AuthHeader = {};

export const authState: Provider = {
  header: async () => {
    const p = config.auth.provider;
    switch (p) {
      case "azure": {
        if (azureProvider === null) {
          return {};
        }

        const rawToken = await (async () => {
          try {
            const token = await azureProvider.getIdToken();
            return token.idToken.rawIdToken;
          } catch (e) {
            if (e instanceof ClientAuthError) {
              console.log("========== msal error:", e);
            } else {
              azureProvider.logout();
            }
          }
          return null;
        })();

        if (rawToken === null) {
          return;
        }

        const headers: AuthHeader = {
          authorization: `Bearer ${rawToken}`,
        };

        if (config.hasura.admin_secret !== undefined) {
          headers["x-hasura-admin-secret"] = config.hasura.admin_secret;
        }

        return headers;
      }
      case "local": {
        const headers: AuthHeader = {
          "x-hasura-admin-secret": config.hasura.admin_secret,
          ...local_header,
        };

        return headers;
      }
    }
  },
};

type AuthProvderProps = {
  children?: JSX.Element;
  login: (login: () => void) => React.ReactNode;
};

export const AuthProvder = (props: AuthProvderProps) => {
  const p = config.auth.provider;

  switch (p) {
    case "azure":
      if (azureProvider === null) {
        return null;
      }
      return (
        <AzureAD provider={azureProvider}>
          {(azprops: IAzureADFunctionProps) => {
            if (
              azprops.authenticationState !== AuthenticationState.Authenticated
            ) {
              return props.login(azprops.login);
            }
            return <AzureAdapter {...azprops}>{props.children}</AzureAdapter>;
          }}
        </AzureAD>
      );
    case "local":
      return <LocalAdapter>{props.children}</LocalAdapter>;
    default:
      return <div>unkown auth provider: {p}</div>;
  }
};

const LocalUserSchema = Yup.object({
  "https://hasura.io/jwt/claims": Yup.object({
    "x-hasura-user-id": Yup.string().required(),
    "x-hasura-allowed-roles": Yup.array()
      .of(Yup.string().oneOf(Object.values(Roles_Enum)).required())
      .required(),
  }).required(),
}).required();

const useLogedinState = createPersistedState("logedin");
const useLogedinRole = createPersistedState("logedin_role");

const LocalAdapter = (props: { children?: JSX.Element }) => {
  const [user, setUser] = useState<object | null>(null);
  const [role, setRole] = useLogedinRole<Roles_Enum | null>(null);
  const [logedin, setLogedin] = useLogedinState(false);
  const [authContext, setAuthContext] = useState<null | AuthContext>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (role === null) return;

    let u = (() => {
      switch (role) {
        case Roles_Enum.Asgard:
          return config.hasura.asgard;
        case Roles_Enum.Midgard:
          return config.hasura.midgard;
      }
    })();

    if (u == null) {
      return;
    }

    local_header = {
      "x-hasura-role": u.role,
      "x-hasura-user-id": u.id,
    };

    setUser({
      id: config.hasura.asgard.id,
      name: "Local",
      "https://hasura.io/jwt/claims": {
        "x-hasura-user-id": u.id,
        "x-hasura-role": u.role,
        "x-hasura-allowed-roles": [u.role],
      },
    });
  }, [role]);

  useEffect(() => {
    if (user === null) return;

    try {
      LocalUserSchema.validateSync(user);
    } catch (e) {
      setError(JSON.stringify(e, null, "  "));
    }

    if (!LocalUserSchema.isValidSync(user)) return;

    const claims = user["https://hasura.io/jwt/claims"];
    setAuthContext({
      user: {
        id: claims["x-hasura-user-id"],
        roles: claims["x-hasura-allowed-roles"],
      },
      login: () => setLogedin(true),
      logout: () => {
        setLogedin(false);
        setRole(null);
      },
      status: AuthenticationState.Authenticated,
    });
  }, [setLogedin, user, setRole]);

  if (error != null) {
    return <pre>{error}</pre>;
  }

  if (logedin === false) {
    return (
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={6}>
          <Card style={{ width: "100%", padding: 20 }}>
            <Grid xs={12} item>
              <FormControl style={{ width: "100%" }}>
                <InputLabel>Role</InputLabel>
                <Select
                  value={role}
                  variant="outlined"
                  onChange={(e) => setRole(e.target.value as Roles_Enum)}
                >
                  <MenuItem value={Roles_Enum.Asgard}>
                    {Roles_Enum.Asgard}
                  </MenuItem>
                  <MenuItem value={Roles_Enum.Midgard}>
                    {Roles_Enum.Midgard}
                  </MenuItem>
                  <MenuItem disabled value={Roles_Enum.Bifrost}>
                    {Roles_Enum.Bifrost}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} item>
              <Button
                variant="outlined"
                disabled={authContext === null}
                onClick={() => setLogedin(true)}
              >
                Login
              </Button>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <AuthContextProvider value={authContext}>
      {authContext !== null ? props.children : null}
    </AuthContextProvider>
  );
};

const AzureUserSchema = Yup.object({
  oid: Yup.string().required(),
  name: Yup.string().required(),
  roles: Yup.array().of(
    Yup.string().oneOf(Object.values(Roles_Enum)).required(),
  ),
  preferred_username: Yup.string().email().required(),
}).required();

const AzureAdapter = (
  props: IAzureADFunctionProps & { children?: JSX.Element },
) => {
  const { accountInfo, login, logout, authenticationState } = props;
  const [authContext, setAuthContext] = useState<AuthContext | null>(null);
  const [error, setError] = useState<string | null>(null);

  const p = config.auth.provider;

  useEffect(() => {
    const idToken = accountInfo?.account?.idToken;
    try {
      AzureUserSchema.validateSync(idToken);
    } catch (e) {
      setError(JSON.stringify(e, null, "  "));
    }

    if (!AzureUserSchema.isValidSync(idToken)) return;
    setAuthContext({
      user: {
        // TODO(jonas) This default role must be updated if default changes in hasura
        roles: idToken.roles ?? [Roles_Enum.Midgard],
        id: idToken.oid,
        name: idToken.name,
        email: idToken.preferred_username,
      },
      login,
      logout,
      status: authenticationState,
    });
  }, [accountInfo, authenticationState, login, logout, p]);

  if (error != null) {
    return <pre>{error}</pre>;
  }

  return (
    <AuthContextProvider value={authContext}>
      {authContext !== null ? props.children : null}
    </AuthContextProvider>
  );
};
