import React, { useState } from "react";
import { Button, IconButton, Drawer } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const DialogColumn = (props: {
  label: string;
  content: JSX.Element;
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button color="primary" onClick={() => setShow(true)}>
        {props.label}
      </Button>
      <Drawer anchor={"right"} open={show} onClose={() => setShow(false)}>
        <div style={{ margin: 10, maxWidth: "1000px" }}>
          <IconButton size="small" onClick={() => setShow(false)}>
            <CloseIcon />
          </IconButton>
          {props.content}
        </div>
      </Drawer>
    </>
  );
};
