import { Chip, Dialog, Drawer, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { Fragment, useState } from "react";

type ChipFieldProps<T> = {
  chips: T[] | undefined;
  onClick?: (chip: T) => void;
  show?: (chip: T) => React.ReactNode;
  showType?: "dialog" | "drawer";
};

export const ChipColumn = <T extends { label: string; show?: React.ReactNode }>(
  props: ChipFieldProps<T>,
) => {
  // Use <Dialog/> component by default for chip content.
  const showType = props.showType || "dialog";
  const [show, setShow] = useState<{ [index: number]: boolean }>([]);
  if (props.chips === undefined) return null;
  return (
    <>
      {props.chips.map((chip, index) => {
        const open = () => setShow((s) => ({ ...s, [index]: true }));
        const close = () => setShow((s) => ({ ...s, [index]: false }));
        return (
          <Fragment key={index}>
            <Chip
              style={{ margin: 3 }}
              variant="outlined"
              label={chip.label}
              {...(chip.show && {
                onClick: open,
              })}
            />
            {showType === "dialog" && (
              <Dialog fullWidth={true} onClose={close} open={!!show[index]}>
                <div style={{ margin: 10 }}>
                  <IconButton size="small" onClick={close}>
                    <Close />
                  </IconButton>
                  {chip.show}
                </div>
              </Dialog>
            )}
            {showType === "drawer" && (
              <Drawer anchor={"right"} open={!!show[index]} onClose={close}>
                <div style={{ margin: 10 }}>
                  <IconButton size="small" onClick={close}>
                    <Close />
                  </IconButton>
                  {chip.show}
                </div>
              </Drawer>
            )}
          </Fragment>
        );
      })}
    </>
  );
};
