import React, { useCallback, useEffect, useState } from "react";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import { Grid, makeStyles } from "@material-ui/core";
import { CloudUpload, CloudUploadOutlined } from "@material-ui/icons";
import {
  MediaFileUploadWithProgress,
  MediaResponseProps,
} from "./MediaFileUploadWithProgress";
import { UploadError } from "./UploadError";
import config from "src/config";

export interface MediaFile {
  id?: string;
  url?: string;
  file: File;
  errors: FileError[];
  key: string;
}

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background.default,
    height: theme.spacing(15),
    outline: "none",
    textAlign: "center",
    marginBottom: "15px",
    paddingTop: "20px",
  },
}));

type MediaFileUploadFieldProp = {
  onUpdate: (F: MediaFile[]) => void;
};

function generateFileKey(file: File): string {
  return `${file.name}${file.lastModified}`;
}

export function MediaFileUploadField({ onUpdate }: MediaFileUploadFieldProp) {
  const classes = useStyles();
  const [files, setFiles] = useState<MediaFile[]>([]);
  const onDrop = useCallback(
    (accFiles: File[], rejFiles: FileRejection[]) => {
      const mappedAcc = accFiles
        .filter((file) => !files.some((fw) => fw.key === generateFileKey(file)))
        .map((file) => ({
          file,
          errors: [],
          key: generateFileKey(file),
        }));
      const mappedRej = rejFiles.map((r) => ({
        ...r,
        key: generateFileKey(r.file),
      }));
      setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
    },
    [files],
  );

  const onUpload = useCallback(
    (file: File, response: MediaResponseProps, onComplete: () => void) => {
      setFiles((curr) =>
        curr.map((fw) => {
          if (fw.file === file) {
            return {
              ...fw,
              id: response.id,
              url: response.url,
            };
          }
          return fw;
        }),
      );
      onComplete();
    },
    [],
  );

  const onClose = useCallback((file: File) => {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
  }, []);

  useEffect(() => {
    onUpdate(files.filter((m) => m.id !== undefined && m.url !== undefined));
  }, [onUpdate, files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": config.media_upload.accepted.images,
      "video/*": config.media_upload.accepted.videos,
    },
    maxSize: config.media_upload.max_size,
    maxFiles: config.media_upload.max_files,
  });

  return (
    <React.Fragment>
      <Grid item>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <em>
            {isDragActive ? (
              <CloudUpload fontSize="large" />
            ) : (
              <CloudUploadOutlined fontSize="large" />
            )}
          </em>
          <p>Drag 'n' drop files here, or click to select files</p>
        </div>
      </Grid>
      {files.map((fileWrapper) => {
        return (
          <Grid item key={fileWrapper.key}>
            {fileWrapper.errors.length ? (
              <UploadError
                file={fileWrapper.file}
                errors={fileWrapper.errors}
                onClose={onClose}
              />
            ) : (
              <MediaFileUploadWithProgress
                onClose={onClose}
                onUpload={onUpload}
                file={fileWrapper.file}
                doUpload={!fileWrapper.id}
              />
            )}
          </Grid>
        );
      })}
    </React.Fragment>
  );
}
