import React, { useState, useMemo } from "react";
import { Button, Card, Divider, Grid, Typography } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { SearchBar } from "src/components/filters";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  InfoDisplay,
  ShowResourceView,
  Tablature,
  TableView,
  TableViewHeader,
} from "src/Layout";
import {
  Hypertasks as Hypertask,
  Hypertasks_Bool_Exp,
  useAllHypertasksQuery,
  useHypertaskQuery,
  Order_By,
} from "src/generated/asgard/graphql";
import { HypertaskForm } from "./HypertaskForm";
import { Link } from "react-router-dom";

// Config table columns from Hypertask fields
export const HypertaskTable = createTable<Hypertask>()({
  keys: (hypertask) => hypertask.id ?? "",
  title: "Hypertask",
  headers: {
    id: { display: "ID" },
    number: { display: "Number" },
    description: { display: "Description" },
    num_recordings: { display: "Total Recordings" },
  },
  columns: (hypertask) => ({
    id: <EllipsisColumn value={hypertask.id} />,
    number: <TextColumn value={hypertask.number} />,
    description: <TextColumn value={hypertask.description} />,
    num_recordings: (
      <TextColumn value={hypertask.recordings_aggregate?.aggregate?.count} />
    ),
  }),
});

// Define a new table component for Hypertasks
type AllHypertasksTableProps = TableOptionals<typeof HypertaskTable> & {
  where?: Hypertasks_Bool_Exp[];
};

export const AllHypertasksTable = (props: AllHypertasksTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Hypertasks_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else if (!isNaN(parseInt(search))) {
    searchFilters.push({ number: { _eq: parseInt(search) } });
  } else {
    const term = like(search);
    searchFilters.push({ description: { _ilike: term } });
  }
  const { data } = useAllHypertasksQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      order_by: { number: Order_By.Desc },
    },
  });

  return (
    <HypertaskTable
      {...props}
      {...pageController}
      total={data?.hypertasks_aggregate?.aggregate?.count}
      data={data?.hypertasks}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main Hypertask resource page
type HypertaskIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof HypertaskTable>;

export const HypertaskIndex = (props: HypertaskIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Hypertasks</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Hypertask
        </Button>
      </TableViewHeader>
      <Card>
        <AllHypertasksTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Create a detailed 'show' page.
type HypertaskShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Hypertask>) => void;
};

const HypertaskShow = (props: HypertaskShowProps) => {
  const hypertaskNav = useHypertaskNav();

  const hypertaskQuery = useHypertaskQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const hypertask = hypertaskQuery.data?.hypertask;

  type Option = {
    id: string;
    display: string;
  };
  type Field = {
    id: string;
    name: string;
    options: Option[];
  };
  const fields: Field[] = useMemo(() => {
    return (hypertask?.hypertask_field_options ?? []).reduce((agg, hfo) => {
      const option = {
        id: hfo.option.id,
        display: hfo.option.display,
      };
      const match = agg.find((group) => group.id === hfo.field.id);
      if (!!match) {
        match.options.push(option);
      } else {
        agg.push({
          id: hfo.field.id,
          name: hfo.field.name,
          options: [option],
        });
      }
      return agg;
    }, [] as Field[]);
  }, [hypertask]);

  const staticFields = fields.filter((f) => f.options.length === 1);
  const dynamicFields = fields.filter((f) => f.options.length > 1);

  const permutations: Option[][] = useMemo(() => {
    const product: Option[][] = [];
    if (dynamicFields.length < 1) return [];
    const max = dynamicFields.length - 1;
    function helper(arr: Option[], i: number) {
      const numFields = dynamicFields[i].options.length;
      for (var j = 0, l = numFields; j < l; j++) {
        var copy = [...arr.slice(0), dynamicFields[i].options[j]];
        if (i === max) product.push(copy);
        else helper(copy, i + 1);
      }
    }
    helper([], 0);
    return product;
  }, [dynamicFields]);

  if (!hypertask) return null;

  return (
    <ShowResourceView
      title={`${hypertask.number}`}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="hypertasks" onClick={() => hypertaskNav.list()} />
          <Breadcrumb label={hypertask.id ?? ""} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(hypertask)}
    >
      <Tablature
        useUrlParams
        tabs={[
          {
            name: "fieldoptions",
            label: "Field Options",
            content: (
              <Grid container spacing={2}>
                {staticFields.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6">Static Fields</Typography>
                      <Divider />
                    </Grid>
                    <InfoDisplay
                      bp={{ xs: 12 }}
                      items={staticFields.map((f) => ({
                        label: f.name,
                        value: f.options[0].display,
                      }))}
                    />
                  </>
                )}
                <Grid item xs={12}>
                  <Typography variant="h6">Dynamic Fields</Typography>
                  <Divider />
                </Grid>
                {permutations.map((p, i) => (
                  <React.Fragment key={i}>
                    <InfoDisplay
                      bp={{ xs: 12 }}
                      items={p.map((o, i) => ({
                        label: dynamicFields[i].name,
                        value: o.display,
                      }))}
                    />
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            ),
          },
        ]}
      />
    </ShowResourceView>
  );
};

// Make reusable link to hypertask "show" pages.
type HypertaskLinkProps = {
  id: string;
  number: number;
  openNewTab?: boolean;
};

export const HypertaskLink = ({
  id,
  number,
  openNewTab,
}: HypertaskLinkProps) => {
  const nav = useHypertaskNav();
  return openNewTab ? (
    <Button
      component={Link}
      to={nav.showUrl(id)}
      target="_blank"
      rel="noopener noreferrer"
      color="primary"
    >
      {number}
    </Button>
  ) : (
    <Button component={Link} to={nav.showUrl(id)} color="primary">
      {number}
    </Button>
  );
};

// Finally, combine into full resource UI.
const path = "hypertasks";
export const useHypertaskNav = () => useResourceNav(path);
export const HypertaskResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <HypertaskIndex
        onEditAction={(item) => item.id && nav.edit(item.id)}
        onShowAction={(item) => item.id && nav.show(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <HypertaskShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <HypertaskForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <HypertaskForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        hypertask={{ id: id ?? "" }}
      />
    )}
  />
);
