import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Chip, Fab, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  AddCircleOutline,
  Delete,
  DragIndicator,
  Refresh,
} from "@material-ui/icons";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { pathProxy, TextField } from "src/components/Form";
import { ClosableDrawer } from "src/Layout";
import { like, md, usePagination } from "src/resources/Utils";
import { useNotification } from "src/Notification";
import {
  Fields as Field,
  useContextQuery,
  useAllFieldsQuery,
  useUpdateContextDisplaysMutation,
  Fields_Bool_Exp,
  Order_By,
} from "src/generated/asgard/graphql";
import { SearchBar } from "src/components/filters";
import { createTriage } from "src/components/Triage";
import { FieldTable } from "./field";

const fieldDisplaySchema = Yup.object({
  key: Yup.string().required(),
  field_id: Yup.string().required(),
  name: Yup.string().required(),
}).required();

const fieldDisplayGroupSchema = Yup.object({
  context_display_id: Yup.string().nullable(),
  key: Yup.string().required(),
  name: Yup.string().required("group name required"),
  fields: Yup.array(fieldDisplaySchema),
}).required();

const formSchema = Yup.object({
  groups: Yup.array(fieldDisplayGroupSchema),
}).required();

type FormSchema = Yup.InferType<typeof formSchema>;
type FieldDisplayGroupSchema = Yup.InferType<typeof fieldDisplayGroupSchema>;
type FieldDisplaySchema = Yup.InferType<typeof fieldDisplaySchema>;

const TriageDisplayGroups = createTriage<
  FieldDisplaySchema,
  FieldDisplayGroupSchema
>();

export const ContextFieldGroupForm = (props: { id: string }) => {
  const notification = useNotification();

  const contextQuery = useContextQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });

  const [updateContextDisplays] = useUpdateContextDisplaysMutation({
    onCompleted: () => contextQuery.refetch && contextQuery.refetch(),
  });

  const formik = useFormik<FormSchema>({
    validationSchema: formSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      groups: [],
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await updateContextDisplays({
          variables: {
            context_id: props.id,
            insert_context_displays:
              values.groups?.map((group, groupIndex) => ({
                context_id: props.id,
                name: group.name,
                position: groupIndex,
                context_display_fields: {
                  data: group.fields?.length
                    ? group.fields.map((field, fieldIndex) => ({
                        field_id: field.field_id,
                        context_id: props.id,
                        position: fieldIndex,
                      }))
                    : [],
                },
              })) ?? [],
          },
        });
        notification.create({
          flashOnly: true,
          severity: "success",
          title: `update successful`,
        });
      } catch (e) {
        formik.setSubmitting(false);
      }
    },
  });

  const { setSubmitting, setValues } = formik;

  // Populate form with existing displays for given context.
  const context = contextQuery.data?.context;
  const hydrateFromQuery = () => {
    if (!context) setSubmitting(true);
    else {
      setValues({
        groups:
          context.context_displays?.map((group) => ({
            context_display_id: group.id,
            key: group.id,
            name: group.name,
            fields:
              group.context_display_fields?.map((cdf) => ({
                key: cdf.field_id,
                field_id: cdf.field_id,
                name: cdf.field?.display ?? "",
                position: cdf.position,
              })) ?? [],
          })) ?? [],
      });
      setSubmitting(false);
    }
  };
  useEffect(hydrateFromQuery, [context, setSubmitting, setValues]);

  const path = pathProxy<typeof formik.values>();

  // Use to get all fields currently added to a context display,
  // to prevent user from using the same field more than once.
  const getUsedFieldIds = () => {
    const groupedFieldIds: string[][] = formik.values.groups
      ? formik.values.groups
          .filter((group) => !!group?.fields?.length)
          .map((group) => group!.fields!.map((field) => field.field_id))
      : [];
    const fieldIds: string[] = groupedFieldIds.reduce(
      (accumulator, value) => accumulator.concat(value),
      [],
    );
    return fieldIds.filter((value, i) => fieldIds.indexOf(value) === i);
  };

  return (
    // Make sure there is enough room at the bottom
    // for the update button when fully scrolled down.
    <div style={{ marginBottom: 80 }}>
      <FormikProvider value={formik}>
        <Form>
          <FieldArray
            name="groups"
            render={(helper) => (
              <>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      onClick={() => {
                        helper.insert(0, {
                          context_display_id: null,
                          key: Date.now().toString(),
                          name: "",
                          fields: [],
                        });
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircleOutline />}
                      disableElevation
                    >
                      New Display Group
                    </Button>
                  </Grid>
                </Grid>
                <TriageDisplayGroups
                  id="triage-context-fields"
                  groups={formik.values.groups ?? []}
                  groupDirection="vertical"
                  getGroupItems={(group) => group.fields ?? []}
                  setGroupItems={(group, items) => (group.fields = items)}
                  renderGroup={({ index, children, group }) => (
                    <Card style={{ marginTop: 8 }}>
                      <CardContent>
                        <Grid container direction="row" spacing={2}>
                          <Grid item>
                            <DragIndicator />
                          </Grid>
                          <Grid item xs container direction="column">
                            <Grid
                              item
                              container
                              spacing={2}
                              justify="space-between"
                            >
                              <Grid item xs={8}>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  name={path.groups![index].name._}
                                  label={"Group"}
                                />
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="text"
                                  size="small"
                                  color="primary"
                                  disabled={!group.name.length}
                                  onClick={() => helper.remove(index)}
                                >
                                  <Delete />
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid item container>
                              {children}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                  itemDirection="horizontal"
                  renderItem={({ item, group, groupIndex }) => (
                    <StyledChip
                      icon={<DragIndicator />}
                      label={item.name}
                      onDelete={() => {
                        const replacement = { ...group };
                        replacement.fields = replacement.fields?.length
                          ? replacement.fields.filter(
                              (field) => field.key !== item.key,
                            )
                          : [];
                        helper.replace(groupIndex, replacement);
                      }}
                    />
                  )}
                  renderItemActions={({ group, index }) => (
                    <NewFieldChip
                      key="new-field-chip"
                      fieldsWhere={{
                        _and: [
                          { context_id: { _eq: props.id } },
                          { id: { _nin: getUsedFieldIds() } },
                        ],
                      }}
                      onSelect={(field, close) => {
                        if (field.id) {
                          const replacement = { ...group };
                          const newField: FieldDisplaySchema = {
                            key: field.id,
                            field_id: field.id,
                            name: field.display ?? "",
                          };
                          if (replacement.fields?.length) {
                            replacement.fields.push(newField);
                          } else {
                            replacement.fields = [newField];
                          }
                          helper.replace(index, replacement);
                          close();
                        }
                      }}
                    />
                  )}
                  onChange={(groups) => {
                    formik.setFieldValue("groups", groups);
                  }}
                />
              </>
            )}
          />
        </Form>
        <div
          style={{
            position: "fixed",
            top: "auto",
            right: 30,
            bottom: 30,
            left: "auto",
          }}
        >
          <Fab
            size="large"
            color="primary"
            variant="extended"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.resetForm();
              hydrateFromQuery();
            }}
          >
            <Refresh />
          </Fab>
          <Fab
            style={{ marginLeft: 4 }}
            size="large"
            color="primary"
            variant="extended"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
              if (Object.keys(formik.errors).length) {
                notification.create({
                  severity: "error",
                  title: `Context Display Group Form Error`,
                  body: md()
                    .syntax(
                      "json",
                      JSON.stringify(
                        { errors: formik.errors, values: formik.values },
                        null,
                        2,
                      ),
                    )
                    .get(),
                });
              }
            }}
          >
            Update
          </Fab>
        </div>
      </FormikProvider>
    </div>
  );
};

const StyledChip = withStyles({
  root: {
    margin: 4,
  },
})(Chip);

type NewFieldChipProps = {
  fieldsWhere: Fields_Bool_Exp;
  onSelect: (item: DeepPartial<Field>, close: () => void) => void;
  disabled?: boolean;
};

const NewFieldChip = (props: NewFieldChipProps) => {
  const [search, setSearch] = useState("");
  const searchTerm = like(search);
  const searchFilters: Fields_Bool_Exp[] = [
    { name: { _ilike: searchTerm } },
    { display: { _ilike: searchTerm } },
  ];

  const [show, setShow] = useState(false);
  const [pageVars, pageController] = usePagination();
  const fields = useAllFieldsQuery({
    ...pageVars,
    variables: {
      where: { _and: [{ _or: searchFilters }, props.fieldsWhere] },
      order_by: [{ name: Order_By.Asc }],
    },
    fetchPolicy: "network-only",
  });
  return (
    <>
      <StyledChip
        icon={<AddCircleOutline fontSize="small" />}
        onClick={() => setShow(true)}
        disabled={!!props.disabled}
        label="Add"
        color="primary"
      />
      <ClosableDrawer show={show} setShow={setShow}>
        <FieldTable
          {...pageController}
          total={fields.data?.fields_aggregate?.aggregate?.count}
          data={fields.data?.fields}
          hideColumns={{ context: true }}
          selectable="single"
          onSelect={(item) => props.onSelect(item, () => setShow(false))}
          tools={
            <Grid item xs={12}>
              <SearchBar onChange={setSearch} />
            </Grid>
          }
        />
      </ClosableDrawer>
    </>
  );
};
