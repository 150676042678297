import { Button, Card, Divider, Typography, Grid } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { SearchBar } from "src/components/filters";
import {
  FormAction,
  FormContainer,
  FormHeader,
  MultiSelect,
  TextField,
} from "src/components/Form";
import { useRequireRole } from "src/auth";
import { Resource } from "src/components/Resource";
import {
  ChipColumn,
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import {
  Engines as Engine,
  Engines_Bool_Exp,
  Order_By,
  useAllEnginesQuery,
  useAvailableOpmodesQuery,
  useCreateEngineMutation,
  Roles_Enum,
} from "src/generated/asgard/graphql";
import { TableView, TableViewHeader } from "src/Layout";
import { useNotification } from "src/Notification";
import { usePagination, uuidIsValid } from "src/resources/Utils";
import * as Yup from "yup";

export const EngineTable = createTable<Engine>()({
  keys: (engine) => engine.id ?? "",
  title: "Engine",
  headers: {
    id: { display: "ID" },
    build_version: { display: "Build Version" },
    graph_version: { display: "Graph Version" },
    build_number: { display: "Build Number" },
    build_reference: { display: "Build Reference" },
    release_reference: { display: "Release Reference" },
    branch: { display: "Branch" },
    // path: { display: "Path" },
    opmodes: { display: "Opmodes" },
    // build_reference: { display: "Build Reference" },
    // release_number: { display: "Release Number" },
  },
  columns: (engine) => ({
    id: <EllipsisColumn value={engine.id} />,
    build_version: <TextColumn value={engine.build_version} />,
    graph_version: <TextColumn value={engine.graph_version} />,
    build_number: <TextColumn value={engine.build_number} />,
    build_reference: <TextColumn value={engine.azure_build_ref} />,
    release_reference: <TextColumn value={engine.azure_release_ref} />,
    branch: <TextColumn value={engine.branch} />,
    // path: <TextColumn value={engine.path} />,
    opmodes: (
      <ChipColumn
        chips={engine.engine_opmodes?.map((opmode) => ({
          id: opmode.opmode_id,
          label: opmode.opmode?.number as unknown as string,
          show: opmode.opmode?.id,
        }))}
      />
    ),
  }),
});

type EngineListProps = {
  search?: string;
  where?: Engines_Bool_Exp[];
} & TableOptionals<typeof EngineTable>;

export const EngineList = (props: EngineListProps) => {
  const [search, setSearch] = useState("");
  const [pageVars, pageController] = usePagination();
  const ag = useRequireRole([Roles_Enum.Asgard]);
  const filters: Engines_Bool_Exp[] = [
    { build_ref: { _ilike: `%${search}%` } },
    { build_version: { _ilike: `%${search}%` } },
    { build_number: { _ilike: `%${search}%` } },
    { branch: { _ilike: `%${search}%` } },
    { path: { _ilike: `%${search}%` } },
  ];

  let num = parseInt(search);
  if (!isNaN(num)) {
    filters.push({ azure_build_ref: { _eq: num } });
    filters.push({ azure_release_ref: { _eq: num } });
  }

  if (uuidIsValid(search)) {
    filters.push({ id: { _eq: search } });
  }

  const parsedSearch = parseInt(search as string);
  if (!isNaN(parsedSearch)) {
    filters.push(
      { engine_opmodes: { opmode: { number: { _eq: parsedSearch } } } },
      { release_number: { _eq: parsedSearch } },
    );
  }

  const query = useAllEnginesQuery({
    fetchPolicy: "network-only",
    variables: {
      ...pageVars,
      where: { _and: [{ _or: filters }, ...(props.where ?? [])] },
      order_by: [{ id: Order_By.Desc }],
    },
  });

  return (
    <EngineTable
      {...props}
      {...pageController}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
      total={query.data?.engines_aggregate?.aggregate?.count}
      data={query.data?.engines}
      hideColumns={ag ? {} : { branch: true }}
    />
  );
};

export const EngineResource = () => {
  return (
    <Resource
      path="engines"
      list={(nav) => <EngineIndex />}
      create={() => <EngineForm />}
    />
  );
};

type EngineIndexProps = {
  onAddNew?: () => void;
} & TableOptionals<typeof EngineTable>;

export const EngineIndex = (props: EngineIndexProps) => {
  const ag = useRequireRole([Roles_Enum.Asgard]);
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Engines</Typography>}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
          onClick={() => {
            props.onAddNew && props.onAddNew();
          }}
        >
          New engine
        </Button>
      </TableViewHeader>
      <Card>
        <EngineList selectable="none" hideColumns={ag ? {} : { branch: true }} {...props} />
      </Card>
    </TableView>
  );
};

const engineSchema = Yup.object()
  .shape({
    build_ref: Yup.string().required(),
    build_version: Yup.string().required(),
    release_number: Yup.number().required(),
    branch: Yup.string().required(),
    path: Yup.string().required(),
    opmodes: Yup.array(
      Yup.object({
        id: Yup.string().required(),
        number: Yup.number().required(),
      }).required(),
    ).required(),
  })
  .required();

type EngineFormSchema = Yup.InferType<typeof engineSchema>;

const EngineForm = (props: {}) => {
  const [createEngine] = useCreateEngineMutation();
  const opmodes = useAvailableOpmodesQuery();
  const notification = useNotification();

  const formik = useFormik<EngineFormSchema>({
    validationSchema: engineSchema,
    initialValues: {
      build_ref: "",
      build_version: "",
      release_number: 0,
      branch: "",
      path: "",
      opmodes: [],
    },
    onSubmit: async (values) => {
      const {
        build_ref,
        build_version,
        release_number,
        branch,
        path,
        opmodes,
      } = values;

      try {
        const result = await (async () => {
          return await createEngine({
            variables: {
              input: {
                build_ref: build_ref,
                build_version: build_version,
                release_number: release_number,
                branch: branch,
                path: path,
                engine_opmodes: {
                  data: opmodes.map((o) => ({ opmode_id: o.id })),
                },
              },
            },
          });
        })();

        let id = result?.data?.insert_engines_one?.id;

        if (id) {
          notification.create({
            severity: "success",
            title: "Engine created",
            // ...notice,
          });
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <FormHeader>
        <Typography variant="h6">Add new engine</Typography>
      </FormHeader>
      <Divider />
      <FormContainer>
        <TextField
          bp={{ xs: 12, sm: 6 }}
          name={"build_ref"}
          label="Build ref"
        />
        <TextField
          bp={{ xs: 12, sm: 6 }}
          name={"build_version"}
          label="Build version"
        />
        <TextField
          bp={{ xs: 12, sm: 6 }}
          name={"release_number"}
          label="Release number"
        />
        <TextField bp={{ xs: 12, sm: 6 }} name={"branch"} label="Branch" />
        <TextField bp={{ xs: 12, sm: 6 }} name={"path"} label="Path" />

        <MultiSelect
          bp={{ xs: 12, sm: 6 }}
          name={"opmodes"}
          label="Opmodes"
          placeholder="Opmode"
          options={opmodes.data?.opmodes}
          getOptionLabel={(opt) => opt?.number.toString()}
        />

        <FormAction>
          <Button
            color="primary"
            variant="contained"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}
            disableElevation
          >
            Create engine
          </Button>
        </FormAction>
      </FormContainer>
    </FormikProvider>
  );
};
