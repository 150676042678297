import React, { useState } from "react";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Button,
  Chip,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  GridSize,
  Hidden,
  IconButton,
  LinearProgress,
  Tabs,
  Tab,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { Archive, Close, Edit, Info, Warning } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import config from "src/config";

export type GridBreakpoints = {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
};

const drawerWidth = 240;

// TODO(jonas) Remove unused styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
      alignItems: "flex-start",
      "*": {
        boxSizing: "unset !important",
      },
    },
    grow: {
      flexGrow: 1,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    inputRoot: {
      color: "inherit",
    },
    sectionDesktop: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      display: "flex",
      minHeight: "100vh",
      width: "100%",
      flexDirection: "column",
      // flex: "0 1 auto",
    },
    infoTooltip: {
      maxWidth: 500,
    },
  }),
);

type LayoutProps = {
  container?: any;
  children?: React.ReactNode | React.ReactNode[];
  drawer?: React.ReactNode | React.ReactNode[];
  toolbarLeft?: React.ReactNode | React.ReactNode[];
  toolbarRight?: React.ReactNode | React.ReactNode[];
};

export const Layout = (props: LayoutProps) => {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Elliptic Manager{config.env === "dev" ? ": Development" : ""}
          </Typography>
          {props.toolbarLeft}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>{props.toolbarRight}</div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {props.drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {props.drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div style={{ display: "flex", flexGrow: 1 }}>{props.children}</div>
      </main>
    </div>
  );
};

type ListContainerProps = {
  children?: JSX.Element | JSX.Element[];
  title?: JSX.Element | JSX.Element[];
  actions?: JSX.Element | JSX.Element[];
};

export const TableView = ({ children, ...props }: ListContainerProps) => {
  return <div style={{ width: "100%" }}>{children}</div>;
};

type TableViewHeaderProps = {
  title?: JSX.Element | JSX.Element[];
  children?: JSX.Element | JSX.Element[];
  info?: JSX.Element;
};

export const TableViewHeader = (props: TableViewHeaderProps) => {
  return (
    <div style={{ display: "flex", gap: 10, width: "100%", marginBottom: 10 }}>
      {props.title}
      {props.info && <InfoTooltip content={props.info} />}
      <div style={{ flexGrow: 1 }} />
      <div style={{ display: "grid", gridGap: 10, gridAutoFlow: "column" }}>
        {props.children}
      </div>
    </div>
  );
};

export const ListHeader = () => {};

export const ClosableDrawer = (props: {
  show: boolean;
  setShow: (show: boolean) => void;
  children: React.ReactNode;
}) => (
  <Drawer
    anchor={"right"}
    open={props.show}
    onClose={() => props.setShow(false)}
  >
    <div style={{ margin: 10, maxWidth: "1000px" }}>
      <IconButton size="small" onClick={() => props.setShow(false)}>
        <Close />
      </IconButton>
      {props.children}
    </div>
  </Drawer>
);

export type ShowResourceViewProps = {
  title: string;
  subtitle?: JSX.Element;
  properties?: { label: string; value: string }[];
  breadcrumbs?: JSX.Element;
  archivedAt?: null | string;
  onEditAction?: () => void;
  onArchiveAction?: () => void;
  onUnarchiveAction?: () => void;
  children?: React.ReactNode;
  loading?: boolean;
};

export const ShowResourceView = (props: ShowResourceViewProps) => {
  const [showChallenge, setShowChallenge] = useState(false);

  if (props.loading) return <LinearProgress />;

  return (
    <>
      <Container maxWidth="lg" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div style={{ marginBottom: 30 }}>
          {!!props.archivedAt && (
            <Alert
              style={{ marginBottom: 10 }}
              severity="warning"
              action={
                props.onUnarchiveAction && (
                  <Button
                    size="small"
                    color="inherit"
                    onClick={() =>
                      props.onUnarchiveAction && props.onUnarchiveAction()
                    }
                  >
                    Unarchive
                  </Button>
                )
              }
            >
              This resource was archived at {props.archivedAt}.
            </Alert>
          )}
          <Grid container spacing={2}>
            {props.breadcrumbs && (
              <Grid item xs={12}>
                {props.breadcrumbs}
              </Grid>
            )}
            <Grid
              item
              sm={props.onEditAction ? (props.onArchiveAction ? 8 : 10) : 12}
              xs={12}
            >
              <Typography variant="h4">{props.title}</Typography>
              {props.subtitle ? props.subtitle : null}
            </Grid>
            {props.onEditAction && (
              <Grid item sm={2} xs={4}>
                <Button
                  onClick={() => props.onEditAction && props.onEditAction()}
                  variant="contained"
                  color="primary"
                  disableElevation
                  fullWidth={true}
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
              </Grid>
            )}
            {props.onArchiveAction && (
              <Grid item sm={2} xs={4}>
                <Button
                  onClick={() => setShowChallenge(true)}
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={!!props.archivedAt}
                  fullWidth={true}
                  startIcon={<Archive />}
                >
                  Archive
                </Button>
              </Grid>
            )}
          </Grid>
          {props.properties && (
            <Grid container spacing={2}>
              {props.properties.map(({ label, value }, i) => (
                <Grid item key={i}>
                  <Typography variant="caption">{label}</Typography>
                  <Typography variant="body1">{value}</Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
        {props.children}
      </Container>
      {props.onArchiveAction && (
        <Dialog open={showChallenge} onClose={() => setShowChallenge(false)}>
          <DialogContent>Are you sure?</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.onArchiveAction && props.onArchiveAction();
                setShowChallenge(false);
              }}
            >
              Yes
            </Button>
            <Button onClick={() => setShowChallenge(false)}>No</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export const Breadcrumbs = ({ children }: { children: React.ReactNode }) => (
  <MUIBreadcrumbs>{children}</MUIBreadcrumbs>
);

type BreadcrumbProps = {
  label: string;
  onClick?: () => void;
};
export const Breadcrumb = (props: BreadcrumbProps) =>
  props.onClick !== undefined ? (
    <Button
      onClick={() => props.onClick!()}
      variant="text"
      color="primary"
      disableElevation
    >
      {props.label}
    </Button>
  ) : (
    <Button disabled={true} variant="text" color="primary" disableElevation>
      {props.label}
    </Button>
  );

export type TabLabelProps = {
  label: string;
  count?: number | null | undefined;
  icon?: JSX.Element | undefined;
  tooltip?: React.ReactNode | undefined;
  warning?: boolean;
};

export const TabLabel = (props: TabLabelProps) => {
  const display = (
    <div style={{ display: "flex", justifyItems: "center" }}>
      {props.icon !== undefined && props.icon}
      <span style={{ marginLeft: 10 }}>{props.label}</span>
      {props.count !== undefined && props.count !== null && (
        <Chip
          style={{ marginLeft: 10 }}
          size="small"
          color="primary"
          label={props.count}
        />
      )}
      {props.warning && (
        <Warning style={{ marginLeft: 10 }} color="error" fontSize="small" />
      )}
    </div>
  );
  if (!!props.tooltip)
    return <Tooltip title={props.tooltip}>{display}</Tooltip>;
  else return display;
};

export type TabContentProps = {
  children?: React.ReactNode;
  index: number;
  current: number;
  loading?: boolean;
  disabled?: boolean;
};

export const TabContent = ({
  children,
  current,
  index,
  loading,
  disabled,
  ...props
}: TabContentProps) => {
  return index === current && !disabled ? (
    <>
      <div hidden={!loading}>
        <LinearProgress style={{ marginTop: 20 }} />
      </div>
      <div hidden={loading && loading === true} {...props}>
        {index === current && children}
      </div>
    </>
  ) : (
    <></>
  );
};

type TabProps = {
  value: number;
  onChange: (event: any, index: number) => void;
};

export type TabViewProps = {
  renderTabs: (tabProps: TabProps) => React.ReactNode;
  renderContent: (tabIndex: number) => React.ReactNode;
};

export const TabView = (props: TabViewProps & { useUrlParams?: boolean }) => {
  const { useUrlParams, ...tabViewProps } = props;
  return useUrlParams ? (
    <TabViewWithUrlParams {...tabViewProps} />
  ) : (
    <TabViewWithoutUrlParams {...tabViewProps} />
  );
};

const TabViewWithUrlParams = (props: TabViewProps) => {
  const hist = useHistory();
  // Be sure to preserve other query parameters.
  const { tab, ...query } = qs.parse(hist.location.search);
  const currentTabIndex = !!tab && !Array.isArray(tab) ? parseInt(tab) : 0;
  return (
    <>
      {props.renderTabs({
        value: currentTabIndex,
        onChange: (_, index) => {
          hist.push("?" + qs.stringify({ tab: index, ...query }));
        },
      })}
      <Divider style={{ marginBottom: 20 }} />
      {props.renderContent(currentTabIndex)}
    </>
  );
};

const TabViewWithoutUrlParams = (props: TabViewProps) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  return (
    <>
      {props.renderTabs({
        value: currentTabIndex,
        onChange: (_, index) => setCurrentTabIndex(index),
      })}
      <Divider style={{ marginBottom: 20 }} />
      {props.renderContent(currentTabIndex)}
    </>
  );
};

// Alternative tab view, which uses a string "name" to index the tabs, instead
// of a number. Makes it easier to hide different tabs without having missing
// number in the sequence.
export type TablatureProps = {
  useUrlParams?: boolean;
  tabs: {
    name: string;
    label: React.ReactNode;
    content: React.ReactNode;
    hide?: boolean;
    loading?: boolean;
    disabled?: boolean;
  }[];
};

export const Tablature = ({ useUrlParams, ...props }: TablatureProps) => {
  return useUrlParams ? (
    <TablatureWithUrlParams {...props} />
  ) : (
    <TablatureWithoutUrlParams {...props} />
  );
};

const TablatureWithUrlParams = (
  props: Omit<TablatureProps, "useUrlParams">,
) => {
  const defaultTab = props.tabs.length > 0 ? props.tabs[0].name : false;
  const hist = useHistory();
  // Be sure to preserve other query parameters.
  const { tab, ...query } = qs.parse(hist.location.search);
  const currentTabValue = !!tab && !Array.isArray(tab) ? tab : defaultTab;
  return (
    <>
      <Tabs
        value={currentTabValue}
        onChange={(_, value) => {
          hist.push("?" + qs.stringify({ tab: value, ...query }));
        }}
      >
        {props.tabs.map(
          ({ name, label, hide, disabled }, i) =>
            !hide && (
              <Tab key={i} value={name} label={label} disabled={disabled} />
            ),
        )}
      </Tabs>
      <Divider style={{ marginBottom: 20 }} />
      <TablatureContent currentTabValue={currentTabValue} tabs={props.tabs} />
    </>
  );
};

const TablatureWithoutUrlParams = (
  props: Omit<TablatureProps, "useUrlParams">,
) => {
  const defaultTab = props.tabs.length > 0 ? props.tabs[0].name : false;
  const [currentTabValue, setCurrentTabValue] = useState<string | false>(
    defaultTab,
  );
  return (
    <>
      <Tabs
        value={currentTabValue}
        onChange={(_, value) => {
          !!value && setCurrentTabValue(value);
        }}
      >
        {props.tabs.map(
          ({ name, label, hide, disabled }, i) =>
            !hide && (
              <Tab key={i} value={name} label={label} disabled={disabled} />
            ),
        )}
      </Tabs>
      <Divider style={{ marginBottom: 20 }} />
      <TablatureContent currentTabValue={currentTabValue} tabs={props.tabs} />
    </>
  );
};

type TablatureContentProps = Pick<TablatureProps, "tabs"> & {
  currentTabValue: string | false;
};
const TablatureContent = (props: TablatureContentProps) => {
  const currentTab = props.tabs.find(
    (tab) => tab.name === props.currentTabValue,
  );
  return currentTab ? (
    <>
      <div hidden={!currentTab.loading}>
        <LinearProgress style={{ marginTop: 20 }} />
      </div>
      <div hidden={currentTab.loading && currentTab.loading === true}>
        {currentTab.content}
      </div>
    </>
  ) : null;
};

type InfoDisplayProps = {
  items: { label: string; value: string | number; bp?: GridBreakpoints }[];
  bp?: GridBreakpoints;
};

export const InfoDisplay = (props: InfoDisplayProps) => {
  const cbp = props.bp ? { ...props.bp } : {};
  const isItem = !!props.bp;
  return (
    <Grid container item={isItem} spacing={4} {...cbp}>
      {props.items.map((item, index) => {
        const bp = !!item.bp ? { ...item.bp } : {};
        return (
          <Grid item key={index} {...bp}>
            <Typography variant="caption">{item.label}</Typography>
            <Typography variant="body1">{item.value}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

// Export MUI components for convenience.
export { Tab, Tabs };

// Generic "info tooltip"
export const InfoTooltip = (props: {
  content: JSX.Element;
  size?: "small" | "large";
}) => {
  const fontSize = props.size ?? "default";
  const classes = useStyles();
  return (
    <Tooltip
      title={props.content}
      classes={{ tooltip: classes.infoTooltip }}
      placement="right-start"
    >
      <Info fontSize={fontSize} color="disabled" />
    </Tooltip>
  );
};
