import React, { useState } from "react";
import { Button, Card, Grid, Typography, CardContent } from "@material-ui/core";
import {
  AddCircleOutline,
  Category,
  ListAltOutlined,
} from "@material-ui/icons";
import { FormContainer, FormHeader, TextField } from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import * as Yup from "yup";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { SearchBar } from "src/components/filters";
import { like, orderBy, usePagination, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumbs,
  Breadcrumb,
  ShowResourceView,
  Tab,
  Tabs,
  TabContent,
  TabLabel,
  TabView,
  TableView,
  TableViewHeader,
} from "src/Layout";
import {
  Contexts as Context,
  useContextQuery,
  useAllContextsQuery,
  useCreateContextMutation,
  useUpdateContextMutation,
  Fields_Bool_Exp,
  useCategoryQuery,
  useAllFieldsQuery,
  Order_By,
  Fields_Order_By,
} from "src/generated/asgard/graphql";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { fieldDescription, FieldTable, useFieldNav } from "./field";
import { useCategoryNav } from "./category";
import { ContextFieldGroupForm } from "./contextFieldGroupForm";
import { ResourceForm, FormAction } from "src/components/ResourceForm";

// Create a detailed description of the Context resource
export const contextDescription = (
  <Typography variant="body2">
    <b>Contexts</b> are used to define different groups of <b>fields</b> in a{" "}
    single <b>category</b>.
    <br />
    <br />
    <b>Contexts</b> are most useful when creating <b>tasks</b>.
    <br />
    <br />
    Someone who is creating a <b>task</b> should be able to find all the{" "}
    <b>fields</b> and <b>options</b> in a particular <b>category</b> that are{" "}
    relevant to a specific use case, instead of searching for them individually.
    <br />
    <br />
    This is achieved by making sure that each use case has its own{" "}
    <b>context</b>, containing separate <b>fields</b>, which have separate{" "}
    possible <b>options</b>.
  </Typography>
);

// Config table columns from Context fields
export const ContextTable = createTable<Context>()({
  keys: (context) => context.id ?? "",
  title: "Context",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
  },
  columns: (context) => ({
    id: <EllipsisColumn value={context.id} />,
    name: <TextColumn value={context.name} />,
  }),
});

// Define a new table component for Contexts
type AllContextsTableProps = TableOptionals<typeof ContextTable>;

export const AllContextsTable = (props: AllContextsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const { data } = useAllContextsQuery({
    variables: {
      ...pageVars,
      order_by: [{ category: { name: Order_By.Asc }, name: Order_By.Asc }],
    },
  });

  return (
    <ContextTable
      {...props}
      {...pageController}
      total={data?.contexts_aggregate?.aggregate?.count}
      data={data?.contexts}
    />
  );
};

// Define form for creating and editing Contexts
const contextSchema = Yup.object({
  name: Yup.string().required("required"),
  category: Yup.object({
    id: Yup.string().required(),
    name: Yup.string(),
  }).required(),
}).required();

export type ContextFormProps = {
  action: FormAction;
  context?: { id: string };
  onSuccess?: (id: string) => void;
};

export const ContextForm = (props: ContextFormProps) => {
  const [createContext] = useCreateContextMutation();
  const [updateContext] = useUpdateContextMutation();

  const existingContexts = useAllContextsQuery({
    variables: {
      where: { id: { _nin: props.context?.id ? [props.context.id] : [] } },
    },
  });

  const contextQuery = useContextQuery({
    variables: { id: props.context?.id ?? "" },
    skip: !props.context,
  });

  const loc = useLocation();
  const query = qs.parse(loc.search);
  const categoryQuery = useCategoryQuery({
    variables: { id: (query.category as string) ?? "" },
    skip: !query.category || props.action === "update",
  });
  const category = categoryQuery.data?.category;

  return (
    <ResourceForm
      action={props.action}
      schema={contextSchema}
      resourceToUpdate={contextQuery.data?.context}
      transform={(context) => ({
        category: {
          id: context.category.id ?? "",
          name: context.category.name ?? "",
        },
        name: context.name,
      })}
      initialValues={{
        name: "",
        category: {
          id: category?.id ?? "",
          name: category?.name ?? "",
        },
      }}
      customValidator={(values, errors) => {
        for (let existing of existingContexts.data?.contexts ?? []) {
          if (
            existing.name === values.name &&
            existing.category.id === values.category.id
          ) {
            errors.name = `Context '${values.name}' already exists for category '${values.category.name}'`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const { category, ...contextValues } = values;
        const result = await (async () => {
          if (props.context?.id)
            return await updateContext({
              variables: {
                id: props.context.id,
                input: { ...contextValues },
              },
            });
        })();
        props.onSuccess && props.onSuccess(result?.data?.context?.id ?? "");
      }}
      onInsert={async (values) => {
        const { category, ...contextValues } = values;
        const result = await (async () => {
          return await createContext({
            variables: {
              input: {
                ...contextValues,
                category_id: category.id,
              },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.context?.id ?? "");
      }}
      disableSubmit={(values) => values.category.id.length === 0}
      render={({ path }) => (
        <>
          <FormHeader>
            {props.context
              ? "Update Context"
              : category?.name
                ? `Create New Context for Category '${category.name}'`
                : "Create New Context"}
          </FormHeader>
          <FormContainer>
            <TextField bp={{ xs: 12, sm: 8 }} name={path.name._} label="Name" />
          </FormContainer>
        </>
      )}
    />
  );
};

type ContextShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Context>) => void;
};

const ContextShow = (props: ContextShowProps) => {
  const fieldNav = useFieldNav();
  const categoryNav = useCategoryNav();
  const [pageVars, pageController] = usePagination();

  // Allow user to search for fields.
  const [search, setSearch] = useState("");
  const searchTerm = like(search);
  const searchFilters: Fields_Bool_Exp[] = [
    { name: { _ilike: searchTerm } },
    { display: { _ilike: searchTerm } },
  ];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  }

  // Allow ordering by column.
  const defaultOrder: Fields_Order_By = { name: Order_By.Asc };
  const [order, setOrder] = useState<Fields_Order_By>(defaultOrder);

  const contextQuery = useContextQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const context = contextQuery.data?.context;

  const paginatedFields = useAllFieldsQuery({
    variables: {
      ...pageVars,
      where: {
        _and: [{ context_id: { _eq: props.id } }, { _or: searchFilters }],
      },
      order_by: [order],
    },
    fetchPolicy: "network-only",
  });

  if (!context) return null;
  return (
    <ShowResourceView
      title={context.name ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="categories" onClick={() => categoryNav.list()} />
          <Breadcrumb
            label={context.category.name}
            onClick={() => categoryNav.show(context.category.id)}
          />
          <Breadcrumb label={context.name} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(context)}
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabsProps) => (
          <Tabs {...tabsProps}>
            <Tab
              label={
                <TabLabel
                  label="Fields"
                  count={context.fields?.length}
                  icon={<ListAltOutlined />}
                />
              }
            />
            <Tab
              label={<TabLabel label="Display Groups" icon={<Category />} />}
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent
              index={0}
              current={current}
              loading={paginatedFields.loading}
            >
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <TableView>
                    <TableViewHeader info={fieldDescription}>
                      <Button
                        onClick={() => fieldNav.create({ context: props.id })}
                        variant="contained"
                        color="primary"
                        disableElevation
                        startIcon={<AddCircleOutline />}
                      >
                        Create new Field
                      </Button>
                    </TableViewHeader>
                    <FieldTable
                      {...pageController}
                      total={
                        paginatedFields.data?.fields_aggregate?.aggregate?.count
                      }
                      data={paginatedFields.data?.fields}
                      hideColumns={{ context: true }}
                      selectable="none"
                      editUrl={(item) => item.id && fieldNav.editUrl(item.id)}
                      showUrl={(item) => item.id && fieldNav.showUrl(item.id)}
                      tools={
                        <Grid item xs={12}>
                          <SearchBar onChange={setSearch} />
                        </Grid>
                      }
                      orderColumn={(order) => {
                        if (!order) {
                          setOrder(defaultOrder);
                        } else {
                          setOrder({
                            id: orderBy(order.id),
                            name: orderBy(order.name),
                            display: orderBy(order.display),
                          });
                        }
                      }}
                    />
                  </TableView>
                </CardContent>
              </Card>
            </TabContent>
            <TabContent index={1} current={current}>
              <ContextFieldGroupForm id={context.id} />
            </TabContent>
          </>
        )}
      />
    </ShowResourceView>
  );
};

export const useContextNav = () => useResourceNav("contexts");
export const ContextResource = () => (
  <Resource
    path={"contexts"}
    show={(nav, id) => (
      <ContextShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <ContextForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <ContextForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        context={{ id: id ?? "" }}
      />
    )}
  />
);
