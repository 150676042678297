import { useEffect, useRef } from "react";

export function useDebouncedEffect(
  callback: () => void,
  dependencies: any[],
  delay: number,
) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      callbackRef.current();
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, delay]);
}
