import { Card, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close, Search } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";

type SearchBarProps = {
  update: (search: string) => void;
  initialValue?: string;
};

export function SearchFilter({ update, initialValue }: SearchBarProps) {
  const updateRef = useRef<SearchBarProps["update"]>();
  updateRef.current = update;
  const [search, setSearch] = useState(initialValue ?? "");
  const inputStyle = useInputStyle();

  const handleClear = () => {
    setSearch("");
  };

  useEffect(() => {
    if (updateRef.current !== undefined) {
      updateRef.current(search);
    }
  }, [search]);

  return (
    <Card className={inputStyle.root}>
      <TextField
        autoFocus
        fullWidth
        value={search}
        onChange={(ev) => setSearch(ev.target.value)}
        placeholder="search"
        classes={{ root: inputStyle.root }}
        inputProps={{
          className: inputStyle.inputPadding,
        }}
        InputProps={{
          style: {
            paddingLeft: 20,
            borderColor: "blue",
          },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleClear}>
                <Close />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Card>
  );
}

const useInputStyle = makeStyles({
  root: {
    flex: 1,
    borderColor: "orange",
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
    },
  },
  inputPadding: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
  },
});
