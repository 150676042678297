import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Translate } from "@material-ui/icons";
import { FormikProvider, useFormik } from "formik";
import {
  FormContainer,
  FormikSubmitFailureEffect,
  FormHeader,
  FormAction,
  TextField,
  pathProxy,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import * as Yup from "yup";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, md, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  TableViewHeader,
  Tab,
  Tabs,
  TabContent,
  TabLabel,
  TabView,
} from "src/Layout";
import { useNotification } from "src/Notification";
import {
  Integration_Test_Instructions as Instruction,
  Integration_Test_Instructions_Bool_Exp,
  Order_By,
  useAllIntegrationTestInstructionsQuery,
  useIntegrationTestInstructionQuery,
  useIntegrationTestInstructionTranslationsSubscription,
  useUpdateIntegrationTestInstructionMutation,
  useUpsertIntegrationTestInstructionTranslationMutation,
} from "src/generated/asgard/graphql";
import { SearchBar } from "src/components/filters";
import { TranslationTable } from "src/resources/Translation";

// Config table columns from Instruction fields
export const InstructionTable = createTable<Instruction>()({
  keys: (instruction) => instruction.id ?? "",
  title: "Instruction",
  headers: {
    id: { display: "ID" },
    description: { display: "Description" },
  },
  columns: (instruction) => ({
    id: <EllipsisColumn value={instruction.id} />,
    description: <TextColumn value={instruction.description} />,
  }),
});

// Define a new table component for Instructions
type AllInstructionsTableProps = TableOptionals<typeof InstructionTable> & {
  where?: Integration_Test_Instructions_Bool_Exp[];
};

export const AllInstructionsTable = (props: AllInstructionsTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Integration_Test_Instructions_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  }
  const term = like(search);
  searchFilters.push({ description: { _ilike: term } });
  const { data } = useAllIntegrationTestInstructionsQuery({
    variables: {
      ...pageVars,
      where: { _and: props.where },
      order_by: [{ description: Order_By.Asc }],
    },
  });

  return (
    <InstructionTable
      {...props}
      {...pageController}
      total={data?.instructions_aggregate?.aggregate?.count}
      data={data?.instructions}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

type InstructionIndexProps = TableOptionals<typeof InstructionTable>;

export const InstructionIndex = (props: InstructionIndexProps) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">Instructions</Typography>}
      />
      <Card>
        <AllInstructionsTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Instructions
const instructionSchema = Yup.object({
  description: Yup.string().required("required"),
}).required();

type InstructionFormSchema = Yup.InferType<typeof instructionSchema>;

export type InstructionFormProps = {
  instruction: { id: string };
  onSuccess?: (id: string) => void;
};

export const InstructionForm = (props: InstructionFormProps) => {
  const notification = useNotification();

  const [updateInstruction] = useUpdateIntegrationTestInstructionMutation();

  const formik = useFormik<InstructionFormSchema>({
    validationSchema: instructionSchema,
    initialValues: {
      description: "",
    },
    onSubmit: async (values) => {
      try {
        const result = await (async () => {
          if (props.instruction) {
            return await updateInstruction({
              variables: {
                id: props.instruction.id,
                input: { ...values },
              },
            });
          }
        })();
        props.onSuccess && props.onSuccess(result?.data?.instruction?.id ?? "");
      } catch (e) {}
    },
  });

  const { setValues, setSubmitting } = formik;

  const instructionQuery = useIntegrationTestInstructionQuery({
    variables: { id: props.instruction.id },
    fetchPolicy: "network-only",
  });
  const instruction = instructionQuery.data?.instruction;
  useEffect(() => {
    if (!instruction) setSubmitting(true);
    else {
      setValues({ description: instruction.description ?? "" });
      setSubmitting(false);
    }
  }, [instruction, setSubmitting, setValues]);

  const path = pathProxy<typeof formik.values>();

  return (
    <FormikProvider value={formik}>
      <FormHeader>
        <Typography variant="h6">Update Instruction</Typography>
      </FormHeader>
      <FormContainer>
        <TextField
          bp={{ xs: 12 }}
          name={path.description._}
          label="Description"
        />
        <FormAction>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Update
          </Button>
          <FormikSubmitFailureEffect
            onSubmitFailure={(errors) =>
              notification.create({
                severity: "error",
                title: `Instruction Form Error`,
                body: md()
                  .syntax("json", JSON.stringify(errors, null, 2))
                  .get(),
              })
            }
          />
        </FormAction>
      </FormContainer>
    </FormikProvider>
  );
};

// Create table for viewing and editing translations.
type InstructionTranslationTableProps = {
  instruction: { id: string };
};

const InstructionTranslationTable = (
  props: InstructionTranslationTableProps,
) => {
  const instructionTranslations =
    useIntegrationTestInstructionTranslationsSubscription({
      variables: { id: props.instruction.id },
    });
  const [upsertTranslation] =
    useUpsertIntegrationTestInstructionTranslationMutation({});
  return (
    <TranslationTable
      data={instructionTranslations.data?.translations ?? []}
      schema={Yup.object({
        description: Yup.string().nullable(),
      }).required()}
      getValues={(data) => ({
        description: data.description ?? "",
      })}
      initialValues={{ description: "" }}
      getLanguage={(data) => data.language}
      labels={{ description: "Description" }}
      columns={(data) => ({ description: data.description ?? "" })}
      onUpdate={(data, values) =>
        upsertTranslation({
          variables: {
            object: {
              instruction_id: props.instruction.id,
              language: data.language.id,
              description: values.description,
            },
          },
        })
      }
      onCreate={(language, values) =>
        upsertTranslation({
          variables: {
            object: {
              instruction_id: props.instruction.id,
              language: language,
              ...values,
            },
          },
        })
      }
    />
  );
};

// Create a detailed 'show' page.
type InstructionShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Instruction>) => void;
};

const InstructionShow = (props: InstructionShowProps) => {
  const instructionNav = useInstructionNav();

  const instructionQuery = useIntegrationTestInstructionQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const instruction = instructionQuery.data?.instruction;
  if (!instruction) return null;

  return (
    <ShowResourceView
      title={instruction.description ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb
            label="instructions"
            onClick={() => instructionNav.list()}
          />
          <Breadcrumb label={instruction.description ?? ""} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(instruction)}
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabProps) => (
          <Tabs {...tabProps}>
            <Tab
              label={<TabLabel label="Translations" icon={<Translate />} />}
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent index={0} current={current}>
              <Card style={{ marginTop: 20 }}>
                <CardContent>
                  <InstructionTranslationTable instruction={{ id: props.id }} />
                </CardContent>
              </Card>
            </TabContent>
          </>
        )}
      />
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
export const useInstructionNav = () => useResourceNav("instructions");
export const InstructionResource = () => (
  <Resource
    path="instructions"
    list={(nav) => (
      <InstructionIndex
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        editUrl={(item) => item.id && nav.editUrl(item.id)}
      />
    )}
    show={(nav, id) => (
      <InstructionShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    edit={(nav, id) => (
      <InstructionForm
        onSuccess={(id) => nav.show(id)}
        instruction={{ id: id ?? "" }}
      />
    )}
  />
);
