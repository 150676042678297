import React, { useState } from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import { AddCircleOutline, ListAltOutlined } from "@material-ui/icons";
import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
import { FormContainer, FormHeader, TextField } from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  TableView,
  TableViewHeader,
  Tab,
  Tabs,
  TabContent,
  TabLabel,
  TabView,
} from "src/Layout";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import {
  Devices as Device,
  Devices_Bool_Exp,
  useAllDevicesQuery,
  useDeviceQuery,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useAggDevicesByProjectQuery,
  AggDevicesByProjectQuery,
} from "src/generated/asgard/graphql";

// Config table columns from Device fields
export const DeviceTable = createTable<Device>()({
  keys: (device) => device.id ?? "",
  title: "Device",
  headers: {
    id: { display: "ID" },
    serialno: { display: "Serial Number" },
    description: { display: "Description" },
    model: { display: "Model" },
  },
  columns: (device) => ({
    id: <EllipsisColumn value={device.id} />,
    serialno: <TextColumn value={device.serialno} />,
    description: <TextColumn value={device.description} />,
    model: <TextColumn value={device.model?.codename ?? ""} />,
  }),
});

export const RecordingsByDeviceTable = createTable<
  NonNullable<AggDevicesByProjectQuery["project"]>["project_dataset"][number]
>()({
  keys: (pd) => pd?.device?.id ?? "",
  title: "Recordings by Device",
  headers: {
    id: { display: "ID" },
    serialno: { display: "Serial Number" },
    description: { display: "Description" },
    clean: { display: "Clean" },
    dirty: { display: "Dirty" },
    untagged: { display: "Untagged" },
  },
  columns: ({ device }) => ({
    id: <EllipsisColumn value={device?.id} />,
    serialno: <TextColumn value={device?.serialno} />,
    description: <TextColumn value={device?.description} />,
    clean: <TextColumn value={device?.clean?.aggregate?.count} />,
    dirty: <TextColumn value={device?.dirty?.aggregate?.count} />,
    untagged: <TextColumn value={device?.untagged?.aggregate?.count} />,
  }),
});

// Define a new table component for Devices
type AllDevicesTableProps = TableOptionals<typeof DeviceTable> & {
  where?: Devices_Bool_Exp[];
};

export const AllDevicesTable = (props: AllDevicesTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Devices_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    // Add search terms for individual fields.
    const term = like(search);
    searchFilters.push({
      _or: [{ serialno: { _ilike: term } }, { description: { _ilike: term } }],
    });
  }
  const { data } = useAllDevicesQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, ...searchFilters] },
      // Add other query variables.
    },
  });

  return (
    <DeviceTable
      {...props}
      {...pageController}
      total={data?.devices_aggregate?.aggregate?.count}
      data={data?.devices}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

type AggDevicesTableProps = TableOptionals<typeof RecordingsByDeviceTable> & {
  project_id: string;
};

export const AggDevicesTable = ({
  project_id,
  ...props
}: AggDevicesTableProps) => {
  const { data } = useAggDevicesByProjectQuery({
    variables: {
      project_id,
    },
  });

  return (
    <RecordingsByDeviceTable {...props} data={data?.project?.project_dataset} />
  );
};

// Define content to display in the main Device resource page
type DeviceIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof DeviceTable>;

export const DeviceIndex = (props: DeviceIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Devices</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Device
        </Button>
      </TableViewHeader>
      <Card>
        <AllDevicesTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Devices
const deviceSchema = Yup.object({
  description: Yup.string(),
}).required();

export type DeviceFormProps = {
  action: FormAction;
  device?: { id: string };
  onSuccess?: (id: string) => void;
};

export const DeviceForm = (props: DeviceFormProps) => {
  const [createDevice] = useCreateDeviceMutation();
  const [updateDevice] = useUpdateDeviceMutation();

  const allDevices = useAllDevicesQuery({
    variables: {
      where: { id: { _nin: props.device?.id ? [props.device.id] : [] } },
    },
  });

  const existingDevice = useDeviceQuery({
    variables: { id: props.device?.id ?? "" },
    skip: !props.device,
  });

  return (
    <ResourceForm
      action={props.action}
      schema={deviceSchema}
      resourceToUpdate={existingDevice.data?.device}
      transform={(device) => ({
        description: device.description ?? "",
      })}
      initialValues={{
        description: "",
      }}
      customValidator={(values, errors) => {
        for (let existing of allDevices.data?.devices ?? []) {
          if (existing.description === values.description) {
            errors.description = `Device ${values.description} already exists`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const { description, ...input } = values;
        const result = await (async () => {
          if (props.device?.id)
            return await updateDevice({
              variables: {
                id: props.device.id,
                input: {
                  description: description === "" ? null : description,
                  ...input,
                },
              },
            });
        })();
        props.onSuccess && props.onSuccess(result?.data?.device?.id ?? "");
      }}
      onInsert={async (values) => {
        const { description, ...input } = values;
        const result = await (async () => {
          return await createDevice({
            variables: {
              input: {
                description: description === "" ? null : description,
                ...input,
              },
            },
          });
        })();
        props.onSuccess && props.onSuccess(result.data?.device?.id ?? "");
      }}
      render={({ path }) => (
        <>
          <FormHeader>
            {props.device ? "Update Device" : "Create New Device"}
          </FormHeader>
          <FormContainer>
            {
              <TextField
                bp={{ xs: 12, sm: 8 }}
                name={path.description?._ ?? ""}
                label="Description"
              />
            }
          </FormContainer>
        </>
      )}
    />
  );
};

// Create a detailed 'show' page.
type DeviceShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Device>) => void;
};

const DeviceShow = (props: DeviceShowProps) => {
  const deviceNav = useDeviceNav();

  const deviceQuery = useDeviceQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const device = deviceQuery.data?.device;
  if (!device) return null;

  return (
    <ShowResourceView
      title={"Insert Title Here!"}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="devices" onClick={() => deviceNav.list()} />
          <Breadcrumb label={device.description ?? ""} />
        </Breadcrumbs>
      }
      onEditAction={() => props.onEditAction && props.onEditAction(device)}
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabProps) => (
          <Tabs {...tabProps}>
            <Tab
              label={<TabLabel label="Tab 1" icon={<ListAltOutlined />} />}
            />
            <Tab
              label={<TabLabel label="Tab 2" icon={<ListAltOutlined />} />}
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <>
            <TabContent index={0} current={current}>
              Insert Content for Tab 1!
            </TabContent>
            <TabContent index={1} current={current}>
              Insert Content for Tab 2!
            </TabContent>
          </>
        )}
      />
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "devices";
export const useDeviceNav = () => useResourceNav(path);
export const DeviceResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <DeviceIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <DeviceShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <DeviceForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <DeviceForm
        action="update"
        // onSuccess={(id) => nav.show(id)}
        device={{ id: id ?? "" }}
      />
    )}
  />
);
