import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";

type FormTableColumns<T> = {
  header: string;
  render: (item: T) => React.ReactNode;
}[];

type FormTableProps<TValues> = {
  /** The objects to represent in the table. */
  rows: TValues[];
  /** The specification for how to label and render the columns in the header and rows */
  columns: FormTableColumns<TValues>;
  /** The call back to use when the user clicks the "remove" button. */
  onRemove?: (item: TValues, index: number) => void;
};

export function FormTable<TValues extends object>(
  props: FormTableProps<TValues>,
) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {props.columns.map(({ header }, i) => (
              <TableCell key={i}>{header}</TableCell>
            ))}
            {props.onRemove && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((item, i) => (
            <TableRow key={i}>
              {props.columns.map(({ render }, j) => (
                <TableCell key={j}>{render(item)}</TableCell>
              ))}
              {props.onRemove && (
                <TableCell align="right">
                  <IconButton onClick={() => props.onRemove!(item, i)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
