import {
  Box,
  Card,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { TextColumn } from "src/components/table";
import {
  useProjectCompareSubscription,
  useProjectCollectionStatusQuery,
} from "src/generated/asgard/graphql";
import { useProjectNav } from "src/resources/Project";

export const ProjectCompare = (props: { ids: string[] }) => {
  const { data } = useProjectCompareSubscription({
    variables: {
      where: { id: { _in: props.ids } },
    },
  });
  return (
    <TableContainer component={Card}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Project</TableCell>
            <TableCell>Greatness</TableCell>
            <TableCell>Recordings</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <Tooltip
              title={
                "Only includes recordings that count towards the project's test plan."
              }
            >
              <TableCell>Progress</TableCell>
            </Tooltip>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.projects?.map((project, i) => (
            <ProjectCompareRow
              key={project.id ?? i}
              projectId={project.id ?? ""}
              projectName={project.project_name?.display_name ?? ""}
              greatness={project.scores?.[0]?.greatness}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ProjectCompareRow = (props: {
  projectId: string;
  projectName: string;
  greatness?: number;
}) => {
  const projectNav = useProjectNav();
  return (
    <TableRow hover>
      <TableCell>{props.projectName}</TableCell>
      <TableCell>
        {props.greatness !== undefined
          ? `${(props.greatness * 100).toFixed(1)}%`
          : "N/A"}
      </TableCell>
      <ProjectRecordingsProgress projectId={props.projectId} />
      <TableCell>
        <Link to={projectNav.showUrl(props.projectId)}>
          <IconButton>
            <Visibility />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
};

const ProjectRecordingsProgress = (props: { projectId: string }) => {
  const subscription = useProjectCollectionStatusQuery({
    variables: {
      project_id: props.projectId,
    },
  });
  const progress = {
    numTasksRequired: 0,
    numTasksCompleted: 0,
  };
  subscription.data?.status.forEach((status) => {
    progress.numTasksRequired += status.required_total ?? 0;
    progress.numTasksCompleted += status.clean_count ?? 0;
  });
  return (
    <>
      <TableCell>
        {progress.numTasksRequired < 1 ? (
          <TextColumn value={undefined} />
        ) : (
          <ProgressBar
            numerator={progress.numTasksCompleted}
            denominator={progress.numTasksRequired}
          />
        )}
      </TableCell>
    </>
  );
};

type ProgressBarProps = { numerator: number; denominator: number };

const ProgressBar = (props: ProgressBarProps) => {
  const percentage =
    props.denominator > 0 ? (100 * props.numerator) / props.denominator : 0;
  // If percentage is less than one, round to nearest tenth.
  // Otherwise, round to nearest whole number.
  const rounded =
    percentage < 1 ? Math.round(10 * percentage) / 10 : Math.round(percentage);
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%">
        <LinearProgress variant="determinate" value={Math.min(100, rounded)} />
      </Box>
      <Box ml={1}>
        <Typography variant="caption">
          {rounded > 100 ? `>100%` : `${rounded}%`} ({props.numerator}/
          {props.denominator})
        </Typography>
      </Box>
    </Box>
  );
};
