import React, { useState } from "react";
import {
  // Button,
  // Card,
  Grid,
  // Typography
} from "@material-ui/core";
// import { AddCircleOutline, ListAltOutlined } from "@material-ui/icons";
// import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
// import {
//   FormContainer,
//   FormHeader,
//   TextField,
// } from "src/components/Form";
// import { Resource, useResourceNav } from "src/components/Resource";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import {
  usePagination,
  // like,
  uuidIsValid,
} from "src/resources/Utils";
// import {
//   Breadcrumb,
//   Breadcrumbs,
//   ShowResourceView,
//   TableView,
//   TableViewHeader,
//   Tab,
//   Tabs,
//   TabContent,
//   TabLabel,
//   TabView,
// } from "src/Layout";
// import { ResourceForm, FormAction } from "src/components/ResourceForm";
import {
  Opmodes as Opmode,
  Opmodes_Bool_Exp,
  useAllOpmodesQuery,
  // useOpmodeQuery,
  // useCreateOpmodeMutation,
  // useUpdateOpmodeMutation,
} from "src/generated/asgard/graphql";

// Config table columns from Opmode fields
export const OpmodeTable = createTable<Opmode>()({
  keys: (opmode) => opmode.id ?? "",
  title: "Opmode",
  headers: {
    id: { display: "ID" },
    number: { display: "Number" },
  },
  columns: (opmode) => ({
    id: <EllipsisColumn value={opmode.id} />,
    number: <TextColumn value={opmode.number} />,
  }),
});

// Define a new table component for Opmodes
type AllOpmodesTableProps = TableOptionals<typeof OpmodeTable> & {
  where?: Opmodes_Bool_Exp[];
};

export const AllOpmodesTable = (props: AllOpmodesTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Opmodes_Bool_Exp[] = [];

  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    var n = parseInt(search);
    if (!isNaN(n)) {
      searchFilters.push({ number: { _eq: n } });
    }
  }

  const { data } = useAllOpmodesQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, ...searchFilters] },
    },
  });

  return (
    <OpmodeTable
      {...props}
      {...pageController}
      total={data?.opmodes_aggregate?.aggregate?.count}
      data={data?.opmodes}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// // Define content to display in the main Opmode resource page
// type OpmodeIndexProps = {
//   onAddNew: () => void;
// } & TableOptionals<typeof OpmodeTable>;

// export const OpmodeIndex = (props: OpmodeIndexProps) => {
//   return (
//     <TableView>
//       <TableViewHeader title={<Typography variant="h5">Opmodes</Typography>}>
//         <Button
//           onClick={props.onAddNew}
//           variant="contained"
//           color="primary"
//           startIcon={<AddCircleOutline />}
//           disableElevation
//         >
//           New Opmode
//         </Button>
//       </TableViewHeader>
//       <Card>
//         <AllOpmodesTable {...props} selectable="none" />
//       </Card>
//     </TableView>
//   );
// };

// // Define form for creating and editing Opmodes
// const opmodeSchema = Yup.object({
//   // Add fields to be validated.
//   // name: Yup.string().required("required"),
// }).required();

// export type OpmodeFormProps = {
//   action: FormAction;
//   opmode?: {id: string};
//   onSuccess?: (id: string) => void;
// };

// export const OpmodeForm = (props: OpmodeFormProps) => {
//   const [createOpmode] = useCreateOpmodeMutation();
//   const [updateOpmode] = useUpdateOpmodeMutation();

//   const allOpmodes = useAllOpmodesQuery({
//     variables: {
//       where: { id: { _nin: props.opmode?.id ? [props.opmode.id] : [] } },
//     },
//   });

//   const existingOpmode = useOpmodeQuery({
//     variables: { id: props.opmode?.id ?? ""},
//     skip: !props.opmode,
//   });

//   return (
//     <ResourceForm
//       action={props.action}
//       schema={opmodeSchema}
//       resourceToUpdate={existingOpmode.data?.opmode}
//       transform={(opmode) => ({
//         // Add fields to be transformed.
//         // name: opmode.name,
//       })}
//       initialValues={{
//         // Add fields to be initialized.
//         // name: "",
//       }}
//       customValidator={(values, errors) => {
//         // for (let existing of allOpmodes.data?.categories ?? []) {
//         // if (existing.name === values.name) {
//         //     errors.name = `Opmode ${values.name} already exists`;
//         //     break;
//         //   }
//         // }
//       }}
//       onUpdate={async (values) => {
//         const result = await (async () => {
//           if (props.opmode?.id)
//             return await updateOpmode({
//               variables: {
//                 id: props.opmode.id,
//                 input: { ...values },
//               },
//             });
//         })();
//         props.onSuccess && props.onSuccess(result?.data?.opmode?.id ?? "");
//       }}
//       onInsert={async (values) => {
//         const result = await (async () => {
//           return await createOpmode({ variables: { input: { ...values } } });
//         })();
//         props.onSuccess && props.onSuccess(result.data?.opmode?.id ?? "");
//       }}
//       render={({ path }) => (
//         <>
//           <FormHeader>
//             {props.opmode ? "Update Opmode" : "Create New Opmode"}
//           </FormHeader>
//           <FormContainer>
//             {/* <TextField bp={{ xs: 12, sm: 8 }} name={path.name._} label="Name" /> */}
//           </FormContainer>
//         </>
//       )}
//     />
//   );
// };

// // Create a detailed 'show' page.
// type OpmodeShowProps = {
//   id: string;
//   onEditAction?: (item: DeepPartial<Opmode>) => void;
// };

// const OpmodeShow = (props: OpmodeShowProps) => {
//   const opmodeNav = useOpmodeNav();

//   const opmodeQuery = useOpmodeQuery({
//     variables: { id: props.id },
//     fetchPolicy: 'network-only',
//   });
//   const opmode = opmodeQuery.data?.opmode;
//   if (!opmode) return null;

//   return (
//     <ShowResourceView
//       title={'Insert Title Here!'}
//       breadcrumbs={
//         <Breadcrumbs>
//           <Breadcrumb
//             label='opmodes'
//             onClick={() => opmodeNav.list()}
//           />
//           <Breadcrumb label={opmode.description ?? ''} />
//         </Breadcrumbs>
//       }
//       onEditAction={() => props.onEditAction && props.onEditAction(opmode)}
//     >
//       <TabView
//         useUrlParams={true}
//         renderTabs={(tabProps) => (
//           <Tabs {...tabProps}>
//             <Tab
//               label={<TabLabel label='Tab 1' icon={<ListAltOutlined />} />}
//             />
//             <Tab
//               label={<TabLabel label='Tab 2' icon={<ListAltOutlined />} />}
//             />
//           </Tabs>
//         )}
//         renderContent={(current) => (
//           <>
//             <TabContent index={0} current={current}>
//               Insert Content for Tab 1!
//             </TabContent>
//             <TabContent index={1} current={current}>
//               Insert Content for Tab 2!
//             </TabContent>
//           </>
//         )}
//       />
//     </ShowResourceView>
//   );
// };

// // Finally, combine into full resource UI.
// const path = "opmodes";
// export const useOpmodeNav = () => useResourceNav(path)
// export const OpmodeResource = () => (
//   <Resource
//     path={path}
//     list={(nav) => (
//       <OpmodeIndex
//         onEditAction={(item) => item.id && nav.edit(item.id)}
//         onAddNew={() => nav.create()}
//       />
//     )}
//     show={(nav, id) => (
//       <OpmodeShow
//         id={id ?? ""}
//         onEditAction={(item) => item.id && nav.edit(item.id)}
//       />
//     )}
//     create={(nav) => (
//       <OpmodeForm
//         action="insert"
//         onSuccess={(id) => nav.show(id)}
//       />
//     )}
//     edit={(nav, id) => (
//       <OpmodeForm
//         action="update"
//         onSuccess={(id) => nav.show(id)}
//         opmode={{ id: id ?? "" }}
//       />
//     )}
//   />
// );
