import React from "react";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { AddCircleOutline, ListAltOutlined } from "@material-ui/icons";
import {
  FormContainer,
  FormHeader,
  IncDecField,
  TextField,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import * as Yup from "yup";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination } from "src/resources/Utils";
import {
  Breadcrumbs,
  Breadcrumb,
  Tab,
  Tabs,
  TabContent,
  TabLabel,
  TabView,
  TableView,
  TableViewHeader,
  ShowResourceView,
} from "src/Layout";
import {
  Categories as Category,
  useCategoryQuery,
  useAllCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useAllContextsQuery,
  Order_By,
} from "src/generated/asgard/graphql";
import { contextDescription, ContextTable, useContextNav } from "./context";
import { ResourceForm, FormAction } from "src/components/ResourceForm";

// Create a detailed description of the Category resource
export const categoryDescription = (
  <Typography variant="body2">
    <b>Categories</b> are used to group <b>fields</b> related to a similar
    concept.
    <br />
    <br />
    <b>Categories</b> are most useful when looking at <b>tasks</b>.
    <br />
    <br />
    Someone who is given a <b>task</b> to perform should be shown all the{" "}
    <b>fields</b> and <b>options</b> of that <b>task</b> in a consistent and{" "}
    logical order.
    <br />
    <br />
    This is achieved by grouping <b>fields</b> into <b>categories</b>, and{" "}
    making sure that <b>fields</b> are always shown together with the other{" "}
    <b>fields</b> in the its <b>category</b>.
  </Typography>
);

// Config table columns from Category fields,
export const CategoryTable = createTable<Category>()({
  keys: (category) => category.id ?? "",
  title: "Category",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    // priority: { display: "Priority" },
  },
  columns: (category) => ({
    id: <EllipsisColumn value={category.id} />,
    name: <TextColumn value={category.name} />,
    // priority: <IncrementCategoryPriority category={category} />,
  }),
});

// Define a new table component for Categories
type AllCategoriesTableProps = TableOptionals<typeof CategoryTable>;

export const AllCategoriesTable = (props: AllCategoriesTableProps) => {
  const { data } = useAllCategoriesQuery({
    variables: {
      order_by: [{ priority: Order_By.Desc }],
    },
  });
  return <CategoryTable {...props} data={data?.categories} />;
};

// Define content to display in the main Category resource page
type CategoryIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof CategoryTable>;

export const CategoryIndex = (props: CategoryIndexProps) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">Categories</Typography>}
        info={categoryDescription}
      >
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Category
        </Button>
      </TableViewHeader>
      <Card>
        <AllCategoriesTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Categories
const categorySchema = Yup.object({
  name: Yup.string().required(),
  priority: Yup.number().integer().required(),
}).required();

export type CategoryFormProps = {
  action: FormAction;
  category?: { id: string };
  onSuccess?: (id: string) => void;
};

export const CategoryForm = (props: CategoryFormProps) => {
  const [createCategory] = useCreateCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();

  const existingCategories = useAllCategoriesQuery({
    variables: {
      where: { id: { _nin: props.category?.id ? [props.category.id] : [] } },
    },
  });

  const categoryQuery = useCategoryQuery({
    variables: { id: props.category?.id ?? "" },
    skip: !props.category,
  });

  return (
    <ResourceForm
      action={props.action}
      schema={categorySchema}
      resourceToUpdate={categoryQuery.data?.category}
      transform={(category) => ({
        name: category.name,
        priority: category.priority,
      })}
      initialValues={{ name: "", priority: 0 }}
      customValidator={(values, errors) => {
        for (let existing of existingCategories.data?.categories ?? []) {
          if (existing.name === values.name) {
            errors.name = `Category ${values.name} already exists`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const result = await (async () => {
          if (props.category?.id)
            return await updateCategory({
              variables: {
                id: props.category.id,
                input: { ...values },
              },
            });
        })();
        props.onSuccess && props.onSuccess(result?.data?.category?.id ?? "");
      }}
      onInsert={async (values) => {
        const result = await (async () => {
          return await createCategory({ variables: { input: { ...values } } });
        })();
        props.onSuccess && props.onSuccess(result.data?.category?.id ?? "");
      }}
      render={({ path }) => (
        <>
          <FormHeader>
            {props.category ? "Update Category" : "Create New Category"}
          </FormHeader>
          <FormContainer>
            <TextField bp={{ xs: 12, sm: 8 }} name={path.name._} label="Name" />
            <IncDecField
              bp={{ xs: 4 }}
              name={path.priority._}
              label="Priority"
              disabled={false}
            />
          </FormContainer>
        </>
      )}
    />
  );
};

type CategoryShowProps = {
  id: string;
  onEditAction: (item: DeepPartial<Category>) => void;
};

const CategoryShow = (props: CategoryShowProps) => {
  const categoryNav = useCategoryNav();
  const contextNav = useContextNav();
  const [pageVars, pageController] = usePagination();

  const categoryQuery = useCategoryQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const category = categoryQuery.data?.category;
  const contextsQuery = useAllContextsQuery({
    variables: {
      ...pageVars,
      where: { category_id: { _eq: props.id } },
      order_by: [{ name: Order_By.Asc }],
    },
    fetchPolicy: "network-only",
  });
  if (!category) return null;

  return (
    <ShowResourceView
      title={category.name ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb label="categories" onClick={() => categoryNav.list()} />
          <Breadcrumb label={category.name} />
        </Breadcrumbs>
      }
    >
      <TabView
        useUrlParams={true}
        renderTabs={(tabsProps) => (
          <Tabs {...tabsProps}>
            <Tab
              label={
                <TabLabel
                  label="Contexts"
                  count={category.contexts?.length}
                  icon={<ListAltOutlined />}
                />
              }
            />
          </Tabs>
        )}
        renderContent={(current) => (
          <TabContent
            index={0}
            current={current}
            loading={contextsQuery.loading}
          >
            <Card style={{ marginTop: 20 }}>
              <CardContent>
                <TableView>
                  <TableViewHeader info={contextDescription}>
                    <Button
                      onClick={() => contextNav.create({ category: props.id })}
                      variant="contained"
                      color="primary"
                      disableElevation
                      fullWidth={true}
                      startIcon={<AddCircleOutline />}
                    >
                      Create new Context
                    </Button>
                  </TableViewHeader>
                  <ContextTable
                    {...pageController}
                    total={
                      contextsQuery.data?.contexts_aggregate?.aggregate?.count
                    }
                    data={contextsQuery.data?.contexts}
                    selectable="none"
                    editUrl={(item) => item.id && contextNav.editUrl(item.id)}
                    showUrl={(item) => item.id && contextNav.showUrl(item.id)}
                  />
                </TableView>
              </CardContent>
            </Card>
          </TabContent>
        )}
      />
    </ShowResourceView>
  );
};

export const useCategoryNav = () => useResourceNav("categories");
export const CategoryResource = () => (
  <Resource
    path={"categories"}
    list={(nav) => (
      <CategoryIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <CategoryShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <CategoryForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <CategoryForm
        action="update"
        onSuccess={() => nav.list()}
        category={{ id: id ?? "" }}
      />
    )}
  />
);
