import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import { FieldArray, FormikContextType, useField } from "formik";
import {
  Card,
  CardHeader,
  CardContent,
  Checkbox as MUICheckbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  TextField as MUITextField,
  IconButton,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Add, Delete, ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  useAllCategoriesQuery,
  useAllFieldOptionsQuery,
  useAllFieldsLazyQuery,
  useHypertaskQuery,
  HypertaskQuery,
  useCreateHypertaskMutation,
  Hypertask_Field_Option_Insert_Input,
  useUpdateHypertaskMutation,
} from "src/generated/asgard/graphql";
import { FormAction, ResourceForm } from "src/components/ResourceForm";
import {
  FormContainer,
  FormHeader,
  PathProxy,
  TextField,
} from "src/components/Form";
import { InfoDisplay } from "src/Layout";

/**
 * Schemas
 */
const optionSchema = Yup.object({
  id: Yup.string().required(),
  display: Yup.string().required(),
  fixed: Yup.boolean().required(),
}).required();

const fieldSchema = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().required(),
  type_id: Yup.string().required(),
  context: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  category: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  options: Yup.array(optionSchema).required(),
}).required();

const columnsSchema = Yup.object({
  description: Yup.string(),
}).required();

const formSchema = Yup.object({
  columns: columnsSchema,
  fields: Yup.array(fieldSchema).required(),
}).required();

type OptionSchema = Yup.InferType<typeof optionSchema>;
type FieldSchema = Yup.InferType<typeof fieldSchema>;
type FormSchema = Yup.InferType<typeof formSchema>;

function transformHypertask(
  hypertask: HypertaskQuery["hypertask"],
): FormSchema {
  const fields = (hypertask?.hypertask_field_options ?? []).reduce(
    (agg, hfo) => {
      const match = agg.find(({ id }) => id === hfo.field.id);
      const option = {
        id: hfo.option.id,
        display: hfo.option.display,
        fixed: true,
      };
      if (match === undefined) {
        agg.push({
          id: hfo.field.id,
          name: hfo.field.name,
          type_id: hfo.field.type_id,
          context: {
            id: hfo.field.context.id,
            name: hfo.field.context.name,
          },
          category: {
            id: hfo.field.context.category.id,
            name: hfo.field.context.category.name,
          },
          options: [option],
        });
      } else {
        match.options.push(option);
      }
      return agg;
    },
    [] as FieldSchema[],
  );
  return {
    columns: {
      description: hypertask?.description ?? "",
    },
    fields,
  };
}

function expandFields(
  fields: FieldSchema[],
): Hypertask_Field_Option_Insert_Input[] {
  return fields.reduce(
    (agg, field) => [
      ...agg,
      ...field.options
        .filter((o) => !o.fixed)
        .map((o) => ({ field_id: field.id, option_id: o.id })),
    ],
    [] as Hypertask_Field_Option_Insert_Input[],
  );
}

/*******************************************************************************
 * Top-level Form
 ******************************************************************************/
export type HypertaskFormProps = {
  onSuccess: (id: string) => void;
  action: FormAction;
  hypertask?: { id: string };
};

export const HypertaskForm = (props: HypertaskFormProps) => {
  const hypertaskQuery = useHypertaskQuery({
    variables: { id: props.hypertask?.id ?? "" },
    skip: !props.hypertask,
  });

  const [createHypertask] = useCreateHypertaskMutation();
  const [updateHypertask] = useUpdateHypertaskMutation();

  return (
    <ResourceForm
      action={props.action}
      schema={formSchema}
      resourceToUpdate={hypertaskQuery?.data?.hypertask}
      initialValues={{
        columns: {
          description: "",
        },
        fields: [],
      }}
      transform={transformHypertask}
      onInsert={async (values) => {
        const { columns, fields } = values;
        const result = await createHypertask({
          variables: {
            input: {
              ...columns,
              hypertask_field_options: {
                data: expandFields(fields).map(({ field_id, option_id }) => ({
                  field_id,
                  option_id,
                  weight: 1.0, // TODO!!! Add weights to this form...
                })),
              },
            },
          },
        });
        props.onSuccess && props.onSuccess(result.data?.hypertask?.id ?? "");
      }}
      onUpdate={async (values) => {
        if (props.hypertask?.id) {
          const { columns, fields } = values;
          const result = await updateHypertask({
            variables: {
              id: props.hypertask.id,
              columns,
              fieldOptions: expandFields(fields).map(
                ({ field_id, option_id }) => ({
                  field_id,
                  option_id,
                  hypertask_id: props.hypertask!.id,
                  weight: 1.0, // TODO!!! Add weights to this form...
                }),
              ),
            },
          });
          props.onSuccess && props.onSuccess(result.data?.hypertask?.id ?? "");
        }
      }}
      renderConfirmationDialog={({ path, formik }) => {
        return <ConfirmationDialog path={path} formik={formik} />;
      }}
      render={({ path }) => (
        <>
          <FormHeader>Hypertask {props.action}</FormHeader>
          <Typography
            variant="body1"
            color="error"
            style={{ margin: 15, marginLeft: 23 }}
          >
            Please be sure to include a "polarity" field from appropriate
            "gesture" context!
          </Typography>
          <FormContainer>
            <TextField
              bp={{ xs: 12 }}
              name={path.columns!.description!._!}
              multiline
              label="Description"
            />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Field-options:</Typography>
            </Grid>
            <TaskFieldsForm name={path.fields!._!} />
          </FormContainer>
        </>
      )}
    />
  );
};

/*******************************************************************************
 * Fields Sub-form
 ******************************************************************************/
type TaskFieldsFormProps = {
  name: string;
  disabled?: boolean;
};

const TaskFieldsForm = (props: TaskFieldsFormProps) => {
  const [formikField] = useField(props.name);
  const currentFields: FieldSchema[] = formikField.value;
  const currentFieldIds = currentFields.map(({ id }) => id);

  type CategoryItem = { id: string; name: string };
  const [selectedCategory, setSelectedCategory] = useState<CategoryItem | null>(
    null,
  );
  const categoriesQuery = useAllCategoriesQuery({
    fetchPolicy: "network-only",
  });
  const selectableCategories: CategoryItem[] =
    categoriesQuery.data?.categories?.map(({ id, name }) => ({ id, name })) ??
    [];

  // If a field from the current category is already being used, make sure user
  // can only add fields from the same context.
  const currentCategoryContextId = currentFields.find(
    (f: FieldSchema) =>
      !!selectedCategory?.id && f.category.id === selectedCategory.id,
  )?.context.id;

  type ContextItem = { id: string; name: string };
  const [selectedContext, setSelectedContext] = useState<ContextItem | null>(
    null,
  );

  const selectableContexts: ContextItem[] =
    selectedCategory === null
      ? []
      : categoriesQuery.data?.categories
          ?.find(({ id }) => id === selectedCategory.id)
          ?.contexts?.filter(
            ({ id }) =>
              currentCategoryContextId === undefined ||
              id === currentCategoryContextId,
          ) ?? [];

  type FieldItem = { id: string; name: string; type_id: string };
  const [selectedField, setSelectedField] = useState<FieldItem | null>(null);
  const [fieldsQuery, fields] = useAllFieldsLazyQuery();
  // User can only select fields that haven't been added to the form yet.
  const selectableFields: FieldItem[] =
    selectedContext === null
      ? []
      : fields.data?.fields
          ?.filter(({ id }) => !currentFieldIds.includes(id))
          .map(({ id, name, type_id }) => ({
            id,
            name,
            type_id,
          })) ?? [];

  return (
    <FieldArray
      name={props.name}
      render={(helper) => {
        return (
          <>
            {/* Category Selector */}
            <Grid item sm={6} xs={12}>
              <Autocomplete
                options={selectableCategories}
                getOptionLabel={(category) => category.name}
                getOptionSelected={(o, v) => o.id === v.id}
                disableClearable
                onChange={(_, newCategory) => {
                  setSelectedCategory(newCategory ?? null);
                  // Reset context/field selectors.
                  setSelectedContext(null);
                  setSelectedField(null);
                }}
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    label="Category"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {/*  Context Selector */}
            <Grid item sm={6} xs={12}>
              <Autocomplete
                // Let react know the component has changed
                // when the category is changed, by changing the key.
                key={selectedCategory?.id ?? ""}
                disabled={!selectedCategory}
                options={selectableContexts}
                getOptionLabel={(context) => context.name}
                getOptionSelected={(o, v) => o.id === v.id}
                disableClearable
                // Use `inputValue` to ensure that display is cleared
                // when category is changed
                inputValue={selectedContext?.name ?? ""}
                onChange={(_, newContext) => {
                  setSelectedContext(newContext ?? null);
                  setSelectedField(null);
                  if (newContext.id) {
                    fieldsQuery({
                      variables: {
                        where: {
                          context_id: { _eq: newContext.id },
                        },
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    label="Context"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {/* Field Selector */}
            <Grid item sm={10} xs={12}>
              <Autocomplete
                // Let react know the component has changed
                // after a field is added.
                key={currentFields.map((f: FieldSchema) => f.id).join(";")}
                disabled={!selectedContext || fields.loading || !!fields.error}
                options={selectableFields}
                getOptionLabel={(field) => field.name}
                getOptionSelected={(o, v) => o.id === v.id}
                disableClearable
                inputValue={selectedField?.name ?? ""}
                onChange={(_, newField) => {
                  setSelectedField(newField ?? null);
                }}
                renderInput={(params) => (
                  <MUITextField {...params} label="Field" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={
                  !(
                    selectedContext?.id &&
                    selectedCategory?.id &&
                    selectedField?.id
                  ) ||
                  fields.loading ||
                  !!fields.error
                }
                color="primary"
                variant="contained"
                onClick={() => {
                  helper.insert(0, {
                    highlight: false,
                    id: selectedField?.id ?? "",
                    type_id: selectedField?.type_id ?? "",
                    name: selectedField?.name ?? "",
                    category: {
                      id: selectedCategory?.id ?? "",
                      name: selectedCategory?.name ?? "",
                    },
                    context: {
                      id: selectedContext?.id ?? "",
                      name: selectedContext?.name ?? "",
                    },
                    options: [],
                  });
                  setSelectedField(null);
                }}
              >
                <Add />
                Field
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {currentFields.map((field: FieldSchema, index: number) => {
              return (
                <FieldForm
                  key={field.id}
                  name={`${props.name}.${index}`}
                  fieldId={field.id}
                  label={field.name}
                  onDelete={() => helper.remove(index)}
                  disableDelete={
                    field.options.filter((o) => o.fixed).length > 0
                  }
                />
              );
            })}
          </>
        );
      }}
    />
  );
};

/*******************************************************************************
 * Multi-option Sub-form
 ******************************************************************************/
type FieldFormProps = {
  fieldId: string;
  label: string;
  name: string;
  onDelete: () => void;
  disableDelete?: boolean;
};

const FieldForm = (props: FieldFormProps) => {
  const [expanded, setExpanded] = useState(true);
  const [formikField] = useField<FieldSchema>(props);
  const selectedOptions = formikField.value.options;
  const fixedOptionIds = selectedOptions
    .filter((o) => o.fixed)
    .map((o) => o.id);

  const fieldOptionsQuery = useAllFieldOptionsQuery({
    fetchPolicy: "network-only",
    variables: {
      where: { field_id: { _eq: props.fieldId } },
    },
    skip: !props.fieldId,
  });

  const selectableOptions: OptionSchema[] =
    fieldOptionsQuery.data?.fo.map((fo) => ({
      id: fo.option?.id ?? "",
      display: fo.option?.display ?? "",
      fixed: fixedOptionIds.includes(fo.option?.id ?? ""),
    })) ?? [];

  return (
    <>
      <FieldArray
        name={`${props.name}.options`}
        render={(helper) => (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={props.label}
                titleTypographyProps={{ variant: "h6" }}
                subheader={`${selectedOptions.length} option(s) selected`}
                avatar={
                  <IconButton
                    onClick={() =>
                      expanded ? setExpanded(false) : setExpanded(true)
                    }
                  >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                }
                action={
                  <IconButton
                    disabled={props.disableDelete ?? false}
                    onClick={props.onDelete}
                  >
                    <Delete />
                  </IconButton>
                }
              />
              <Collapse in={expanded}>
                <CardContent>
                  <FormGroup>
                    {selectableOptions.map((o) => {
                      const matchIndex = selectedOptions.findIndex(
                        ({ id }) => id === o.id,
                      );
                      return (
                        <FormControlLabel
                          key={o.id}
                          control={
                            <MUICheckbox
                              checked={matchIndex !== -1}
                              disabled={fixedOptionIds.includes(o.id)}
                              onChange={(ev) => {
                                if (ev.target.checked) {
                                  helper.push(o);
                                } else if (matchIndex !== -1) {
                                  helper.remove(matchIndex);
                                }
                              }}
                            />
                          }
                          label={o.display}
                        />
                      );
                    })}
                  </FormGroup>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
        )}
      />
    </>
  );
};

/*******************************************************************************
 * Confirmation Dialog
 ******************************************************************************/
type ConfirmationDialogProps = {
  path: PathProxy<FormSchema, FormSchema>;
  formik: FormikContextType<FormSchema>;
};

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const fields = props.formik.values.fields;

  const staticFields = fields.filter((f) => f.options.length === 1);
  const dynamicFields = fields.filter((f) => f.options.length > 1);

  // Find all the permutations of tasks possible from the field/options
  // selected in the form.
  const permutations: OptionSchema[][] = useMemo(() => {
    const product: OptionSchema[][] = [];
    if (dynamicFields.length < 1) return [];
    const max = dynamicFields.length - 1;
    function helper(arr: OptionSchema[], i: number) {
      const numFields = dynamicFields[i].options.length;
      for (var j = 0, l = numFields; j < l; j++) {
        var copy = [...arr.slice(0), dynamicFields[i].options[j]];
        if (i === max) product.push(copy);
        else helper(copy, i + 1);
      }
    }
    helper([], 0);
    return product;
  }, [dynamicFields]);

  // Construct id columns to help with row-spanning;
  const columns = dynamicFields.map((_, i) => permutations.map((p) => p[i].id));
  const countRepeatsOfFirstValue = (values: string[]) => {
    let i = 0;
    const valToMatch = values[0];
    while (values[i] === valToMatch) {
      i++;
    }
    return i;
  };

  return (
    <Grid container spacing={2}>
      {staticFields.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Static Fields</Typography>
          </Grid>
          <InfoDisplay
            bp={{ xs: 12 }}
            items={staticFields.map((f) => ({
              label: f.name,
              value: f.options[0].display,
            }))}
          />
        </>
      )}
      {dynamicFields.length > 0 && (
        <>
          <Grid item xs={12}>
            <Divider />
            <Typography variant="h6">Dynamic Fields</Typography>
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  {dynamicFields.map((f) => (
                    <TableCell key={f.id}>{f.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {permutations.map((options, iRow) => (
                  <TableRow key={iRow}>
                    {options.map((o, iCol) => {
                      const col = columns[iCol];
                      const prev = iRow > 0 ? col[iRow - 1] : null;
                      if (o.id !== prev) {
                        const rowSpan = countRepeatsOfFirstValue(
                          col.slice(iRow),
                        );
                        return (
                          <TableCell rowSpan={rowSpan} key={o.id}>
                            {o.display}
                          </TableCell>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </>
      )}
    </Grid>
  );
};
