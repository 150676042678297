import React, { useState } from "react";
import { SearchBar } from "src/components/filters";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import {
  Order_By,
  Projects as Project,
  Projects_Bool_Exp,
  Projects_Order_By,
  useAllProjectsQuery,
} from "src/generated/asgard/graphql";
import { like, orderBy, usePagination, uuidIsValid } from "src/resources/Utils";

export const ProjectTable = createTable<Project>()({
  keys: (project) => project.id!,
  title: "Projects",
  headers: {
    id: { display: "ID" },
    customer: { display: "Customer" },
    model: { display: "Model" },
    deliverable: { display: "Deliverable" },
  },
  columns: (project) => ({
    id: <EllipsisColumn value={project.id ?? ""} />,
    customer: <TextColumn value={project.customer?.codename} />,
    model: <TextColumn value={project?.model?.codename} />,
    deliverable: <TextColumn value={project?.deliverable?.name} />,
  }),
});

type AllProjectsTableProps = TableOptionals<typeof ProjectTable> & {
  where?: Projects_Bool_Exp[];
};

export const AllProjectsTable = ({ ...props }: AllProjectsTableProps) => {
  const [search, setSearch] = useState("");
  const [pageVars, pageController] = usePagination();
  const [order, setOrder] = useState<Projects_Order_By>({
    updated_at: Order_By.Desc,
  });

  const term = like(search);
  const filters: Projects_Bool_Exp[] = [
    { customer: { codename: { _ilike: term } } },
    { model: { codename: { _ilike: term } } },
    { deliverable: { name: { _ilike: term } } },
  ];

  if (uuidIsValid(search)) {
    filters.push({ id: { _eq: search } });
  }

  const query = useAllProjectsQuery({
    variables: {
      ...pageVars,
      order_by: [order],
      where: { _and: [{ _and: props.where }, { _or: filters }] },
    },
  });

  const { data } = query;

  return (
    <ProjectTable
      {...props}
      {...pageController}
      total={data?.projects_aggregate?.aggregate?.count}
      data={data?.projects}
      tools={<SearchBar onChange={setSearch} />}
      orderColumn={(order) => {
        if (!order) {
          setOrder({ updated_at: Order_By.Desc });
          return;
        }

        setOrder({
          id: orderBy(order.id),
          customer: { codename: orderBy(order.customer) },
          model: { codename: orderBy(order.model) },
          deliverable: { name: orderBy(order.deliverable) },
        });
      }}
    />
  );
};
