import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { useMemo } from "react";
import { useRecordingTagCountByDayQuery } from "src/generated/asgard/graphql";
import {
  uuidArrToPostgresLiteral,
  strArrToPostgresLiteral,
} from "src/resources/Utils";

export type TagPlotTypes = "pie" | "bar";
export type TagDisplayGroups = "show_all" | "show_dirty" | "show_highlighted";
type RecordingsQualityChartProps = {
  project_ids: string;
  tag_options: TagDisplayGroups;
  plot_type: TagPlotTypes;
};

export function RecordingsQualityChart({
  project_ids,
  tag_options,
  plot_type,
}: RecordingsQualityChartProps) {
  // plot pie or bar chart
  // prepare and filter data
  const tag_count = useRecordingTagCountByDayQuery({
    variables: {
      project_ids: uuidArrToPostgresLiteral([project_ids]),
      exclude_tag_names: strArrToPostgresLiteral([]),
    },
  });
  const data = tag_count.data?.recording_tag_count_by_day;
  const unique_tag_names = data
    ?.map((d) => d.tag_name ?? "")
    .filter((value, index, self) => self.indexOf(value) === index);
  let filtered_data = data;
  switch (tag_options) {
    case "show_all":
      break;
    case "show_dirty":
      filtered_data = data?.filter((row) => {
        return row.tag_is_dirty;
      });
      break;
    case "show_highlighted":
      filtered_data = data?.filter((row) => {
        return row.tag_is_highlighted;
      });
      break;
    default:
      break;
  }
  let serializeFunc:
    | typeof SerializeBarChartData
    | typeof SerializeBarChartData;
  switch (plot_type) {
    case "bar":
      serializeFunc = SerializeBarChartData;
      break;
    case "pie":
      serializeFunc = SerializePieChartData;
      break;
    default:
      serializeFunc = SerializeBarChartData;
      break;
  }
  const grouped_values = useMemo(
    () => serializeFunc(filtered_data),
    [filtered_data, serializeFunc],
  );
  if (!data) {
    return <div>loading...</div>;
  }
  const commonDefs = [
    {
      id: "dots",
      type: "patternDots",
      background: "inherit",
      color: "rgba(255, 255, 255, 0.3)",
      size: 4,
      padding: 1,
      stagger: true,
    },
    {
      id: "lines",
      type: "patternLines",
      background: "inherit",
      color: "rgba(255, 255, 255, 0.3)",
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
  ];
  switch (plot_type) {
    case "bar":
      return (
        <ResponsiveBar
          data={grouped_values}
          keys={unique_tag_names}
          indexBy="formatted_day"
          margin={{ top: 15, right: 20, bottom: 50, left: 60 }}
          padding={0.3}
          colors={{ scheme: "paired" }}
          defs={commonDefs}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: "date (Day/Month)",
            legendPosition: "middle",
            legendOffset: 40,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "tag count",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "top-left",
              direction: "column",
              justify: false,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          fill={[
            {
              id: "dots",
              match: (d: any) => {
                const tag_is_dirty_key = `${d.data.id}_is_dirty`;
                return d?.data?.data[tag_is_dirty_key];
              },
            },
          ]}
          tooltip={({ id, data }) => {
            const description_key = `${id}_description`;
            return (
              <div>
                <span>{data[description_key]}</span>
              </div>
            );
          }}
        />
      );
    case "pie":
      return (
        <ResponsivePie
          data={grouped_values}
          margin={{ top: 30, right: 20, bottom: 50, left: 60 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          isInteractive={true}
          enableRadialLabels={false}
          legends={[
            {
              anchor: "top-left",
              direction: "column",
              justify: false,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          radialLabel={(d) => {
            return `${d.id} ${d.percent}`;
          }}
          defs={commonDefs}
          fill={[
            {
              id: "dots",
              match: (d: any) => {
                return d?.data?.is_dirty;
              },
            },
          ]}
          animate={true}
          tooltip={(d) => {
            return (
              <div>
                <span>{d.tag_description}</span>
              </div>
            );
          }}
        />
      );
    default:
      return <div>error</div>;
  }
}

function SerializeBarChartData(data: any) {
  const grouped_data: { [day: string]: any } = {};
  if (Array.isArray(data)) {
    data.forEach((row) => {
      if (!(row.day in grouped_data)) {
        grouped_data[row.day] = {
          formatted_day: formatDay(row.day),
        };
      }
      if (!(`${row.tag_name}` in grouped_data[row.day])) {
        grouped_data[row.day][`${row.tag_name}_description`] =
          row.tag_description;
        grouped_data[row.day][`${row.tag_name}_is_dirty`] = row.tag_is_dirty;
        // grouped_data[row.day][`${row.tag_name}_fill`] = row.tag_is_dirty ? "dots" : "lines";
      }
      grouped_data[row.day][row.tag_name] = row.count;
    });
    const grouped_values = Object.values(grouped_data);
    return grouped_values;
  } else {
    return [];
  }
}

function SerializePieChartData(data: any) {
  type rowType = {
    id: string;
    label: string;
    tag_description: string;
    value: number;
    percent: string;
    // fill: "dots" | "line"
    is_dirty: boolean;
  };
  const groupedData: { [tag_name: string]: rowType } = {};
  let nTotalTags: number = 0;
  if (Array.isArray(data)) {
    data.forEach((row) => {
      if (!(row.tag_name in groupedData)) {
        groupedData[row.tag_name] = {
          id: row.tag_name,
          label: row.tag_name,
          tag_description: row.tag_description,
          value: 0,
          percent: "0%",
          // "fill": row.tag_is_dirty ? "dots" : "line",
          is_dirty: row.tag_is_dirty,
        };
      }
      groupedData[row.tag_name]["value"] += row.count;
      nTotalTags += row.count;
    });
    Object.keys(groupedData).forEach((k: string) => {
      groupedData[k]["percent"] = `${(
        (groupedData[k]["value"] / nTotalTags) *
        100
      ).toFixed(1)}%`;
    });
    const groupedValues: rowType[] = Object.values(groupedData);
    return groupedValues;
  } else {
    return [];
  }
}

function formatDay(day: string) {
  const d = new Date(Date.parse(day));
  return `${d.getDate()}/${d.getMonth() + 1}`;
}
