import React, { useState, Fragment, useEffect, useCallback } from "react";
import {
  Chip,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Link } from "react-router-dom";
import {
  Task_Groups_Hierarchy_Groups,
  Projects,
  useGroupedProjectTasksQuery,
  useAllProjectsQuery,
} from "src/generated/asgard/graphql";
import { useTaskNav } from "src/resources/CollectionPlan";

const StyledChip = withStyles({
  root: {
    margin: 2,
  },
})(Chip);

/**
 * A "grouping" is a unique set of field-option pairs, used to group a set of
 * tasks that all possess the given pair.
 */
type Grouping = {
  fieldId: string;
  optionId: string | null;
  optionDisplay: string;
}[];

const keyFromGrouping = (grouping: Grouping) => {
  return grouping.map((g) => g.optionId ?? "null").join("/");
};

const displayFromGrouping = (grouping: Grouping) => {
  return grouping.map((g) => g.optionDisplay).join(" / ");
};

type Task = { id: string; number: number };

/**
 * A "task group" is the set of tasks that possess the unique set of
 * field-option pairs of a given "grouping".
 */
type TaskGroup = {
  grouping: Grouping;
  groupingKey: string;
  tasks: Task[];
};

type GroupListContentProps = {
  groups: DeepPartial<Task_Groups_Hierarchy_Groups>[];
  tasks: Task[];
  taskSearchId?: string;
};
const GroupListContent = (props: GroupListContentProps) => {
  const taskNav = useTaskNav();
  const { groups, tasks, taskSearchId } = props;
  const [expanded, setExpanded] = useState<string[]>([]);

  // For each task, determine which set of options it belongs to, for
  // fields in this group.
  const [first, ...rest] = groups;
  const taskGroups: TaskGroup[] = tasks.reduce((agg, task) => {
    const grouping: Grouping =
      first.group?.group_fields?.map((gf) => {
        const matchingFieldOption =
          gf.field?.field_options?.find(
            (fo) =>
              fo.task_field_options?.findIndex(
                (tfo) => tfo.task_id === task.id,
              ) !== -1,
          ) ?? null;
        return {
          fieldId: gf.field_id ?? "",
          optionId: matchingFieldOption?.option_id ?? null,
          optionDisplay: matchingFieldOption?.option?.display ?? "–",
        };
      }) ?? [];
    const groupingKey = keyFromGrouping(grouping);
    const existing = agg.find((gt) => gt.groupingKey === groupingKey);
    if (!existing) {
      return [...agg, { grouping, groupingKey, tasks: [task] }];
    } else {
      existing.tasks.push(task);
      return agg;
    }
  }, [] as TaskGroup[]);

  // Method for creating a unique key for any "task group" in this grouping
  const keyFromTaskGroup = useCallback(
    (tg: TaskGroup) => `${tg.groupingKey}-${first?.group_id ?? ""}`,
    [first],
  );

  // If user singles out a task, adjust the list of expanded task groups
  const [lastTaskSearchId, setLastTaskSearchId] = useState<string | undefined>(
    undefined,
  );
  useEffect(() => {
    if (taskSearchId !== lastTaskSearchId) {
      if (taskSearchId !== undefined) {
        setExpanded(
          taskGroups
            .filter(
              (tg) => tg.tasks.findIndex((t) => t.id === taskSearchId) !== -1,
            )
            .map((tg) => keyFromTaskGroup(tg)),
        );
      }
      setLastTaskSearchId(taskSearchId);
    }
  }, [
    taskSearchId,
    setExpanded,
    lastTaskSearchId,
    setLastTaskSearchId,
    keyFromTaskGroup,
    taskGroups,
  ]);

  const classes = useListStyles();

  return (
    <>
      {taskGroups.map((taskGroup) => {
        const taskGroupKey = keyFromTaskGroup(taskGroup);
        const taskGroupExpanded = expanded.includes(taskGroupKey);
        return (
          <Fragment key={taskGroupKey}>
            <ListItem
              dense
              ContainerComponent={"div"}
              style={{ maxWidth: "100%" }}
              id={taskGroupKey}
              button
              onClick={() => {
                setExpanded((expanded) =>
                  expanded.includes(taskGroupKey)
                    ? expanded.filter((key) => key !== taskGroupKey)
                    : [...expanded, taskGroupKey],
                );
              }}
            >
              <ListItemAvatar>
                {taskGroupExpanded ? <ExpandLess /> : <ExpandMore />}
              </ListItemAvatar>
              <ListItemText
                style={{ margin: 0 }}
                primary={displayFromGrouping(taskGroup.grouping)}
                secondary={first.group?.name ?? ""}
              />
              <ListItemSecondaryAction>
                <Chip size="small" label={taskGroup.tasks.length} />
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse mountOnEnter={true} in={taskGroupExpanded}>
              <List dense component="div" className={classes.listItem}>
                {rest.length > 0 ? (
                  <GroupListContent
                    groups={rest}
                    tasks={taskGroup.tasks}
                    taskSearchId={taskSearchId}
                  />
                ) : (
                  <ListItem>
                    <div>
                      {[...taskGroup.tasks]
                        .sort((a, b) => a.number - b.number)
                        .map(({ id, number }) => (
                          <Link
                            key={id}
                            to={taskNav.showUrl(id)}
                            style={{ textDecoration: "none" }}
                          >
                            <StyledChip
                              clickable
                              size="small"
                              label={number}
                              color={
                                id === taskSearchId ? "secondary" : "primary"
                              }
                            />
                          </Link>
                        ))}
                    </div>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </Fragment>
        );
      })}
    </>
  );
};

const useListStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      borderLeft: "1px dashed #ccc",
      "&:hover": {
        borderLeft: "1px solid",
        borderColor: theme.palette.primary.main,
      },
      marginLeft: 26,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
);

type GroupedProjectTasksProps = {
  hierarchyId: string;
};

export const GroupedProjectTasks = (props: GroupedProjectTasksProps) => {
  const [projectInput, setProjectInput] = useState("");
  const [selectedProject, setSelectedProject] =
    useState<DeepPartial<Projects> | null>(null);
  const [taskInput, setTaskInput] = useState("");
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const projectsQuery = useAllProjectsQuery({});
  const projectOptions = (projectsQuery.data?.projects ??
    []) as DeepPartial<Projects>[];
  const groupedProjectTasksQuery = useGroupedProjectTasksQuery({
    variables: {
      hierarchy_id: props.hierarchyId,
      project_id: selectedProject?.id ?? "",
    },
    skip: !selectedProject?.id,
  });
  const hierarchyGroups =
    groupedProjectTasksQuery.data?.hierarchy?.hierarchy_groups ?? [];

  // Gather all tasks that might fit into this "hierarchy".
  const allTasks: Task[] = [];
  hierarchyGroups.forEach((hg) => {
    hg.group.group_fields.forEach((gf) => {
      gf.field.field_options.forEach((fo) => {
        fo.task_field_options.forEach((tfo) => {
          if (allTasks.findIndex((task) => task.id === tfo.task_id) === -1) {
            allTasks.push({
              id: tfo.task_id,
              number: tfo.task.legacy_template_id,
            });
          }
        });
      });
    });
  });

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} xs={12}>
        <Autocomplete
          fullWidth
          options={projectOptions}
          getOptionLabel={(project) =>
            project.project_name?.name ?? project.id ?? ""
          }
          getOptionSelected={(a, b) => a.id === b.id}
          value={selectedProject}
          inputValue={projectInput}
          onInputChange={(_, nv) => setProjectInput(nv)}
          onChange={(_event, nv) => {
            setSelectedProject(nv ?? null);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Tasks from Project"}
              variant="outlined"
            />
          )}
          loading={projectsQuery.loading}
        ></Autocomplete>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Autocomplete
          fullWidth
          options={allTasks}
          getOptionLabel={(task) => `${task.number}`}
          getOptionSelected={(a, b) => a.id === b.id}
          value={selectedTask}
          inputValue={taskInput}
          onInputChange={(_, nv) => setTaskInput(nv)}
          onChange={(_event, nv) => {
            setSelectedTask(nv ?? null);
          }}
          renderInput={(params) => (
            <TextField {...params} label={"Isolate Task"} variant="outlined" />
          )}
          loading={groupedProjectTasksQuery.loading}
        ></Autocomplete>
      </Grid>
      <Grid item xs={12}>
        <List dense>
          <GroupListContent
            groups={hierarchyGroups}
            tasks={allTasks}
            taskSearchId={selectedTask?.id ?? undefined}
          />
        </List>
      </Grid>
    </Grid>
  );
};
