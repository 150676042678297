import React from "react";
import { Button, Card, Typography } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { FormikProvider, useFormik } from "formik";
import {
  ActionInfo,
  FormContainer,
  FormHeader,
  FormAction,
} from "src/components/Form";
import { Resource } from "src/components/Resource";
import * as Yup from "yup";
import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination } from "src/resources/Utils";
import { TableView, TableViewHeader } from "src/Layout";
import {
  Order_By,
  Types as Type,
  useAllTypesQuery,
} from "src/generated/asgard/graphql";

// Config table columns from Type fields,
export const TypeTable = createTable<Type>()({
  keys: (type) => type.id ?? "",
  title: "Type",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
  },
  columns: (type) => ({
    id: <EllipsisColumn value={type.id} />,
    name: <TextColumn value={type.name} />,
  }),
});

// Define a new table component for Types
type AllTypesTableProps = TableOptionals<typeof TypeTable>;

export const AllTypesTable = (props: AllTypesTableProps) => {
  const [pageVars, pageController] = usePagination();
  const { data } = useAllTypesQuery({
    variables: {
      ...pageVars,
      order_by: [{ name: Order_By.Asc }],
    },
  });

  return (
    <TypeTable
      {...props}
      {...pageController}
      total={data?.types_aggregate?.aggregate?.count}
      data={data?.types}
    />
  );
};

// Define content to display in the main Type resource page
type TypeIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof TypeTable>;

export const TypeIndex = (props: TypeIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Types</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Type
        </Button>
      </TableViewHeader>
      <Card>
        <AllTypesTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing Types
const typeSchema = Yup.object({
  // Add fields to be validated.
  // name: Yup.string().required("required"),
}).required();

type TypeFormSchema = Yup.InferType<typeof typeSchema>;

type TypeFormAction = "insert" | "update";

type TypeFormInfo = {
  title: string;
  submitButtonTitle: string;
  notification: {
    success: (id: string) => {
      title: string;
      description: string;
    };
    error: (id: string) => {
      title: string;
      description: string;
    };
  };
};

export type TypeFormProps = {
  type?: { id: string };
  action: TypeFormAction;
  onSuccess?: (id: string) => void;
};

export const TypeForm = (props: TypeFormProps) => {
  const formInfo: ActionInfo<TypeFormAction, TypeFormInfo> = {
    insert: {
      title: "Create new Type",
      submitButtonTitle: "Create Type",
      notification: {
        success: (id) => ({
          title: "Type Created",
          description: `id: ${id}`,
        }),
        error: (id) => ({
          title: "Unable to Create Type",
          description: "An error occured when trying to create type.",
        }),
      },
    },
    update: {
      title: "Update Type",
      submitButtonTitle: "Update Type",
      notification: {
        success: (id) => ({
          title: "Type Updated",
          description: `id: ${id}`,
        }),
        error: (id) => ({
          title: "Could Not Update Type",
          description: `An error occured when trying to update type ${id}.`,
        }),
      },
    },
  };

  const info = formInfo[props.action];

  // const notification = useNotification();

  const formik = useFormik<TypeFormSchema>({
    validationSchema: typeSchema,
    initialValues: {
      // Set initial form values
      // name: ""
    },
    onSubmit: async (values) => {
      // try {
      //   const {
      //     // Desctructure to extract any related entities.
      //     ...vals
      //   } = values;
      //   const result = await (async () => {
      //     if (props.action === "update" && props.type) {
      //       return await updateType({
      //         variables: {
      //           id: props.type.id,
      //           input: {
      //             ...vals,
      //             // Add additional `update` arguments.
      //           },
      //         },
      //       });
      //     }
      //     else {
      //       return await createType({
      //         variables: {
      //           input: {
      //             ...vals,
      //             // Add additional `create` arguments.
      //           },
      //         },
      //       });
      //     }
      //   })();
      //   props.onSuccess && props.onSuccess(result.data?.type?.id ?? "");
      // } catch (e) {
      //   const err = JSON.stringify(e.graphQLErrors, null, 2);
      //   const note = info.notification.error("");
      //   notification.create({
      //     severity: "error",
      //     body: md()
      //       .header("h4", "The json error")
      //       .syntax("json", err)
      //       .get(),
      //     ...note,
      //   });
      // }
    },
  });

  // const [createType] = useCreateTypeMutation();
  // const [updateType] = useUpdateTypeMutation();

  return (
    <FormikProvider value={formik}>
      <FormHeader>
        <Typography variant="h6">{info.title}</Typography>
      </FormHeader>
      <FormContainer>
        {/* <TextField bp={{ xs: 12 }} name={"name"} label="Name" /> */}
        <FormAction>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            {info.submitButtonTitle}
          </Button>
        </FormAction>
      </FormContainer>
    </FormikProvider>
  );
};

// Finally, combine into full resource UI.
export const TypeResource = () => (
  <Resource
    path="types"
    list={(nav) => (
      <TypeIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    // show={(_, id) => <TypesShow id={id ?? ""} />}
    create={(nav) => <TypeForm action="insert" onSuccess={() => nav.list()} />}
    edit={(nav, id) => (
      <TypeForm
        action="update"
        onSuccess={() => nav.list()}
        type={{ id: id ?? "" }}
      />
    )}
  />
);
