import React from "react";
import { ProjectTaskEditor } from "./ProjectTaskEditor";
import { ProjectHypertaskEditor } from "./ProjectHypertaskEditor";
import { Tablature } from "src/Layout";

type ProjectCollectionEditorProps = {
  projectId: string;
};

export const ProjectCollectionEditor = (
  props: ProjectCollectionEditorProps,
) => {
  return (
    <Tablature
      useUrlParams={false}
      tabs={[
        {
          name: "tasks",
          label: "Tasks",
          content: <ProjectTaskEditor projectId={props.projectId} />,
        },
        {
          name: "hypertasks",
          label: "Hypertasks",
          content: <ProjectHypertaskEditor projectId={props.projectId} />,
        },
      ]}
    />
  );
};
