import {
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination } from "src/resources/Utils";

import {
  Ground_Truth_Types,
  Ground_Truth_Types_Bool_Exp,
  useAllGroundTruthTypesQuery,
} from "src/generated/asgard/graphql";

// Config table columns from GroundTruthType fields
export const GroundTruthTypeTable = createTable<Ground_Truth_Types>()({
  keys: (gt_type) => gt_type.id ?? "",
  title: "Ground Truth Type",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    description: { display: "Description" },
    default_near_threshold: { display: "Default Near Threshold [mm]" },
    default_far_threshold: { display: "Default Far Threshold [mm]" },
  },
  columns: (gt_type) => ({
    id: <EllipsisColumn value={gt_type.id} />,
    name: <TextColumn value={gt_type.name} />,
    description: <TextColumn value={gt_type.description} />,
    default_near_threshold: (
      <TextColumn value={gt_type.default_near_threshold} />
    ),
    default_far_threshold: <TextColumn value={gt_type.default_far_threshold} />,
  }),
});

// Define a new table component for GroundTruthTypes
type AllGroundTruthTypesTableProps = TableOptionals<
  typeof GroundTruthTypeTable
> & {
  where?: Ground_Truth_Types_Bool_Exp[];
};

export const AllGroundTruthTypesTable = (
  props: AllGroundTruthTypesTableProps,
) => {
  const [pageVars, pageController] = usePagination();
  const { data } = useAllGroundTruthTypesQuery({
    variables: {
      ...pageVars,
    },
  });

  return (
    <GroundTruthTypeTable
      {...props}
      {...pageController}
      total={data?.ground_truth_types_aggregate?.aggregate?.count}
      data={data?.ground_truth_types}
    />
  );
};
