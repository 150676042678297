import React, { Fragment, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { createHash } from "crypto";
import { FieldArray, FormikContextType, useField } from "formik";
import {
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  TextField as MUITextField,
  IconButton,
  Button,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  CheckboxProps,
  Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  Add,
  Attachment,
  Delete,
  Info,
  Launch,
  ListAltOutlined,
  Subscriptions,
} from "@material-ui/icons";
import {
  Field_Options_Order_By,
  Order_By,
  Task_Field_Option,
  Task_Field_Option_Order_By,
  Tasks,
  useAllCategoriesQuery,
  useAllFieldOptionsQuery,
  useAllFieldsLazyQuery,
  useTaskLazyQuery,
  useTaskFieldOptionHashesQuery,
  useInsertTasksMutation,
  Tasks_Insert_Input,
  Media_Type_Enum,
  Task_Media_Constraint,
  Task_Media_Update_Column,
  Media_Constraint,
  Media_Update_Column,
} from "src/generated/asgard/graphql";
import { GridBreakpoints, TabLabel, Tablature } from "src/Layout";
import { ResourceForm } from "src/components/ResourceForm";
import { MediaFileUploadField } from "src/components/MediaUpload";
import {
  DrawerButton,
  FormContainer,
  FormHeader,
  MultiSelect,
  PathProxy,
  TextField,
} from "src/components/Form";
import {
  AllMediaTable,
  AllTasksTable,
} from "src/resources/CollectionPlan/task";
import { useNotification } from "src/Notification";

const fieldOptionOrder: Field_Options_Order_By[] = [
  {
    field: {
      context: { category: { name: Order_By.Asc } },
    },
  },
  {
    field: {
      name: Order_By.Asc,
    },
  },
];
const taskFieldOptionOrder: Task_Field_Option_Order_By[] = fieldOptionOrder.map(
  (clause) => ({
    field_option: clause,
  }),
);

/**
 * Schemas
 */
const optionSchema = Yup.object({
  id: Yup.string().required(),
  display: Yup.string().required(),
  field_option_id: Yup.string().required(),
}).required();

const fieldSchema = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().required(),
  type_id: Yup.string().required(),
  context: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  category: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  }).required(),
  options: Yup.array(optionSchema).required(),
  highlight: Yup.boolean().required(),
}).required();

const columnsSchema = Yup.object({
  note: Yup.string(),
  is_template: Yup.boolean().required(),
  is_test: Yup.boolean().required(),
}).required();

const mediaSchema = Yup.object({
  id: Yup.string().required(),
  url: Yup.string().required(),
  title: Yup.string().required(),
  description: Yup.string().nullable().required(),
  type: Yup.mixed<Media_Type_Enum>()
    .oneOf(Object.values(Media_Type_Enum))
    .required(),
}).required();

const fieldOptionGroupSchema = Yup.object({
  include: Yup.boolean().required(),
  matchingTaskNumbers: Yup.array(Yup.string().required()),
  options: Yup.array(optionSchema).required(),
}).required();

const formSchema = Yup.object({
  columns: columnsSchema,
  fields: Yup.array(fieldSchema).required(),
  fieldOptionGroups: Yup.array(fieldOptionGroupSchema).required(),
  media: Yup.array(mediaSchema),
}).required();

type OptionSchema = Yup.InferType<typeof optionSchema>;
type FieldSchema = Yup.InferType<typeof fieldSchema>;
type FieldOptionGroupSchema = Yup.InferType<typeof fieldOptionGroupSchema>;
type MediaSchema = Yup.InferType<typeof mediaSchema>;
type FormSchema = Yup.InferType<typeof formSchema>;

/**
 * Transformations/Helpers
 */
function transformTaskFields(
  taskFieldOptions: DeepPartial<Task_Field_Option>[],
): FieldSchema[] {
  return taskFieldOptions.map((tfo, i) => ({
    id: tfo.field_option?.field?.id ?? "",
    name: tfo.field_option?.field?.display ?? "",
    type_id: tfo.field_option?.field?.type_id ?? "",
    category: {
      id: tfo.field_option?.field?.context?.category?.id ?? "",
      name: tfo.field_option?.field?.context?.category?.name ?? "",
    },
    context: {
      id: tfo.field_option?.field?.context?.id ?? "",
      name: tfo.field_option?.field?.context?.name ?? "",
    },
    options: [
      // When transforming/cloning an existing task, there should only be a
      // single option selected for a field.
      {
        id: tfo.field_option?.option?.id ?? "",
        display: tfo.field_option?.option?.display ?? "",
        field_option_id: tfo.field_option?.id ?? "",
      },
    ],
    highlight: tfo.highlight ?? false,
  }));
}

function transformTask(task: DeepPartial<Tasks>): FormSchema {
  return {
    columns: {
      note: task?.note ?? "",
      is_template: task?.is_template ?? false,
      is_test: task.is_test ?? false,
    },
    fields: transformTaskFields(task?.task_field_options ?? []),
    fieldOptionGroups: [],
    media: [],
  };
}

/**
 * Top-Level Form
 */
export type TaskFormProps = {
  onSuccess: () => void;
};

export const TaskForm = (props: TaskFormProps) => {
  const notification = useNotification();

  const [insertTasks] = useInsertTasksMutation();

  // Async function to insert to the database.
  async function insert(values: FormSchema) {
    const tasks: Tasks_Insert_Input[] = values.fieldOptionGroups
      .filter((g) => g.include)
      .map((g) => {
        return {
          ...values.columns,
          force_ground_truth_to_negative: false,
          task_field_options: {
            data: g.options.map((o, i) => ({
              highlight: values.fields[i].highlight,
              field_option_id: o.field_option_id,
            })),
          },
          task_media: {
            on_conflict: {
              constraint: Task_Media_Constraint.TaskMediaPkey,
              update_columns: [
                Task_Media_Update_Column.TaskId,
                Task_Media_Update_Column.MediaId,
              ],
            },
            data: values.media!.map((m) => ({
              media: {
                on_conflict: {
                  constraint: Media_Constraint.MediaUrlKey,
                  update_columns: [
                    Media_Update_Column.Title,
                    Media_Update_Column.Url,
                    Media_Update_Column.Description,
                  ],
                },
                data: {
                  title: m?.title,
                  url: m?.url,
                  description: m?.description,
                  type: m?.type,
                },
              },
            })),
          },
        };
      });
    const result = await insertTasks({
      variables: { tasks },
    });
    notification.create({
      flashOnly: true,
      severity: "success",
      title: `inserted ${result.data?.tasks?.returning?.length ?? 0} new tasks`,
    });
    props.onSuccess();
  }

  // Setup lazy query for a task the user wants to "clone".
  const [taskToCloneQuery, taskToClone] = useTaskLazyQuery({
    fetchPolicy: "network-only",
  });
  const [taskToCloneLoading, setTaskToCloneLoading] = useState(false);
  useEffect(() => {
    if (taskToClone.loading || taskToClone.error) return;
    setTaskToCloneLoading(false);
  }, [taskToCloneLoading, setTaskToCloneLoading, taskToClone]);

  return (
    <ResourceForm
      action={"insert"}
      schema={formSchema}
      resourceToUpdate={undefined}
      initialValues={{
        columns: {
          note: "",
          is_template: false,
          is_test: false,
        },
        fields: [],
        fieldOptionGroups: [],
        media: [],
      }}
      transform={transformTask}
      modifyForm={
        !!taskToClone?.data?.task
          ? (path, formik) => {
              const clone = taskToClone.data!.task!;
              formik.setFieldValue(
                path.fields?._ ?? "fields",
                transformTaskFields(clone.task_field_options ?? []),
              );
            }
          : undefined
      }
      disableSubmit={() => taskToCloneLoading}
      onInsert={insert}
      onUpdate={() => null}
      renderConfirmationDialog={({ path, formik }) => {
        return <ConfirmationDialog path={path} formik={formik} />;
      }}
      render={({ path, formik }) => (
        <>
          <FormHeader>Create New Tasks</FormHeader>
          <Typography
            variant="body1"
            color="error"
            style={{ margin: 15, marginLeft: 23 }}
          >
            Please be sure to include a "polarity" field from appropriate
            "gesture" context!
          </Typography>
          <Tablature
            useUrlParams={false}
            tabs={[
              {
                name: "general",
                label: <TabLabel label="General" icon={<Info />} />,
                content: (
                  <FormContainer>
                    <Grid container item xs={12} spacing={1}>
                      <TooltipCheckbox
                        bp={{ sm: 6, xs: 12 }}
                        label="This is a template"
                        onChange={(event) => {
                          formik.setFieldValue(
                            path.columns.is_template._,
                            event.target.checked,
                          );
                        }}
                        checked={formik.values.columns.is_template}
                        tooltip="A 'template' task should contain a set of fields and options that can be cloned to create many variations of a similar task. It should not be used to create recordings."
                      />
                      <TooltipCheckbox
                        bp={{ sm: 6, xs: 12 }}
                        label="This is a test"
                        onChange={(event) => {
                          formik.setFieldValue(
                            path.columns.is_test._,
                            event.target.checked,
                          );
                        }}
                        checked={formik.values.columns.is_test}
                        tooltip="A 'test' task is only used in a new, experimental data collection workflow. It should not be used for typical tasks for making recordings."
                      />
                    </Grid>
                    <TextField
                      bp={{ xs: 12 }}
                      name={path.columns!.note!._!}
                      multiline
                      label="Note"
                      tooltip="A custom note is optional. It should only be used to hold information that cannot otherwise be encoded in fields and options."
                    />
                  </FormContainer>
                ),
              },
              {
                name: "fields",
                label: (
                  <TabLabel label="Fields/Options" icon={<ListAltOutlined />} />
                ),
                content: (
                  <FormContainer>
                    <DrawerButton
                      bp={{ xs: 12 }}
                      color="primary"
                      variant="contained"
                      disableElevation
                      label="Clone Existing Task"
                      content={(close) => (
                        <AllTasksTable
                          selectable="single"
                          enableIsolateTemplates={true}
                          isolateTemplatesDefault={true}
                          onSelect={(item) => {
                            if (item.id?.length) {
                              setTaskToCloneLoading(true);
                              taskToCloneQuery({
                                variables: {
                                  id: item.id,
                                  order_field_options_by: taskFieldOptionOrder,
                                },
                              });
                            }
                            close();
                          }}
                        />
                      )}
                    />
                    <TaskFieldsForm name={path.fields!._!} />
                  </FormContainer>
                ),
              },
              {
                name: "media",
                label: <TabLabel label="Media" icon={<Subscriptions />} />,
                content: (
                  <FormContainer>
                    <MediaForm name={path.media?._ ?? "media"} />
                  </FormContainer>
                ),
              },
            ]}
          />
        </>
      )}
    />
  );
};

type TooltipCheckboxProps = CheckboxProps & {
  label: string;
  bp: GridBreakpoints;
  tooltip?: string;
};

const TooltipCheckbox = (props: TooltipCheckboxProps) => {
  return (
    <Grid {...props.bp} item>
      <FormControlLabel
        control={<Checkbox onChange={props.onChange} checked={props.checked} />}
        label={props.label}
      />
      {props.tooltip && (
        <Tooltip title={props.tooltip}>
          <Info color="disabled" />
        </Tooltip>
      )}
    </Grid>
  );
};

/*******************************************************************************
 * Fields Sub-form
 ******************************************************************************/
type TaskFieldsFormProps = {
  name: string;
  disabled?: boolean;
};

const TaskFieldsForm = (props: TaskFieldsFormProps) => {
  const [formikField] = useField(props.name);
  const currentFields: FieldSchema[] = formikField.value;
  const currentFieldIds = currentFields.map(({ id }) => id);

  type CategoryItem = { id: string; name: string };
  const [selectedCategory, setSelectedCategory] = useState<CategoryItem | null>(
    null,
  );
  const categoriesQuery = useAllCategoriesQuery({
    fetchPolicy: "network-only",
  });
  const selectableCategories: CategoryItem[] =
    categoriesQuery.data?.categories?.map(({ id, name }) => ({ id, name })) ??
    [];

  // If a field from the current category is already being used, make sure user
  // can only add fields from the same context.
  const currentCategoryContextId = currentFields.find(
    (f: FieldSchema) =>
      !!selectedCategory?.id && f.category.id === selectedCategory.id,
  )?.context.id;

  type ContextItem = { id: string; name: string };
  const [selectedContext, setSelectedContext] = useState<ContextItem | null>(
    null,
  );

  const selectableContexts: ContextItem[] =
    selectedCategory === null
      ? []
      : categoriesQuery.data?.categories
          ?.find(({ id }) => id === selectedCategory.id)
          ?.contexts?.filter(
            ({ id }) =>
              currentCategoryContextId === undefined ||
              id === currentCategoryContextId,
          ) ?? [];

  type FieldItem = { id: string; name: string; type_id: string };
  const [selectedField, setSelectedField] = useState<FieldItem | null>(null);
  const [fieldsQuery, fields] = useAllFieldsLazyQuery();
  // User can only select fields that haven't been added to the form yet.
  const selectableFields: FieldItem[] =
    selectedContext === null
      ? []
      : fields.data?.fields
          ?.filter(({ id }) => !currentFieldIds.includes(id))
          .map(({ id, name, type_id }) => ({
            id,
            name,
            type_id,
          })) ?? [];

  return (
    <FieldArray
      name={props.name}
      render={(helper) => {
        return (
          <>
            {/* Category Selector */}
            <Grid item sm={6} xs={12}>
              <Autocomplete
                options={selectableCategories}
                getOptionLabel={(category) => category.name}
                getOptionSelected={(o, v) => o.id === v.id}
                disableClearable
                onChange={(_, newCategory) => {
                  setSelectedCategory(newCategory ?? null);
                  // Reset context/field selectors.
                  setSelectedContext(null);
                  setSelectedField(null);
                }}
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    label="Category"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {/*  Context Selector */}
            <Grid item sm={6} xs={12}>
              <Autocomplete
                // Let react know the component has changed
                // when the category is changed, by changing the key.
                key={selectedCategory?.id ?? ""}
                disabled={!selectedCategory}
                options={selectableContexts}
                getOptionLabel={(context) => context.name}
                getOptionSelected={(o, v) => o.id === v.id}
                disableClearable
                // Use `inputValue` to ensure that display is cleared
                // when category is changed
                inputValue={selectedContext?.name ?? ""}
                onChange={(_, newContext) => {
                  setSelectedContext(newContext ?? null);
                  setSelectedField(null);
                  if (newContext.id) {
                    fieldsQuery({
                      variables: {
                        where: {
                          context_id: { _eq: newContext.id },
                        },
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    label="Context"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {/* Field Selector */}
            <Grid item sm={10} xs={12}>
              <Autocomplete
                // Let react know the component has changed
                // after a field is added.
                key={currentFields.map((f: FieldSchema) => f.id).join(";")}
                disabled={!selectedContext || fields.loading || !!fields.error}
                options={selectableFields}
                getOptionLabel={(field) => field.name}
                getOptionSelected={(o, v) => o.id === v.id}
                disableClearable
                inputValue={selectedField?.name ?? ""}
                onChange={(_, newField) => {
                  setSelectedField(newField ?? null);
                }}
                renderInput={(params) => (
                  <MUITextField {...params} label="Field" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={
                  !(
                    selectedContext?.id &&
                    selectedCategory?.id &&
                    selectedField?.id
                  ) ||
                  fields.loading ||
                  !!fields.error
                }
                color="primary"
                variant="contained"
                onClick={() => {
                  helper.insert(0, {
                    highlight: false,
                    id: selectedField?.id ?? "",
                    type_id: selectedField?.type_id ?? "",
                    name: selectedField?.name ?? "",
                    category: {
                      id: selectedCategory?.id ?? "",
                      name: selectedCategory?.name ?? "",
                    },
                    context: {
                      id: selectedContext?.id ?? "",
                      name: selectedContext?.name ?? "",
                    },
                    options: [],
                  });
                  setSelectedField(null);
                }}
              >
                <Add />
                Field
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {currentFields.map((field: FieldSchema, index: number) => (
              <Grid key={field.id} container item spacing={2} xs={12}>
                <Grid item xs={12} sm={8}>
                  <MultiOptionSelector
                    key={field.id}
                    disabled={
                      fields.loading || !!fields.error || props.disabled
                    }
                    fieldId={field.id}
                    name={`${props.name}.${index}.options`}
                    // name={path.fields![index]._}
                    label={field.name}
                  />
                </Grid>
                <Grid item xs={10} sm={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          helper.replace(index, {
                            ...field,
                            highlight: event.target.checked,
                          });
                        }}
                        checked={field.highlight}
                      />
                    }
                    label="Highlight"
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  <IconButton onClick={() => helper.remove(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </>
        );
      }}
    />
  );
};

/*******************************************************************************
 * Multi-option Sub-form
 ******************************************************************************/
type MultiOptionSelectorProps = {
  fieldId: string;
  label: string;
  name: string;
  disabled?: boolean;
};

const MultiOptionSelector = (props: MultiOptionSelectorProps) => {
  const fieldOptionsQuery = useAllFieldOptionsQuery({
    fetchPolicy: "network-only",
    variables: {
      where: { field_id: { _eq: props.fieldId } },
    },
    skip: !props.fieldId,
  });
  const selectableOptions: OptionSchema[] =
    fieldOptionsQuery.data?.fo.map((fo) => ({
      id: fo.option?.id ?? "",
      display: fo.option?.display ?? "",
      field_option_id: fo.id ?? "",
      action: "add",
    })) ?? [];

  return (
    <MultiSelect
      bp={{ xs: 12 }}
      name={props.name}
      label={props.label}
      options={selectableOptions}
      getOptionLabel={(option) => option.display}
      getOptionSelected={(a, b) => a.id === b.id}
    />
  );
};

/*******************************************************************************
 * Media Sub-form
 ******************************************************************************/
function fileTypeStringToEnum(fileType: String): Media_Type_Enum {
  if (fileType.includes("image")) {
    return Media_Type_Enum.Image;
  } else {
    return Media_Type_Enum.Video;
  }
}

type MediaFormProps = { name: string };

const MediaForm = (props: MediaFormProps) => {
  const [formikField] = useField(props.name);
  const currentMedia: MediaSchema[] = formikField.value ?? [];
  const usedMediaIds = currentMedia.filter((m) => !!m.id).map((m) => m.id);
  return (
    <FieldArray
      name={props.name}
      render={(helper) => (
        <>
          <Grid item xs={12}>
            <MediaFileUploadField
              onUpdate={(files) => {
                files
                  .filter((f) => f.id && !usedMediaIds.includes(f.id))
                  .forEach((f) => {
                    helper.push({
                      id: f.id ?? "",
                      url: f.url ?? "",
                      title: f.file.name,
                      description: "",
                      type: fileTypeStringToEnum(f.file.type),
                    });
                  });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {currentMedia.map((media, index) => (
            <Grid key={index} container item spacing={1} xs={12}>
              <Grid container item spacing={1} xs={12} sm={8}>
                <TextField
                  bp={{ xs: 12 }}
                  name={`${props.name}.${index}.title`}
                  label="Title"
                />
                <TextField
                  bp={{ xs: 10 }}
                  multiline
                  name={`${props.name}.${index}.description`}
                  label="Description"
                />
              </Grid>
              <Grid container item spacing={1} xs={12} sm={4}>
                <Grid item xs={8}>
                  <Button
                    fullWidth
                    endIcon={<Launch />}
                    key={index}
                    title={`${media?.title}\n${media?.url}`}
                    target="_blank"
                    href={media?.url ?? ""}
                  >
                    {media.type}
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => helper.remove(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <DrawerButton
              bp={{ xs: 12 }}
              variant="contained"
              color="primary"
              label="Add existing media"
              tooltip="Add existing media"
              startIcon={<Attachment />}
              disableElevation
              content={(close) => (
                <AllMediaTable
                  selectable="single"
                  where={[{ id: { _nin: usedMediaIds } }]}
                  onSelect={(m) => {
                    // TODO!!!
                    helper.insert(0, m);
                    close();
                  }}
                />
              )}
            />
          </Grid>
        </>
      )}
    />
  );
};

/*******************************************************************************
 * Confirmation Dialog
 ******************************************************************************/
function fieldOptionsHash(options: OptionSchema[]): string {
  const hashContents = options
    .map((o) => o.field_option_id)
    .sort()
    .reverse()
    .join(";");
  const md5 = createHash("md5").update(hashContents).digest("hex");
  return md5;
}

type ConfirmationDialogProps = {
  path: PathProxy<FormSchema, FormSchema>;
  formik: FormikContextType<FormSchema>;
};

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const fields = props.formik.values.fields;

  // Find all the permutations of tasks possible from the field/options
  // selected in the form.
  const permutations: OptionSchema[][] = useMemo(() => {
    const product: OptionSchema[][] = [];
    if (fields.length < 1) return [];
    const max = fields.length - 1;
    function helper(arr: OptionSchema[], i: number) {
      for (var j = 0, l = fields[i].options.length; j < l; j++) {
        var copy = [...arr.slice(0), fields[i].options[j]];
        if (i === max) product.push(copy);
        else helper(copy, i + 1);
      }
    }
    helper([], 0);
    return product;
  }, [fields]);

  // Generate hashes for each permutation and use to query the "task field
  // option hashes" view for any tasks that already exist with the same
  // combination of field/options.
  const hashes: string[] = useMemo(
    () => permutations.map(fieldOptionsHash),
    [permutations],
  );
  const matchingHashesQuery = useTaskFieldOptionHashesQuery({
    variables: { where: { md5: { _in: hashes } } },
    skip: hashes.length < 1,
    fetchPolicy: "network-only",
  });
  const matchingHashes = matchingHashesQuery.data?.task_field_option_hashes;

  const fieldOptionGroups: FieldOptionGroupSchema[] = useMemo(() => {
    return permutations.map((options, i) => {
      const hash = hashes[i];
      const matchingTaskNumbers: string[] = (matchingHashes ?? [])
        .filter((h) => h.md5 === hash)
        .map((h) => `${h.task?.legacy_template_id ?? ""}`);
      return {
        options,
        matchingTaskNumbers,
        include: matchingTaskNumbers.length > 0 ? false : true,
      };
    });
  }, [permutations, matchingHashes, hashes]);

  /**
   * Upon rendering the confirmation page, determine if any permutations match
   * any existing tasks in the database, and populate the form.
   */
  const [loaded, setLoaded] = useState(false);
  const { setFieldValue, setSubmitting } = props.formik;
  useEffect(() => {
    if (matchingHashesQuery.loading) {
      setSubmitting(true);
    } else if (!loaded) {
      setFieldValue("fieldOptionGroups", fieldOptionGroups);
      setSubmitting(false);
      setLoaded(true);
    }
  }, [matchingHashesQuery.loading, loaded, fieldOptionGroups]);

  return (
    <FieldArray
      name={props.path.fieldOptionGroups!._}
      render={(helper) => (
        <Grid container spacing={2}>
          {props.formik.values.fieldOptionGroups.map((group, i) => {
            const matches = !!group.matchingTaskNumbers?.length;
            return (
              <Grid key={i} item xs={4}>
                <Card>
                  <CardContent>
                    {fields.map((field, j) => {
                      // It's not guaranteed that fields and options are in sync on every render.
                      // This can cause a crash if accessing the option is not checked beforehand.
                      const option = group.options[j];
                      return (
                        <Fragment key={j}>
                          <Typography variant="body1">
                            {option?.display ?? "<something is wrong>"}
                          </Typography>
                          <Typography variant="caption">
                            {field.name}
                          </Typography>
                        </Fragment>
                      );
                    })}
                  </CardContent>
                  <CardActions>
                    {matches ? (
                      <Typography color="error" variant="caption">
                        Already Exists as task(s){" "}
                        {group.matchingTaskNumbers!.join(", ")}
                      </Typography>
                    ) : (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            disabled={matches}
                            checked={group.include}
                            onChange={(event) =>
                              helper.replace(i, {
                                ...group,
                                include: event.target.checked,
                              })
                            }
                          />
                        }
                        label={
                          <Typography variant="caption">Include</Typography>
                        }
                      />
                    )}
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              Be aware, all the inserted tasks will be identical apart from
              their unique fields/options! This includes any given "note", and
              all attached "media".
            </Typography>
          </Grid>
        </Grid>
      )}
    />
  );
};
