import React, { useState } from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import * as Yup from "yup";
import { SearchBar } from "src/components/filters";
import {
  DrawerButton,
  FormContainer,
  FormHeader,
  TextField,
} from "src/components/Form";
import { Resource, useResourceNav } from "src/components/Resource";
import {
  ChipColumn,
  createTable,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import { usePagination, like, uuidIsValid } from "src/resources/Utils";
import {
  Breadcrumb,
  Breadcrumbs,
  ShowResourceView,
  Tablature,
  TableView,
  TableViewHeader,
} from "src/Layout";
import { ResourceForm, FormAction } from "src/components/ResourceForm";
import {
  Rabbit_Test_Plans as Rabbit_Test_Plan,
  Rabbit_Test_Plans_Bool_Exp,
  useAllBenchmarkPlansQuery,
  useBenchmarkPlanQuery,
  useCreateBenchmarkPlanMutation,
  useUpdateBenchmarkPlanMutation,
  Order_By,
} from "src/generated/asgard/graphql";
import { FieldArray } from "formik";
import { AllTasksTable } from "../CollectionPlan";
import { FormTable } from "src/components/FormTable";

// Config table columns from Rabbit_Test_Plan fields
export const BenchmarkPlanTable = createTable<Rabbit_Test_Plan>()({
  keys: (benchmark_plan) => benchmark_plan.id ?? "",
  title: "Benchmark Plans",
  headers: {
    id: { display: "ID" },
    name: { display: "Name" },
    description: { display: "Description" },
    projects: { display: "Projects" },
  },
  columns: (benchmark_plan) => ({
    id: <EllipsisColumn value={benchmark_plan.id} />,
    name: <TextColumn value={benchmark_plan.name} />,
    description: <TextColumn value={benchmark_plan.description} />,
    projects: (
      <ChipColumn
        chips={benchmark_plan.plan_projects?.map((p) => ({
          label: p.project?.project_name?.name ?? "",
        }))}
      />
    ),
  }),
});

type AllBenchmarkPlansTableProps = TableOptionals<typeof BenchmarkPlanTable> & {
  where?: Rabbit_Test_Plans_Bool_Exp[];
};

export const AllBenchmarkPlansTable = (props: AllBenchmarkPlansTableProps) => {
  const [pageVars, pageController] = usePagination();
  const [search, setSearch] = useState("");
  const searchFilters: Rabbit_Test_Plans_Bool_Exp[] = [];
  if (uuidIsValid(search)) {
    searchFilters.push({ id: { _eq: search } });
  } else {
    const term = like(search);
    searchFilters.push({ name: { _ilike: term } });
  }
  const { data } = useAllBenchmarkPlansQuery({
    variables: {
      ...pageVars,
      where: { _and: [{ _and: props.where }, { _or: searchFilters }] },
      order_by: { created_at: Order_By.Desc },
    },
    fetchPolicy: "network-only",
  });

  return (
    <BenchmarkPlanTable
      {...props}
      {...pageController}
      total={data?.rabbit_test_plans_aggregate?.aggregate?.count}
      data={data?.rabbit_test_plans}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
    />
  );
};

// Define content to display in the main Rabbit_Test_Plan resource page
type BenchmarkPlanIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof BenchmarkPlanTable>;

export const BenchmarkPlanIndex = (props: BenchmarkPlanIndexProps) => {
  return (
    <TableView>
      <TableViewHeader
        title={<Typography variant="h5">Benchmark Plans</Typography>}
        info={
          <Typography variant="body2">
            <b>Benchmark Plans</b> are collections of <b>tasks</b> that should
            all be completed as part of a single <b>instance</b>, to test the
            performance of a single <b>engine</b>, for a single <b>project</b>,
            on a single <b>device</b>.
            <br />
            <br />
            Only <b>tasks</b> for which have the "is_test" property is true may
            be added to a <b>benchmark plan</b>.
          </Typography>
        }
      >
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disableElevation
        >
          New Benchmark Plan
        </Button>
      </TableViewHeader>
      <Card>
        <AllBenchmarkPlansTable {...props} selectable="none" />
      </Card>
    </TableView>
  );
};

// Define form for creating and editing BenchmarkPlans
const planTaskSchema = Yup.object({
  id: Yup.string().required(),
  number: Yup.string().required(),
}).required();

const benchmarkPlanSchema = Yup.object({
  name: Yup.string().required("required"),
  description: Yup.string(),
  tasks: Yup.array(planTaskSchema),
}).required();

type PlanTaskSchema = Yup.InferType<typeof planTaskSchema>;

export type BenchmarkPlanFormProps = {
  action: FormAction;
  benchmark_plan?: { id: string };
  onSuccess?: (id: string) => void;
};

export const BenchmarkPlanForm = (props: BenchmarkPlanFormProps) => {
  const [createBenchmarkPlan] = useCreateBenchmarkPlanMutation();
  const [updateBenchmarkPlan] = useUpdateBenchmarkPlanMutation();

  const allBenchmarkPlans = useAllBenchmarkPlansQuery({
    variables: {
      where: {
        id: {
          _nin: props.benchmark_plan?.id ? [props.benchmark_plan.id] : [],
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const existingBenchmarkPlan = useBenchmarkPlanQuery({
    variables: { id: props.benchmark_plan?.id ?? "" },
    skip: !props.benchmark_plan,
    fetchPolicy: "network-only",
  });

  return (
    <ResourceForm
      action={props.action}
      schema={benchmarkPlanSchema}
      resourceToUpdate={existingBenchmarkPlan.data?.rabbit_test_plan}
      transform={(benchmark_plan) => ({
        name: benchmark_plan.name,
        description: benchmark_plan.description ?? "",
        tasks: benchmark_plan.plan_tasks.map((pt) => ({
          id: pt.task?.id ?? "",
          number: `${pt.task?.legacy_template_id}` ?? "",
        })),
      })}
      initialValues={{
        name: "",
        description: "",
        tasks: [],
      }}
      customValidator={(values, errors) => {
        for (let existing of allBenchmarkPlans.data?.rabbit_test_plans ?? []) {
          if (existing.name === values.name) {
            errors.name = `Benchmark plan "${values.name}" already exists`;
            break;
          }
        }
      }}
      onUpdate={async (values) => {
        const { tasks, ...columns } = values;
        const result = await (async () => {
          if (props.benchmark_plan?.id) {
            const existingTaskIds: string[] =
              existingBenchmarkPlan.data?.rabbit_test_plan?.plan_tasks?.map(
                (pt) => pt.task!.id,
              ) ?? [];
            const selectedTaskIds: string[] = tasks?.map(({ id }) => id) ?? [];
            const newTaskIds = selectedTaskIds.filter(
              (id) => !existingTaskIds.includes(id),
            );
            const deletedTaskIds = existingTaskIds.filter(
              (id) => !selectedTaskIds.includes(id),
            );
            return await updateBenchmarkPlan({
              variables: {
                id: props.benchmark_plan.id,
                input: { ...columns },
                tasks_to_add: newTaskIds.map((id) => ({
                  task_id: id,
                  plan_id: props.benchmark_plan?.id,
                  is_test: true,
                })),
                task_ids_to_remove: deletedTaskIds,
              },
            });
          }
        })();
        props.onSuccess &&
          props.onSuccess(result?.data?.rabbit_test_plan?.id ?? "");
      }}
      onInsert={async (values) => {
        const { tasks, ...columns } = values;
        const result = await (async () => {
          return await createBenchmarkPlan({
            variables: {
              input: {
                ...columns,
                plan_tasks: {
                  data:
                    tasks?.map(({ id }) => ({ task_id: id, is_test: true })) ??
                    [],
                },
              },
            },
          });
        })();
        props.onSuccess &&
          props.onSuccess(result.data?.rabbit_test_plan?.id ?? "");
      }}
      render={({ formik, path }) => (
        <>
          <FormHeader>
            {props.benchmark_plan
              ? "Update Benchmark Plan"
              : "Create New Benchmark Plan"}
          </FormHeader>
          <FormContainer>
            <TextField bp={{ xs: 12, sm: 8 }} name={path.name._} label="Name" />
            <TextField
              bp={{ xs: 12, sm: 8 }}
              name={path.description?._ ?? "description"}
              label="Description"
            />
          </FormContainer>
          <FormContainer>
            <FieldArray
              name={path.tasks?._ ?? "tasks"}
              render={(helper) => (
                <>
                  <DrawerButton
                    bp={{ xs: 4 }}
                    variant="contained"
                    color="primary"
                    label="Add Task"
                    startIcon={<AddCircleOutline />}
                    disableElevation
                    fullWidth={true}
                    content={(close) => (
                      <AllTasksTable
                        where={[
                          {
                            id: {
                              _nin:
                                formik.values.tasks?.map(({ id }) => id) ?? [],
                            },
                            is_test: { _eq: true },
                          },
                        ]}
                        selectable="single"
                        onSelect={(task) => {
                          helper.insert(0, {
                            id: task.id,
                            number: task.legacy_template_id,
                          });
                          close();
                        }}
                      />
                    )}
                  />
                  <Grid item />
                  <Grid item xs={12} sm={8}>
                    <FormTable
                      rows={(formik.values.tasks ?? []) as PlanTaskSchema[]}
                      columns={[
                        {
                          header: "Task",
                          render: (item) => item.number,
                        },
                      ]}
                      onRemove={(item, i) => helper.remove(i)}
                    />
                  </Grid>
                </>
              )}
            />
          </FormContainer>
        </>
      )}
    />
  );
};

// Create a detailed 'show' page.
type BenchmarkPlanShowProps = {
  id: string;
  onEditAction?: (item: DeepPartial<Rabbit_Test_Plan>) => void;
};

const BenchmarkPlanShow = (props: BenchmarkPlanShowProps) => {
  const benchmarkPlanNav = useBenchmarkPlanNav();

  const benchmarkPlanQuery = useBenchmarkPlanQuery({
    variables: { id: props.id },
    fetchPolicy: "network-only",
  });
  const benchmarkPlan = benchmarkPlanQuery.data?.rabbit_test_plan;
  if (!benchmarkPlan) return null;

  return (
    <ShowResourceView
      title={benchmarkPlan.name ?? ""}
      breadcrumbs={
        <Breadcrumbs>
          <Breadcrumb
            label="Benchmark Plans"
            onClick={() => benchmarkPlanNav.list()}
          />
          <Breadcrumb label={benchmarkPlan.name ?? ""} />
        </Breadcrumbs>
      }
      properties={[
        { label: "Description", value: benchmarkPlan.description ?? "" },
      ]}
      onEditAction={() =>
        props.onEditAction && props.onEditAction(benchmarkPlan)
      }
    >
      <Tablature
        useUrlParams
        tabs={[
          {
            name: "tasks",
            label: "Tasks",
            content: (
              <AllTasksTable
                selectable="none"
                where={[{ rabbit_test_tasks: { plan_id: { _eq: props.id } } }]}
              />
            ),
          },
        ]}
      />
    </ShowResourceView>
  );
};

// Finally, combine into full resource UI.
const path = "benchmark-plans";
export const useBenchmarkPlanNav = () => useResourceNav(path);
export const BenchmarkPlanResource = () => (
  <Resource
    path={path}
    list={(nav) => (
      <BenchmarkPlanIndex
        editUrl={(item) => item.id && nav.editUrl(item.id)}
        showUrl={(item) => item.id && nav.showUrl(item.id)}
        onAddNew={() => nav.create()}
      />
    )}
    show={(nav, id) => (
      <BenchmarkPlanShow
        id={id ?? ""}
        onEditAction={(item) => item.id && nav.edit(item.id)}
      />
    )}
    create={(nav) => (
      <BenchmarkPlanForm action="insert" onSuccess={(id) => nav.show(id)} />
    )}
    edit={(nav, id) => (
      <BenchmarkPlanForm
        action="update"
        onSuccess={(id) => nav.show(id)}
        benchmark_plan={{ id: id ?? "" }}
      />
    )}
  />
);
