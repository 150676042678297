import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Dialog,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardActions,
} from "@material-ui/core";
import React, { useState } from "react";
import { Resource } from "src/components/Resource";
import { useToolsQuery, ToolsQuery } from "src/generated/asgard/graphql";

export const ToolResource = () => {
  return (
    <Resource
      path="tools"
      list={(nav) => <ToolIndex onAddNew={() => nav.create()} />}
    />
  );
};

type ToolIndexProps = {
  onAddNew: () => void;
};

export const ToolIndex = (_: ToolIndexProps) => {
  const { data } = useToolsQuery();

  return (
    <div>
      <div>
        <Typography variant="h5" style={{ textAlign: "center" }}>Available tools</Typography>
      </div>
      <Grid container style={{ padding: 50 }} justify="center">
        {data?.tools?.map((tool) => (
          <RenderTool key={tool.id} tool={tool} />
        ))}
      </Grid>
    </div>
  );
};

type RenderToolProps = {
  tool: ToolsQuery["tools"][0];
};
const RenderTool = (props: RenderToolProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const storageAccount = props.tool.storage_account || '';
  const storageContainer = props.tool.storage_container || '';

  const downloadBaseUrl: string = storageAccount && storageContainer
    ? `https://${storageAccount}.blob.core.windows.net/${storageContainer}/`
    : '';

  const SAS: string = props.tool.sas || '';

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const renderActionButton = () => {
    if (props.tool.url) {
      return (
        <Button
          href={props.tool.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue' }}
        >
          Go to
        </Button >
      );
    } else {
      return (
        <Button
          onClick={() => setOpen(true)}
          style={{ color: 'green' }}>
          Download
        </Button>
      );
    }
  };

  return (
    <>
      <Card style={{
        textAlign: "center",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
        margin: 10,
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <CardContent style={{ textAlign: "center" }}>
          <Typography variant="h6">{props.tool.title}</Typography>
          <Typography style={{
            display: '-webkit-box', overflow: showFullDescription ? undefined : 'hidden', textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', WebkitLineClamp: showFullDescription ? undefined : 3,
          }} onClick={toggleDescription}>
            {props.tool.description}
          </Typography>
          {props.tool.tool_versions.length > 0 && (
            <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
              Latest Release: {new Date(props.tool.tool_versions[0].released_at).toLocaleDateString('en-GB')}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          {renderActionButton()}
          <Button
            href={
              typeof props.tool.documentation_url === "string"
                ? props.tool.documentation_url
                : ""
            }
            target="_blank"
            disabled={!props.tool.documentation_url}
          >
            Documentation
          </Button>
        </CardActions>
      </Card>
      <Dialog
        PaperProps={{
          style: {
            padding: '50px',
            height: 'auto',
          },
        }}
        open={open} onClose={() => setOpen(false)}>
        <Typography style={{ marginBottom: 20, textAlign: "center" }}>
          {props.tool.title}
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tool name</TableCell>
                <TableCell>File name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.tool.tool_versions.map((version, i) => (
                <TableRow key={i}>
                  <TableCell>
                    {version.name} {i === 0 ? " (latest)" : ""}
                  </TableCell>
                  <TableCell>{version.filename}</TableCell>
                  <TableCell>
                    {new Date(version.released_at).toLocaleDateString('en-GB')}
                  </TableCell>
                  <TableCell>
                    <a
                      href={
                        downloadBaseUrl +
                        version.storage_path +
                        version.filename +
                        SAS
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog >
    </>
  );
};
