import {
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  Avatar,
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import {
  FormHeader,
  FormContainer,
  MultiSelect,
  TextField,
} from "src/components/Form";
import { Resource } from "src/components/Resource";
import {
  ChipColumn,
  createTable,
  DialogColumn,
  EllipsisColumn,
  TableOptionals,
  TextColumn,
} from "src/components/table";
import {
  Roles_Enum,
  useAllProjectsQuery,
  useAllUsersQuery,
  useCreateUserMutation,
  Users as User,
  Users_Update_Column,
  Users_Constraint,
  Order_By,
  Users_Order_By,
  Users_Bool_Exp,
} from "src/generated/asgard/graphql";
import { ProjectTable } from "src/resources/Project";
import { usePagination, like, uuidIsValid, orderBy } from "src/resources/Utils";
import * as Yup from "yup";
import { TableView, TableViewHeader } from "src/Layout";
import * as crypto from "crypto";
import { RequireRole, useRequireRole } from "src/auth";
import { SearchBar } from "src/components/filters";

export const UserAvatar = (props: { user: DeepPartial<User> }) => {
  return (
    <Avatar
      src={`https://www.gravatar.com/avatar/${crypto
        .createHash("md5")
        .update(props.user?.email ?? "")
        .digest("hex")}?d=identicon`}
      alt={props.user?.name ?? props.user?.email ?? ""}
    />
  );
};

const UserTable = createTable<User>()({
  title: "Users",
  keys: (data) => data.id ?? "",
  headers: {
    id: { display: "id" },
    avatar: { display: "" },
    email: { display: "Email" },
    name: { display: "Name" },
    description: { display: "Description" },
    roles: { display: "Roles" },
    projects: { display: "Projects" },
  },
  columns: (data) => ({
    id: <EllipsisColumn value={data.id ?? "unknown"} />,
    avatar: <UserAvatar user={data} />,
    email: <TextColumn value={data.email} />,
    description: <TextColumn value={data.description} />,
    name: <TextColumn value={data.name} />,
    roles: (
      <ChipColumn
        chips={data.role_users?.map((d) => ({
          label: d.role_id ?? "undefined",
        }))}
      />
    ),
    projects: (
      <DialogColumn
        label={`Show (${data?.project_users_aggregate?.aggregate?.count})`}
        content={<UserProjectTable id={data.id ?? ""} />}
      />
    ),
  }),
});

export const UserProjectTable = (props: { id: string }) => {
  const [pageVars, pageController] = usePagination();
  const { data } = useAllProjectsQuery({
    fetchPolicy: "network-only",
    variables: {
      ...pageVars,
      where: { project_users: { user_id: { _eq: props.id } } },
      order_by: [{ id: Order_By.Asc }],
    },
  });

  return (
    <ProjectTable
      {...pageController}
      selectable="none"
      total={data?.projects_aggregate?.aggregate?.count ?? 0}
      data={data?.projects}
    />
  );
};

type UserListProps = {
  exclude?: string[];
} & TableOptionals<typeof UserTable>;

export const UserList = (props: UserListProps) => {
  const ag = useRequireRole([Roles_Enum.Asgard]);
  const [pageVars, pageController] = usePagination();

  const [search, setSearch] = useState("");
  const [order, setOrder] = useState<Users_Order_By>({
    updated_at: Order_By.Desc,
  });

  const term = like(search);
  const filters: Users_Bool_Exp[] = [
    { name: { _ilike: term } },
    { email: { _ilike: term } },
    { role_users: { role: { id: { _ilike: term } } } },
  ];

  if (uuidIsValid(search)) {
    filters.push({ id: { _eq: search } });
  }

  const query = useAllUsersQuery({
    fetchPolicy: "network-only",
    variables: {
      ...pageVars,
      order_by: [order],
      where: {
        _and: [
          { id: { _nin: props.exclude } },
          { _or: filters },
          { deleted_at: { _is_null: true } },
        ],
      },
    },
  });

  return (
    <UserTable
      selectable="none"
      {...props}
      {...pageController}
      hideColumns={{ roles: !ag }}
      data={query.data?.users}
      total={query.data?.users_aggregate.aggregate?.count}
      tools={
        <Grid item xs={12}>
          <SearchBar onChange={setSearch} />
        </Grid>
      }
      orderColumn={(order) => {
        if (!order) {
          setOrder({ updated_at: Order_By.Desc });
          return;
        }

        setOrder({
          id: orderBy(order.id),
          name: orderBy(order.name),
          email: orderBy(order.email),
          description: orderBy(order.description),
          role_users_aggregate: { count: orderBy(order.roles) },
          project_users_aggregate: { count: orderBy(order.projects) },
        });
      }}
    />
  );
};

export const UserResource = () => {
  return (
    <Resource
      path="users"
      list={(nav) => <UserIndex onAddNew={() => nav.create()} />}
      create={(nav) => (
        <UserForm action="insert" onCreateSuccess={() => nav.list()} />
      )}
    />
  );
};

type UserIndexProps = {
  onAddNew: () => void;
} & TableOptionals<typeof UserTable>;

export const UserIndex = (props: UserIndexProps) => {
  return (
    <TableView>
      <TableViewHeader title={<Typography variant="h5">Users</Typography>}>
        <Button
          onClick={props.onAddNew}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          disabled
          disableElevation
        >
          New user
        </Button>
      </TableViewHeader>
      <Card>
        <UserList {...props} />
      </Card>
    </TableView>
  );
};

const CreateUserSchema = Yup.object({
  name: Yup.string().min(1),
  roles: Yup.array()
    .min(1, "user must be assigned at least one role")
    .of(Yup.string().oneOf(Object.values(Roles_Enum)).required())
    .required(),
  email: Yup.string().required("required").email().min(1),
}).required();

type UserFormProps = Yup.InferType<typeof CreateUserSchema>;

type UserUpsertProps = {
  user?: {
    id: string;
    email?: string | null;
    name?: string | null;
    roles: Roles_Enum[];
  };
  onCreateSuccess?: (id?: string) => void;
  action: UserFormAction;
};

type UserFormAction = "insert" | "update" | "register";

type UserFormInfo = {
  title: string;
  subTitle?: string;
  createButtonTitle: string;
};

export const UserForm = ({ user, ...props }: UserUpsertProps) => {
  const formInfo: { [key in UserFormAction]: UserFormInfo } = {
    register: {
      title: "Register new user",
      createButtonTitle: "Register user",
    },
    insert: {
      title: "Create new user",
      createButtonTitle: "Create user",
    },
    update: {
      title: "Update user",
      createButtonTitle: "Update user",
    },
  };

  const info = formInfo[props.action];

  const [createUser] = useCreateUserMutation();

  const formik = useFormik<UserFormProps>({
    validationSchema: CreateUserSchema,
    initialValues: {
      name: user?.name ?? "",
      email: user?.email ?? "",
      roles: user?.roles ?? [],
    },
    onSubmit: async (values) => {
      const { name, email, roles } = values;

      try {
        const result = await (async () => {
          return await createUser({
            variables: {
              on_conflict: {
                constraint: Users_Constraint.UsersPkey,
                update_columns: [
                  Users_Update_Column.Id,
                  Users_Update_Column.Email,
                  Users_Update_Column.Name,
                ],
              },
              input: {
                id: user?.id,
                name: name,
                email: email,
                role_users: {
                  data: roles.map((r) => ({ role_id: r })),
                },
              },
            },
          });
        })();

        if (result?.data?.insert_users_one?.id) {
          props.onCreateSuccess && props.onCreateSuccess(user?.id);
        }
      } catch (error) {
        formik.setSubmitting(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <FormHeader>{info.title}</FormHeader>
      <Divider />
      <FormContainer>
        <TextField bp={{ xs: 12, sm: 6 }} name={"name"} label="User name" />
        <TextField bp={{ xs: 12, sm: 6 }} name={"email"} label="Email" />
        <RequireRole roles={[Roles_Enum.Asgard]}>
          <MultiSelect
            bp={{ xs: 12 }}
            name={"roles"}
            label="Roles"
            placeholder="Role"
            disabled={user !== undefined}
            options={Object.values(Roles_Enum)}
            getOptionLabel={(opt) => opt}
          />
        </RequireRole>
        <Grid xs={12} item>
          <Button
            color="primary"
            variant="contained"
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}
            disableElevation
            fullWidth
          >
            {info.createButtonTitle}
          </Button>
        </Grid>
      </FormContainer>
    </FormikProvider>
  );
};
