import React from "react";
import { green, grey, red, blue, yellow } from "@material-ui/core/colors";
import { Tooltip, Typography, makeStyles } from "@material-ui/core";

const colorBarStyles = {
  green: {
    height: "100%",
    backgroundColor: green["400"], //
    borderStyle: "solid",
    borderWidth: 3,
    borderColor: green["400"], //
    transition: "all 0.1s, width 1s ease-in-out",
    "&:hover": {
      backgroundColor: green["600"], //
      borderColor: green["700"], //
    },
  },
  blue: {
    height: "100%",
    backgroundColor: blue["400"], //
    borderStyle: "solid",
    borderWidth: 3,
    borderColor: blue["400"], //
    transition: "all 0.1s, width 1s ease-in-out",
    "&:hover": {
      backgroundColor: blue["600"], //
      borderColor: blue["700"], //
    },
  },
  yellow: {
    height: "100%",
    backgroundColor: yellow["400"], //
    borderStyle: "solid",
    borderWidth: 3,
    borderColor: yellow["400"], //
    transition: "all 0.1s, width 1s ease-in-out",
    "&:hover": {
      backgroundColor: yellow["600"], //
      borderColor: yellow["700"], //
    },
  },
  red: {
    height: "100%",
    backgroundColor: red["400"], //
    borderStyle: "solid",
    borderWidth: 3,
    borderColor: red["400"], //
    transition: "all 0.1s, width 1s ease-in-out",
    "&:hover": {
      backgroundColor: red["600"], //
      borderColor: red["700"], //
    },
  },
  grey: {
    height: "100%",
    backgroundColor: grey["400"], //
    borderStyle: "solid",
    borderWidth: 3,
    borderColor: grey["400"], //
    transition: "all 0.1s, width 1s ease-in-out",
    "&:hover": {
      backgroundColor: grey["600"], //
      borderColor: grey["700"], //
    },
  },
};

const useProgressBarStyles = makeStyles(() => ({
  container: {
    "&:hover": {
      borderColor: "white",
      boxShadow: "1px 6px 8px #00000021",
    },
    boxShadow: "1px 1px 0#FFFFFFFF",
    borderStyle: "solid",
    borderColor: grey["100"],
    borderWidth: 4,
    transition: "all 0.2s ease-in-out",
    width: "100%",
    height: 28,
    backgroundColor: grey["300"],
    borderRadius: 10,
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
  },
  ...colorBarStyles,
}));

type Bar = { value: number; label: string; color: keyof typeof colorBarStyles };

type MultiProgressBarProps = {
  denominator: number;
  numerators: Bar[];
};

export const MultiProgressBar = (props: MultiProgressBarProps) => {
  const { numerators, denominator } = props;
  const classes = useProgressBarStyles();
  const sumNumerators = numerators.reduce(
    (sum, numerator) => sum + numerator.value,
    0,
  );
  const widthFractionDenominator = Math.max(denominator, sumNumerators);

  return (
    <div className={classes.container}>
      {denominator > 0 &&
        props.numerators
          .filter(({ value }) => value > 0)
          .map(({ value, label, color }, i) => {
            const style = classes[color];
            const percentage = (value / denominator) * 100;
            const widthPercentage = (value / widthFractionDenominator) * 100;
            return (
              <Tooltip
                title={
                  <Typography>
                    {label}:{" "}
                    <strong>
                      {value}/{denominator}
                    </strong>{" "}
                    ({percentage.toFixed(2)}%)
                  </Typography>
                }
                placement="top"
                arrow
              >
                <div
                  className={style}
                  style={{ width: `${widthPercentage}%` }}
                />
              </Tooltip>
            );
          })}
    </div>
  );
};
