import React, {
  useRef,
  useState,
  useEffect,
  ComponentProps,
  Fragment,
} from "react";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Table as MDTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Grid,
  Collapse,
  Box,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  ArrowForward,
  Edit as EditIcon,
  ExpandLess,
  ExpandMore,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxHeight: "inherit",

      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      // boxSizing: "border-box",
      // gridTemplateRows: "auto",
      // alignContent: "space-between",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }),
);

// Metatype of optional fields only used to pick the keys from the action type
export type TableOptionalFields = {
  hideColumns: void;
  selectable: void;
  onSelect: void;
  onSelectMany: void;
  /** Use to add navigation to the "show" button. */
  showUrl: void;
  /** Use to add navigation to the "edit" button. */
  editUrl: void;
  /**
   * Use to perform an arbitrary action when "show" button is clicked.
   * If defined, the `showUrl` property will be ignored.
   */
  onShowAction: void;
  /**
   * Use to perform an arbitrary action when "edit" button is clicked.
   * If defined, the `editUrl` property will be ignored.
   */
  onEditAction: void;
  tools: void;
};

export type TableOptionals<
  T extends (props: ResourceTableProps<any, any>) => JSX.Element,
> = Pick<ComponentProps<T>, keyof TableOptionalFields>;

type Header = {
  display?: string;
  align?: "left" | "right";
  info?: string;
};

type HeaderMap = {
  [key: string]: Header;
};

type CreateTableProps<TData, THeader> = {
  headers: THeader;
  columns: (data: TData) => { [key in keyof THeader]: JSX.Element };
  title: string;
  keys: (data: TData) => string;
};

export function createTable<TData extends object>(): <
  THeader extends HeaderMap,
>(
  table: CreateTableProps<DeepPartial<TData>, THeader>,
) => (props: Table<TData, THeader>) => JSX.Element {
  return (table) => (props) => <ResourceTable {...props} {...table} />;
}

export type ResourceTableProps<TData, THeader extends HeaderMap> = {
  data: Array<TData> | undefined;
  keys: (data: TData) => string;
  title: string;
  headers: THeader;
  columns: (data: TData) => { [key in keyof THeader]: React.ReactNode };
  onSelect?: (item: TData) => void;
  onSelectMany?: (items: TData[]) => void;
  hideColumns?: { [key in keyof THeader]?: boolean };
  selectable?: "single" | "multiple" | "none";
  showUrl?: (item: TData) => string | undefined;
  onShowAction?: (item: TData) => void;
  editUrl?: (item: TData) => string | undefined;
  onEditAction?: (item: TData) => void;
  orderColumn?: (cols?: { [key in keyof THeader]?: Order }) => void;

  expandedContent?: (item: TData) => React.ReactNode;

  tools?: JSX.Element[] | JSX.Element;
  total?: number | null;
  offset?: number;
  limit?: number;
  setOffset?: (r: number) => void;
  setLimit?: (r: number) => void;
  // orderBy?: "asc" | "desc" | "none";
};

export type Order = "asc" | "desc" | undefined;

type HeadCells = ({
  id: string;
} & Header)[];

type TableHeadProps = {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  headCells: HeadCells;
  selectable?: "single" | "multiple" | "none";
  hasActions: boolean;
  rowsExpandable: boolean;
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  order: Order | undefined;
  orderBy: string | undefined;
  rowCount: number;
  setOrderCallback?: (column: string, a: Order) => void;
};

function TableHeadContainer({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  headCells,
  selectable,
  ...props
}: TableHeadProps) {
  const [prevOrderBy, setPrevOrderBy] = useState("");
  const headTitleCells = headCells.map((headCell) => {
    const content = props.setOrderCallback ? (
      <TableSortLabel
        active={orderBy === headCell.id && order !== undefined}
        direction={order}
        onClick={() => {
          const orderState = (() => {
            if (prevOrderBy !== headCell.id) return "asc";
            switch (order) {
              case undefined:
                return "asc";
              case "asc":
                return "desc";
              case "desc":
                return undefined;
            }
          })();
          setPrevOrderBy(headCell.id);
          props.setOrderCallback &&
            props.setOrderCallback(headCell.id, orderState);
        }}
      >
        {headCell.display}
      </TableSortLabel>
    ) : (
      <>{headCell.display}</>
    );
    return (
      <TableCell key={headCell.id} align={headCell.align}>
        {headCell.info ? (
          <Tooltip title={headCell.info} placement="right">
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </TableCell>
    );
  });

  return (
    <TableHead>
      <TableRow>
        {props.rowsExpandable && <TableCell />}
        {/* TODO: why is the checkbox the default? */}
        {(!selectable || selectable === "multiple") && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {selectable === "single" && <TableCell />}
        {headTitleCells}
        {props.hasActions && <TableCell align="right">Actions</TableCell>}
      </TableRow>
    </TableHead>
  );
}

export type Table<T, K extends HeaderMap> = Omit<
  ResourceTableProps<DeepPartial<T>, K>,
  "keys" | "columns" | "title" | "headers"
>;

export function ResourceTable<TData extends object, THeader extends HeaderMap>(
  props: ResourceTableProps<TData, THeader>,
) {
  var { data } = props;
  const classes = useStyles();
  const [order, setOrder] = useState<Order>(undefined);
  const [orderBy, setOrderBy] = useState<string>();
  const [selected, setSelected] = useState<string[] | undefined>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [dense] = useState(true);
  const tableRef = useRef<HTMLDivElement>(null);

  const { total, offset, setOffset } = props;

  // If using pagination, ensure that the offset is not greater than the total.
  useEffect(() => {
    if (!total || !offset || !setOffset) return;
    if (offset > total) setOffset(0);
  }, [total, offset, setOffset]);

  // Generate header parameters, with "hidden" columns filtered out.
  const headers: HeadCells = Object.keys(props.headers)
    .filter((head) => !(props.hideColumns && props.hideColumns[head]))
    .map((head) => {
      const h = props.headers[head];
      return {
        id: head,
        display: h.display ?? head,
        align: h.align,
        info: h.info ?? "",
      };
    });

  // Generate content for all cells.
  type Item = {
    key: string;
    element: (JSX.Element | null)[];
    data: TData | null;
  };
  const items: Item[] = (() => {
    // If data is undefined, generate loading indicators (aka. Skeleton) for
    // each cell instead.
    if (data === undefined) {
      const skells = headers.map((label) => {
        return (
          <TableCell key={label.id}>
            <Skeleton />
          </TableCell>
        );
      });
      return Array(props.limit)
        .fill("")
        .map((_, index) => ({
          key: "" + index,
          element: skells,
          data: null,
        }));
    }
    return data.map((entity) => {
      const column = props.columns(entity);
      const m = Object.keys(props.headers).map((cell, index) => {
        const head = props.headers[cell];
        if (props.hideColumns && props.hideColumns[cell]) {
          return null;
        }
        return (
          <TableCell key={index} align={head.align}>
            {column[cell]}
          </TableCell>
        );
      });
      return { key: props.keys(entity), element: m, data: entity };
    });
  })();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data?.map((n) => props.keys(n));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_: React.MouseEvent<unknown>, name: string) => {
    if (selected === undefined) return;

    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleExpand = (key: string) => {
    if (expanded.includes(key)) {
      setExpanded((e) => e.filter((k) => k !== key));
    } else {
      setExpanded((e) => [...e, key]);
    }
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    if (tableRef != null && tableRef.current != null)
      tableRef.current.scrollTop = 0;

    if (!props.limit || !props.setOffset) return;

    props.setOffset((newPage - 1) * props.limit);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    if (!props.setLimit) return;
    props.setLimit(parseInt(event.target.value as string, 10));
  };

  const handleShowAction = (item: TData) =>
    props.onShowAction && props.onShowAction(item);

  const handleEditAction = (item: TData) =>
    props.onEditAction && props.onEditAction(item);

  const isSelected = (name: string) => selected?.indexOf(name) !== -1;

  const hasActions = !!(
    props.onEditAction ||
    props.onShowAction ||
    props.showUrl ||
    props.editUrl
  );
  const rowsSelectable = props.selectable === "none";
  const rowsExpandable = !!props.expandedContent;

  const actionMenu = hasActions
    ? (data: TData | null) => (
        <TableCell align="right">
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            {props.onShowAction ? (
              <IconButton onClick={() => data && handleShowAction(data)}>
                <VisibilityIcon />
              </IconButton>
            ) : props.showUrl ? (
              <IconButton
                component={Link}
                to={(data && props.showUrl(data)) ?? ""}
              >
                <VisibilityIcon />
              </IconButton>
            ) : undefined}
            {props.onEditAction ? (
              <IconButton onClick={() => data && handleEditAction(data)}>
                <EditIcon />
              </IconButton>
            ) : props.editUrl ? (
              <IconButton
                component={Link}
                to={(data && props.editUrl(data)) ?? ""}
              >
                <EditIcon />
              </IconButton>
            ) : undefined}
          </div>
        </TableCell>
      )
    : null;

  const numColumns =
    [hasActions, rowsSelectable, rowsExpandable].filter((p) => p).length +
    headers.length;

  return (
    <div className={classes.root}>
      <TableToolbar
        numSelected={selected?.length ?? 0}
        title={props.title}
        tools={props.tools}
      />
      <TableContainer ref={tableRef} component="div">
        <MDTable
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <TableHeadContainer
            classes={classes}
            numSelected={selected?.length || 0}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            headCells={headers}
            rowCount={props.total || (data?.length ?? 0)}
            selectable={props.selectable}
            hasActions={hasActions}
            rowsExpandable={rowsExpandable}
            setOrderCallback={
              props.orderColumn &&
              ((col, order) => {
                setOrderBy(col);
                setOrder(order);
                if (order === undefined) {
                  props.orderColumn && props.orderColumn(undefined);
                  return;
                }
                props.orderColumn && props.orderColumn({ [col as any]: order });
              })
            }
          />
          <TableBody>
            {items.map((item) => {
              const isExpanded = expanded.includes(item.key);
              return (
                <Fragment key={item.key}>
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    selected={isSelected(item.key)}
                  >
                    {rowsExpandable && (
                      <TableCell>
                        <IconButton onClick={() => handleExpand(item.key)}>
                          {isExpanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </TableCell>
                    )}
                    {(!props.selectable || props.selectable === "multiple") && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected(item.key)}
                          onClick={(event) => handleClick(event, item.key)}
                        />
                      </TableCell>
                    )}
                    {props.selectable === "single" && (
                      <TableCell>
                        <Button
                          endIcon={<ArrowForward />}
                          variant="text"
                          title="select"
                          onClick={() =>
                            props.onSelect &&
                            item.data &&
                            props.onSelect(item.data)
                          }
                        >
                          Select
                        </Button>
                      </TableCell>
                    )}
                    {item.element}
                    {actionMenu && actionMenu(item.data)}
                  </TableRow>
                  {rowsExpandable && (
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={numColumns}
                      >
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                          <Box margin={1}>
                            {item.data &&
                              props.expandedContent &&
                              props.expandedContent(item.data)}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </MDTable>
      </TableContainer>
      {props.setOffset && props.setLimit ? (
        <div
          style={{ padding: 12, position: "sticky", top: 0, display: "unset" }}
        >
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={props.limit}
            onChange={handleChangeRowsPerPage}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Pagination
            style={{ float: "right" }}
            count={
              props.total ? Math.ceil(props.total / (props.limit ?? 0)) : 0
            }
            siblingCount={2}
            onChange={handleChangePage}
          />
        </div>
      ) : null}
    </div>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    margin: {
      margin: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  }),
);

interface TableToolbarProps {
  title: string;
  numSelected: number;
  tools?: JSX.Element[] | JSX.Element;
}

const TableToolbar = (props: TableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  if (props.tools === undefined) return null;

  return (
    <Toolbar
      style={{ padding: 12, alignItems: "start" }}
      disableGutters={true}
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Grid container spacing={2}>
        {props.tools}
      </Grid>
    </Toolbar>
  );
};
