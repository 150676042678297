import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  IconButton,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { AddCircleOutline, Edit } from "@material-ui/icons";
import { FormikProvider, useFormik } from "formik";
import { Languages, useAllLanguagesQuery } from "src/generated/asgard/graphql";
import * as Yup from "yup";
import { ClosableDrawer, TableView, TableViewHeader } from "src/Layout";
import {
  FormContainer,
  FormAction,
  TextField,
  Select,
} from "src/components/Form";

export const getLanguageDisplay = (language: DeepPartial<Languages>) => {
  return language.description?.length ? language.description : language.id;
};

// Utility function for getting a property of an object with a string.
function getProperty<T extends object>(obj: T, key: string) {
  const item = Object.entries(obj).find(([k, v]) => k === key);
  return item ? item[1] : null;
}

type SharedProps<TData, TSchema> = {
  labels: { [key in keyof Yup.InferType<TSchema>]: string };
  schema: TSchema;
  onUpdate: (data: TData, values: Yup.InferType<TSchema>) => void;
  onCreate: (languageId: string, values: Yup.InferType<TSchema>) => void;
  getLanguage: (data: TData) => DeepPartial<Languages>;
  getValues: (data: TData) => Yup.InferType<TSchema>;
  initialValues: Yup.InferType<TSchema>;
};

type TranslationTableProps<TData, TSchema> = {
  data: TData[];
  columns: (data: TData) => {
    [key in keyof Yup.InferType<TSchema>]: React.ReactNode;
  };
} & SharedProps<TData, TSchema>;

export function TranslationTable<
  TData extends object,
  TSchema extends Yup.ObjectSchema,
>(props: TranslationTableProps<TData, TSchema>) {
  const { data, columns, ...formProps } = props;
  const [formOpen, setFormOpen] = useState(false);
  const [selected, setSelected] = useState<TData | undefined>(undefined);
  // Gather unused languages from data set.
  const unusedLanguages = useAllLanguagesQuery({
    variables: {
      where: {
        id: {
          _nin: data
            .map(props.getLanguage)
            .filter((l) => !!l.id?.length)
            .map((l) => l.id!),
        },
      },
    },
  });
  return (
    <>
      <TableView>
        <TableViewHeader>
          <Button
            disabled={!unusedLanguages.data?.languages?.length}
            onClick={() => {
              setSelected(undefined);
              setFormOpen(true);
            }}
            variant="contained"
            color="primary"
            disableElevation
            fullWidth={true}
            startIcon={<AddCircleOutline />}
          >
            New Translation
          </Button>
        </TableViewHeader>
        <TableContainer component="div">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Language</TableCell>
                {Object.entries(formProps.labels).map(([k, v]) => (
                  <TableCell key={k}>{`${v}`}</TableCell>
                ))}
                <TableCell style={{ width: 50 }}>
                  {/* Empty cell for edit buttons */}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d, i) => {
                const cols = columns(d);
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {getLanguageDisplay(props.getLanguage(d))}
                    </TableCell>
                    {Object.keys(formProps.labels).map((key) => (
                      <TableCell key={key}>{`${getProperty(
                        cols,
                        key,
                      )}`}</TableCell>
                    ))}
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setSelected(d);
                          setFormOpen(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableView>
      <ClosableDrawer show={formOpen} setShow={setFormOpen}>
        <TranslationTableForm
          data={selected}
          onSuccess={() => setFormOpen(false)}
          languages={unusedLanguages.data?.languages ?? []}
          {...formProps}
        />
      </ClosableDrawer>
    </>
  );
}

type TranslationTableFormProps<TData, TSchema> = {
  data?: TData;
  languages: DeepPartial<Languages>[];
  onSuccess: () => void;
} & SharedProps<TData, TSchema>;

function TranslationTableForm<
  TData extends object,
  TSchema extends Yup.ObjectSchema,
>(props: TranslationTableFormProps<TData, TSchema>) {
  // Add language field to schema.
  const validationSchema = Yup.object({
    language: Yup.object({
      id: Yup.string().required(),
      description: Yup.string(),
    }).required(),
    ...props.schema.fields,
  }).required();
  type Schema = Yup.InferType<typeof validationSchema>;
  const initialValues = useMemo(() => {
    return { language: { id: "", description: "" }, ...props.initialValues };
  }, [props.initialValues]);
  // Set up form.
  const formik = useFormik<Schema>({
    validationSchema,
    initialValues,
    onSubmit: async ({ language, ...values }) => {
      try {
        props.data
          ? props.onUpdate(props.data, values as Yup.InferType<TSchema>)
          : props.onCreate(language.id, values as Yup.InferType<TSchema>);
        props.onSuccess();
      } catch (e) {
        formik.setSubmitting(false);
      }
    },
  });
  // Hydrate form from data if possible.
  const { setValues } = formik;
  useEffect(() => {
    if (props.data) {
      const language = props.getLanguage(props.data);
      setValues({
        language: {
          id: language.id ?? "",
          description: getLanguageDisplay(language),
        },
        ...props.getValues(props.data),
      });
    } else {
      setValues(initialValues);
    }
  }, [props, setValues, initialValues]);
  return (
    <FormikProvider value={formik}>
      <FormContainer>
        {!props.data && (
          <Select fullWidth name="language.id" label="Language" bp={{ xs: 12 }}>
            {props.languages.map((l) => (
              <MenuItem key={l.id} value={l.id}>
                {l.description?.length ? l.description : l.id}
              </MenuItem>
            ))}
          </Select>
        )}
        {Object.entries(props.labels).map(([name, label]) => (
          <TextField
            key={name}
            name={name as string}
            label={label as string}
            bp={{ xs: 12 }}
          />
        ))}
        <FormAction>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Submit
          </Button>
        </FormAction>
      </FormContainer>
    </FormikProvider>
  );
}
